<template>
    <div class="autocomplete">
        <v-autocomplete
            autocomplete="new-password"
            :data-cy="name + '_autocomplete'"
            :items="entries"
            :loading="isLoading"
            :value-comparator="comparatoreValori"
            min-length="2"
            @keydown.enter="selectItem"
            v-bind="$attrs"
            v-on="$listeners"
            :value="value"
            @input="(v) => $emit('input', v)"
        >
            <!-- SLOT EREDITATI -->
            <template
                v-for="(_, scopedSlotName) in $scopedSlots"
                #[scopedSlotName]="slotData"
            >
                <slot :name="scopedSlotName" v-bind="slotData" />
            </template>
            <template v-for="(_, slotName) in $slots" #[slotName]>
                <slot :name="slotName" />
            </template>
        </v-autocomplete>

        <!-- return-object -->
    </div>
</template>
<script>
// docs | vuetify | https://dev.to/onurelibol/creating-custom-components-with-vuetify-inheriting-props-events-slots-in-composition-api-56ef
// docs | vuetify | https://vuetify.cn/en/components/autocompletes/
// docs | LEGGERE | vuetify | IMPORTANTE | extending-vue-component |  https://medium.com/js-dojo/extending-vue-component-templates-1394a7ddc904
// docs | RESET |  https://stackoverflow.com/questions/60540733/vuetify-clear-autocomplete-input-programmatically
export default {
    name: "TeoAutocomplete",
    data: () => ({
        // NOTE: Disabilitata
        selectedItem: null,
        descriptionLimit: 60,
        entries: [],
        isLoading: false,

        items: [],
    }),
    props: {
        name: String,
        value: [String, Number, Object],
        resource: [String, Array],
        // todo testare base url
        "base-url": {
            default: "",
            type: String,
        },
    },
    mounted() {
        if (Array.isArray(this.resource)) {
            this.entries = this.resource;
        }

        if (typeof this.resource === "string") {
            this.isLoading = true;
            const url = `${process.env.VUE_APP_API_URL}${
                this.resource
            }?api_token=${window.sessionStorage.getItem("token")}`;
            fetch(url)
                .then((res) => res.json())
                .then((json) => {
                    // NOTA: formato adattato alla chiamata di teo
                    const { data } = json;
                    this.entries = data;
                    // console.log('Autocomplete informazioni da http',this.resource)
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => (this.isLoading = false));
        }
    },
    methods: {
        comparatoreValori(a, b) {
            return String(a) == String(b);
        },
        selectItem() {
            if (this.selectedItem !== null) {
                this.$refs.autocomplete.selectItem(this.selectedItem);
            }
        },
    },
};
</script>
