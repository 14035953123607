<template>
    <v-container fluid>
        <table-component
            fixed-header
            :id="model.toLowerCase() + '_elenco'"
            :resource="clienti"
            :config="model.toLowerCase() + '-table'"
            :toolbar-title="models"
            :loading="loading"
            loading-text="attendi un attimo"
            @on-edit="onEdit($event)"
            @on-delete="onDelete($event)"
            @on-add-new-item="onAddNewItem($event)"
            :items-per-page="itemsPerPage"
        >
        </table-component>
    </v-container>
</template>
<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
import TableComponent from "../ui/TableComponent.vue";

const model = "Cliente";
const models = "Clienti";

// const options = {
//   // page: number,
//   itemsPerPage: number,
//   // sortBy: string[],
//   // sortDesc: boolean[],
//   // groupBy: string[],
//   // groupDesc: boolean[],
//   // multiSort: boolean,
//   // mustSort: boolean
// }

export default {
    name: "cliente-assicurativo-page",
    components: { TableComponent },
    data() {
        return {
            model: model,
            models: models,
            /** @var selectedItems elementi selezionati della tabella passati tramite un evento */
            selectedItems: [],
        };
    },
    mounted() {
        //  this.$store.dispatch("ACTION_GET_"+models.toLocaleUpperCase());
        this.$store.dispatch("ClienteModule/index");
    },
    methods: {
        onEdit(itemToEdit) {
            this.$router.push("/modifica/" + model + "/" + itemToEdit.id);
        },
        /** ADD_NEW */
        onAddNewItem() {
            this.$router.push("/new/" + model.toLowerCase());
        },
        afterItemCreated() {},
        onAnnulla() {
            this.dialog = false;
        },
        onDelete(evt) {
            // alert("onDelete Cliente "+evt);
            // console.log("onDelete",evt);
            this.delete(evt);
        },
        ...mapActions("ClienteModule", ["delete"]),
    },
    computed: {
        ...mapFields("ClienteModule", [
            "clienti",
            "loading",
            // "errors",
        ]),
        // tableDataset() {
        //   //console.log("tableDataset",this.$store.state[models.toLowerCase()]);
        //   return this.$store.state.[models.toLowerCase()];
        // },
        // loading() {
        //   return this.$store.state.loading;
        // },
    },
};
</script>
