import { Api, errorCallback } from "../../http_tools/axiosModule";
import { getField, updateField } from "vuex-map-fields";
import { router } from "@/main";

Api, errorCallback;
// __frontend/src/store/modules/ClienteModule.js
const state = {
    loading: false,
    cliente: {},
    clienti: [],
    errors: {},
};

const actions = {
    index: ({ commit }) => {
        // DOCS: https://youtu.be/M0xOzWHaoOU?t=1100

        Api.get(process.env.VUE_APP_API_URL + "/api/cliente")
            .then((res) => {
                commit("setClienti", res.data.data);
            })
            .catch((err) => {
                errorCallback(err);
            });
    },
    create: (context) => {
        const { commit } = context;
        commit("setCliente", {});
    },
    show: (context, cliente_id) => {
        const { commit } = context;

        commit("setCliente", {});
        commit("setErrors", {});
        commit("setLoading", true);

        Api.get(process.env.VUE_APP_API_URL + "/api/cliente/" + cliente_id)
            .then((res) => {
                commit("setCliente", res.data.data);
                commit("setLoading", false);
            })
            .catch((error) => {
                // SET ERRORS
                commit("setErrors", {});
                commit("setLoading", false);
                errorCallback(error, "Cliente");
            });
    },

    edit: (context, cliente) => {
        const { commit } = context;
        commit("setCliente", cliente);
        commit("setLoading", true);
        commit("setErrors", {});

        let reqOptions = {
            url: process.env.VUE_APP_API_URL + "/api/cliente/" + cliente.id,
            method: "PUT",
            data: JSON.stringify(cliente),
        };

        Api.request(reqOptions)
            .then((res) => {
                // TODO: fare versione giusta delprodotto nella response
                res;
                commit("setCliente", cliente);
            })
            .catch(function (errors) {
                // console.log('catch', errors.response.data.errors)
                commit("setLoading", false);
                commit("setErrors", errors.response.data.errors);
                errorCallback(errors);
            });
    },

    setNewCliente: ({ commit }, cliente) => {
        let reqOptions = {
            url: process.env.VUE_APP_API_URL + "/api/cliente",
            method: "POST",
            data: JSON.stringify(cliente),
        };

        Api.request(reqOptions)

            .then((response) => {
                const cliente = response.data.data;

                commit("setCliente", cliente);

                router.push("/modifica/cliente/" + cliente.id).catch((err) => {
                    err;
                });
            })
            .catch((error) => {
                console.log(">> errors", error);
                if (error.response) {
                    commit("setLoading", false);
                    commit("setErrors", error.response.data.errors);

                    errorCallback(error);
                } else {
                    alert("errore ACTION_STORE_CLIENTE");
                    console.log("errors ACTION_STORE_CLIENTE", error);
                }
            });
    },
    store: (context, cliente) => {
        const { commit } = context;
        commit("setErrors", {});

        let reqOptions = {
            url: process.env.VUE_APP_API_URL + "/api/cliente",
            method: "POST",
            data: JSON.stringify(cliente),
        };

        Api.request(reqOptions)
            .then((response) => {
                const cliente = response.data.data.model;
                commit("setCliente", cliente);
                commit("setNotification", {
                    message: "cliente inserito",
                    type: "success",
                });
                router.push("/modifica/cliente/" + cliente.id);
            })
            .catch((errors) => {
                // console.log('>> errors', errors)
                // #TODO: ERROR MODULE
                const status = errors.response.status;
                if (status == 422) {
                    commit("setErrors", errors.response.data.errors);
                    commit("setNotification", {
                        message: errors.response.data.message,
                        type: "error",
                    });
                    commit("setLoading", false);
                } else {
                    errorCallback(errors);
                }
            });
        // router.push('/modifica/cliente/')
    },
    annulla: ({ commit }) => {
        commit("setErrors", {});
        commit("setCliente", {});
        router.push("/cliente");
    },
    delete: ({ commit }, cliente) => {
        // console.log("action delete",cliente.id)
        // TODO: Spostare dentro il componente
        const clienti = [...state.clienti.filter((c) => cliente.id !== c.id)];
        Api.delete(process.env.VUE_APP_API_URL + "/api/cliente/" + cliente.id);
        commit("setClienti", clienti);
    },
};

const mutations = {
    updateField,
    setCliente: (state, cliente) => {
        state.cliente = cliente;
        state.loading = false;
    },
    setLoading: (state, loading) => {
        state.loading = loading;
    },
    setNotification: (state, message) => {
        state.message = message;
        state.loading = false;
    },
    setErrors: (state, errors) => {
        state.errors = errors;
        state.loading.false;
    },
    setClienti: (state, elencoClienti) => {
        state.clienti = elencoClienti;
        state.loading = false;
    },
};

const getters = {
    getField,
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
