<template>
    <div data-test="ClienteSearchForm">
        <v-row>
            <v-col>
                <v-text-field
                    @input="debounceFetchItems"
                    v-model="params.filter.cognome_nome"
                    label="Cerca un cliente: Cognome Nome"
                    class="ma-0"
                    cols="12"
                    lg="6"
                    clearable
                ></v-text-field>
                <!-- <div>Trovati {{ items.length }}</div> -->
            </v-col>
            <v-col cols="12" lg="6">
                <v-row>
                    <v-col>
                        <div
                            id="cliente-selezionato"
                            class="d-flex align-end"
                            v-if="clienteSelezionato[0]"
                        >
                            <v-row id="cliente-card">
                                <v-col col="12" md="6">
                                    <h3 class="primary--text ma-0">
                                        {{ clienteSelezionato[0].nome }}
                                        {{ clienteSelezionato[0].cognome }}
                                    </h3>
                                    <div v-if="clienteSelezionatoNonHaVeicoli">
                                        <p>Non ha veicoli inseriti in Teo</p>
                                    </div>
                                    <div
                                        v-if="
                                            clienteSelezionatoHaVeicoli &&
                                            !veicoloSelezionato[0]
                                        "
                                    >
                                        Seleziona un veicolo di uso quotidiano
                                    </div>
                                    <div
                                        v-if="
                                            clienteSelezionatoHaVeicoli &&
                                            veicoloSelezionato[0]
                                        "
                                    >
                                        Procedi alla quotazione del veicolo
                                        <br />
                                        <strong>
                                            {{ veicoloSelezionato[0].marca }}
                                            {{ veicoloSelezionato[0].modello }}
                                            {{ veicoloSelezionato[0].modello }}
                                        </strong>
                                        <br />
                                        Targato
                                        <strong>{{
                                            veicoloSelezionato[0].targa
                                        }}</strong>

                                        <!-- <div id="selezione_tipo_veicolo">
                                            {{ tipologiaVeicolo }}
                                            <MainSelectionForm
                                                v-model="tipologiaVeicolo"
                                            ></MainSelectionForm> 
                                        </div> -->
                                    </div>
                                </v-col>
                                <v-col
                                    cols="12"
                                    md="auto"
                                    class="d-flex align-center justify-center"
                                >
                                    <v-btn
                                        @click="onSearchResultSelected"
                                        v-if="
                                            veicoloSelezionato[0] ||
                                            clienteSelezionatoNonHaVeicoli
                                        "
                                        v-bind="btn_primary_style"
                                    >
                                        Vai Avanti
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </div>

                        <!-- </v-col>

                    <v-col> -->
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" lg="6">
                <v-data-table
                    v-model="clienteSelezionato"
                    :headers="headers"
                    :hide-main-header="items.length == 0"
                    :items="items"
                    :loading="loading"
                    :items-per-page="itemsPerPage"
                    :page="page"
                    :fixed-header="true"
                    :single-select="true"
                    :item-class="getRowClass"
                    v-bind="table_selectable_style"
                    item-key="codice_fiscale"
                    @page="onPageChange"
                    show-select
                    @item-selected="onRowClick"
                >
                    <!-- @click:row="onRowClick" -->
                    <!-- :item-class="getRowClass" -->

                    <!-- <template v-slot:top> </template> -->

                    <template v-slot:body v-if="items.length === 0">
                        <tr>
                            <td colspan="100%" class="pa-5 w-100">
                                <v-alert
                                    v-bind="alert_warning_style"
                                    icon="mdi-account"
                                >
                                    Nessun Cliente Trovato
                                </v-alert>
                                <!-- <v-btn v-bind="btn_primary_style"
                                        >Nuovo Cliente</v-btn
                                    > -->
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-col>
            <v-col v-if="clienteSelezionatoHaVeicoli" cols="12" lg="6">
                <!-- {{ clienteSelezionato[0]?.veicoli_moderni }} -->
                <div id="veicoli_moderni_cliente" v-if="clienteSelezionato[0]">
                    <v-data-table
                        :hide-default-footer="true"
                        :items="
                            uniqBy(
                                orderBy(
                                    clienteSelezionato[0]?.veicoli_moderni,
                                    ['quotazione_id'],
                                    ['desc'],
                                ),
                                'targa',
                            )
                        "
                        v-model="veicoloSelezionato"
                        v-bind="table_selectable_style"
                        item-key="veicolo_id"
                        :headers="[
                            {
                                text: 'id',
                                value: 'veicolo_id',
                            },
                            {
                                text: 'Targa',
                                value: 'targa',
                                class: 'pa-5',
                                align: 'start',
                                width: 100,
                            },
                            {
                                text: 'Marca',
                                value: 'marca',
                                class: 'pa-1',
                                align: 'start',
                                width: 100,
                            },
                            {
                                text: 'Modello',
                                value: 'modello',
                                class: 'pa-1',
                                align: 'start',
                                width: 100,
                            },
                            {
                                text: 'Anno',
                                value: 'anno',
                                class: 'pa-1',
                                align: 'start',
                                width: 100,
                            },
                            {
                                text: 'Rif',
                                value: 'quotazione_id',
                                class: 'pa-1',
                                align: 'start',
                                width: 100,
                            },
                        ]"
                    >
                    </v-data-table>
                    <!-- <pre>
                        {{ veicoloSelezionato }}
                    </pre> -->
                </div>
            </v-col>
        </v-row>

        <!-- Questo servira per fare una ricerca avanzata -->
        <!-- <v-form-base v-if="show" :schema="schema" :model="model"> </v-form-base> -->
    </div>
</template>

<script>
import { Api } from "@/http_tools/axiosModule";
import { DateMixin } from "@/services/DateUtils";
import { JsonToolsMixing } from "@/services/JSONTools";
import { StylesMixin } from "@/style/StyleMixing";
// import axios from "axios";
// import MainSelectionForm from "@/v-form-base/quixa/form/MainSelectionForm.vue";
// import MainSelectionForm from "@/v-form-base/quixa/form/MainSelectionForm.vue";
import { debounce } from "lodash";
// import VFormBase from "vuetify-form-base";

export default {
    name: "ClienteSearchForm",
    mixins: [StylesMixin, JsonToolsMixing, DateMixin],
    props: ["type", "value", "obj", "errors"],
    components: {
        // VFormBase,
    },
    created() {
        this.debounceFetchItems = debounce(this.fetchItems, 1000);
    },
    async mounted() {
        // this.schema = await ClienteSearchFormSchema(this.errors);
        // this.show = true;
        // this.model.typeCode = this.obj.schema.typeCode
        this.debounceFetchItems();
    },
    data() {
        return {
            show: true,
            schema: {},
            veicoloSelezionato: [],
            clienteSelezionato: [],
            tipologiaVeicolo: null,
            cancelToken: null,
            headers: [
                {
                    text: "id",
                    value: "cliente_id",
                    class: "text-no-wrap",
                    // width: "100px",
                    // class?: string | string[],
                    cellClass: ["cell-class-nome", "text-no-wrap"],
                },
                {
                    text: "Nome",
                    value: "nome",
                    class: "text-no-wrap",
                    // width: "100px",
                    // class?: string | string[],
                    cellClass: ["cell-class-nome", "text-no-wrap"],
                },

                {
                    text: "Cognome",
                    value: "cognome",
                    class: "text-no-wrap",
                    // width: "100px",
                },
                {
                    text: "Codice Fiscale",
                    value: "codice_fiscale",
                    // class: "text-no-wrap",
                    // width: "100px",
                },
                {
                    text: "Data di Nascita",
                    value: "data_nascita",
                    cellClass: ["cell-class-data", "text-no-wrap"],
                    // class: "text-no-wrap",
                    // width: "100px",
                },
                {
                    text: "V",
                    value: "veicoli_moderni.length",
                    // class: "text-no-wrap",
                    // width: "100px",
                    sortable: false,
                },
            ],
            items: [],
            loading: false,
            itemsPerPage: 10,
            page: 1,
            params: {
                filter: {
                    // cognome_nome: "Mario",
                    // cognome_nome: "VERONICA",
                    cognome_nome: "",
                    // cognome_nome: "",
                },
            },
        };
    },
    methods: {
        onSearchResultSelected() {
            this.$emit("on-search-result-selected", {
                cliente: this.clienteSelezionato,
                veicolo: this.veicoloSelezionato,
            });
        },
        async fetchItems() {
            this.loading = true;
            this.clienteSelezionato = [];
            this.items = [];
            try {
                // Annulla la richiesta precedente, se esiste
                if (this.cancelToken) {
                    this.cancelToken.cancel(
                        "Annullata la richiesta precedente",
                    );
                }

                // Crea un nuovo token di cancellazione
                // this.cancelToken = axios.CancelToken.source();

                // http://staging-teo.aurumib.it/api/search/cliente?filter[cognome_nome]=B&sort=aa&page=1&per_page=10
                const response = await Api.get(
                    process.env.VUE_APP_API_URL + "/api/search/cliente",
                    {
                        params: this.params,
                        // cancelToken: this.cancelToken.token,
                    },
                );

                this.items = response.data.data;
                this.loading = false;
            } catch (error) {
                this.loading = false;

                // ! TODO: Compilare message bag
                // if (axios.isCancel(error)) {
                //     console.log(
                //         "Richiesta annullata:",
                //         this.param,
                //         error.message
                //     );
                // this.$emit("on-request-cancel");
                // } else {
                //     // this.$emit("on-request-error");
                //     console.error("Errore nella richiesta:", error);
                // }
            }
        },
        onPageChange(page) {
            this.page = page;
            this.debounceFetchItems();
        },
        toggleExpand(item) {
            item.isExpanded = !item.isExpanded;
        },

        // SELEZIONE DELLA RIGA
        onRowClick() {
            // this.clienteSelezionato = item;
            this.veicoloSelezionato = [];
        },
        getRowClass(item) {
            return item === this.clienteSelezionato ? "selected-row" : "";
        },
    },
    computed: {
        clienteSelezionatoHaVeicoli() {
            return this.clienteSelezionato[0]?.veicoli_moderni.length > 0;
        },
        clienteSelezionatoNonHaVeicoli() {
            return this.clienteSelezionato[0]?.veicoli_moderni.length === 0;
        },
        // clienteSelezionato(){
        //     return this.clienteSelezionato[0]
        // },
        model: {
            get() {
                return this.value;
            },
            set(v) {
                this.$emit("input", v);
            },
        },
    },
};

// async function ClienteSearchFormSchema(errors={}) {
//     return {}
// }
</script>

<style>
.table-row {
    border-bottom: 2px solid black;
}

.selected-row {
}

.cell-class-data {
}
td.cell-class-nome {
    width: 1rem !important;
    overflow: hidden;
    /* background-color: azure; */
    padding: 0 0rem !important;
}

.row .col {
    /* outline: 2px dashed red; */
}
</style>
