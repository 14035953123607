export function setSezioneAttiva(state, txt) {
    state.sezioneAttiva = txt;
}

export function setLoading(state, loading) {
    state.loading = loading;
}

export const setAuthenticated = (state, value) => {
    state.authenticated = value;
};

export const setAuthErrors = (state, value) => {
    state.authErrors = value.response?.data.message;
};

export const setUser = (state, response_data) => {
    state.user = JSON.parse(window.sessionStorage.getItem("user"));
    state.permissions = response_data.permissions;
    state.roles = response_data.roles;
    state.env = [
        response_data.verti_api_emissione_mode,
        response_data.verti_api_ania,
        response_data.active_env,
    ];
    window.sessionStorage.setItem("token", response_data.token);
    window.sessionStorage.setItem(
        "permissions",
        JSON.stringify(response_data.permissions),
    );
    window.sessionStorage.setItem("roles", JSON.stringify(response_data.roles));
    window.sessionStorage.setItem("env", JSON.stringify(state.env));
};

export const setNotify = (state, value) => {
    state.notify = value;
};

// COLLABORATORE

export function setCollaboratori(state, collaboratori) {
    state.collaboratori = collaboratori;
}

export function setCollaboratoreAttivo(state, collaboratore) {
    state.collaboratoreAttivo = collaboratore;
}

export function setCollaboratoreFormError(state, errors) {
    state.collaboratoreFormError = errors;
}

// CLIENTI

export function setClienti(state, clienti) {
    state.clienti = clienti;
}

export function setClienteAttivo(state, cliente) {
    state.clienteAttivo = cliente;
}

export function setClienteFormError(state, errors) {
    state.clienteFormError = errors;
}

// setClienteNew
export function setClienteNew(state) {
    state.loading = false;
    state.clienteAttivo = { attivo: false };
    state.sezioneAttiva = "";
    state.formError = {};
    state.touch = false;
}

// polizza
export function setPolizze(state, polizze) {
    state.polizze = polizze;
}

export function setPolizzaAttivo(state, polizza) {
    state.polizzaAttivo = polizza;
}

export function setPolizzaFormError(state, errors) {
    state.polizzaFormError = errors;
}

// compagnia
export function setCompagnie(state, compagnie) {
    state.compagnie = compagnie;
}

export function setCompagniaAttivo(state, compagnia) {
    state.compagniaAttivo = compagnia;
    state.sezioneAttiva = compagnia.ragioneSociale;
    state.formError = {};
    state.touch = false;
}

export function setCompagniaNew(state) {
    state.loading = false;
    state.compagniaAttivo = { attivo: false };
    state.sezioneAttiva = "";
    state.formError = {};
    state.touch = false;
}

export function setFormError(state, errors) {
    console.log("setFormError", errors);
    state.formError = errors;
    state.errorsNumbers = Object.keys(errors).length;
    state.isValid = state.errorsNumbers == 0;
    state.touch = true;
}

export function resetFormError(state) {
    console.log("resetFormError");
    state.formError = {};
    state.errorsNumbers = 0;
    state.isValid = true;
    state.touch = false;
}

// MODALE PRODOTTO ASSICURATIVO

export function setProdottiCompagniaContrattuale(state, data) {
    console.log("setProdottiCompagniaContrattuale", data);
    state.prodottiCompagniaContrattuale = data.prodotti;
    state.prodotto_garanzia = data.prodotto_garanzia;
    state.categoria_prodotto = data.categoria_prodotto;
}

export function setCategorieProdotto(state, data) {
    console.log("setCategorieProdotto - data", data);
    const categoria_mandato_default = {
        percentuale_provvigione_attiva: "0",
        data_inizio_mandato: new Date().toJSON().slice(0, 10),
        mandatabile_type: "App\\Models\\CategoriaProdotto",
        compagnia_fornitore_id: null,
    };

    data.map((categoria_prodotto) => {
        if (categoria_prodotto.mandato == null) {
            categoria_prodotto.mandato = categoria_mandato_default;
        }
    });

    state.categoria_prodotto = data;
    // state.prodottiCompagniaContrattuale = data.prodotti
    // state.prodotto_garanzia = data.prodotto_garanzia
    // state.categoria_prodotto =  data.categoria_prodotto
}

export function setProdottiAssicurativi(state, data) {
    console.log("setProdottiAssicurativi", data);
    state.prodottiAssicurativi = data;
}

export function setProdottoAssicurativoAttivo(state, data) {
    console.log("setProdottoAssicurativoAttivo", data);
    state.prodottoAssicurativoAttivo = data;
}

export function setProdottoAssicurativoFormError(state, data) {
    console.log("setProdottoAssicurativoFormError", data);
    state.prodottoAssicurativoFormError = data;
}

// Autocomplete
export function setFornitoriAutocomplete(state, elenco) {
    console.log("setFornitoriAutocomplete", elenco);
    state.fornitoriAutocomplete = elenco;
}
