<template>
    <div>
        <!-- <pre>
                              simulation      {{  simulation }}
                                </pre> -->
        <v-row v-if="true" :gutter="2">
            <!-- Card 1: Simulation Info -->
            <v-col cols="12" md="4">
                <v-card class="pa-1">
                    <v-card-title>Simulazione</v-card-title>
                    <v-card-subtitle>
                        Tipo di quotazione:
                        {{ simulation?.commonData?.quotationType }}<br />
                        Numero di simulazione:
                        {{ simulation?.commonData?.simulationNumber }}
                    </v-card-subtitle>
                </v-card>
            </v-col>

            <!-- Card 2: Validity Dates -->
            <v-col cols="12" md="4">
                <v-card class="pa-1">
                    <v-card-title>Scadenze</v-card-title>
                    <v-card-subtitle>
                        Data di validità:
                        {{ formatDate(simulation.commonData.validityDate)
                        }}<br />
                        Data fine simulazione:
                        {{
                            formatDate(simulation.commonData.simulationEndDate)
                        }}
                    </v-card-subtitle>
                </v-card>
            </v-col>

            <!-- Card 3: Price Information -->
            <v-col cols="12" md="4">
                <v-card class="pa-1">
                    <v-card-title>Prezzo</v-card-title>
                    <v-card-subtitle>
                        Prezzo totale: € {{ simulation.quotation.totalPrice
                        }}<br />
                        Prezzo ritardato: €
                        {{ simulation.quotation.totalPriceDelay }}
                    </v-card-subtitle>
                </v-card>
            </v-col>
        </v-row>

        <!-- v-model="applicableSelectedCoverage" -->
        <!-- show-select -->
        <v-data-table
        v-if="true"
            :items="simulation.quotation.coverages"
            item-key="code"
            :disable-pagination="true"
            :items-per-page="-1"
            :headers="headers"
            :loading="loading"
        >
            <template v-slot:expanded-item="{ item }">
                <div class="expanded-content">
                    <!-- Add your expanded row content here -->
                    <p>Additional information for {{ item.code }}</p>
                    <!-- <p>Description: {{ item.description }}</p> -->
                    <!-- Add more fields as needed -->
                </div>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { DateMixin } from "@/services/DateUtils.js";
// import { CardStyleMixin } from '@/style/card-style.js'
export default {
    name: "QuotationStatus",
    data() {
        return {
            loading: false,
            headers: [
                {
                    text: "Cod",
                    align: "start",
                    sortable: false,
                    value: "code",
                    width: "1%",
                },
                {
                    text: "Copertura",
                    align: "start",
                    sortable: false,
                    value: "description",
                },
                {
                    text: "Price",
                    value: "price",
                    sortable: false,
                    align: "start",
                },
                {
                    text: "Price Delay",
                    value: "priceDelay",
                    sortable: false,
                    align: "start",
                },
                {
                    text: "Price Blackbox",
                    value: "priceBlackbox",
                    sortable: false,
                    align: "start",
                },
                {
                    text: "Price Blackbox Delay",
                    value: "priceBlackboxDelay",
                    sortable: false,
                    align: "start",
                },
                {
                    text: "Pack Code",
                    value: "packCode",
                    sortable: false,
                    align: "start",
                },
                {
                    text: "Pack Price",
                    value: "packPrice",
                    sortable: false,
                    align: "start",
                },
                {
                    text: "Pack Description",
                    value: "packDescription",
                    sortable: false,
                    align: "start",
                },
            ],
            // applicableSelectedCoverage: [],
        };
    },
    mixins: [
        DateMixin,
        // CardStyleMixin
    ], // Adding date format mixin
    props: {
        simulation: Object,
        // applicableSelectedCoverage: Array,
    },
    methods: {},
};
</script>

<style scoped></style>
