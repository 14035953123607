<template>
    <div :data-test="model.addressType">
        <h6>{{ model.addressType }}</h6>
        <!-- <pre>{{ model }}</pre> -->
        <v-form-base v-if="show" :schema="schema" :model="model"> </v-form-base>
    </div>
</template>

<script>
// import AddressTypeObj from "@/enum/QuiXA/AddressTypeObj";
import { AddressSchema } from "@/v-form-base/form-common";
import VFormBase from "vuetify-form-base";

export default {
    name: "address-form",
    props: ["type", "value", "obj", "errors"],
    components: {
        VFormBase,
    },
    created() {},
    // async mounted() {
    //     this.schema = await AddressSchema(this.errors);
    //     this.show = true;
    //     // this.model.typeCode = this.obj.schema.typeCode
    // },

    data() {
        return {
            show: true,
            schema: {},
        };
    },
    methods: {},
    computed: {
        // async schema() {
        //     return await AddressSchema(this.errors);
        // },
        model: {
            get() {
                return this.value;
            },
            set(v) {
                this.$emit("input", v);
            },
        },
    },
    watch: {
        errors: {
            immediate: true, // Esegui subito anche la prima volta
            handler: async function (newErrors) {
                this.schema = await AddressSchema(newErrors); // Aggiorna schema con i nuovi errors
                // this.show = true;
            },
        },
    },
};
</script>

<style></style>
