var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.compagnia_contrattuale)?_c('section',{staticClass:"compagnia_contrattuale"},[_c('v-row',{staticClass:"pt-0 d-flex align-end"},[_c('v-col',{staticClass:"m-0 p-0",staticStyle:{"display":"none"},attrs:{"cols":"12","sm":"12"}},[_c('v-checkbox',{attrs:{"label":"Attiva","id":"attivo","dense":"","data-cy":"attivo","color":"primary","error-messages":_vm.errors.attivo,"error":_vm.errors.attivo && _vm.errors.attivo.length > 0},model:{value:(_vm.compagnia_contrattuale.attivo),callback:function ($$v) {_vm.$set(_vm.compagnia_contrattuale, "attivo", $$v)},expression:"compagnia_contrattuale.attivo"}})],1),_c('v-col',{staticClass:"m-0 p-0",attrs:{"cols":"12","sm":"12"}},[_c('v-text-field',{attrs:{"id":"nome","label":"Nome","data-cy":"nome","dense":"","type":"text","error-messages":_vm.errors.nome,"error":_vm.errors.nome && _vm.errors.nome.length > 0},model:{value:(_vm.compagnia_contrattuale.nome),callback:function ($$v) {_vm.$set(_vm.compagnia_contrattuale, "nome", $$v)},expression:"compagnia_contrattuale.nome"}})],1),_c('v-col',{staticClass:"m-0 p-0",attrs:{"cols":"12","sm":"12"}},[_c('v-checkbox',{attrs:{"label":"Visibile Allegato 4","id":"visibile_allegato_4","dense":"","data-cy":"visibile_allegato_4","color":"primary","error-messages":_vm.errors.visibile_allegato_4,"error":_vm.errors.visibile_allegato_4 &&
                    _vm.errors.visibile_allegato_4.length > 0},model:{value:(_vm.compagnia_contrattuale.visibile_allegato_4),callback:function ($$v) {_vm.$set(_vm.compagnia_contrattuale, "visibile_allegato_4", $$v)},expression:"compagnia_contrattuale.visibile_allegato_4"}})],1),_c('v-col',{staticClass:"m-0 p-0",attrs:{"cols":"12","sm":"12"}},[_c('v-checkbox',{attrs:{"label":"Esiste Ratifica","id":"esiste_ratifica","dense":"","data-cy":"esiste_ratifica","color":"primary","error-messages":_vm.errors.esiste_ratifica,"error":_vm.errors.esiste_ratifica &&
                    _vm.errors.esiste_ratifica.length > 0},model:{value:(_vm.compagnia_contrattuale.esiste_ratifica),callback:function ($$v) {_vm.$set(_vm.compagnia_contrattuale, "esiste_ratifica", $$v)},expression:"compagnia_contrattuale.esiste_ratifica"}})],1),_c('v-col',{staticClass:"m-0 p-0",attrs:{"cols":"12","sm":"12"}},[_c('v-checkbox',{attrs:{"label":"Associato Preventivatore Rca","id":"associato_preventivatore_rca","dense":"","data-cy":"associato_preventivatore_rca","color":"primary","error-messages":_vm.errors.associato_preventivatore_rca,"error":_vm.errors.associato_preventivatore_rca &&
                    _vm.errors.associato_preventivatore_rca.length > 0},model:{value:(
                    _vm.compagnia_contrattuale.associato_preventivatore_rca
                ),callback:function ($$v) {_vm.$set(_vm.compagnia_contrattuale, "associato_preventivatore_rca", $$v)},expression:"\n                    compagnia_contrattuale.associato_preventivatore_rca\n                "}})],1),_c('v-col',{staticClass:"m-0 p-0",attrs:{"cols":"12","sm":"12"}},[_c('v-checkbox',{attrs:{"label":"Effettua Versamenti Netto Provvigioni","id":"effettua_versamenti_netto_provvigioni","dense":"","data-cy":"effettua_versamenti_netto_provvigioni","color":"primary","error-messages":_vm.errors.effettua_versamenti_netto_provvigioni,"error":_vm.errors.effettua_versamenti_netto_provvigioni &&
                    _vm.errors.effettua_versamenti_netto_provvigioni.length > 0},model:{value:(
                    _vm.compagnia_contrattuale.effettua_versamenti_netto_provvigioni
                ),callback:function ($$v) {_vm.$set(_vm.compagnia_contrattuale, "effettua_versamenti_netto_provvigioni", $$v)},expression:"\n                    compagnia_contrattuale.effettua_versamenti_netto_provvigioni\n                "}})],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }