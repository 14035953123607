// import queryString from "query-string";

export default {
    namespaced: true,

    state: {
        model_id: null,
    },

    mutations: {
        setModelId(state, modelId) {
            state.model_id = modelId;
        },
    },

    actions: {},

    getters: {
        model_id: (state) => state.model_id,
        getMode: (state, getters, rootState) => {
            const currentRouteName = rootState.router.currentRoute.name; // Ottieni il nome della route attuale
            if (currentRouteName && currentRouteName.includes("_edit")) {
                return "EDIT_MODE"; // Restituisce EDIT_MODE se il nome della route contiene _edit
            } else if (
                currentRouteName &&
                currentRouteName.includes("_store")
            ) {
                return "STORE_MODE"; // Restituisce STORE_MODE se il nome della route contiene _store
            } else if (
                currentRouteName &&
                currentRouteName.includes("index_")
            ) {
                return "INDEX_MODE"; // Restituisce INDEX_MODE se il nome della route contiene index_
            } else {
                return null; // Altrimenti restituisce null
            }
        },
    },
};
