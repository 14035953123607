import Vue from "vue";

// Funzione per la formattazione delle valute in Euro (stile italiano con virgola)
export function formatCurrency(value) {
    if (typeof value !== "number") {
        return value;
    }
    var formatter = new Intl.NumberFormat("it-IT", {
        style: "currency",
        currency: "EUR",
    });
    return formatter.format(value);
}

export const CurrencyMixin = {
    methods: {
        currency(value) {
            return formatCurrency(value);
        },

        // formatValuesToCurrency(values) {
        //   return values.map(value => ({
        //     label: formatCurrency(value),
        //     value: String(value)
        //   }));
        // }
    },
};

// Filtro Vue.js per formattare i valori in Euro
Vue.filter("currency", function (value) {
    return formatCurrency(value);
});
