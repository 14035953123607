import { Api, errorCallback } from "../../http_tools/axiosModule";
import { getField, updateField } from "vuex-map-fields";
// import { router } from "@/main";

Api, errorCallback;

const state = {
    loading: false,
    comuni: [],
};

const actions = {
    index: ({ commit }) => {
        Api.get(process.env.VUE_APP_API_URL + "/api/autocomplete/comune")
            .then((res) => {
                commit("setComuni", res.data.data);
            })
            .catch((err) => {
                errorCallback(err);
            });
    },
};

const mutations = {
    updateField,
    // setCliente: (state, cliente) => {
    //     state.cliente = cliente
    //     state.loading = false
    // },
    setComuni: (state, comuni) => {
        state.comuni = comuni;
        state.loading = false;
    },
};

const getters = {
    getField,
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

let cachedComuni = null;
export async function getComuni() {
    if (cachedComuni) {
        return cachedComuni;
    }

    try {
        const res = await Api.get(
            process.env.VUE_APP_API_URL + "/api/autocomplete/comune",
        );
        cachedComuni = res.data.data; // Memorizza i dati nella cache
        return cachedComuni;
    } catch (err) {
        console.error(err);
        throw err;
    }
}
