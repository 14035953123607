var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{attrs:{"id":"CollaboratoreDetails"}},[_c('v-tabs',{attrs:{"align-with-title":""},model:{value:(_vm.collaboratore_tabs),callback:function ($$v) {_vm.collaboratore_tabs=$$v},expression:"collaboratore_tabs"}},[_c('v-tabs-slider',{attrs:{"color":"white"}}),_c('v-tab',[_vm._v(" Dati Del collaboratore ")]),(_vm.collaboratore.id)?_c('v-tab',[_vm._v(" Clienti ")]):_vm._e(),(_vm.collaboratore.id)?_c('v-tab',[_vm._v(" Provvigioni ")]):_vm._e(),(_vm.collaboratore.id)?_c('v-tab',[_vm._v(" Prodotti Assegnati ")]):_vm._e()],1),_c('v-tabs-items',{model:{value:(_vm.collaboratore_tabs),callback:function ($$v) {_vm.collaboratore_tabs=$$v},expression:"collaboratore_tabs"}},[_c('v-tab-item',[_c('CollaboratoreForm')],1),_c('v-tab-item',[(_vm.collaboratore.id)?_c('table-component',{key:'cc-' + _vm.collaboratore.id,attrs:{"id":"clienti_collaboratore","resource":_vm.collaboratore.clienti,"config":"collaboratore-prodotti-table","toolbar-title":"Clienti del collaboratore","items-per-page":_vm.itemsPerPage},on:{"on-edit":function($event){return _vm.onClienteCollaboratoreEdit($event)},"on-delete":function($event){return _vm.onClienteCollaboratoreDelete($event)},"on-add-new-item":function($event){return _vm.onAssegnaClienteACollaboratore($event)}},scopedSlots:_vm._u([{key:`item.pivot.perc_provvigione_passiva`,fn:function({
                        item,
                    }){return [_c('v-edit-dialog',{attrs:{"return-value":item.pivot.perc_provvigione_passiva,"large":""},on:{"update:returnValue":function($event){return _vm.$set(item.pivot, "perc_provvigione_passiva", $event)},"update:return-value":function($event){return _vm.$set(item.pivot, "perc_provvigione_passiva", $event)},"save":function($event){return _vm.update(item)},"cancel":function($event){return _vm.cancel(item)},"open":function($event){return _vm.open(item)},"close":function($event){return _vm.close(item)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',[_vm._v(" Il valore verrà applicato a tutti i collaboratori ")]),_c('v-text-field',{attrs:{"type":"number","label":"Modifica la percentuale per tutti i collaboratori","single-line":""},model:{value:(
                                    item.perc_provvigione_passiva
                                ),callback:function ($$v) {_vm.$set(item, "perc_provvigione_passiva", _vm._n($$v))},expression:"\n                                    item.perc_provvigione_passiva\n                                "}})]},proxy:true}],null,true)},[_c('span',{staticClass:"text-uppercase editable text-primary"},[_c('v-icon',{staticClass:"editable__ico",attrs:{"small":"","primary":""}},[_vm._v(" mdi-pencil ")]),_c('span',{class:{
                                    'red--text':
                                        item.perc_provvigione_passiva ==
                                        '0.00',
                                }},[_vm._v(" "+_vm._s(item.perc_provvigione_passiva)+" % ")]),_c('span',{staticClass:"tipo-provvigione-passiva-text percentuale-text"},[(
                                        item.pivot
                                            .perc_provvigione_passiva ===
                                        'PERC_RETROCESSIONE'
                                    )?_c('span',{staticClass:"label"},[_vm._v("retrocessione")]):_vm._e(),(
                                        item.pivot
                                            .perc_provvigione_passiva ===
                                        'PERC_IMPONIBILE'
                                    )?_c('span',{staticClass:"label"},[_vm._v("imponibile")]):_vm._e()])],1)])]}}],null,true)}):_vm._e()],1),_c('v-tab-item',[_vm._v(" provvigioni "),(false)?_c('table-component',{key:'cr-' + _vm.collaboratore.id,attrs:{"id":"provvigioni_collaboratore","resource":_vm.collaboratore.prodotti,"config":"collaboratore-prodotti-table","toolbar-title":"Provvigioni","items-per-page":_vm.itemsPerPage},on:{"on-edit":function($event){return _vm.onEdit($event)},"on-delete":function($event){return _vm.onDelete($event)},"on-add-new-item":function($event){return _vm.onAddNewItem($event)}},scopedSlots:_vm._u([{key:`item.pivot.perc_provvigione_passiva`,fn:function({
                        item,
                    }){return [_c('v-edit-dialog',{attrs:{"return-value":item.pivot.perc_provvigione_passiva,"large":""},on:{"update:returnValue":function($event){return _vm.$set(item.pivot, "perc_provvigione_passiva", $event)},"update:return-value":function($event){return _vm.$set(item.pivot, "perc_provvigione_passiva", $event)},"save":function($event){return _vm.update(item)},"cancel":function($event){return _vm.cancel(item)},"open":function($event){return _vm.open(item)},"close":function($event){return _vm.close(item)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',[_vm._v(" Il valore verrà applicato a tutti i collaboratori ")]),_c('v-text-field',{attrs:{"type":"number","label":"Modifica la percentuale per tutti i collaboratori","single-line":""},model:{value:(
                                    item.perc_provvigione_passiva
                                ),callback:function ($$v) {_vm.$set(item, "perc_provvigione_passiva", _vm._n($$v))},expression:"\n                                    item.perc_provvigione_passiva\n                                "}})]},proxy:true}],null,true)},[_c('span',{staticClass:"text-uppercase editable text-primary"},[_c('v-icon',{staticClass:"editable__ico",attrs:{"small":"","primary":""}},[_vm._v(" mdi-pencil ")]),_c('span',{class:{
                                    'red--text':
                                        item.perc_provvigione_passiva ==
                                        '0.00',
                                }},[_vm._v(" "+_vm._s(item.perc_provvigione_passiva)+" % ")]),_c('span',{staticClass:"tipo-provvigione-passiva-text percentuale-text"},[(
                                        item.pivot
                                            .perc_provvigione_passiva ===
                                        'PERC_RETROCESSIONE'
                                    )?_c('span',{staticClass:"label"},[_vm._v("retrocessione")]):_vm._e(),(
                                        item.pivot
                                            .perc_provvigione_passiva ===
                                        'PERC_IMPONIBILE'
                                    )?_c('span',{staticClass:"label"},[_vm._v("imponibile")]):_vm._e()])],1)])]}}],null,true)}):_vm._e()],1),_c('v-tab-item',[_vm._v(" prodotto "),(false)?_c('prodotto-assicurativo-tab',{key:'pa-' + _vm.collaboratore.id,attrs:{"resource":`/prodotto_assicurativo_search?filters[]=collaboratoreFornitore.id=${_vm.collaboratore.id}`,"config":"collaboratore-fornitore-prodotti-table"}}):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }