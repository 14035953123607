<template>
    <div class="ProvvigioniSettoriForm">
        <!--     
    <pre>
          value:  {{provvigioni}}
        </pre>  -->

        <!-- DOCS: https://codesandbox.io/s/vmj3r80nxy?file=/src/App.vue -->
        <!-- DOCS: https://stackoverflow.com/questions/48280990/using-custom-theming-in-vuetify-and-pass-color-variables-to-components -->
        <!-- DOCS: https://www.smashingmagazine.com/2017/08/creating-custom-inputs-vue-js/ -->
        <!-- DOCS: https://dev.to/viniciuskneves/vue-custom-input-bk8 -->
        <v-row :key="id + settore.nome" v-for="settore in provvigioni">
            <!-- 
        <v-col cols="2" class="provvigione_settori__header">
        <label class="settore__label font-weight-black">{{
          settore.nome
        }}</label>
      </v-col> 
      -->

            <v-col cols="4" class="p-0">
                <v-text-field
                    :label="settore.nome"
                    v-model="settore.perc_provvigione"
                    class="text-left"
                    placeholder="% provvigione"
                    color="primary"
                    type="number"
                    min="0"
                    max="100"
                    :value="value"
                    @input="$emit('input', provvigioni)"
                >
                </v-text-field>
            </v-col>
            <v-col cols="8" class="p-0 px-2">
                <!-- :hint="`${select.state}, ${select.abbr}`" -->
                <v-select
                    v-model="settore.tipo_provvigione"
                    :items="items"
                    item-text="nome"
                    item-value="value"
                    label="Tipo di provvigione"
                    persistent-hint
                    :value="value"
                    @input="$emit('input', provvigioni)"
                    color="primary"
                    single-line
                >
                </v-select>
                <!-- return-object -->
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: "provvigioni-settori-form",
    model: {
        prop: "value",
        event: "input",
    },
    props: {
        id: {
            type: String,
            required: true,
        },
        value: {
            type: Array,
            required: false,
            default: function () {
                return this.predefinito;
            },
        },
    },
    data() {
        return {
            items: [
                {
                    nome: "Imponibile",
                    value: "PERC_IMPONIBILE",
                },
                {
                    nome: "Retrocessione",
                    value: "PERC_RETROCESSIONE",
                },
            ],
            predefinito: [
                {
                    id: 1,
                    nome: "danni",
                    perc_provvigione: "",
                    tipo_provvigione: "PERC_IMPONIBILE",
                },
                {
                    id: 2,
                    nome: "rca",
                    perc_provvigione: "",
                    tipo_provvigione: "PERC_IMPONIBILE",
                },
                {
                    id: 3,
                    nome: "vita",
                    perc_provvigione: "",
                    tipo_provvigione: "PERC_IMPONIBILE",
                },
            ],
        };
    },
    computed: {
        provvigioni() {
            if (this.value == undefined || this.value.length == 0) {
                return this.predefinito;
            }
            return this.predefinito.map((predefinito) => {
                const valore = this.value.find((v) => v.id == predefinito.id);

                // console.log(valore ? valore : 'predefinito-')
                if (valore) {
                    return valore;
                } else {
                    return predefinito;
                }
            });
        },
    },
};
</script>

<style scoped>
.v-text-field--outlined fieldset {
    color: var(--v-primary-base);
    border-color: var(--v-accent-lighten2);
}

.provvigione_settori__header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: nowrap;
}
</style>
