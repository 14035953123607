export const TAB_KEYS = {
    QUOTAZIONE_RCA: "tab-quotazione-rca",
    QUOTAZIONE_VELOCE: "fastQuotationForm",
    BENE_ASSICURARE: "tab-bene-assicurare",
    CONTRAENTE: "tab-contraente",
    GARANZIE: "tab-garanzie",
    DOCUMENTI: "tab-documenti",
    PREVIEW: "tab-preview",
};

export const mixinRcaTabs = {
    data() {
        return {
            tabKeys: TAB_KEYS,
        };
    },
    methods: {
        setActiveTab(tabKey) {
            if (Object.values(this.tabKeys).includes(tabKey)) {
                this.ui.tab = tabKey;
            }
        },
    },
};
