<template>
    <div data-test="LayoutFromJson">
        <h6>Riepilogo</h6>
        <pre>{{ model }}</pre>
        <v-form-base :schema="schema" :model="model"> </v-form-base>
    </div>
</template>

<script>
import { StylesMixin } from "@/style/StyleMixing";
import VFormBase from "vuetify-form-base";
// # Da usare per gli schemi da utilizzare
// import {MainSelectionFormSchema} from "@/v-form-base/form-common"

export default {
    name: "LayoutFromJson",
    mixing: [StylesMixin],
    props: {
        type: String,
        value: {
            type: Object,
            default: () => ({}),
        },
        obj: Object,
        errors: Array,
    },
    components: {
        VFormBase,
    },
    created() {},
    mounted() {
        this.schema = this.LayoutFromJsonSchema();
        console.log(this.schema);
    },
    data() {
        return {
            schema: {},
        };
    },
    methods: {
        LayoutFromJsonSchema() {
            const formSchema = {
                commonData: {
                    type: "group",
                    label: "Dati Comuni",
                    col: 12,
                    children: {
                        simulationNumber: {
                            type: "text",
                            label: "Numero Simulazione",
                            disabled: true,
                            col: 2,
                        },
                        quotationType: {
                            type: "text",
                            label: "Tipo di Quotation",
                            disabled: true,
                            col: 2,
                        },
                        simulationEndDate: {
                            type: "text",
                            label: "Data Fine Simulazione",
                            disabled: true,
                            col: 2,
                        },
                        validityDate: {
                            type: "text",
                            label: "Data di Validità",
                            disabled: true,
                            col: 2,
                        },
                    },
                },
                quotation: {
                    type: "group",
                    label: "Dettagli Quotation",
                    col: 12,
                    children: {
                        quotationStatus: {
                            type: "text",
                            label: "Stato Quotation",
                            disabled: true,
                            col: 2,
                        },
                        totalPrice: {
                            type: "text",
                            label: "Prezzo Totale",
                            disabled: true,
                            col: 2,
                        },
                        totalPriceDelay: {
                            type: "text",
                            label: "Prezzo Totale con Ritardo",
                            disabled: true,
                            col: 2,
                            class: "no-border no-animation",
                        },
                        coverages: {
                            type: "array",
                            label: "Coperture",
                            col: 12,
                            items: {
                                type: "group",
                                children: {
                                    code: {
                                        type: "text",
                                        label: "Codice",
                                        disabled: true,
                                        col: 2,
                                    },
                                    description: {
                                        type: "text",
                                        label: "Descrizione",
                                        disabled: true,
                                        col: 6,
                                    },
                                    selected: {
                                        type: "switch",
                                        label: "Selezionato",
                                        trueValue: true,
                                        falseValue: false,
                                        col: 2,
                                    },
                                },
                            },
                        },
                    },
                },
                fastFlowData: {
                    type: "group",
                    label: "Dati Polizza",
                    col: 12,
                    children: {
                        effectDate: {
                            type: "text",
                            label: "Data di Effetto",
                            col: 2,
                        },
                        bonusMalus: {
                            type: "number",
                            label: "Bonus Malus",
                            col: 2,
                        },
                        insuranceSituation: {
                            type: "select",
                            label: "Situazione Assicurativa",
                            items: [
                                "AlreadyInsuredATR",
                                "NewCustomer",
                                "Other",
                            ],
                            col: 2,
                        },
                        personData: {
                            type: "array",
                            label: "Contraenti",
                            col: 12,
                            items: {
                                type: "group",
                                children: {
                                    name: {
                                        type: "text",
                                        label: "Nome",
                                        col: 6,
                                    },
                                    surname: {
                                        type: "text",
                                        label: "Cognome",
                                        col: 6,
                                    },
                                    gender: {
                                        type: "select",
                                        label: "Genere",
                                        items: ["Male", "Female"],
                                        col: 6,
                                    },
                                },
                            },
                        },
                    },
                },
            };

            return formSchema;
        },
    },
    computed: {
        model: {
            get() {
                return this.value || {};
            },
            set(v) {
                this.$emit("input", v);
            },
        },
    },
};
</script>

<style>
/* Rimuovi animazioni */
.no-animation input {
    transition: none !important;
}

/* Rimuovi bordi */
.no-border .v-input {
    border: none !important;
    box-shadow: none !important;
}

/* Personalizzazione per stato disabilitato */
.no-border .v-input.v-input--is-disabled {
    background-color: transparent !important;
}
</style>
