<template>
    <v-container fluid>
        <v-alert text :type="alert.type" v-if="alert.type">
            <ul v-if="this.errors.length > 0">
                <li v-for="(error, index) in this.errors" :key="index">
                    {{ error }}
                </li>
            </ul>
            {{ alert.message }}
        </v-alert>
        <v-card-title class="pa-0">
            <div id="header-info-cliente">
                <span class="nome pr-3" v-if="quotazione?.nome"
                    >Cliente {{ quotazione.nome }}
                </span>
                <span class="pr-3 cognome" v-if="quotazione.cognome"
                    >{{ quotazione.cognome }}
                </span>
            </div>
        </v-card-title>
        <section class="h6">
            <div class="text-overline">
                collaboratore {{ quotazione?.collaboratore?.nomeCognome }}
            </div>

            <span
                class="policy-number"
                v-if="quotazione?.polizza?.verti_policy_number"
            >
                polizza N°
                <b color="primary">{{
                    quotazione?.polizza?.verti_policy_number
                }}</b></span
            >

            <span class="infodrive-id" v-if="quotazione?.polizza?.infodrive_id">
                Infodrive N°
                <b>{{ quotazione?.polizza?.infodrive_id }}</b></span
            >
            <span class="quotazione-id">
                Quotazione ID <b>{{ quotazione.id }}</b>
            </span>
        </section>
        <section class="h6">
            <span class="quotazione-id" v-if="quotazione?.rinnovo">
                Questa polizza ha un rinnovo

                {{ quotazione?.rinnovo?.id }}
                <!-- 
        Leggi per impostare aggiornamento della pagina quando si preme il bottone
        https://chatgpt.com/c/3736dfd2-bdaf-430f-a60e-6e9aa5c34077 
        -->
            </span>

            <span id="box-bottone-rinnovo">
                <v-btn
                    v-if="
                        quotazione.stato_quotazione.cod > 8 &&
                        quotazione.stato_quotazione_id != 11 &&
                        !quotazione?.rinnovo?.id
                    "
                    color="danger"
                    dark
                    class="my-2"
                    @click="rinnovoPolizza"
                    v-bind="BtnSmall"
                >
                    Rinnova Polizza
                </v-btn>
            </span>
        </section>

        <!-- BOX RINNOVO DELLA POLIZZA -->
        <!-- RINNOVO CARD ------------------------------- -->
        <div class="" v-if="quotazione?.rinnovo_della_polizza?.numero_polizza">
            <b>
                Rinnovo della Polizza N°
                {{ quotazione.rinnovo_della_polizza.numero_polizza }} con
                Quotazione ID
                {{ quotazione.rinnovo_della_polizza.quotazione_id }}
            </b>
        </div>

        <div
            id="box-info-quotazione-precedente"
            v-if="quotazione?.rinnovo_della_polizza?.numero_polizza"
        >
            <span class="premio">
                premio {{ quotazione.rinnovo_della_polizza?.premio }}
            </span>
            <!-- 
      <span class="premio_verti">
        premio_verti {{ quotazione.rinnovo_della_polizza?.premio_verti }}
      </span>
      -->
            <span class="premio_forzato">
                premio_forzato
                {{ quotazione.rinnovo_della_polizza?.premio_forzato }}
            </span>
            <span class="competenze">
                competenze {{ quotazione.rinnovo_della_polizza?.competenze }}
            </span>
        </div>
        <!-- </v-card> -->
        <!-- RINNOVO CARD ------------------------------- -->

        <div class="row pa-4">
            <v-alert
                dense
                text
                v-if="response"
                autocomplete="new-password"
                :type="response.type"
            >
                {{ response.data.message ?? "nessun messaggio" }}
            </v-alert>
        </div>
        <v-navigation-drawer
            v-model="rightSideDrawer"
            fixed
            hide-overlay
            right
            temporary
            :style="{ height: '100vh', width: '33%' }"
        >
            <h4>Gross Premium Verti</h4>
            <h3
                v-if="payloadPreview.data && payloadPreview.data.Quotation"
                class="mx-3"
            >
                € {{ payloadPreview?.data?.Quotation[0].grossPremium }}
            </h3>

            <v-divider></v-divider>
            <v-list dense>
                <pre>{{ payloadPreview.data }}</pre>
            </v-list>
            <v-list-item v-if="showEmettiBtn">
                <v-list-item-content class="px-3">
                    <v-dialog
                        data-cy="dialogEmetti"
                        v-model="dialogEmetti"
                        max-width="600px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <date-picker
                                label="Data di Decorrenza"
                                data-cy="quotazione.decorrenza:Date"
                                v-model="quotazione.decorrenza"
                                value-type="YYYY-MM-DD"
                                format="DD/MM/YYYY"
                            ></date-picker>

                            <v-btn
                                v-if="
                                    hasPermission(
                                        'emettere_polizza_auto_storica',
                                    )
                                "
                                data-cy="btn_conferma_quotazione_open_dialogEmetti"
                                small
                                color="danger"
                                dark
                                class="mb-2"
                                v-bind="attrs"
                                v-on="on"
                            >
                                Emetti
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-title class="text-h5"
                                >Sicuro di voler emettere la
                                polizza?</v-card-title
                            >
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn small color="blue darken-1" text
                                    >Cancel</v-btn
                                >
                                <v-btn
                                    small
                                    color="blue darken-1"
                                    text
                                    @click="emettiPolizza"
                                    >OK</v-btn
                                >
                                <!-- <v-btn small color="blue darken-1" text @click="emettiPolizzaForzato">OK</v-btn> -->
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-content>
                    <v-btn
                        class="ma-2"
                        color="primary"
                        small
                        @click.stop="rightSideDrawer = !rightSideDrawer"
                    >
                        Chiudi
                    </v-btn>
                </v-list-item-content>
            </v-list-item>
        </v-navigation-drawer>
        <!-- DRAWER RIGHT SIDE FINE -->
        <!-- {{ quotazione.stato_quotazione_id }} {{   [9,10,11].includes(quotazione.stato_quotazione_id) }} && {{ roleIs('super_admin')  }} -->
        <v-tabs>
            <v-tab> Raccolta dati </v-tab>

            <v-tab
                v-if="
                    quotazione.stato_quotazione.cod > 2 ||
                    quotazione.stato_quotazione.cod == 1.5
                "
            >
                Documenti
            </v-tab>

            <v-tab v-if="showTabsAppendici"> Inclusione </v-tab>
            <v-tab v-if="showTabsAppendici"> Esclusione </v-tab>
            <v-tab-item>
                <div class="row pa-4">
                    <div class="col-9">
                        <v-card class="">
                            <v-card-text>
                                <section class="fieldset">
                                    <div class="fieldset__content row">
                                        <v-card-title
                                            >Dati anagrafici</v-card-title
                                        >

                                        <!-- Sesso Field -->
                                        <div
                                            id="Sesso"
                                            v-if="false"
                                            class="form-group col-md-2 py-0 px-1 mb-3"
                                        >
                                            <label for="sesso">Sesso:</label>
                                            <select
                                                class="form-control-sm form-control custom-select"
                                                id="sesso"
                                                v-model="quotazione.sesso"
                                            >
                                                <option value="M">
                                                    Maschio
                                                </option>
                                                <option value="F">
                                                    Femmina
                                                </option>
                                                <option value="A">
                                                    Azienda
                                                </option>
                                            </select>
                                            {{ quotazione.sesso }}
                                            <invalid-feedback
                                                :errors="errors.sesso"
                                            />
                                        </div>

                                        <div
                                            id="nome"
                                            v-if="quotazione.sesso !== 'A'"
                                            class="form-group col-md-4 py-0 px-1 mb-3"
                                        >
                                            <label for="nome">Nome:</label>

                                            <input
                                                class="form-control-sm form-control"
                                                autocomplete="new-password"
                                                type="text"
                                                id="nome"
                                                data-cy="quotazione.nome"
                                                v-model="quotazione.nome"
                                            />

                                            <invalid-feedback
                                                :errors="errors?.nome"
                                            />
                                        </div>

                                        <div
                                            id="cognome"
                                            class="form-group col-md-4 py-0 px-1 mb-3"
                                            :class="
                                                quotazione.sesso == 'A'
                                                    ? 'col-md-8'
                                                    : 'col-md-4'
                                            "
                                        >
                                            <label
                                                v-if="quotazione.sesso != 'A'"
                                                for="cognome"
                                                >Cognome:</label
                                            >
                                            <label
                                                v-if="quotazione.sesso == 'A'"
                                                for="ragioneSociale"
                                                >Ragione Sociale:</label
                                            >

                                            <input
                                                class="form-control-sm form-control"
                                                autocomplete="new-password"
                                                type="text"
                                                data-cy="quotazione.cognome"
                                                id="cognome"
                                                v-model="quotazione.cognome"
                                            />

                                            <invalid-feedback
                                                :errors="errors.cognome"
                                            />
                                        </div>

                                        <div
                                            class="form-group col-md-4 py-0 px-1 mb-3"
                                        >
                                            <label for="email">Email:</label>

                                            <input
                                                class="form-control-sm form-control"
                                                autocomplete="new-password"
                                                type="email"
                                                data-cy="quotazione.email"
                                                v-model="quotazione.email"
                                                name="email"
                                            />

                                            <invalid-feedback
                                                :errors="errors.email"
                                            />
                                        </div>

                                        <div
                                            class="form-group col-md-4 py-0 px-1 mb-3"
                                            v-if="
                                                quotazione.stato_quotazione
                                                    .cod > 4
                                            "
                                        >
                                            <label for="cfisc"
                                                >Codice Fiscale:</label
                                            >

                                            <input
                                                class="form-control-sm form-control"
                                                autocomplete="new-password"
                                                type="text"
                                                data-cy="quotazione.cfisc"
                                                v-model="quotazione.cfisc"
                                                name="cfisc"
                                            />

                                            <invalid-feedback
                                                :errors="errors.cfisc"
                                            />
                                        </div>

                                        <div
                                            class="form-group col-md-4 py-0 px-1 mb-3"
                                            v-if="
                                                quotazione.stato_quotazione
                                                    .cod > 4
                                            "
                                        >
                                            <label for="telefono"
                                                >Telefono:</label
                                            >

                                            <input
                                                class="form-control-sm form-control"
                                                autocomplete="new-password"
                                                type="telefono"
                                                data-cy="quotazione.telefono"
                                                v-model="quotazione.telefono"
                                                name="telefono"
                                            />

                                            <invalid-feedback
                                                :errors="errors.telefono"
                                            />
                                        </div>
                                    </div>

                                    <div class="fieldset__content row">
                                        <v-card-title>Residenza</v-card-title>

                                        <div
                                            class="form-group col-md-4 py-0 px-1 mb-3"
                                            v-if="
                                                quotazione.stato_quotazione
                                                    .cod > 4
                                            "
                                        >
                                            <label for="indirizzo"
                                                >Indirizzo:</label
                                            >

                                            <input
                                                class="form-control-sm form-control"
                                                autocomplete="new-password"
                                                type="indirizzo"
                                                data-cy="quotazione.indirizzo"
                                                v-model="quotazione.indirizzo"
                                                name="indirizzo"
                                            />

                                            <invalid-feedback
                                                :errors="errors.indirizzo"
                                            />
                                        </div>

                                        <div
                                            class="form-group col-md-4 py-0 px-1 mb-3"
                                            v-if="
                                                quotazione.stato_quotazione
                                                    .cod > 4
                                            "
                                        >
                                            <label for="indirizzo"
                                                >Civico:</label
                                            >

                                            <input
                                                class="form-control-sm form-control"
                                                autocomplete="n"
                                                type="residenza_civico"
                                                data-cy="quotazione.residenza_civico"
                                                v-model="
                                                    quotazione.residenza_civico
                                                "
                                                name="residenza_civico"
                                            />

                                            <invalid-feedback
                                                :errors="
                                                    errors.residenza_civico
                                                "
                                            />
                                        </div>

                                        <div
                                            class="form-group col-md-4 py-0 px-1 mb-3"
                                            v-if="
                                                quotazione.stato_quotazione
                                                    .cod > 4
                                            "
                                        >
                                            <teo-autocomplete
                                                v-model="
                                                    quotazione.residenza_comune_id
                                                "
                                                label="Comune"
                                                item-text="nome"
                                                item-value="codice"
                                                server-side-search
                                                resource="/api/autocomplete/comune"
                                            >
                                            </teo-autocomplete>

                                            <invalid-feedback
                                                :errors="
                                                    errors.residenza_comune_id
                                                "
                                            />
                                        </div>

                                        <div
                                            id="provincia"
                                            class="form-group col-md-4 py-0 px-1 mb-3"
                                        >
                                            <teo-autocomplete
                                                autocomplete="new-password"
                                                name="provincia"
                                                label="Provincia"
                                                item-text="nome"
                                                item-value="codice"
                                                server-side-search
                                                resource="/api/autocomplete/province"
                                                data-cy="quotazione.provincia_codice"
                                                v-model="
                                                    quotazione.provincia_codice
                                                "
                                                @change="calcoloQuotazione"
                                                :disabled="!premioEditabile"
                                            >
                                            </teo-autocomplete>

                                            <invalid-feedback
                                                :errors="
                                                    errors.provincia_codice
                                                "
                                            />
                                        </div>
                                    </div>

                                    <div class="fieldset__content row">
                                        <v-card-title>Varie</v-card-title>

                                        <div
                                            class="form-group col-md-4 py-0 px-1 mb-3"
                                        >
                                            <v-select
                                                autocomplete="new-password"
                                                :items="[
                                                    1, 2, 3, 4, 5, 6, 7, 8, 9,
                                                    10,
                                                ]"
                                                label="Veicoli uso quotidiano:"
                                                data-cy="quotazione.quotidiano"
                                                v-model="quotazione.quotidiano"
                                            ></v-select>

                                            <invalid-feedback />
                                        </div>

                                        <div
                                            class="form-group col-md-4 py-0 px-1 mb-3"
                                        >
                                            <label for="club">Club:</label>

                                            <input
                                                class="form-control-sm form-control"
                                                autocomplete="new-password"
                                                type="text"
                                                id="club"
                                                name="club"
                                                data-cy="quotazione.club"
                                                v-model="quotazione.club"
                                            />

                                            <invalid-feedback
                                                :errors="errors.club"
                                            />
                                        </div>

                                        <div class="row">
                                            <div class="col-6">
                                                <v-checkbox
                                                    id="quotazione-minoreAnni"
                                                    data-cy="quotazione.minoreAnni"
                                                    v-model="
                                                        quotazione.minoreAnni
                                                    "
                                                    label="Conducenti < 25 anni"
                                                    @change="calcoloQuotazione"
                                                    :disabled="!premioEditabile"
                                                ></v-checkbox>
                                            </div>
                                            <div class="col-6">
                                                <v-checkbox
                                                    data-cy="quotazione.doppia_circolazione"
                                                    v-model="
                                                        quotazione.doppia_circolazione
                                                    "
                                                    label="Doppia circolazione"
                                                    :disabled="!premioEditabile"
                                                    @change="
                                                        cambiaDoppiaCircolazione
                                                    "
                                                ></v-checkbox>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="fieldset__content row">
                                        <v-alert
                                            type="danger"
                                            v-if="
                                                quotazione.stato_quotazione
                                                    .cod == 1.5
                                            "
                                        >
                                            Attenzione controllare se sono stati
                                            esclusi o inclusi dei veicoli
                                            rispetto alla polizza precedente
                                        </v-alert>
                                        <v-data-table
                                            cy-data="data_table__veicoli_storici"
                                            :headers="headers_storici"
                                            :items="veicoli_storici"
                                            item-key="id"
                                            :show-expand="true"
                                        >
                                            <!-- EXPAND ----------------------------------------------------------------------------- -->
                                            <template
                                                v-slot:expanded-item="{
                                                    headers,
                                                    item,
                                                }"
                                            >
                                                <td :colspan="headers.length">
                                                    <v-container class="w-100">
                                                        <v-row>
                                                            <v-col
                                                                cols="7"
                                                                class="ANIA"
                                                            >
                                                                <v-row>
                                                                    <v-col>
                                                                        <v-list-item>
                                                                            <v-list-item-content>
                                                                                <v-list-item-title
                                                                                    @click="
                                                                                        assegnaAventeDirittoContraente(
                                                                                            item,
                                                                                            quotazione,
                                                                                        )
                                                                                    "
                                                                                    >Avente
                                                                                    Diritto</v-list-item-title
                                                                                >
                                                                                <v-text-field
                                                                                    label="Nome"
                                                                                    v-model="
                                                                                        item.avente_diritto_nome
                                                                                    "
                                                                                ></v-text-field>

                                                                                <v-text-field
                                                                                    label="Cognome"
                                                                                    v-model="
                                                                                        item.avente_diritto_cognome
                                                                                    "
                                                                                ></v-text-field>

                                                                                <v-text-field
                                                                                    label="Codice Fiscale / Piva"
                                                                                    :hint="
                                                                                        item.avente_diritto_codice_fiscale_iva
                                                                                    "
                                                                                    persistent-hint
                                                                                    a
                                                                                    v-model="
                                                                                        item.avente_diritto_codice_fiscale_iva
                                                                                    "
                                                                                ></v-text-field>
                                                                            </v-list-item-content>
                                                                        </v-list-item>

                                                                        <v-list-item>
                                                                            <v-list-item-content>
                                                                                {{
                                                                                    item.alert
                                                                                }}
                                                                            </v-list-item-content>
                                                                        </v-list-item>
                                                                    </v-col>
                                                                    <v-col>
                                                                        <template>
                                                                            <v-list-item
                                                                                two-line
                                                                            >
                                                                                <v-list-item-content>
                                                                                    <v-list-item-title
                                                                                        class="d-flex"
                                                                                        @click="
                                                                                            assegnaAventeDirittoSivi(
                                                                                                item,
                                                                                                quotazione,
                                                                                            )
                                                                                        "
                                                                                    >
                                                                                        <h5
                                                                                            color="primary"
                                                                                        >
                                                                                            <v-btn
                                                                                                color="primary"
                                                                                                icon
                                                                                                small
                                                                                            >
                                                                                                <v-icon
                                                                                                    >mdi-cog</v-icon
                                                                                                >
                                                                                                Sivi
                                                                                            </v-btn>
                                                                                        </h5>
                                                                                    </v-list-item-title>

                                                                                    <v-list-item-subtitle>
                                                                                        <b
                                                                                            >sivi_tax_code</b
                                                                                        >
                                                                                        <v-chip
                                                                                            class="ma-2"
                                                                                        >
                                                                                            {{
                                                                                                item.sivi_tax_code
                                                                                                    ? item.sivi_tax_code
                                                                                                    : "assente"
                                                                                            }}
                                                                                        </v-chip>
                                                                                    </v-list-item-subtitle>
                                                                                </v-list-item-content>
                                                                            </v-list-item>

                                                                            <v-list-item>
                                                                                <v-list-item-content
                                                                                    three-line
                                                                                >
                                                                                    <b
                                                                                        color="primary"
                                                                                        >ContractDate</b
                                                                                    >
                                                                                    <v-chip>
                                                                                        {{
                                                                                            item.atrc_contract_expiration_date
                                                                                        }}
                                                                                    </v-chip>
                                                                                </v-list-item-content>
                                                                            </v-list-item>
                                                                            <v-list-item
                                                                                three-line
                                                                            >
                                                                                <v-list-item-content>
                                                                                    <v-list-item-title
                                                                                        color="primary"
                                                                                        @click="
                                                                                            assegnaAventeDirittoAtrc(
                                                                                                item,
                                                                                                quotazione,
                                                                                            )
                                                                                        "
                                                                                    >
                                                                                        <v-btn
                                                                                            link
                                                                                            small
                                                                                            text
                                                                                            color="primary"
                                                                                            dark
                                                                                        >
                                                                                            usa
                                                                                            <b
                                                                                                >Atrc</b
                                                                                            >
                                                                                        </v-btn>
                                                                                    </v-list-item-title>
                                                                                    <v-list-item-subtitle>
                                                                                        <b
                                                                                            color="primary"
                                                                                            >BeneficiaryTaxCode</b
                                                                                        >

                                                                                        <v-chip
                                                                                            class="ma-2"
                                                                                        >
                                                                                            {{
                                                                                                item.atrc_beneficiary_tax_code
                                                                                                    ? item.atrc_beneficiary_tax_code
                                                                                                    : "assente"
                                                                                            }}
                                                                                        </v-chip>
                                                                                    </v-list-item-subtitle>
                                                                                    <v-list-item-subtitle>
                                                                                        <b
                                                                                            color="primary"
                                                                                            >BeneficiaryFirstName</b
                                                                                        >
                                                                                        <v-chip
                                                                                            class="ma-2"
                                                                                        >
                                                                                            {{
                                                                                                item.atrc_beneficiary_first_name
                                                                                                    ? item.atrc_beneficiary_first_name
                                                                                                    : "assente"
                                                                                            }}
                                                                                        </v-chip>
                                                                                    </v-list-item-subtitle>
                                                                                    <v-list-item-subtitle>
                                                                                        <b
                                                                                            color="primary"
                                                                                            >BeneficiaryLastName</b
                                                                                        >
                                                                                        <v-chip
                                                                                            class="ma-2"
                                                                                        >
                                                                                            {{
                                                                                                item.atrc_beneficiary_last_name
                                                                                                    ? item.atrc_beneficiary_last_name
                                                                                                    : "assente"
                                                                                            }}
                                                                                        </v-chip>
                                                                                    </v-list-item-subtitle>
                                                                                </v-list-item-content>
                                                                            </v-list-item>
                                                                        </template>
                                                                    </v-col>
                                                                    <v-col
                                                                        v-if="
                                                                            librettoUrl(
                                                                                item,
                                                                            )
                                                                        "
                                                                    >
                                                                        <a
                                                                            class="btn"
                                                                            target="_blank"
                                                                            :href="
                                                                                librettoUrl(
                                                                                    item,
                                                                                )
                                                                            "
                                                                            >Libretto</a
                                                                        >
                                                                    </v-col>
                                                                </v-row>
                                                            </v-col>
                                                            <v-col
                                                                cols="5"
                                                                class="TEO"
                                                            >
                                                                <!-- v-if="roleIs('super_admin')" -->
                                                                <v-list-item>
                                                                    <v-list-item-content>
                                                                        <v-checkbox
                                                                            :label="
                                                                                item.classe_18
                                                                                    ? `Il Veicolo E' in classe 18°`
                                                                                    : `Il Veicolo NON è in classe 18°`
                                                                            "
                                                                            data-cy="data_table__veicolo_storico_checkbox_classe_18_item.furto"
                                                                            v-model="
                                                                                item.classe_18
                                                                            "
                                                                        ></v-checkbox>
                                                                    </v-list-item-content>
                                                                </v-list-item>
                                                                <v-list-item>
                                                                    <v-list-item-content>
                                                                        <v-select
                                                                            label="Tipologia di veicolo"
                                                                            :items="
                                                                                tipologie_ania
                                                                            "
                                                                            item-text="nome"
                                                                            item-value="codice"
                                                                            :data-cy="
                                                                                'item.tipologia_ania' +
                                                                                item.marca
                                                                            "
                                                                            v-model="
                                                                                item.tipologia_ania
                                                                            "
                                                                        >
                                                                        </v-select>
                                                                    </v-list-item-content>
                                                                </v-list-item>

                                                                <v-list-item
                                                                    v-if="
                                                                        item.tipologia_ania ==
                                                                        'C'
                                                                    "
                                                                >
                                                                    <v-list-item-content>
                                                                        <v-list-item-subtitle>
                                                                            <b
                                                                                @click="
                                                                                    assegnaQuintaliDaSivi(
                                                                                        item,
                                                                                    )
                                                                                "
                                                                                >sivi
                                                                                vehicle
                                                                                weight
                                                                                {{
                                                                                    item.sivi_vehicle_weight
                                                                                }}</b
                                                                            >
                                                                        </v-list-item-subtitle>
                                                                        <v-text-field
                                                                            type="number"
                                                                            label="Inserire i quintali"
                                                                            v-model="
                                                                                item.quintali
                                                                            "
                                                                        >
                                                                        </v-text-field>
                                                                    </v-list-item-content>
                                                                </v-list-item>
                                                                <v-list-item-content>
                                                                    <!-- |{{ item.targa }}| -->
                                                                    <v-list-item>
                                                                        <v-text-field
                                                                            label="Targa"
                                                                            v-model.trim="
                                                                                item.targa
                                                                            "
                                                                        ></v-text-field>

                                                                        <v-select
                                                                            label="Tipo di targa"
                                                                            :items="
                                                                                tipologie_targa
                                                                            "
                                                                            color="primary"
                                                                            item-text="nome"
                                                                            item-value="codice"
                                                                            v-model="
                                                                                item.tipologia_targa
                                                                            "
                                                                        >
                                                                        </v-select>
                                                                    </v-list-item>

                                                                    <v-list-item>
                                                                        <v-btn
                                                                            dense
                                                                            small
                                                                            color="danger"
                                                                            dark
                                                                            @click="
                                                                                updateQuotazione(
                                                                                    false,
                                                                                )
                                                                            "
                                                                            >Salva</v-btn
                                                                        >
                                                                        <!-- @click="updateQuotazionePerEmissione" -->
                                                                        <v-btn
                                                                            dense
                                                                            small
                                                                            @click="
                                                                                updateAniaData(
                                                                                    item,
                                                                                )
                                                                            "
                                                                        >
                                                                            <v-icon>
                                                                                mdi-refresh </v-icon
                                                                            >Aggiorna
                                                                            Ania
                                                                        </v-btn>
                                                                    </v-list-item>

                                                                    <v-list-item>
                                                                        Tipo di
                                                                        targa
                                                                        teo
                                                                        {{
                                                                            item.teo_tipologia_targa
                                                                        }}
                                                                    </v-list-item>

                                                                    <v-list-item-subtitle>
                                                                        <b
                                                                            >Data
                                                                            immatricolazione:
                                                                            {{
                                                                                item.data_immatricolazione
                                                                            }}</b
                                                                        >
                                                                    </v-list-item-subtitle>
                                                                    <v-list-item
                                                                        v-if="
                                                                            roleIs(
                                                                                'super_admin',
                                                                            )
                                                                        "
                                                                    >
                                                                        <date-picker
                                                                            v-model="
                                                                                item.data_immatricolazione
                                                                            "
                                                                            value-type="YYYY-MM-DD"
                                                                            format="DD/MM/YYYY"
                                                                        ></date-picker>
                                                                    </v-list-item>
                                                                    <v-list-item
                                                                        two-line
                                                                    >
                                                                        <v-radio-group
                                                                            dense
                                                                            v-model="
                                                                                item.data_immatricolazione
                                                                            "
                                                                            class="ma-0 pa-0"
                                                                        >
                                                                            <v-radio
                                                                                :label="`sivi registration date ${item.sivi_registration_date}`"
                                                                                :value="
                                                                                    item.sivi_registration_date
                                                                                "
                                                                            />
                                                                            <v-radio
                                                                                :label="`data auto ${item.teo_data_immatricolazione}`"
                                                                                :value="
                                                                                    item.teo_data_immatricolazione
                                                                                "
                                                                            />
                                                                        </v-radio-group>
                                                                    </v-list-item>
                                                                </v-list-item-content>

                                                                <v-list-item-content>
                                                                    <v-list-item-title
                                                                        >Note</v-list-item-title
                                                                    >
                                                                    <v-list-item>
                                                                        <v-textarea
                                                                            name="item.comunicazioni_note"
                                                                            label="Comunicazioni"
                                                                            v-model="
                                                                                item.note_veicolo
                                                                            "
                                                                            hint="Scrivi le note per comunicare  a verti errori ania"
                                                                        ></v-textarea>
                                                                    </v-list-item>
                                                                </v-list-item-content>
                                                                <v-btn
                                                                    dense
                                                                    small
                                                                    color="warning"
                                                                    dark
                                                                    @click="
                                                                        updateQuotazionePerEmissione
                                                                    "
                                                                    >Salva</v-btn
                                                                >
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </td>
                                            </template>
                                            <!-- END EXPAND ----------------------------------------------------------------------------- -->

                                            <template
                                                v-slot:[`item.furto`]="{ item }"
                                            >
                                                <v-simple-checkbox
                                                    v-if="item.furtabile"
                                                    data-cy="data_table__veicolo_storico_checkbox_furto_item.furto"
                                                    v-model="item.furto"
                                                    @click="calcoloQuotazione"
                                                    :disabled="!premioEditabile"
                                                >
                                                </v-simple-checkbox>
                                            </template>

                                            <!-- <template  v-slot:[`item.classe_18`]="{ item }">
                        <v-simple-checkbox
                          data-cy="data_table__veicolo_storico_checkbox_classe_18_item.furto"
                          v-model="item.classe_18"
                        ></v-simple-checkbox>
                      </template> -->

                                            <template
                                                v-slot:[`item.tipologia_ania`]="{
                                                    item,
                                                }"
                                            >
                                                <v-badge>
                                                    {{ item.tipologia_ania }}
                                                </v-badge>
                                            </template>
                                            <template
                                                v-slot:[`item.colore`]="{
                                                    item,
                                                }"
                                            >
                                                <v-chip
                                                    :color="
                                                        getColor(item.colore)
                                                    "
                                                    dark
                                                    v-if="
                                                        can(
                                                            'show_quotazioni_suoi_collaboratori',
                                                        ) ||
                                                        getColor(item.colore) ==
                                                            'red'
                                                    "
                                                >
                                                </v-chip>
                                            </template>
                                            <template
                                                v-slot:[`item.actions`]="{
                                                    item,
                                                }"
                                            >
                                                <v-icon
                                                    small
                                                    @click="deleteItem(item)"
                                                >
                                                    mdi-delete
                                                </v-icon>
                                            </template>
                                            <template v-slot:top>
                                                <v-toolbar flat>
                                                    <v-toolbar-title
                                                        >Veicoli
                                                        Storici</v-toolbar-title
                                                    >

                                                    <v-divider
                                                        class="mx-4"
                                                        inset
                                                        vertical
                                                    ></v-divider>

                                                    <v-spacer></v-spacer>

                                                    <v-dialog
                                                        data-cy="dialog"
                                                        v-model="dialog"
                                                        max-width="500px"
                                                        persistent
                                                    >
                                                        <template
                                                            v-slot:activator="{
                                                                on,
                                                                attrs,
                                                            }"
                                                        >
                                                            <v-btn
                                                                data-cy="data_table__veicoli_storici__btn__aggiungi"
                                                                small
                                                                color="primary"
                                                                dark
                                                                class="mb-2"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                @click="
                                                                    fetchMarche
                                                                "
                                                                v-if="
                                                                    premioEditabile
                                                                "
                                                            >
                                                                Aggiungi veicolo
                                                            </v-btn>
                                                        </template>
                                                        <v-card>
                                                            <v-form>
                                                                <v-container>
                                                                    <v-autocomplete
                                                                        autocomplete="new-password"
                                                                        data-cy="selectedMarca"
                                                                        v-model="
                                                                            selectedMarca
                                                                        "
                                                                        :items="
                                                                            marche
                                                                        "
                                                                        item-text="marca"
                                                                        label="Seleziona la marca"
                                                                        @change="
                                                                            fetchData
                                                                        "
                                                                    >
                                                                    </v-autocomplete>
                                                                    <v-autocomplete
                                                                        autocomplete="new-password"
                                                                        data-cy="selectedItem"
                                                                        v-model="
                                                                            selectedItem
                                                                        "
                                                                        :items="
                                                                            items
                                                                        "
                                                                        item-text="nome"
                                                                        return-object
                                                                        label="Seleziona il modello"
                                                                    >
                                                                    </v-autocomplete>
                                                                </v-container>
                                                            </v-form>
                                                            <v-card-actions>
                                                                <v-btn
                                                                    small
                                                                    data-cy="dialog_aggiungi_veicolo_annulla"
                                                                    color="blue darken-1"
                                                                    text
                                                                    @click="
                                                                        closeDialog
                                                                    "
                                                                >
                                                                    Annulla
                                                                </v-btn>
                                                                <v-btn
                                                                    small
                                                                    data-cy="dialog_aggiungi_veicolo_aggiungi"
                                                                    color="blue darken-1"
                                                                    text
                                                                    @click="
                                                                        aggiungiVeicolo
                                                                    "
                                                                >
                                                                    Aggiungi
                                                                </v-btn>
                                                            </v-card-actions>
                                                        </v-card>
                                                    </v-dialog>
                                                    <v-dialog
                                                        data-cy="dialogDelete"
                                                        v-model="dialogDelete"
                                                        max-width="500px"
                                                    >
                                                        <v-card>
                                                            <v-card-title
                                                                class="text-h5"
                                                                >Sicuro di voler
                                                                cancellare il
                                                                veicolo?</v-card-title
                                                            >
                                                            <v-card-actions>
                                                                <v-spacer></v-spacer>
                                                                <v-btn
                                                                    small
                                                                    data-cy="dialog__cancella_veicolo_annulla"
                                                                    color="secondary"
                                                                    text
                                                                    >Annulla</v-btn
                                                                >
                                                                <v-btn
                                                                    small
                                                                    data-cy="dialog__cancella_veicolo_conferma"
                                                                    color="danger"
                                                                    text
                                                                    @click="
                                                                        deleteItemConfirm
                                                                    "
                                                                    >Conferma</v-btn
                                                                >
                                                                <v-spacer></v-spacer>
                                                            </v-card-actions>
                                                        </v-card>
                                                    </v-dialog>
                                                </v-toolbar>
                                            </template>
                                            <template
                                                v-slot:[`item.cfisc`]="{ item }"
                                            >
                                                <v-chip
                                                    :color="
                                                        checkCfisc(item.cfisc)
                                                    "
                                                    dark
                                                >
                                                    {{ item.cfisc }}
                                                </v-chip>
                                            </template>
                                            <template
                                                v-slot:[`item.json`]="{ item }"
                                            >
                                                <v-icon
                                                    small
                                                    @click="
                                                        openAniaDialog(
                                                            item.json,
                                                        )
                                                    "
                                                >
                                                    mdi-eye
                                                </v-icon>
                                                <v-icon
                                                    small
                                                    @click="
                                                        updateAniaData(item)
                                                    "
                                                >
                                                    mdi-refresh
                                                </v-icon>
                                            </template>
                                            <template
                                                v-slot:[`item.stato`]="{ item }"
                                            >
                                                <v-chip color="blue"
                                                    >{{ item.stato }}
                                                </v-chip>
                                            </template>
                                        </v-data-table>
                                    </div>
                                    <div
                                        class="fieldset__content row"
                                        v-if="!premioEditabile"
                                    >
                                        <v-data-table
                                            :headers="headers_moderni"
                                            :items="quotazione.veicoli_moderni"
                                        >
                                            <template v-slot:top>
                                                <v-toolbar flat>
                                                    <v-toolbar-title
                                                        >Veicoli
                                                        Moderni</v-toolbar-title
                                                    >
                                                </v-toolbar>
                                            </template>
                                        </v-data-table>
                                    </div>
                                    <v-container fluid>
                                        <v-textarea
                                            clearable
                                            clear-icon="mdi-close-circle"
                                            label="Note"
                                            data-cy="quotazione.note"
                                            v-model="quotazione.note"
                                        ></v-textarea>
                                    </v-container>
                                </section>
                            </v-card-text>
                        </v-card>
                    </div>
                    <div class="col-3">
                        <v-card
                            outlined
                            class="p-0 mb-3"
                            v-if="quotazione.stato_quotazione"
                        >
                            <v-card-title
                                class="text-primary text-overline mb-2 py-2"
                                >Stato quotazione</v-card-title
                            >
                            <v-card-text class="m-0">
                                <v-select
                                    :items="stati_quotazione"
                                    item-text="stato_quotazione.nome"
                                    item-value="stato_quotazione.id"
                                    v-model="quotazione.stato_quotazione_id"
                                    label="Cambia Stato"
                                    @change="cambioStato"
                                    :disabled="!showCambiaStato"
                                >
                                </v-select>
                            </v-card-text>
                        </v-card>

                        <v-card outlined class="p-0 mb-3">
                            <div
                                v-if="
                                    quotazione?.rinnovo_della_polizza
                                        ?.numero_polizza
                                "
                            >
                                <v-card-title
                                    class="text-primary text-overline mb-1 py-0"
                                    v-if="quotazione.premio"
                                    >Premio Precedente</v-card-title
                                >

                                <v-card-text
                                    id="box-info-quotazione-precedente"
                                >
                                    Rinnovo della Polizza N°
                                    {{
                                        quotazione.rinnovo_della_polizza
                                            .numero_polizza
                                    }}
                                    <span class="d-block premio_forzato">
                                        Premio forzato
                                        <b class="text-primary">{{
                                            quotazione?.rinnovo_della_polizza
                                                ?.premio_forzato
                                        }}</b>
                                    </span>
                                    <span class="d-block premio">
                                        Premio
                                        <b class="text-primary">{{
                                            quotazione.rinnovo_della_polizza
                                                .premio
                                        }}</b>
                                    </span>
                                    <!--
                  <span class="d-block premio_verti">
                    premio_verti {{ quotazione.rinnovo_della_polizza.premio_verti }}
                  </span>
                  -->
                                    <span class="competenze">
                                        Competenze
                                        <b class="text-primary">{{
                                            quotazione.rinnovo_della_polizza
                                                .competenze
                                        }}</b>
                                    </span>
                                </v-card-text>
                            </div>

                            <v-card-title
                                class="text-primary text-overline mb-2 py-2"
                                v-if="quotazione.premio"
                                >Premio</v-card-title
                            >
                            <v-card-title
                                class="text-primary text-overline mb-2 py-2"
                                v-if="!quotazione.premio"
                                >Compila il form</v-card-title
                            >
                            <v-card-subtitle
                                v-if="
                                    this.deroga &&
                                    quotazione.stato_quotazione_id < 3
                                "
                            >
                                <v-chip
                                    class="ma-2"
                                    color="red"
                                    text-color="white"
                                >
                                    Attenzione! Deroga necessaria.
                                </v-chip>
                            </v-card-subtitle>

                            <!--- VISUALIZZAZIONE PREMIO FORZATO CHK -->
                            <v-row align="center" class="px-3 pt-0 pb-3">
                                <!-- SE non Esiste il PREMIO FORZATO  -->
                                <div
                                    :class="{
                                        'text-h2': !quotazione.premio_forzato,
                                    }"
                                    v-if="quotazione.premio"
                                >
                                    <div
                                        class="flex-nowrap align-content-start"
                                        :class="{
                                            'text-decoration-line-through':
                                                quotazione.premio_forzato,
                                        }"
                                    >
                                        € {{ quotazione.premio }}
                                    </div>
                                </div>

                                <div
                                    class="text-h2 flex-nowrap align-content-start"
                                    v-if="quotazione.premio"
                                >
                                    <div v-if="quotazione.premio_forzato">
                                        € {{ quotazione.premio_forzato }}
                                    </div>
                                </div>
                            </v-row>

                            <v-list-item
                                v-if="premioEditabile && quotazione.premio"
                            >
                                <v-list-item-content>
                                    <div class="form-group">
                                        <v-alert
                                            type="error"
                                            v-if="alertPremioForzato"
                                        >
                                            Non è possibile forzare il premio al
                                            ribasso
                                        </v-alert>

                                        <v-text-field
                                            v-model="quotazione.premio_forzato"
                                            clear-icon="mdi-close-circle"
                                            label="Importo premio forzato"
                                            type="text"
                                            variant="filled"
                                            clearable
                                            name="premio_forzato"
                                        ></v-text-field>
                                    </div>
                                </v-list-item-content>
                            </v-list-item>
                            <!-- SE ESISTE il PREMIO FORZATO  -->
                            <div
                                elevation-0
                                class="pa-3"
                                v-if="
                                    quotazione.premio_forzato >
                                    quotazione.premio
                                "
                            >
                                <v-alert
                                    dense
                                    icon="mdi-account"
                                    outlined
                                    type="success"
                                >
                                    competenze € {{ quotazione.competenze }}
                                </v-alert>
                            </div>
                            <!--- COMANDI -->
                            <v-list-item v-if="showSalvaBtn">
                                <v-list-item-content>
                                    <v-btn
                                        data-cy="btn__salva__storeQuotazione"
                                        small
                                        color="danger"
                                        dark
                                        class="mb-2"
                                        @click="storeQuotazione"
                                    >
                                        Salva
                                    </v-btn>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="showAggiornaBtn">
                                <v-list-item-content>
                                    <v-btn
                                        data-cy="btn__aggiorna__updateQuotazione"
                                        small
                                        color="primary"
                                        dark
                                        class="mb-2"
                                        @click="updateQuotazione"
                                    >
                                        Aggiorna
                                    </v-btn>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="showConfermaBtn">
                                <v-list-item-content>
                                    <v-dialog
                                        data-cy="dialogConferma"
                                        v-model="dialogConferma"
                                        max-width="600px"
                                    >
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-btn
                                                data-cy="btn_conferma_quotazione_open_dialogConferma"
                                                small
                                                color="primary"
                                                dark
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                Conferma
                                            </v-btn>
                                        </template>
                                        <v-card>
                                            <v-card-title
                                                class="text-h5 text-primary text-overline mb-2 py-2"
                                                v-if="!deroga"
                                                >Sicuro di voler confermare la
                                                quotazione ed inviare la
                                                mail?</v-card-title
                                            >
                                            <v-card-title
                                                class="text-h5"
                                                v-if="deroga"
                                                >Confermi di mandare in deroga
                                                la quotazione?</v-card-title
                                            >
                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                    small
                                                    color="secondary"
                                                    data-cy="btn_dialog_invia_quotazione_cancel"
                                                    text
                                                    @click="
                                                        dialogConferma = false
                                                    "
                                                    >Annulla</v-btn
                                                >
                                                <v-btn
                                                    small
                                                    color="danger"
                                                    data-cy="btn_dialog_invia_quotazione_conferma"
                                                    text
                                                    @click="confermaQuotazione"
                                                    >OK</v-btn
                                                >
                                                <v-spacer></v-spacer>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="showConfermaBtn">
                                <v-list-item-content>
                                    <v-dialog
                                        data-cy="dialogConferma"
                                        v-model="dialogConfermaDiretta"
                                        max-width="600px"
                                    >
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-btn
                                                data-cy="btn_conferma_quotazione_open_dialogConferma"
                                                small
                                                color="primary"
                                                dark
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                Conferma per Emissione Diretta
                                            </v-btn>
                                        </template>
                                        <v-card>
                                            <v-card-title
                                                class="text-h5 text-primary text-overline mb-2 py-2"
                                                v-if="!deroga"
                                                >Sicuro di voler confermare la
                                                quotazione e compilare il
                                                form?</v-card-title
                                            >
                                            <v-card-title
                                                class="text-h5"
                                                v-if="deroga"
                                                >Confermi di mandare in deroga
                                                la quotazione?</v-card-title
                                            >
                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                    small
                                                    color="secondary"
                                                    data-cy="btn_dialog_invia_quotazione_cancel"
                                                    text
                                                    @click="
                                                        dialogConfermaDiretta = false
                                                    "
                                                    >Annulla</v-btn
                                                >
                                                <v-btn
                                                    small
                                                    color="danger"
                                                    data-cy="btn_dialog_invia_quotazione_conferma"
                                                    text
                                                    @click="
                                                        confermaQuotazioneDiretta
                                                    "
                                                    >OK</v-btn
                                                >
                                                <v-spacer></v-spacer>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                </v-list-item-content>
                            </v-list-item>
                            <!-- SALVA EMISSIONE-->
                            <v-list-item
                                v-if="
                                    showEmettiBtn &&
                                    quotazione.stato_quotazione.cod > 6
                                "
                            >
                                <v-list-item-content>
                                    <v-btn
                                        data-cy="btn__aggiorna__updateQuotazione"
                                        small
                                        color="danger"
                                        dark
                                        class="mb-2"
                                        @click="updateQuotazionePerEmissione"
                                    >
                                        <!-- @click="updateQuotazione(false)" -->
                                        Salva Emissione
                                        <!-- Emissione -->
                                    </v-btn>
                                </v-list-item-content>
                            </v-list-item>

                            <!-- PREVIEW EMISSIONE -->
                            <v-list-item v-if="showEmettiBtn">
                                <v-list-item-content class="">
                                    <v-btn
                                        data-cy="btn__aggiorna__updateQuotazione"
                                        small
                                        color="warning"
                                        dark
                                        @click="previewEmissione"
                                    >
                                        Preview Emissione
                                    </v-btn>
                                    <div
                                        v-if="roleIs('super_admin')"
                                        class="box box-atrc_contract_expiration_date"
                                    >
                                        <v-switch
                                            v-model="
                                                options.check_atrc_contract_expiration_date
                                            "
                                            label="Controlla atrc contract expiration date"
                                            color="warning"
                                        >
                                            <template v-slot:label>
                                                <span>
                                                    <span
                                                        class="text-error"
                                                        v-if="
                                                            options.check_atrc_contract_expiration_date
                                                        "
                                                    >
                                                        Controlla se la polizza
                                                        precedente è scaduta
                                                    </span>

                                                    <span
                                                        class="text-error"
                                                        v-if="
                                                            !options.check_atrc_contract_expiration_date
                                                        "
                                                    >
                                                        <v-icon left
                                                            >mdi-alert</v-icon
                                                        >
                                                        <b>Attenzione</b> NON
                                                        controlla se la polizza
                                                        precedente è scaduta.
                                                    </span>
                                                </span>
                                            </template>
                                        </v-switch>
                                    </div>
                                </v-list-item-content>
                            </v-list-item>

                            <!-- DATA DECORRENZA -->
                            <v-list-item>
                                <v-list-item-content>
                                    <date-picker
                                        label="Data di Decorrenza"
                                        data-cy="quotazione.decorrenza:Date"
                                        v-model="quotazione.decorrenza"
                                        value-type="YYYY-MM-DD"
                                        format="DD/MM/YYYY"
                                        :disabled="enableEmissioneDecorrenza"
                                    ></date-picker>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item v-if="showRichiediEmissioneBtn">
                                <v-list-item-content>
                                    <v-dialog
                                        data-cy="dialogRichiediEmissione"
                                        v-model="dialogRichiediEmissione"
                                        max-width="600px"
                                    >
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-btn
                                                data-cy="btn_conferma_quotazione_open_dialogRichiediEmissione"
                                                small
                                                color="primary"
                                                dark
                                                class="mb-2"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                Richieidi Emissione Polizza
                                            </v-btn>
                                        </template>
                                        <v-card>
                                            <v-card-title class="text-h5"
                                                >Sicuro di voler richiedere
                                                l'emissione della
                                                polizza?</v-card-title
                                            >
                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                    small
                                                    color="primary"
                                                    text
                                                    >Cancel</v-btn
                                                >
                                                <v-btn
                                                    small
                                                    color="danger"
                                                    text
                                                    @click="
                                                        richiediEmissionePolizza
                                                    "
                                                    >OK</v-btn
                                                >
                                                <v-spacer></v-spacer>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                </v-list-item-content>
                            </v-list-item>
                        </v-card>

                        <v-card
                            v-if="
                                quotazione.verti_policy_number &&
                                quotazione.polizza.infodrive_id
                            "
                            outlined
                            class="p-0 mb-3"
                        >
                            <box-firma
                                v-if="
                                    quotazione &&
                                    quotazione.stato_quotazione.cod > 8
                                "
                                :cell="quotazione?.telefono"
                                :email="quotazione?.email"
                                :state="boxFirmaStato"
                                :media="boxFirmaMedia"
                                :error-message="boxFirmaError"
                                @on-invia-richiesta-di-firma="
                                    onInviaRichiestaDiFirma
                                "
                                @on-update="updateQuotazione(false)"
                            >
                                <!-- @on-update="updateQuotazionePerEmissione" -->
                            </box-firma>
                            <div
                                v-if="
                                    false && quotazione.last_signature_request
                                "
                            >
                                <div
                                    v-if="
                                        quotazione.last_signature_request
                                            .response &&
                                        quotazione.last_signature_request
                                            .response.documents
                                    "
                                >
                                    <div
                                        :key="document.id"
                                        class=""
                                        v-for="document in quotazione
                                            .last_signature_request.response
                                            .documents"
                                    >
                                        {{ document.id }}
                                    </div>
                                </div>
                            </div>
                        </v-card>
                        <v-card
                            outlined
                            class="p-0 border border-danger mb-3"
                            v-if="
                                roleIs('super_admin') &&
                                quotazione.stato_quotazione.cod > 8 &&
                                quotazione.stato_quotazione_id != 11
                            "
                        >
                            <v-list-item>
                                <v-list-item-content>
                                    <v-dialog
                                        data-cy="dialogStorna"
                                        v-model="dialogStorna"
                                        max-width="600px"
                                    >
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-btn
                                                small
                                                color="danger"
                                                dark
                                                class="my-2"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                Storna Polizza
                                            </v-btn>
                                        </template>

                                        <v-card>
                                            <v-card-title
                                                class="text-overline mb-2 text-center"
                                            >
                                                Sicuro di voler stornare questa
                                                polizza ?
                                            </v-card-title>

                                            <p class="text-center">
                                                {{
                                                    quotazione.verti_policy_number +
                                                    " di " +
                                                    quotazione.cognome +
                                                    " " +
                                                    quotazione?.nome
                                                }}
                                            </p>

                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                    small
                                                    color="primary"
                                                    dark
                                                    class="mb-2"
                                                    @click="
                                                        dialogStorna = false
                                                    "
                                                >
                                                    Annulla
                                                </v-btn>

                                                <v-btn
                                                    small
                                                    color="danger"
                                                    dark
                                                    class="mb-2"
                                                    @click="stornaPolizza"
                                                >
                                                    Conferma Storno
                                                </v-btn>

                                                <v-spacer></v-spacer>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                </v-list-item-content>
                            </v-list-item>
                        </v-card>
                        <v-list-item v-if="showApprovaBtn">
                            <v-list-item-content>
                                <v-btn
                                    small
                                    class="mb-3"
                                    data-cy="btn_quotazione_approva__approvaQuotazione"
                                    color="success"
                                    dark
                                    @click="approvaQuotazione"
                                >
                                    Approva
                                </v-btn>

                                <v-btn
                                    small
                                    color="error"
                                    data-cy="btn_quotazione_rifiuta__rifiutaQuotazione"
                                    dark
                                    @click="rifiutaQuotazione"
                                >
                                    Rifiuta
                                </v-btn>
                            </v-list-item-content>
                        </v-list-item>
                        <!-- roleIs('super_admin') && -->
                        <v-card
                            outlined
                            class="p-3 border border-warning mb-3"
                            v-if="
                                quotazione.polizza &&
                                !quotazione.polizza.infodrive_id &&
                                quotazione.stato_quotazione.cod > 8 &&
                                quotazione.stato_quotazione_id != 11
                            "
                        >
                            <v-btn
                                @click="emettiPolizza"
                                small
                                color="warning"
                                dark
                                class="my-2"
                            >
                                Riprova A emettere infodrive
                            </v-btn>
                        </v-card>

                        <v-card
                            style="border: 1px solid red"
                            v-if="
                                roleIs('super_admin') &&
                                (this.quotazione.stato_quotazione_id == 9 ||
                                    this.quotazione.stato_quotazione_id == 10)
                            "
                        >
                            <v-card-text>
                                Attenzione: Ogni volta che premi il bottone
                                verranno inviati dati duplicati in Koala

                                <v-btn
                                    small
                                    color="danger"
                                    @click="rimandaDatiAKoala"
                                    dark
                                    class="my-2"
                                >
                                    Rimanda i dati a Koala
                                </v-btn>
                            </v-card-text>
                        </v-card>
                    </div>
                </div>
            </v-tab-item>

            <v-tab-item>
                <v-card flat>
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">Documento</th>

                                    <th class="text-left">Categoria</th>

                                    <th class="text-left">Download</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr
                                    v-for="item in quotazione.media"
                                    :key="item.id"
                                >
                                    <td>{{ item.name }}</td>

                                    <td>{{ item.collection_name }}</td>
                                    <td>
                                        <a
                                            target="_blank"
                                            :href="documentUrl(item)"
                                            >Download</a
                                        >
                                    </td>
                                </tr>

                                <template
                                    v-for="item in quotazione.veicoli_storici"
                                >
                                    <tr
                                        v-for="media in item.media"
                                        :key="media.id"
                                    >
                                        <td>{{ media.collection_name }}</td>

                                        <td>{{ media.file_name }}</td>

                                        <td>
                                            <a :href="documentUrl(media)"
                                                >Download</a
                                            >
                                        </td>
                                    </tr>
                                </template>
                                <template v-if="quotazione.polizza">
                                    <tr class="media-table--header">
                                        <td colspan="3">
                                            <v-row class="justify-sm-start">
                                                <v-col>
                                                    <div class="text-overline">
                                                        Dopo emissione
                                                    </div>
                                                </v-col>
                                                <v-col>
                                                    <v-btn
                                                        v-bind="BtnSmall"
                                                        @click="
                                                            rigeneraDocumenti
                                                        "
                                                        class="text-overline"
                                                        >Rigenera
                                                        documenti</v-btn
                                                    >
                                                </v-col>
                                            </v-row>
                                        </td>
                                    </tr>

                                    <tr
                                        v-for="media in quotazione.polizza
                                            .media"
                                        :key="media.id"
                                    >
                                        <td>{{ media.collection_name }}</td>

                                        <td>{{ media.file_name }}</td>

                                        <td>
                                            <a :href="documentUrl(media)"
                                                >Download</a
                                            >
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </template>
                    </v-simple-table>
                    <v-card-actions>
                        <v-dialog
                            max-width="600px"
                            data-cy="revisioneDialog"
                            v-model="revisioneDialog"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    small
                                    color="primary"
                                    dark
                                    class="mb-2 mr-2"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    Richiedi revisione documenti</v-btn
                                >
                            </template>
                            <v-card>
                                <v-card-title
                                    class="text-primary text-overline mb-2"
                                >
                                    <span class="text-h5"
                                        >Richiedi revisione documenti</span
                                    >
                                </v-card-title>
                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-form>
                                                <v-textarea
                                                    clearable
                                                    clear-icon="mdi-close-circle"
                                                    label="Note"
                                                    data-cy="revisioneNote"
                                                    v-model="revisioneNote"
                                                ></v-textarea>
                                            </v-form>
                                        </v-row>
                                    </v-container>
                                    <small
                                        >*una mail sarà inviata al cliente con
                                        le presenti note</small
                                    >
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>

                                    <v-btn
                                        small
                                        color="blue darken-1"
                                        text
                                        @click="revisioneDialog = false"
                                        >Cancel</v-btn
                                    >
                                    <v-btn
                                        small
                                        color="blue darken-1"
                                        text
                                        @click="revisioneDoc"
                                        >OK</v-btn
                                    >
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog
                            max-width="600px"
                            data-cy="confermaDialog"
                            v-model="confermaDialog"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    small
                                    color="primary"
                                    dark
                                    class="mb-2 mr-2"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    Conferma documenti
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-title class="text-h5">
                                    Confermare la correttezza dei documenti ed
                                    inviare mail?
                                </v-card-title>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        small
                                        color="blue darken-1"
                                        text
                                        @click="confermaDialog = false"
                                        >Cancel</v-btn
                                    >
                                    <v-btn
                                        small
                                        color="blue darken-1"
                                        text
                                        @click="confermaDoc"
                                        >OK</v-btn
                                    >
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog
                            max-width="600px"
                            data-cy="addDocumentDialog"
                            v-model="addDocumentDialog"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    small
                                    color="primary"
                                    dark
                                    class="mb-2 mr-2"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    Aggiungi documento
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-title class="text-h5"
                                    >Aggiungi Documento</v-card-title
                                >
                                <v-card-text>
                                    <v-form>
                                        <v-container>
                                            <div
                                                class="form-group py-0 px-1 mb-3"
                                            >
                                                <v-text-field
                                                    v-model="nome_file"
                                                    label="Nome File"
                                                    required
                                                ></v-text-field>
                                                <v-select
                                                    v-model="collection"
                                                    :items="collections"
                                                    dense
                                                    label="Seleziona una categoria"
                                                    clearable
                                                ></v-select>
                                                <v-file-input
                                                    label="file"
                                                    v-model="file"
                                                    accept="image/*,.pdf"
                                                ></v-file-input>
                                            </div>
                                        </v-container>
                                    </v-form>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        small
                                        color="blue darken-1"
                                        text
                                        @click="addDocumentDialog = false"
                                        >Annulla</v-btn
                                    >
                                    <v-btn
                                        small
                                        color="warning"
                                        text
                                        @click="addDocument"
                                        >Salva</v-btn
                                    >
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-card-actions>
                </v-card>
            </v-tab-item>

            <v-tab-item>
                <appendice-inclusione-form :quotazione="quotazione">
                </appendice-inclusione-form>
            </v-tab-item>
            <v-tab-item>
                <appendice-esclusione-form :quotazione="quotazione">
                </appendice-esclusione-form>
            </v-tab-item>
        </v-tabs>

        <v-dialog max-width="1100px" v-model="aniaDialog" scrollable>
            <v-card>
                <v-card-title class="text-h5">Dettagli Ania</v-card-title>
                <v-card-text>
                    <pre>{{ json | pretty }}</pre>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        color="primary"
                        variant="text"
                        @click="aniaDialog = false"
                    >
                        Chiudi
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <div
            :class="{ 'loading-overlay': loading }"
            style="z-index: 10000"
        ></div>
        <div class="row pa-4">
            <div class="col-12">
                <v-card data-cy="urlForm_cliente">
                    <v-card-text>
                        <a target="_BLANK" :href="urlForm()">{{ urlForm() }}</a>
                    </v-card-text>
                </v-card>
            </div>
        </div>
    </v-container>
</template>

<script>
import DatePicker from "../ui/form/teo-date-picker.vue";

import AutocompleteVue from "../ui/Autocomplete.vue";
import InvalidFeedback from "../ui/InvalidFeedback.vue";
import { errorCallback, Api } from "@/http_tools/axiosModule";

import AppendiceInclusioneFormVue from "./AppendiceInclusioneForm.vue";
import AppendiceEsclusioneFormVue from "./AppendiceEsclusioneForm.vue";
import axios from "axios";
import BoxFirma from "../ui/form/box-firma.vue";
import {
    boxFirmaStato,
    boxFirmaMedia,
} from "../ui/form/box-firma-quotazione-service.js";
import {
    HasPermission,
    PuoEmettereAppendiceAutoStorica,
    PuoEmetterePolizzaAutoStorica,
} from "@/services/PermessiRuoliUtente.js";
import { NumberUtilsMixing } from "@/mixin/NumberUtilsMixing";
import { StyleComponent } from "@/components/ui/theme/StyleComponent";

import { QuotazioneUtilsMixing } from "@/mixin/QuotazioneUtilsMixing";
import { tipologieAnia, tipologieTarga } from "@/enum/Verti/Tipologie";
import {
    addHeaders,
    //, removeHeadersByValues
} from "@/services/VuetifyDatatableUtils";

export default {
    mixins: [NumberUtilsMixing, QuotazioneUtilsMixing, StyleComponent],
    data: () => ({
        options: {
            check_atrc_contract_expiration_date: true,
        },
        premio_forzato: null,
        forceCheck: null,
        //oldDeroga: null,
        premio: null,
        colore: null,
        alert: {},
        requestAppendice: {
            auto_storica: {},
            veicolo: {},
        },
        nome_file: "",
        file: null,
        dialogConfermaDiretta: false,
        addDocumentDialog: false,
        rightSideDrawer: false,
        payloadPreview: "",
        dialog: false,
        dialogDelete: false,
        dialogConferma: false,
        dialogStorna: false,
        veicoli_storici: [],
        items: [],
        json: null,
        marche: [],
        search: "",
        loading: false,
        selectedItem: null,
        selectedMarca: null,
        editedIndex: -1,
        quotazione: {},
        errors: [],
        note: "",
        response: null,
        editMode: null,
        id: null,
        revisioneNote: null,
        veicoli: [],
        stati_quotazione: [],
        //deroga: false,
        aniaDialog: false,
        dialogEmetti: false,
        dialogStorno: false,
        revisioneDialog: false,
        confermaDialog: false,
        boxFirmaError: false,
        dialogRichiediEmissione: false,
        collection: null,
        collections: [
            "libretto",
            "carta_identita",
            "patente",
            "codice_fiscale",
            "tessera_club",
            "varie",
        ],
        headers_moderni: [
            {
                text: "Marca",
                value: "marca",
            },
            {
                text: "Modello",
                value: "modello",
            },
            {
                text: "Anno",
                value: "anno",
            },
            {
                text: "Parentela",
                value: "grado_parentela",
            },
            {
                text: "Targa",
                value: "targa",
            },
        ],
        tipologie_ania: tipologieAnia,
        tipologie_targa: tipologieTarga,
    }),
    components: {
        "teo-autocomplete": AutocompleteVue,
        "invalid-feedback": InvalidFeedback,
        "date-picker": DatePicker,
        "appendice-inclusione-form": AppendiceInclusioneFormVue,
        "appendice-esclusione-form": AppendiceEsclusioneFormVue,
        BoxFirma,
    },
    created() {
        this.fetchStatiQuotazione();
        this.quotazione.stato_quotazione = {};
    },
    async mounted() {
        if (this.mode == "EDIT_MODE") {
            this.id = this.$route.params.id;
            await this.fetchQuotazione(this.id);
            this.alert.message = this.$route.params.message;
            this.alert.type = this.$route.params.type;
            this.editMode = true;

            // if (this.quotazione.stato_quotazione.cod == 1.5) {
            //   // alert("calcolo");
            //   this.calcoloQuotazione(); // 6940
            // }
        } else if (this.mode == "ADD_NEW_MODE") {
            this.editMode = false;
        }
    },

    methods: {
        async cambiaDoppiaCircolazione() {
            // if (this.quotazione.doppia_circolazione) {
            //   this.deroga = true;
            // }
            this.calcoloQuotazione();
        },
        async fetchQuotazione(id) {
            await Api.get(process.env.VUE_APP_API_URL + "/api/quotazioni/" + id)
                .then((response) => {
                    this.loading = true;
                    this.quotazione = response.data.data;
                    this.premio = response.data.data.premio;
                    const veicoli = response.data.data.veicoli_storici;

                    this.forceCheck = this.quotazione?.premio_forzato;
                    this.veicoli_storici = veicoli;

                    if (response.data.data.colore == "Verde") {
                        //this.deroga = false;
                        this.chip = "green";
                    }
                    if (response.data.data.colore == "Giallo") {
                        //this.deroga = true;
                        this.chip = "yellow";
                    }
                    if (response.data.data.colore == "Rosso") {
                        //this.deroga = true;
                        this.chip = "red";
                    }
                    //this.oldDeroga = this.deroga;
                    this.loading = false;
                })
                .catch((error) => {
                    if (error.response) {
                        errorCallback(error);
                        this.errors = error.response.data.errors;
                    }
                });
        },

        async cambioStato() {
            var apiURL =
                process.env.VUE_APP_API_URL + "/api/quotazioni/cambioStato";
            let formData = new FormData();
            formData.append(
                "stato_quotazione_id",
                this.quotazione.stato_quotazione_id,
            );
            formData.append("id", this.quotazione.id);
            Api.post(apiURL, formData, {}).catch((error) => {
                this.errors = Object.values(error.response.data.errors).flat();
                this.alert.message = null;
                // console.log(this.errors.length);
                this.alert.type = "error";
                this.addDocumentDialog = false;
            });
        },
        async rigeneraDocumenti() {
            // "api/v1/goold/emissione/{$quotazione_id}/rigenera-documenti-emissione"
            var apiURL =
                process.env.VUE_APP_API_URL +
                `/api/v1/goold/emissione/${this.quotazione.id}/rigenera-documenti-emissione`;

            Api.post(apiURL, {}, {}).catch((error) => {
                // this.errors = Object.values(error.response.data.errors).flat();
                // this.alert.message = null;
                console.log(error);
                // this.alert.type = "error";
                // this.addDocumentDialog = false;
            });
        },
        async addDocument() {
            var apiURL = process.env.VUE_APP_API_URL + "/api/quotazioni/upload";
            let formData = new FormData();
            formData.append("file", this.file);
            formData.append("nome_file", this.nome_file);
            formData.append("id", this.quotazione.id);
            formData.append("collection", this.collection);
            formData.append("model", "Quotazione");
            axios
                .post(apiURL, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then(() => {
                    this.fetchQuotazione(this.id);
                    this.errors = [];
                    this.addDocumentDialog = false;
                    this.alert.type = "success";
                    this.alert.message = "Documento caricato con successo";
                })
                .catch((error) => {
                    this.errors = Object.values(
                        error.response.data.errors,
                    ).flat();
                    this.alert.message = null;

                    this.alert.type = "error";
                    this.addDocumentDialog = false;
                });
        },
        openAniaDialog(json) {
            this.aniaDialog = true;
            this.json = json;
        },
        async updateAniaData(veicolo) {
            this.loading = true;
            const response = await Api.post(
                process.env.VUE_APP_API_URL +
                    `/api/veicolo_update_dati_ania/${veicolo.id}/${veicolo.targa}/${veicolo.tipologia_ania}`,
            );
            veicolo.json = JSON.stringify(response.data);
            this.loading = false;
        },
        checkCfisc(cfisc) {
            if (cfisc === this.quotazione.cfisc) return "green";
            else return "red";
        },
        async revisioneDoc() {
            this.loading = true;
            const payload = {
                revisioneNote: this.revisioneNote,
            };
            await Api.post(
                process.env.VUE_APP_API_URL +
                    "/api/quotazioni/revisioneDoc/" +
                    this.id,
                payload,
            )
                .then((response) => {
                    this.response = response;
                    if (this.response.status == 201) {
                        this.response.type = "success";
                    }
                    this.$router.push({
                        name: "quotazione_index",
                        params: {
                            message: this.response.data.message,
                            type: "success",
                        },
                    });
                })
                .catch((error) => {
                    if (error.response) {
                        errorCallback(error);
                        this.errors = error.response.data.errors;
                    }
                });
        },
        async emettiPolizza() {
            this.loading = true;
            await Api.post(
                process.env.VUE_APP_API_URL +
                    "/api/quotazioni/emetti/" +
                    this.id +
                    "?decorrenza=" +
                    this.quotazione.decorrenza,
                { options: this.options },
            )
                .then((response) => {
                    // TODO: Eliminare tutto mettere un unica gesione degli errori lato server
                    this.response = response.data;
                    console.log(this.response);
                    if (this.response.result == 0) {
                        this.response.type = "success";
                        this.$router.push({
                            name: "quotazione_index",
                            params: {
                                message: "Polizza emessa correttamente",
                                type: this.response.type,
                            },
                        });
                    } else if (this.response.result == 1) {
                        this.response.type = "warning";
                        this.$router.push({
                            name: "quotazione_index",
                            params: {
                                message:
                                    "WARNING " +
                                    this.response.Error[0]["descError"],
                                type: this.response.type,
                            },
                        });
                    } else if (this.response.result == 2) {
                        this.response.type = "error";
                        this.$router.push({
                            name: "quotazione_index",
                            params: {
                                message: this.response.Error[0]["descError"],
                                type: this.response.type,
                            },
                        });
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        errorCallback(error);
                        this.errors = error.response.data.errors;
                    }
                });
            this.dialogEmetti = false;
            this.loading = false;
        },
        async previewEmissione() {
            this.loading = true;
            await Api.post(
                process.env.VUE_APP_API_URL +
                    "/api/preview_quotazione_per_emissione/" +
                    this.id +
                    "?decorrenza=" +
                    this.quotazione.decorrenza,
                { options: this.options },
            ).then((response) => {
                this.rightSideDrawer = true;
                this.payloadPreview = response;
            });
            this.loading = false;
        },

        async richiediEmissionePolizza() {
            this.loading = true;
            await Api.post(
                process.env.VUE_APP_API_URL +
                    "/api/quotazioni/richiediEmissione/" +
                    this.id,
            )
                .then((response) => {
                    this.response = response;
                    if (this.response.status == 201) {
                        this.response.type = "success";
                    }
                    this.$router.push({
                        name: "quotazione_index",
                        params: {
                            message: this.response.data.message,
                            type: "success",
                        },
                    });
                })
                .catch((error) => {
                    if (error.response) {
                        errorCallback(error);
                        this.errors = error.response.data.errors;
                    }
                });
            this.dialogRichiediEmissione = false;
            this.loading = false;
        },

        previewStornaPolizza() {
            console.log("previewStornaPolizza", this.quotazione);
        },
        async stornaPolizza() {
            this.loading = true;
            await Api.post(
                process.env.VUE_APP_API_URL +
                    `/api/storna_emissione/${this.quotazione.id}/${this.quotazione.verti_policy_number}`,
            )
                .then((response) => {
                    this.response = response;
                    if (this.response.status == 200) {
                        this.response.type = "success";

                        this.id = this.$route.params.id;
                        this.fetchQuotazione(this.id);
                        this.editMode = true;

                        this.loading = false;
                        this.dialogStorna = false;
                        console.log("stornaPolizza", this.response.status);
                    }
                })
                .catch((error) => {
                    this.dialogStorna = false;
                    // alert("errore");
                    errorCallback(error);
                    this.response = error.response;
                    this.errors = error.response.data.errors;
                    this.response.type = "error";
                    this.loading = false;

                    console.log("stornaPolizza", this.response.status, error);
                });
            this.dialogStorna = false;
            this.loading = false;
        },

        async confermaDoc() {
            this.loading = true;

            await Api.post(
                process.env.VUE_APP_API_URL +
                    "/api/quotazioni/confermaDoc/" +
                    this.id,
            )
                .then((response) => {
                    this.response = response;
                    if (this.response.status == 201) {
                        this.response.type = "success";
                    }
                    this.$router.push({
                        name: "quotazione_index",
                        params: {
                            message: this.response.data.message,
                            type: "success",
                        },
                    });
                })
                .catch((error) => {
                    if (error.response) {
                        //console.log(error.response.status);
                        errorCallback(error);
                        this.errors = error.response.data.errors;
                    }
                });
        },

        documentUrl(item) {
            return (
                process.env.VUE_APP_API_URL +
                "/api/quotazioni/getDocument/" +
                item.collection_name +
                "/" +
                item.id
            );
        },
        librettoUrl(item) {
            // https://teo.sarassoroberto.it/api/quotazioni/getDocument/card_assistenza_stradale/7113
            // https://teo.sarassoroberto.it/api/quotazioni/getDocument/libretto/7110

            // https://teo.sarassoroberto.it/api/quotazioni/getDocument/libretto/7110
            if (item.media) {
                const media = item.media.find(
                    (m) => m.collection_name == "libretto",
                );
                if (media) {
                    return (
                        process.env.VUE_APP_API_URL +
                        "/api/quotazioni/getDocument/" +
                        "libretto" +
                        "/" +
                        media.id
                    );
                } else {
                    return false;
                }
            } else {
                return false;
            }
        },
        precontrattualeUrl() {},
        async fetchStatiQuotazione() {
            await Api.get(process.env.VUE_APP_API_URL + "/api/stati_quotazione")
                .then((response) => {
                    this.stati_quotazione = response.data;
                })
                .catch((error) => {
                    errorCallback(error);
                    this.errors = error.response.data.errors;
                });
        },

        async onInviaRichiestaDiFirma() {
            await Api.post(
                process.env.VUE_APP_API_URL +
                    `/api/v1/policies/${this.quotazione?.polizza?.polizza?.id}/signature_request/send`,
            )
                .then(() => {
                    this.fetchQuotazione(this.id);
                    this.boxFirmaError = false;
                })
                .catch((error) => {
                    // errorCallback(error);
                    this.boxFirmaError = error.response.data;
                });
        },

        /* ------------------------------------------------------------------
                 AVENTE DIRITTO
                -------------------------------------------------------------------- */
        assegnaAventeDirittoSivi(veicolo, quotazione) {
            console.log("assegnaAventeDirittoSivi", veicolo, quotazione);
            if (veicolo.sivi_tax_code) {
                if (veicolo.sivi_tax_code == quotazione.cfisc) {
                    veicolo.avente_diritto_cognome = quotazione.cognome;
                    veicolo.avente_diritto_nome = quotazione?.nome;
                    veicolo.avente_diritto_codice_fiscale_iva =
                        quotazione.cfisc;
                    veicolo.active_sivi_tax_code = true;
                    veicolo.alert =
                        "il codice fiscale corrisponde a quello del contraente";
                    return veicolo;
                } else {
                    veicolo.avente_diritto_cognome = "";
                    veicolo.avente_diritto_nome = "";
                    veicolo.avente_diritto_codice_fiscale_iva =
                        veicolo.sivi_tax_code;
                    veicolo.alert =
                        "il codice fiscale indicato dal SIVI non corrisponde a quello del contraente";
                    return veicolo;
                }
            } else {
                veicolo.alert = "non sono presenti informazioni sul SIVI";
            }
        },
        assegnaAventeDirittoAtrc(veicolo, quotazione) {
            if (veicolo.atrc_beneficiary_tax_code) {
                if (veicolo.atrc_beneficiary_tax_code == quotazione.cfisc) {
                    veicolo.avente_diritto_cognome = quotazione.cognome;
                    veicolo.avente_diritto_nome = quotazione?.nome;
                    veicolo.avente_diritto_codice_fiscale_iva =
                        quotazione.cfisc;
                    veicolo.active_atrc_beneficiary_tax_code = true;
                    veicolo.alert =
                        "il codice fiscale dell' avente diritto coincide con il contraente indicato dal sivi";
                    return veicolo;
                } else {
                    veicolo.avente_diritto_cognome =
                        veicolo.atrc_beneficiary_last_name;
                    veicolo.avente_diritto_nome =
                        veicolo.atrc_beneficiary_first_name;
                    veicolo.avente_diritto_codice_fiscale_iva =
                        veicolo.atrc_beneficiary_tax_code;
                    veicolo.alert =
                        "il codice fiscale dell' avente diritto è stato preso dall'attestato di rischio e non coincide con il contraente";
                    return veicolo;
                }
            } else {
                veicolo.alert =
                    "non sono presenti informazioni sul ATRC (attestato di rischio)";
            }
        },
        assegnaAventeDirittoContraente(veicolo, quotazione) {
            veicolo.avente_diritto_cognome = quotazione.cognome;
            veicolo.avente_diritto_nome = quotazione?.nome;
            veicolo.avente_diritto_codice_fiscale_iva = quotazione.cfisc;
            veicolo.active_teo_cliente = true;
            // veicolo.alert.aventeDiritto = = "
            veicolo.alert = "Il contraente è l'avente diritto";
        },
        assegnaQuintaliDaSivi(veicolo) {
            // alert(veicolo.sivi_vehicle_weight);
            veicolo.alert = "Quintali assegnati dal sivi";
            veicolo.quintali = veicolo.sivi_vehicle_weight;
            // veicolo.quintali = 100;
        },

        assegnaQuintaliDaLibretto() {
            // veicolo.item.sivi_vehicle_weight
        },

        async storeQuotazione() {
            const payload = {
                auto: this.veicoli_storici,
                ...this.quotazione,
            };
            await Api.post(
                process.env.VUE_APP_API_URL + "/api/quotazioni",
                payload,
            )
                .then((response) => {
                    this.response = response;
                    if (this.response.status == 201) {
                        this.response.type = "success";
                    }
                    this.$router.push({
                        name: "quotazione_index",
                        params: {
                            message: this.response.data.message,
                            type: "success",
                        },
                    });
                })
                .catch((error) => {
                    if (error.response) {
                        //console.log(error.response.status);
                        errorCallback(error);
                        this.errors = error.response.data.errors;
                    }
                });
        },
        async updateQuotazione(redirect = true) {
            // if(this.quotazione.sesso == 'A'){
            //        this.quotazione.nome = null
            //     }

            const payload = {
                auto: this.veicoli_storici,
                ...this.quotazione,
            };
            await Api.put(
                process.env.VUE_APP_API_URL + "/api/quotazioni/" + this.id,
                payload,
            )
                .then((response) => {
                    this.response = response;
                    if (this.response.status == 201) {
                        this.response.type = "success";
                    }
                    if (redirect) {
                        this.$router.push({
                            name: "quotazione_index",
                            params: {
                                message: this.response.data.message,
                                type: "success",
                            },
                        });
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        //console.log(error.response.status);
                        errorCallback(error);
                        this.errors = error.response.data.errors;
                    }
                });
        },
        async updateQuotazionePerEmissione() {
            // console.log("veicoli_storici", this.veicoli_storici);

            this.quotazione.veicoli_storici = this.veicoli_storici;

            await Api.put(
                process.env.VUE_APP_API_URL +
                    "/api/update_quotazione_per_emissione/" +
                    this.id,
                this.quotazione,
            )
                .then((response) => {
                    this.response = response;
                    if (this.response.status == 201) {
                        this.response.type = "success";
                    }
                })
                .catch((error) => {
                    error;
                    // if (error.response) {
                    //     //console.log(error.response.status);
                    //     errorCallback(error);
                    //     this.errors = error.response.data.errors;
                    // }
                });
        },

        async confermaQuotazione() {
            this.loading = true;
            const payload = {
                auto: this.veicoli_storici,
                ...this.quotazione,
            };
            await Api.post(
                process.env.VUE_APP_API_URL +
                    "/api/quotazioni/conferma/" +
                    this.id,
                payload,
            )
                .then((response) => {
                    this.response = response;
                    if (this.response.status == 201) {
                        this.response.type = "success";
                    }
                    this.$router.push({
                        name: "quotazione_index",
                        params: {
                            message: this.response.data.message,
                            type: "success",
                        },
                    });
                })
                .catch((error) => {
                    if (error.response) {
                        //console.log(error.response.status);
                        errorCallback(error);
                        this.errors = error.response.data.errors;
                        this.loading = false;
                        this.dialogConferma = false;
                    }
                });
        },
        async confermaQuotazioneDiretta() {
            this.loading = true;
            const payload = {
                auto: this.veicoli_storici,
                ...this.quotazione,
            };
            await Api.post(
                process.env.VUE_APP_API_URL +
                    "/api/v1/goold/quotations/directConfirm/" +
                    this.id,
                payload,
            )
                .then((response) => {
                    this.response = response;
                    if (this.response.status == 201) {
                        this.response.type = "success";
                    }
                    this.$router.push({
                        name: "quotazione_index",
                        params: {
                            message: this.response.data.message,
                            type: "success",
                        },
                    });
                })
                .catch((error) => {
                    if (error.response) {
                        //console.log(error.response.status);
                        errorCallback(error);
                        this.errors = error.response.data.errors;
                        this.loading = false;
                        this.dialogConferma = false;
                    }
                });
        },

        async approvaQuotazione() {
            this.loading = true;

            await Api.post(
                process.env.VUE_APP_API_URL +
                    "/api/quotazioni/approva/" +
                    this.id,
            )
                .then((response) => {
                    this.response = response;
                    if (this.response.status == 201) {
                        this.response.type = "success";
                    }
                    this.$router.push({
                        name: "quotazione_index",
                        params: {
                            message: this.response.data.message,
                            type: "success",
                        },
                    });
                })
                .catch((error) => {
                    if (error.response) {
                        errorCallback(error);
                        this.errors = error.response.data.errors;
                    }
                });
        },
        async rinnovoPolizza() {
            this.loading = true;
            // /quotazioni/rinnovo-polizza/{quotazione}
            await Api.post(
                process.env.VUE_APP_API_URL +
                    "/api/quotazioni/rinnovo-polizza/" +
                    this.id,
            )
                .then((response) => {
                    this.response = response;
                    // if (this.response.status == 201) {
                    //     this.response.type = "success";
                    // }
                    this.$router.push({
                        name: "quotazione_index",
                        params: {
                            message: this.response.data.message,
                            type: "success",
                        },
                    });
                })
                .catch((error) => {
                    if (error.response) {
                        errorCallback(error);
                        this.errors = error.response.data.errors;
                    }
                });
        },

        async rifiutaQuotazione() {
            this.loading = true;
            await Api.post(
                process.env.VUE_APP_API_URL +
                    "/api/quotazioni/rifiuta/" +
                    this.id,
            )
                .then((response) => {
                    this.response = response;
                    if (this.response.status == 201) {
                        this.response.type = "success";
                    }
                    this.$router.push({
                        name: "quotazione_index",
                        params: {
                            message: this.response.data.message,
                            type: "success",
                        },
                    });
                })
                .catch((error) => {
                    if (error.response) {
                        //console.log(error.response.status);
                        errorCallback(error);
                        this.errors = error.response.data.errors;
                    }
                });
        },
        async fetchMarche() {
            //this.loading = true;
            const response = await Api.get(
                process.env.VUE_APP_API_URL + "/api/autostoriche/marche",
            );
            this.marche = response.data;
        },
        async rimandaDatiAKoala() {
            const response = await Api.post(
                // "api/polizza_gold/reimporta_emissione_koala_broker/{$quotazione_id}"
                process.env.VUE_APP_API_URL +
                    "/api/polizza_gold/reimporta_emissione_koala_broker/" +
                    this.quotazione.id,
            );
            this.marche = response.data;
        },

        async fetchData() {
            const response = await Api.get(
                process.env.VUE_APP_API_URL +
                    "/api/autostoriche/search?filter[marca]=" +
                    this.selectedMarca,
            );

            this.items = response.data;
            this.loading = false;
        },

        aggiungiVeicolo() {
            this.selectedItem.auto_storica_id = this.selectedItem.id;
            this.selectedItem.id = null;
            this.selectedItem.valore = this.selectedItem.valore_b;
            this.veicoli_storici.push(this.selectedItem);

            //console.log(this.selectedItem);

            this.selectedMarca = null;
            this.selectedItem = null;
            this.dialog = false;
            this.calcoloQuotazione();
        },

        closeDialog() {
            this.selectedMarca = null;
            this.selectedItem = null;
            this.dialog = false;
            this.loading = false;
        },

        deleteItem(item) {
            // console.log(this.veicoli_storici);
            // console.log(this.veicoli_storici.indexOf(item));
            this.editedIndex = this.veicoli_storici.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },

        deleteItemConfirm() {
            this.veicoli_storici.splice(this.editedIndex, 1);
            this.calcoloQuotazione();
            this.closeDelete();
        },

        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },

        getColor(colore) {
            // console.log(colore);
            if (colore === "verde") return "green";
            else if (colore === "giallo") return "yellow";
            else return "red";
        },

        urlForm() {
            return (
                process.env.VUE_APP_API_URL +
                "/quotazione/cliente/" +
                this.quotazione.uuid
            );
        },
        roleIs(role) {
            //console.log(JSON.parse(window.sessionStorage["roles"]).includes(role));
            return JSON.parse(window.sessionStorage["roles"]).includes(role);
        },
        hasPermission(permission) {
            return HasPermission(permission);
        },
        async calcoloQuotazione() {
            if (
                this.veicoli_storici.length > 0 &&
                this.quotazione.provincia_codice
            ) {
                this.loading = true;
                const payload = {
                    auto: this.veicoli_storici,
                    ...this.quotazione,
                };

                await Api.post(
                    process.env.VUE_APP_API_URL + "/api/quotazioni/calcolo",
                    payload,
                )
                    .then((response) => {
                        if (response.data.colore == "v") {
                            this.quotazione.colore = "Verde";
                            this.chip = "green";
                        }
                        if (response.data.colore == "g") {
                            this.quotazione.colore = "Giallo";
                            this.chip = "yellow";
                        }
                        if (response.data.colore == "r") {
                            //this.deroga = true;
                            this.quotazione.colore = "Rosso";
                            this.chip = "red";
                        }
                        this.quotazione.premio = response.data.premio;
                        this.premio = this.quotazione.premio;

                        this.quotazione.premio_forzato = null;
                        this.colore = this.quotazione.colore;
                        this.quotazione.premio_verti =
                            response.data.premio_verti;
                    })
                    .catch((error) => {
                        if (error.response) {
                            errorCallback(this.errors);
                            this.errors = error.response.data.errors;
                        }
                    });

                this.loading = false;
                console.log("aggiorna calcolo");
            }
        },
    },
    filters: {
        pretty: function (value) {
            return JSON.stringify(JSON.parse(value), null, 2);
        },
    },
    computed: {
        deroga() {
            if (
                this.quotazione.minoreAnni ||
                this.quotazione.colore == "Giallo" ||
                this.quotazione.colore == "Rosso"
            ) {
                return true;
            } else {
                return false;
            }
        },
        alertPremioForzato() {
            if (this.roleIs("super_admin")) return false;
            if (
                Number(this.quotazione.premio_forzato) <
                    Number(this.quotazione.premio) &&
                this.quotazione.premio_forzato != null &&
                this.quotazione.premio_forzato != ""
            ) {
                return true;
            } else {
                return false;
            }
        },
        showCambiaStato() {
            let _return = true;

            if (!this.can("cambiare_stato_quotazione")) _return = false;
            return _return;
        },
        mode() {
            return !this.$route.params.id ? "ADD_NEW_MODE" : "EDIT_MODE";
        },
        showSalvaBtn() {
            let _return = true;
            if (!this.can("conferma_quotazione")) _return = false;
            if (this.mode != "ADD_NEW_MODE") {
                _return = false;
            }
            return _return;
        },
        showAggiornaBtn() {
            let _return = true;
            if (!this.can("conferma_quotazione")) _return = false;
            if (this.mode == "ADD_NEW_MODE") {
                _return = false;
            }
            if (this.quotazione.stato_quotazione.cod > 1.5) {
                _return = false;
            }
            return _return;
        },
        showAggiornaFaseEmissioneBtn() {
            let _return = true;
            if (!window.sessionStorage["roles"].includes("super_admin")) {
                _return = false;
            }
            return _return;
        },
        showConfermaBtn() {
            let _return = true;
            if (!this.can("conferma_quotazione")) _return = false;
            if (this.quotazione.stato_quotazione.cod > 1.5) _return = false;
            if (!this.id) _return = false;
            return _return;
        },
        showApprovaBtn() {
            let _return = true;

            if (this.quotazione.stato_quotazione_id != 2) _return = false;
            if (!this.can("show_quotazioni_suoi_collaboratori"))
                _return = false;
            return _return;
        },
        showRichiediEmissioneBtn() {
            let _return = true;
            if (this.quotazione.stato_quotazione_id != 7) _return = false;
            if (this.can("emettere_polizza_auto_storica")) _return = false;
            return _return;
        },
        showEmettiBtn() {
            return PuoEmetterePolizzaAutoStorica(this.quotazione);
        },
        showTabsAppendici() {
            return PuoEmettereAppendiceAutoStorica(this.quotazione);
        },
        enableEmissioneDecorrenza() {
            // return !(this.quotazione.stato_quotazione_id < 8);
            return false;
        },
        premioEditabile() {
            let _return = true;
            if (this.quotazione.stato_quotazione.cod > 2) _return = false;
            if (
                this.quotazione.stato_quotazione.cod > 1.5 &&
                !this.can("show_quotazioni_suoi_collaboratori")
            )
                _return = false;
            return _return;
        },

        headers_storici() {
            let updatedHeaders = null;
            let headers_base = [
                {
                    text: "",
                    value: "colore",
                },
                {
                    text: "Operazione",
                    value: "operazione",
                },
                {
                    text: "Marca",
                    value: "marca",
                },
                {
                    text: "Modello",
                    value: "modello",
                },
                {
                    text: "Cilindrata",
                    value: "cilindrata",
                },
                {
                    text: "Anno",
                    value: "anno",
                },
                {
                    text: "Valore",
                    value: "valore",
                },
                {
                    text: "Furto",
                    value: "furto",
                },
                {
                    text: "Targa",
                    value: "targa",
                },
                {
                    text: "Codice Fiscale",
                    value: "cfisc",
                },

                {
                    text: "Ania",
                    value: "json",
                },
                { text: "dettagli", value: "data-table-expand" },
            ];

            if (
                this.quotazione.stato_quotazione_id <= 1.5 ||
                this.premioEditabile
            ) {
                // Aggiungi nuovi headers dopo l'elemento con value "modello"
                let newHeaders = [
                    {
                        text: "Actions",
                        value: "actions",
                        sortable: false,
                    },
                ];

                updatedHeaders = addHeaders(
                    headers_base,
                    newHeaders,
                    "data-table-expand",
                ); // Aggiunge un valore alla fine
                // updatedHeaders = removeHeadersByValues(updatedHeaders, "data-table-expand");
            }

            return updatedHeaders ?? headers_base;
        },

        boxFirmaStato() {
            return boxFirmaStato(this.quotazione);
        },
        boxFirmaMedia() {
            return boxFirmaMedia(this.quotazione);
        },
    },
    watch: {
        // "quotazione.sesso" : function () {
        // }
    },

    showAggiornaBtn() {
        let _return = true;
        if (!this.can("conferma_quotazione")) _return = false;
        if (this.mode == "ADD_NEW_MODE") {
            _return = false;
        }
        if (this.quotazione.stato_quotazione.cod > 1.5) {
            _return = false;
        }
        return _return;
    },
    showConfermaBtn() {
        let _return = true;
        if (!this.can("conferma_quotazione")) _return = false;
        if (this.quotazione.stato_quotazione.cod > 1.5) _return = false;
        if (!this.id) _return = false;
        return _return;
    },
    showApprovaBtn() {
        let _return = true;
        if (this.quotazione.stato_quotazione_id != 2) _return = false;
        if (!this.can("show_quotazioni_suoi_collaboratori")) _return = false;
        return _return;
    },
    showRichiediEmissioneBtn() {
        let _return = true;
        if (this.quotazione.stato_quotazione_id != 7) _return = false;
        if (this.can("emettere_polizza_auto_storica")) _return = false;
        return _return;
    },
    showEmettiBtn() {
        // console.log("show", !this.can("emettere_polizza_auto_storica"));
        let _return = true;
        // if ((this.quotazione.stato_quotazione_id != 7) && (this.quotazione.stato_quotazione_id != 11))
        //   _return = false;
        // // if (!this.can('emettere_polizza_auto_storica'))
        // //   _return = false;
        return _return;
    },

    premioEditabile() {
        let _return = true;
        if (this.quotazione.stato_quotazione.cod > 2) _return = false;
        if (
            this.quotazione.stato_quotazione.cod > 1.5 &&
            !this.can("show_quotazioni_suoi_collaboratori")
        )
            _return = false;
        return _return;
    },
};
</script>

<style>
.v-slider__thumb {
    width: 36px;
    height: 36px;
    left: -18px;
}

.v-slider__thumb:before {
    left: 0px;
    top: 0px;
}

.v-slider--horizontal .v-slider__track-container {
    height: 8px;
}
</style>
