<template>
    <v-menu>
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                class="action_menu__btn"
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
            >
                Azioni
            </v-btn>
        </template>
        <v-list class="action_menu__list" dense>
            <v-subheader v-if="subHeader">{{ subHeader }}</v-subheader>
            <v-list-item
                v-for="(item, i) in actions"
                :key="i"
                @click="onActionSelect(item)"
            >
                <v-list-item-icon>
                    <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-menu>
</template>
<script>
import { EventBus } from "../../global-event-bus";
export default {
    name: "action-menu",
    props: { subHeader: String, actions: Array },
    methods: {
        onActionSelect(actionSelected) {
            EventBus.$emit("action-select", actionSelected);
        },
    },
};
</script>

<style>
.v-list-item__title {
    font-size: 18px;
}
</style>
