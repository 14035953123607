var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"data-test":"ClienteSearchForm"}},[_c('v-row',[_c('v-col',[_c('v-text-field',{staticClass:"ma-0",attrs:{"label":"Cerca un cliente: Cognome Nome","cols":"12","lg":"6","clearable":""},on:{"input":_vm.debounceFetchItems},model:{value:(_vm.params.filter.cognome_nome),callback:function ($$v) {_vm.$set(_vm.params.filter, "cognome_nome", $$v)},expression:"params.filter.cognome_nome"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-row',[_c('v-col',[(_vm.clienteSelezionato[0])?_c('div',{staticClass:"d-flex align-end",attrs:{"id":"cliente-selezionato"}},[_c('v-row',{attrs:{"id":"cliente-card"}},[_c('v-col',{attrs:{"col":"12","md":"6"}},[_c('h3',{staticClass:"primary--text ma-0"},[_vm._v(" "+_vm._s(_vm.clienteSelezionato[0].nome)+" "+_vm._s(_vm.clienteSelezionato[0].cognome)+" ")]),(_vm.clienteSelezionatoNonHaVeicoli)?_c('div',[_c('p',[_vm._v("Non ha veicoli inseriti in Teo")])]):_vm._e(),(
                                        _vm.clienteSelezionatoHaVeicoli &&
                                        !_vm.veicoloSelezionato[0]
                                    )?_c('div',[_vm._v(" Seleziona un veicolo di uso quotidiano ")]):_vm._e(),(
                                        _vm.clienteSelezionatoHaVeicoli &&
                                        _vm.veicoloSelezionato[0]
                                    )?_c('div',[_vm._v(" Procedi alla quotazione del veicolo "),_c('br'),_c('strong',[_vm._v(" "+_vm._s(_vm.veicoloSelezionato[0].marca)+" "+_vm._s(_vm.veicoloSelezionato[0].modello)+" "+_vm._s(_vm.veicoloSelezionato[0].modello)+" ")]),_c('br'),_vm._v(" Targato "),_c('strong',[_vm._v(_vm._s(_vm.veicoloSelezionato[0].targa))])]):_vm._e()]),_c('v-col',{staticClass:"d-flex align-center justify-center",attrs:{"cols":"12","md":"auto"}},[(
                                        _vm.veicoloSelezionato[0] ||
                                        _vm.clienteSelezionatoNonHaVeicoli
                                    )?_c('v-btn',_vm._b({on:{"click":_vm.onSearchResultSelected}},'v-btn',_vm.btn_primary_style,false),[_vm._v(" Vai Avanti ")]):_vm._e()],1)],1)],1):_vm._e()])],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-data-table',_vm._b({attrs:{"headers":_vm.headers,"hide-main-header":_vm.items.length == 0,"items":_vm.items,"loading":_vm.loading,"items-per-page":_vm.itemsPerPage,"page":_vm.page,"fixed-header":true,"single-select":true,"item-class":_vm.getRowClass,"item-key":"codice_fiscale","show-select":""},on:{"page":_vm.onPageChange,"item-selected":_vm.onRowClick},scopedSlots:_vm._u([(_vm.items.length === 0)?{key:"body",fn:function(){return [_c('tr',[_c('td',{staticClass:"pa-5 w-100",attrs:{"colspan":"100%"}},[_c('v-alert',_vm._b({attrs:{"icon":"mdi-account"}},'v-alert',_vm.alert_warning_style,false),[_vm._v(" Nessun Cliente Trovato ")])],1)])]},proxy:true}:null],null,true),model:{value:(_vm.clienteSelezionato),callback:function ($$v) {_vm.clienteSelezionato=$$v},expression:"clienteSelezionato"}},'v-data-table',_vm.table_selectable_style,false))],1),(_vm.clienteSelezionatoHaVeicoli)?_c('v-col',{attrs:{"cols":"12","lg":"6"}},[(_vm.clienteSelezionato[0])?_c('div',{attrs:{"id":"veicoli_moderni_cliente"}},[_c('v-data-table',_vm._b({attrs:{"hide-default-footer":true,"items":_vm.uniqBy(
                            _vm.orderBy(
                                _vm.clienteSelezionato[0]?.veicoli_moderni,
                                ['quotazione_id'],
                                ['desc'],
                            ),
                            'targa',
                        ),"item-key":"veicolo_id","headers":[
                        {
                            text: 'id',
                            value: 'veicolo_id',
                        },
                        {
                            text: 'Targa',
                            value: 'targa',
                            class: 'pa-5',
                            align: 'start',
                            width: 100,
                        },
                        {
                            text: 'Marca',
                            value: 'marca',
                            class: 'pa-1',
                            align: 'start',
                            width: 100,
                        },
                        {
                            text: 'Modello',
                            value: 'modello',
                            class: 'pa-1',
                            align: 'start',
                            width: 100,
                        },
                        {
                            text: 'Anno',
                            value: 'anno',
                            class: 'pa-1',
                            align: 'start',
                            width: 100,
                        },
                        {
                            text: 'Rif',
                            value: 'quotazione_id',
                            class: 'pa-1',
                            align: 'start',
                            width: 100,
                        },
                    ]},model:{value:(_vm.veicoloSelezionato),callback:function ($$v) {_vm.veicoloSelezionato=$$v},expression:"veicoloSelezionato"}},'v-data-table',_vm.table_selectable_style,false))],1):_vm._e()]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }