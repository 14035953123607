import { Api, errorCallback } from "../../http_tools/axiosModule";
import { getField, updateField } from "vuex-map-fields";
import { SpatieQueryBuilder } from "../../http_tools/spatie/query-builder/index.js";

const headers = [
    {
        text: "#",
        width: "2rem",
        align: "right",
        value: "id",
        cellClass: "align-center",
    },
    {
        text: "attivo",
        width: "2rem",
        align: "center",
        value: "mandato.attivo",
        cellClass: "align-center",
    },
    // {
    //   text: 'Compagnia',
    //   width: '15rem',
    //   align: 'left',
    //   value: 'compagnia_contrattuale',
    //   cellClass: 'align-left',
    // },
    {
        text: "Prodotto",
        width: "auto",
        align: "left",
        filterable: false,
        value: "nome",
        cellClass: "align-center",
    },
    {
        text: "Ramo",
        width: "15rem",
        align: "left",
        filterable: true,
        value: "ramo_id",
        cellClass: "align-center",
    },
    {
        text: "Settore",
        align: "left",
        width: "10rem",
        filterable: true,
        value: "settore_id",
        cellClass: "align-center",
    },

    {
        width: "10rem",
        text: "provvigione",
        align: "right",
        value: "mandato.percentuale_provvigione_attiva",
        cellClass: "align-center",
    },
    {
        text: "data inizio mandato",
        width: "10rem",
        align: "left",
        value: "mandato.data_inizio_mandato",
        cellClass: "align-center",
    },
    { text: "Azioni", value: "actions", sortable: false },
]; // TODO IMPLEMENTARE IL FILTRO
const filters = {
    compagnia_id: [],
    nome: [],
    ramo_id: [],
    percentuale_provvigione_attiva: [],
    data_inizio_mandato: [],
};

const state = {
    // categoria_prodotto: [],
    headers: headers,
    filters: filters,
    rerenderTabellaProdotti: 0,
    categoria_prodotto: [],
    categoria_prodotto_filtred: [],
    compagnia_fornitore_id: null,
    loading: false,
    errors: {},
    new_categoria_prodotto: {
        // FIX: ELIMINARE METTERE STATO PREDEFINITO
        compagnia_contrattuale_id: null,
        search_input: "",
        nome: "",
        mandato: {
            percentuale_provvigione_attiva: "0",
            data_inizio_mandato: new Date().toJSON().slice(0, 10),
            mandatabile_type: "App\\Models\\CategoriaProdotto",

            compagnia_fornitore_id: null,
        },
    },
    dialog_delete_mandato_prodotto: true,
    loading_delete_mandato_prodotto: true,
    mandato_prodotto_da_eliminare: {},
};

const getters = {
    getField,
    // TODO: impostare i valori filtrati come getter
    categoria_prodotto_filtred() {
        // return this.desserts.filter((d) => {
        //     return Object.keys(this.filters).every((f) => {
        //         //console.log("filterDesserts", this.filters[f])
        //         return this.filters[f].length < 1 || this.filters[f].includes(d[f]);
        //     });
        // });
    },
    payload_new_categoria_prodotto(state) {
        return { ...state.new_categoria_prodotto };
        // return {...state.new_categoria_prodotto ...};
    },
};

const actions = {
    // rootState
    /**
     * _queryStringParams
     * @param {*} param0
     * @param {*} _queryStringParams
     */
    async ACTION_SEARCH_MANDATO_PRODOTTO({ commit }, _queryStringParams) {
        commit("setLoading", true);

        const spatieParams = SpatieQueryBuilder.stringify(_queryStringParams);
        console.log("_queryStringParams", spatieParams);
        const res = await Api.get(
            process.env.VUE_APP_API_URL +
                `/api/categoria_prodotto/search?${spatieParams}`,
        );

        commit("SET_INDEX_MANDATO_PRODOTTO", res.data.data);
        commit("setLoading", false);
    },

    // DELETE
    async deleteCategoriaProdottoRow({ commit, state, dispatch }, prodotto) {
        console.log("deleteCategoriaProdottoRow", prodotto.id);

        state.categoria_prodotto = [
            ...state.categoria_prodotto.filter((p) => prodotto.id != p.id),
        ];

        commit("setLoading", true);
        commit("setErrors", {});
        let reqOptions = {
            url:
                process.env.VUE_APP_API_URL +
                "/api/categoria_prodotto/" +
                prodotto.id,
            method: "DELETE",
        };

        Api.request(reqOptions)
            .then((res) => {
                // TODO: fare versione giusta delprodotto nella response
                res;
                const _queryStringParams = {
                    compagnia_fornitore_id: state.compagnia_fornitore_id,
                    prodotto: {
                        compagnia_contrattuale_id:
                            state.new_categoria_prodotto
                                .compagnia_contrattuale_id,
                    },
                };
                dispatch("ACTION_SEARCH_MANDATO_PRODOTTO", _queryStringParams);
                commit("setLoading", false);
            })
            .catch(function (errors) {
                console.log("catch", errors.response.data.errors);
                errorCallback(errors);
                commit("setLoading", false);
            });
    },

    async action_add_new_categoria_prodotto_row(
        { commit, state, dispatch },
        prodottoCategoria,
    ) {
        commit("setLoading", true);
        commit("setErrors", {});

        let reqOptions = {
            url: process.env.VUE_APP_API_URL + "/api/categoria_prodotto",
            method: "POST",
            data: JSON.stringify(prodottoCategoria),
        };

        Api.request(reqOptions)
            .then((res) => {
                // TODO: fare versione giusta delprodotto nella response
                res;

                const _queryStringParams = {
                    compagnia_fornitore_id: state.compagnia_fornitore_id,
                    prodotto: {
                        compagnia_contrattuale_id:
                            state.new_categoria_prodotto
                                .compagnia_contrattuale_id,
                    },
                };

                dispatch("ACTION_SEARCH_MANDATO_PRODOTTO", _queryStringParams);

                commit("setLoading", false);
            })
            .catch(function (errors) {
                // TODO: ERROR MODULE
                const status = errors.response.request.status;
                if (status == 422) {
                    commit("setErrors", errors.response.data.errors);
                    commit("setNotification", {
                        message: errors.response.data.message,
                        type: "error",
                    });
                    commit("setLoading", false);
                } else {
                    errorCallback(errors);
                }
            });
        commit("setLoading", false);
    },

    async action_categoria_prodotto_row_update(
        { commit, state, dispatch },
        prodottoCategoria,
    ) {
        // console.log("action_categoria_prodotto_row_update",prodottoCategoria.id)
        commit("setLoading", true);
        commit("setErrors", {});

        let reqOptions = {
            url:
                process.env.VUE_APP_API_URL +
                "/api/categoria_prodotto/" +
                prodottoCategoria.id,
            method: "PUT",
            data: JSON.stringify(prodottoCategoria),
        };

        Api.request(reqOptions)
            .then((res) => {
                // TODO: fare versione giusta delprodotto nella response
                res, state, dispatch;

                commit("setLoading", false);
            })
            .catch(function (errors) {
                // TODO: ERROR MODULE
                const status = errors.response.request.status;
                if (status == 422) {
                    commit("setErrors", errors.response.data.errors);
                    commit("setNotification", {
                        message: errors.response.data.message,
                        type: "error",
                    });
                    commit("setLoading", false);
                } else {
                    errorCallback(errors);
                }
            });
        commit("setLoading", false);
    },
};

const mutations = {
    updateField,
    setLoading: (state, loading) => {
        state.loading = loading;
    },
    setNotification: (state, message) => {
        state.message = message;
    },
    setErrors: (state, errors) => {
        state.errors = errors;
    },
    SET_INDEX_MANDATO_PRODOTTO: (state, categoria_prodotto) => {
        // console.log(JSON.stringify(categoria_prodotto,null,2))
        state.categoria_prodotto = categoria_prodotto;
    },
    // TODO implementare il filtro
    // addEvents: (state, todo) => state.events.unshift(todo),
    // TODO
    removeCategoriaProdotto: (state, categoria_prodotto_id) => (
        state.categoria_prodotto.filter(
            (res) => res.id !== categoria_prodotto_id,
        ),
        state.categoria_prodotto.splice((res) => res.id, 1)
    ),
    setResetTabellaProdotti: (state, compagnia_fornitore_id) => {
        // alert("setResetTabellaProdotti "+ state.rerenderTabellaProdotti);
        state.rerenderTabellaProdotti += 1;
        state.categoria_prodotto = [];
        state.new_categoria_prodotto.compagnia_contrattuale_id =
            compagnia_fornitore_id;

        // const reset = {
        //   categoria_prodotto:[],
        //   new_categoria_prodotto: {
        //     // FIX: ELIMINARE METTERE STATO PREDEFINITO
        //     compagnia_contrattuale_id: null,
        //     search_input :"",
        //     nome: "",
        //     mandato: {
        //       percentuale_provvigione_attiva: "0",
        //       data_inizio_mandato: new Date().toJSON().slice(0, 10),
        //       mandatabile_type: "App\\Models\\CategoriaProdotto",

        //       compagnia_fornitore_id: compagnia_fornitore_id,
        //     },
        //   },
        // }

        // eslint-disable-next-line
        // state = { ...state, ...reset }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
