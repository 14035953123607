// import { VehicleCategoryEnum } from "@/enum/QuiXA/VehicleCategoryEnum";
import { QuixaCoverage } from "@/v-form-base/quixa/form/CoveragesQuixaDisponibiliList.js";
import VehicleCategoryObj from "@/enum/QuiXA/VehicleCategoryObj";

export function filterByVehicleCategory(vehicleCategory, garanzieEsistenti) {
  return garanzieEsistenti.filter(g =>
    g.VehicleCategory.includes(vehicleCategory)
  );
}

export function applyRequirements(_garanzieSelezionate, garanzieEsistenti) {
  const garanzieSelezionate = [..._garanzieSelezionate];
  const selectedCoverageCode = garanzieSelezionate.map(v => v.code);
  const selectedCoverage = garanzieEsistenti.find(g => g.code === selectedCoverageCode);

  if (selectedCoverage && selectedCoverage.Requires.length > 0) {
    selectedCoverage.Requires.forEach(reqCode => {
      const requiredCoverage = garanzieEsistenti.find(g => g.code === reqCode);
      if (requiredCoverage && !garanzieSelezionate.includes(requiredCoverage)) {
        garanzieSelezionate.push(requiredCoverage);
      }
    });
  }
  return _.uniqBy(garanzieSelezionate, 'code');
  // return garanzieSelezionate
}

