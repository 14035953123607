import { MaritalStatusDtoEnum } from "@/enum/QuiXA/MaritalStatusDtoEnum";

export const wrap_style = {
    col: 12,
    color: "grey lighten-5",
    elevation: 0,
    class: "pa-1 ma-1",
    ripple: false,
};

export const date_picker_style = {
    label: "Data",
    type: "text",
    ext: "date",
    locale: "it",
    col: 4,
};

export const civil_status_select_style = {
    type: "select",
    class: "text-overline",
    col: 4,

    multiple: false,
    returnObject: false,
    itemValue: "value",
    itemText: "label",
    items: MaritalStatusDtoEnum,
};

export const select_style = {
    type: "select",
    solo: false,
    class: "text-overline",
    col: 12,

    multiple: false,
    returnObject: false,
    itemValue: "value",
    itemText: "label",
};

export const radio_yes_or_no_style = {
    type: "radio",
    mandatory: true,
    // toCtrl: ({value} ) => Boolean(Number(value)),
    row: true,
    multiple: false, // Non selezionare più opzioni
    returnObject: false, // Restituisce solo il valore selezionato, non l'intero oggetto
    value: "value", // La proprietà "value" che sarà utilizzata per il binding del radio button
    error: true,
    options: [
        {
            value: "1",
            label: "si",
        },
        {
            value: "0",
            label: "no",
        },
    ],
};

export const yes_or_not_radio_form_style = {
    type: "yes-or-not-radio-form",
    // error:true,
    // "error-messages":["ciccio"]
};
