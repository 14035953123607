import { Api, errorCallback } from "../../http_tools/axiosModule";
import { getField, updateField } from "vuex-map-fields";
import { router } from "@/main";
// import { storeAction } from "./LaravelModule";
// import { router } from "../../main.js"

Api, errorCallback;

const new_collaboratore = {
    provvigioni_settori: [
        {
            id: 1,
            nome: "danni",
            perc_provvigione: 0,
            tipo_provvigione: "PERC_IMPONIBILE",
        },
        {
            id: 2,
            nome: "rca",
            perc_provvigione: 0,
            tipo_provvigione: "PERC_RETROCESSIONE",
        },
        {
            id: 3,
            nome: "vita",
            perc_provvigione: 0,
            tipo_provvigione: "PERC_IMPONIBILE",
        },
    ],
};

const state = {
    loading: false,
    collaboratore: {},
    errors: {},
};

const actions = {
    create: (context) => {
        const { commit } = context;
        commit("setCollaboratore", new_collaboratore);
    },
    show: (context, collaboratore_id) => {
        const { commit } = context;

        commit("setErrors", {});
        Api.get(
            process.env.VUE_APP_API_URL +
                "/api/collaboratore/" +
                collaboratore_id,
        )
            .then((res) => {
                commit("setCollaboratore", res.data.data);
                commit("setLoading", false);
            })
            .catch((error) => {
                // SET ERRORS
                commit("setErrors", {});
                commit("setLoading", false);
                errorCallback(error);
            });
    },

    setAggiorna: (context, collaboratore) => {
        // console.log("AGGIORNA");
        const { commit } = context;

        commit("setLoading", true);
        commit("setErrors", {});

        // TODO: non da implementare
        let reqOptions = {
            url:
                process.env.VUE_APP_API_URL +
                "/api/collaboratore/" +
                collaboratore.id,
            method: "PUT",
            data: JSON.stringify(collaboratore),
        };

        Api.request(reqOptions)
            .then((res) => {
                // TODO: fare versione giusta delprodotto nella response
                res;

                commit("setLoading", false);

                commit("setCollaboratore", collaboratore);
            })
            .catch(function (errors) {
                // console.log('catch', errors.response.data.errors)
                commit("setLoading", false);
                commit("setErrors", errors.response.data.errors);
                errorCallback(errors);
            });
    },

    setNewCollaboratore: ({ commit }, collaboratore) => {
        let reqOptions = {
            url: process.env.VUE_APP_API_URL + "/api/collaboratore",
            method: "POST",
            data: JSON.stringify(collaboratore),
        };

        Api.request(reqOptions)

            .then((response) => {
                const collaboratore = response.data.data;
                commit("setLoading", false);
                commit("setCollaboratore", collaboratore);

                router
                    .push("/modifica/collaboratore/" + collaboratore.id)
                    .catch((err) => {
                        err;
                    });
            })
            .catch((error) => {
                console.log(">> errors", error);
                if (error.response) {
                    commit("setLoading", false);
                    commit("setErrors", error.response.data.errors);

                    errorCallback(error);
                } else {
                    alert("errore ACTION_STORE_COLLABORATORE");
                    console.log("errors ACTION_STORE_COLLABORATORE", error);
                }
            });
    },
    store: (context, collaboratore) => {
        // console.log("store");

        const { commit } = context;
        commit("setErrors", {});

        let reqOptions = {
            url: process.env.VUE_APP_API_URL + "/api/collaboratore",
            method: "POST",
            data: JSON.stringify(collaboratore),
        };

        Api.request(reqOptions)
            .then((response) => {
                const collaboratore = response.data.data.model;
                commit("setCollaboratore", collaboratore);
                commit("setNotification", {
                    message: "collaboratore inserito",
                    type: "success",
                });
                commit("setLoading", false);

                // http://localhost:8080/modifica/collaboratore/3
                router.push("/modifica/collaboratore/" + collaboratore.id);
            })
            .catch((errors) => {
                console.log(">> errors", errors);

                // #TODO: ERROR MODULE
                const status = errors.response.status;
                if (status == 422) {
                    commit("setErrors", errors.response.data.errors);
                    commit("setNotification", {
                        message: errors.response.data.message,
                        type: "error",
                    });
                    commit("setLoading", false);
                } else {
                    errorCallback(errors);
                }
            });
        // router.push('/modifica/collaboratore/')
    },
};

const mutations = {
    updateField,
    setCollaboratore: (state, collaboratore) => {
        state.collaboratore = collaboratore;
    },
    setLoading: (state, loading) => {
        state.loading = loading;
    },
    setNotification: (state, message) => {
        state.message = message;
    },
    setErrors: (state, errors) => {
        state.errors = errors;
    },
};

const getters = {
    getField,
    //,
    //mode: (state) => route.params.id ? "ADD_NEW_MODE" : "EDIT_MODE"
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
