<template>
    <div class="w-100">
        <!-- Bottone per attivare il drawer -->
        <!-- BUTTON PREVIEW -->
        <v-btn
            @click.stop="onButtonPreviewPressed"
            v-bind="BtnMainSideBar"
            :disabled="buttonPreviewDisabledProp"
            block
        >
            {{ buttonPreview }}
        </v-btn>
        <!-- Bottone per attivare il drawer -->

        <!-- DRAWER -->
        <v-navigation-drawer
            v-model="drawerIsOpen"
            fixed
            hide-overlay
            right
            temporary
            :style="{ height: '100vh', width: '33%' }"
        >
            <!-- DRAWER - CONTENUTO -->
            <v-card-text v-if="dataPreview">
                <pre>{{ dataPreview }}</pre>
            </v-card-text>
            <!-- DRAWER - CONTENUTO -->

            <!-- DRAWER - CONTENUTO -->
            <v-card-text v-if="this.errors?.message">
                <v-alert text type="error" v-if="this.errors">
                    <pre>{{ this.errors }}</pre>
                </v-alert>
            </v-card-text>
            <!-- DRAWER - CONTENUTO -->

            <!-- DRAWER - DIALOG -->
            <v-dialog
                data-cy="dialogIsOpen"
                v-model="dialogIsOpen"
                max-width="600px"
            >
                <v-card>
                    <v-card-title class="text-h6 text-center"
                        ><div class="text-center">{{ MessageModalDialog }}</div>
                    </v-card-title>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            v-bind="BtnModalDialog"
                            color="primary"
                            @click="onDialogAnnullaBtnPress"
                        >
                            Annulla
                        </v-btn>
                        <v-btn
                            v-bind="BtnModalDialog"
                            color="red"
                            @click="onDialogOkBtnPress"
                            >OK</v-btn
                        >
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- DRAWER - DIALOG -->

            <v-card-text>
                <v-btn
                    @click.stop="dialogIsOpen = !dialogIsOpen"
                    v-bind="BtnMainSideBar"
                >
                    {{ buttonAction }}
                </v-btn>
                <v-btn
                    @click.stop="drawerIsOpen = !drawerIsOpen"
                    v-bind="BtnMainSideBar"
                    color="red"
                >
                    Annulla
                </v-btn>
            </v-card-text>
        </v-navigation-drawer>
    </div>
</template>

<script>
import { StyleComponent } from "@/components/ui/theme/StyleComponent";
export default {
    name: "QuotazioneDrawer",
    // Example of mixing with a reusable utility function
    mixins: [StyleComponent],
    props: {
        MessageModalDialog: {
            type: String,
            default: () => "Conferma",
        },
        errors: {
            type: [Object, String],
            default: () => {},
        },
        drawerIsOpenProp: {
            type: Boolean,
            default: () => false,
        },
        buttonPreview: {
            type: String,
            default: () => "Visualizza Preview",
        },
        buttonAction: {
            type: String,
            default: () => "Emetti",
        },
        buttonPreviewDisabledProp: {
            type: Boolean,
            default: () => false,
        },
        dataPreview: {
            type: Object, // Tipo dell'oggetto data
            default: () => ({ items: [] }), // Valore di default per data
        },
        drawerOpen: {
            type: Boolean, // Tipo della proprietà drawerOpen
            default: false, // Valore di default per drawerOpen
        },
    },
    data() {
        return {
            drawerIsOpen: this.drawerIsOpenProp,
            dialogIsOpen: false,
        };
    },
    mounted() {},
    methods: {
        onButtonPreviewPressed() {
            this.drawerIsOpen = !this.drawerIsOpen;
            this.$emit("button-preview-pressed");
        },
        onDialogOkBtnPress() {
            this.dialogIsOpen = false;
            this.drawerIsOpen = false;
            this.$emit("button-dialog-ok-pressed");
        },
        onDialogAnnullaBtnPress() {
            this.dialogIsOpen = false;
        },
    },
    computed: {
        fullName() {
            return this.firstName + " " + this.lastName;
        },
    },
    // watch: {
    //   count(newVal, oldVal) {
    //     console.log(`Count changed from ${oldVal} to ${newVal}`);
    //     this.doubledCount = newVal * 2;
    //   },
    // },
};
</script>

<style scoped>
/* Add any styling for your component here */
</style>
