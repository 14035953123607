export function getTodaySQLDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
}

// console.log(jsonData);

// Funzione per controllare se l'argomento è già un oggetto Date o se è una stringa
function parseDate(date) {
    return typeof date === "string" ? new Date(date) : date;
}

// Funzione per formattare una data in formato italiano (dd/mm/yyyy)
export function formatDate(date) {
    if (date == null) {
        return "non disponibile";
    }
    const parsedDate = parseDate(date);
    return parsedDate.toLocaleDateString("it-IT", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
    });
}

// Funzione per formattare una data con il mese in lettere (dd Mmmm yyyy)
export function longFormatDate(date) {
    const parsedDate = parseDate(date);
    return parsedDate.toLocaleDateString("it-IT", {
        day: "2-digit",
        month: "long",
        year: "numeric",
    });
}

// Funzione per calcolare il tempo rimanente fino a una scadenza
export function timeUntil(date) {
    const now = new Date();
    const targetDate = parseDate(date);
    const diff = targetDate - now;

    if (diff <= 0) {
        return "La data è già passata.";
    }

    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

    return `${days} giorni, ${hours} ore e ${minutes} minuti rimanenti`;
}

// Mixin Vue per includere i metodi di formattazione data
export const DateMixin = {
    methods: {
        formatDate(date) {
            return formatDate(date);
        },

        longFormatDate(date) {
            return longFormatDate(date);
        },
        timeUntil(date) {
            return timeUntil(date);
        },
    },
};

// Vue.filter('longFormatDate', function (value) {
//   if (!value) return '';
//   return longFormatDate(value);
// });
