<template>
    <section id="ProdottoAssicurativoForm" class="model__form">
        <v-card class="pa-4">
            <CardAction
                v-model="formData.attivo"
                sezione-attiva="Prodotto Assicurativo"
                @on-annulla="annulla()"
                @on-aggiorna="aggiorna()"
            >
            </CardAction>
            <v-row class="pt-4">
                <v-col>
                    <!-- resource="https://api.publicapis.org/entries" -->
                    <teo-autocomplete
                        color="primary"
                        name="fornitore"
                        label="Fornitore"
                        ref="fornitore"
                        v-model="formData.compagnia_fornitore_id"
                        item-value="id"
                        item-text="label"
                        resource="/api/autocomplete/fornitore"
                    />

                    <teo-autocomplete
                        name="compagnia_contrattuale"
                        label="Compagnia Contrattuale"
                        ref="contrattuale"
                        clearable
                        color="primary"
                        v-model="formData.compagnia_contrattuale_id"
                        item-value="id"
                        item-text="nome"
                        resource="/api/compagnia_contrattuale"
                        @change="changeCompagniaContrattuale"
                    />

                    <div class="mt-5 h6">
                        Inserisci una nuova categoria prodotto
                    </div>
                    <v-row
                        class="mt-5"
                        v-if="
                            formData.compagnia_contrattuale_id &&
                            formData.compagnia_fornitore_id &&
                            new_categoria_prodotto
                        "
                    >
                        <v-col>
                            <v-text-field
                                label="categoria/prodotto"
                                v-model.trim="new_categoria_prodotto.nome"
                                dense
                                color="primary"
                                placeholder="nome nuova categoria"
                            >
                            </v-text-field>
                        </v-col>
                        <v-col>
                            <text-formatted
                                label="provvigione attiva"
                                v-model="
                                    new_categoria_prodotto.mandato
                                        .percentuale_provvigione_attiva
                                "
                                dense
                                color="primary"
                                :error-messages="[]"
                                min="0"
                                max="100"
                                suffix="%"
                            >
                            </text-formatted>
                        </v-col>
                        <v-col>
                            <teo-autocomplete
                                name="ramo"
                                v-model="new_categoria_prodotto.ramo_id"
                                item-value="id"
                                item-text="label"
                                ref="ramo"
                                dense
                                clearable
                                label="Ramo"
                                resource="/api/autocomplete/ramo"
                            />
                        </v-col>
                        <v-col>
                            <date-picker
                                label="data inizio mandato"
                                data-cy="data_inizio_mandato"
                                color="primary"
                                v-model="
                                    new_categoria_prodotto.mandato
                                        .data_inizio_mandato
                                "
                                value-type="YYYY-MM-DD"
                                format="DD/MM/YYYY"
                            >
                            </date-picker>
                        </v-col>

                        <v-col>
                            <teo-autocomplete
                                name="ramo"
                                v-model="formData.settore_id"
                                item-value="id"
                                item-text="label"
                                ref="ramo"
                                label="Settore"
                                resource="/api/autocomplete/settore"
                            />
                        </v-col>

                        <v-col>
                            <v-btn
                                dense
                                :disabled="
                                    new_categoria_prodotto &&
                                    new_categoria_prodotto.nome &&
                                    new_categoria_prodotto.nome.length <= 0
                                "
                                color="primary"
                                >aggiungi
                            </v-btn>
                        </v-col>
                    </v-row>
                    <div class="mt-5 h6">Categorie inserite</div>
                </v-col>
                <!-- <v-col v-if="formData.mandatoAttivo"> -->
            </v-row>
        </v-card>
    </section>
</template>

<script>
// import TipoProvvigione from "../models/tipo_provvigione.js";
import DatePicker from "vue2-datepicker";
import Autocomplete from "./ui/Autocomplete.vue";
import CardAction from "./ui/form/card-action.vue";
import TextFormatted from "./ui/form/text-formatted.vue";
// import TableComponent from './ui/TableComponent.vue';
// import InvalidFeedbackVue from "./ui/InvalidFeedback.vue";

export default {
    components: {
        "date-picker": DatePicker,
        "teo-autocomplete": Autocomplete,
        CardAction,
        TextFormatted,
        // "table-component":TableComponent
    },
    mounted() {
        // ACTION_GET_PRODOTTI_COMPAGNIA_CONTRATTUALE
    },
    data() {
        return {
            dialog_categoria_prodotto: false,
            // new_categoria_prodotto: {
            //   mandato: {
            //     percentuale_provvigione_attiva: "0",
            //     data_inizio_mandato: new Date().toJSON().slice(0, 10),
            //     mandatabile_type: "App\\Models\\CategoriaProdotto",
            //     compagnia_fornitore_id: null,
            //   },
            // },
            prodotto_assicurativo_header: "",
            categoria_prodotto_text: "",
            prodotto_garanzia_text: "",
        };
    },
    methods: {
        annulla() {
            //console.log("annulla implementare")
            this.$emit("on-annulla");
        },
        aggiorna() {
            // console.log("aggiorna implementare",)
            this.$store.dispatch(
                "ACTION_STORE_PRODOTTO_ASSICURATIVO",
                this.formData,
            );
        },
        row_classes(item) {
            if (item.add_new < 200) {
                return "orange"; //can also return multiple classes e.g ["orange","disabled"]
            }
        },
        changeCategoriaProdotto(event) {
            console.log("changeCategoriaProdotto", event);
        },
        changeCompagniaContrattuale() {
            this.$store.dispatch(
                "ACTION_GET_CATEGORIE_PRODOTTO",
                this.formData,
            );
            this.categoria_prodotto_text = "";
            this.prodotto_garanzia_text = "";
            // this.formData.prodotto_garanzia = null
        },

        comparatoreValori(a, b) {
            return String(a) == String(b);
        },
    },
    computed: {
        formData() {
            return this.$store.state.prodottoAssicurativoAttivo;
        },
        new_categoria_prodotto() {
            return this.$store.state.new_categoria_prodotto;
        },

        prodotto_garanzia() {
            return this.$store.state.prodotto_garanzia;
        },
        provvigioneLabel() {
            return "provvigioneLabel";
        },
        fornitori() {
            return this.$store.state.fornitoriAutocomplete;
        },
        errors() {
            return this.$store.state.prodottoAssicurativoFormError;
        },
        loading() {
            return this.$store.state.loading;
        },
    },
};
</script>

<style scoped>
.prodotto_assicurativo__tabs_items {
}

td .v-text-field__slot {
    /* margin-bottom: -1rem; */
    margin-top: 8px;
}
</style>
