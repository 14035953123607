<template>
    <div data-test="RAutocomplete">
        <v-autocomplete
            v-model="model"
            autocomplete="new-password"
            :items="items"
            :loading="loading"
            :label="label"
            :item-text="itemText"
            :item-value="itemValue"
            @search-input="onSearch"
            v-bind="$attrs"
            v-on="$listeners"
        >
            <template
                v-for="(_, scopedSlotName) in $scopedSlots"
                #[scopedSlotName]="slotData"
            >
                <slot :name="scopedSlotName" v-bind="slotData" />
            </template>

            <template v-for="(_, slotName) in $slots" #[slotName]>
                <slot :name="slotName" />
            </template>
        </v-autocomplete>
    </div>
</template>

<script>
import { StylesMixin } from "@/style/StyleMixing";
import { JsonToolsMixing } from "@/services/JSONTools";
import { debounce } from "lodash";
import { Api } from "@/http_tools/axiosModule";

/**
 * @component RAutocomplete
 * @description Componente wrapper per v-autocomplete che aggiunge la funzionalità di caricamento dati
 * da API o array statico.
 *
 * @example <caption>Base con source API</caption>
 * <r-autocomplete
 *     v-model="selectedValue"
 *     source="/api/autocomplete/collaboratore"
 *     label="Seleziona collaboratore"
 * />
 *
 * @example <caption>Con array statico</caption>
 * <r-autocomplete
 *     v-model="selectedValue"
 *     :source="[
 *         { id: 1, label: 'Opzione 1' },
 *         { id: 2, label: 'Opzione 2' }
 *     ]"
 *     label="Seleziona opzione"
 * />
 *
 * @example <caption>Personalizzato con slot</caption>
 * <r-autocomplete
 *     v-model="selectedValue"
 *     source="/api/autocomplete/collaboratore"
 *     label="Collaboratore"
 *     clearable
 *     dense
 *     :menu-props="{ maxHeight: 400 }"
 *     @change="onChange"
 * >
 *     <template #prepend>
 *         <v-icon>mdi-account</v-icon>
 *     </template>
 *     <template #item="{ item }">
 *         <v-list-item-content>
 *             <v-list-item-title>{{ item.label }}</v-list-item-title>
 *             <v-list-item-subtitle>ID: {{ item.id }}</v-list-item-subtitle>
 *         </v-list-item-content>
 *     </template>
 * </r-autocomplete>
 *
 * @example <caption>Con gestione errori</caption>
 * <r-autocomplete
 *     v-model="selectedValue"
 *     source="/api/autocomplete/collaboratore"
 *     :error-messages="errors.collaboratore"
 *     :error="!!errors.collaboratore"
 *     @input="clearError('collaboratore')"
 * />
 */
export default {
    name: "RAutocomplete",
    mixins: [StylesMixin, JsonToolsMixing],
    inheritAttrs: false,

    props: {
        /**
         * Valore selezionato (usato per v-model)
         * @type {any}
         * @required
         */
        value: {
            required: true,
        },
        /**
         * URL dell'API o array di oggetti
         * @type {String|Array}
         * @required
         */
        source: {
            required: true,
            type: [String, Array],
        },
        /**
         * Label del campo
         * @type {String}
         * @default ""
         */
        label: {
            type: String,
            default: "",
        },
        /**
         * Chiave per il testo da visualizzare
         * @type {String}
         * @default "label"
         */
        itemText: {
            type: String,
            default: "label",
        },
        /**
         * Chiave per il valore da utilizzare
         * @type {String}
         * @default "id"
         */
        itemValue: {
            type: String,
            default: "id",
        },
    },

    created() {
        this.debouncedSearch = debounce(this.fetchItems, 300);
        if (Array.isArray(this.source)) {
            this.items = this.source;
        }
    },

    data() {
        return {
            items: [],
            loading: false,
            cancelToken: null,
        };
    },

    methods: {
        /**
         * Gestisce l'input di ricerca
         * @param {String} searchText - Testo da cercare
         * @private
         */
        onSearch(searchText) {
            if (!this.isSourceUrl) return;

            if (searchText?.length > 2) {
                this.debouncedSearch(searchText);
            }
        },

        /**
         * Recupera i dati dall'API
         * @param {String} searchText - Testo da cercare
         * @private
         */
        async fetchItems(searchText) {
            if (!this.isSourceUrl) return;

            try {
                if (this.cancelToken) {
                    this.cancelToken.cancel(
                        "Annullata la richiesta precedente",
                    );
                }

                this.cancelToken = Api.CancelToken.source();
                this.loading = true;

                const response = await Api.get(
                    process.env.VUE_APP_API_URL + this.source,
                    {
                        params: { search: searchText },
                        cancelToken: this.cancelToken.token,
                    },
                );

                this.items = response.data.data;
            } catch (error) {
                if (!Api.isCancel(error)) {
                    console.error("Errore nella richiesta:", error);
                }
            } finally {
                this.loading = false;
            }
        },
    },

    computed: {
        /**
         * Gestisce il v-model
         * @private
         */
        model: {
            get() {
                return this.value;
            },
            set(v) {
                this.$emit("input", v);
            },
        },
        /**
         * Verifica se source è un URL
         * @private
         */
        isSourceUrl() {
            return typeof this.source === "string";
        },
    },
};
</script>
<style></style>
