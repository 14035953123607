// Funzione per la regola del campo obbligatorio
export const required = (fieldName = "Questo campo") => {
    return (value) => !!value || `${fieldName} è obbligatorio`;
};

// Funzione per impostare i messaggi di errore nello schema
export const setErrorMessageInSchema = (schema, errorMessages) => {
    for (const prop in errorMessages) {
        const propErrors = errorMessages[prop];
        if (schema[prop]) {
            schema[prop]["error-messages"] = propErrors;
        }
    }
    return schema;
};

// Funzione per la regola di validazione del numero di telefono
export const phoneRule = () => {
    return (v) =>
        /^\+\d{1,3}/.test(v) ||
        "Il numero di telefono deve avere un prefisso internazionale valido";
};

// Funzione per controllare se ci sono errori totali o per una chiave specifica
export const hasErrors = (messageBag, key = false) => {
    if (!key) {
        return Object.keys(messageBag?.errors || {}).length;
    } else {
        return Object.keys(messageBag?.errors?.[key] || {}).length;
    }
};

// Modulo parseNestedJSONString.js
export function parseNestedJSONString(obj) {
    for (let key in obj) {
        if (typeof obj[key] === "string") {
            try {
                const parsed = JSON.parse(obj[key]);
                if (typeof parsed === "object" && parsed !== null) {
                    obj[key] = parsed;
                }
            } catch (e) {
                // Non è un JSON valido, si ignora
            }
        } else if (typeof obj[key] === "object" && obj[key] !== null) {
            parseNestedJSONString(obj[key]); // Ricorsivamente decodifica JSON negli oggetti annidati
        }
    }
    return obj;
}

// Esempio di utilizzo
// const serverError = {
//   "server_error": [
//     {
//       "error": "Error in POST request. Status code 500",
//       "quixa_message": "{\n  \"errorCode\": \"ESG120\",\n  \"errorMessage\": \"Routing failed\",\n  \"errorDetail\": \"Backend not available, no answer from backend.\"\n}"
//     }
//   ]
// };

// // console.log(parseNestedJSON(serverError));

// Mixin per Vue che include le funzioni sopra
export const validationMixin = {
    methods: {
        required(fieldName = "Questo campo") {
            return required(fieldName);
        },

        setErrorMessageInSchema(schema, errorMessages) {
            return setErrorMessageInSchema(schema, errorMessages);
        },

        phoneRule() {
            return phoneRule();
        },

        hasErrors(messageBag, key = false) {
            return hasErrors(messageBag, key);
        },

        parseNestedJSONString(objJson) {
            return parseNestedJSONString(objJson);
        },
    },
};
