<template>
    <section id="CollaboratoreDetails">
        <v-tabs v-model="collaboratore_tabs" align-with-title>
            <v-tabs-slider color="white"></v-tabs-slider>

            <v-tab> Dati Del collaboratore </v-tab>
            <v-tab v-if="collaboratore.id"> Clienti </v-tab>
            <v-tab v-if="collaboratore.id"> Provvigioni </v-tab>
            <v-tab v-if="collaboratore.id"> Prodotti Assegnati </v-tab>
        </v-tabs>

        <v-tabs-items v-model="collaboratore_tabs">
            <v-tab-item>
                <CollaboratoreForm></CollaboratoreForm>
            </v-tab-item>

            <v-tab-item>
                <table-component
                    v-if="collaboratore.id"
                    id="clienti_collaboratore"
                    :key="'cc-' + collaboratore.id"
                    :resource="collaboratore.clienti"
                    config="collaboratore-prodotti-table"
                    toolbar-title="Clienti del collaboratore"
                    @on-edit="onClienteCollaboratoreEdit($event)"
                    @on-delete="onClienteCollaboratoreDelete($event)"
                    @on-add-new-item="onAssegnaClienteACollaboratore($event)"
                    :items-per-page="itemsPerPage"
                >
                    <template
                        v-slot:[`item.pivot.perc_provvigione_passiva`]="{
                            item,
                        }"
                    >
                        <v-edit-dialog
                            :return-value.sync="
                                item.pivot.perc_provvigione_passiva
                            "
                            @save="update(item)"
                            @cancel="cancel(item)"
                            @open="open(item)"
                            @close="close(item)"
                            large
                        >
                            <span class="text-uppercase editable text-primary">
                                <v-icon class="editable__ico" small primary>
                                    mdi-pencil
                                </v-icon>
                                <span
                                    :class="{
                                        'red--text':
                                            item.perc_provvigione_passiva ==
                                            '0.00',
                                    }"
                                >
                                    {{ item.perc_provvigione_passiva }}
                                    %
                                </span>
                                <span
                                    class="tipo-provvigione-passiva-text percentuale-text"
                                >
                                    <span
                                        v-if="
                                            item.pivot
                                                .perc_provvigione_passiva ===
                                            'PERC_RETROCESSIONE'
                                        "
                                        class="label"
                                        >retrocessione</span
                                    >
                                    <span
                                        v-if="
                                            item.pivot
                                                .perc_provvigione_passiva ===
                                            'PERC_IMPONIBILE'
                                        "
                                        class="label"
                                        >imponibile</span
                                    >
                                </span>
                            </span>

                            <!-- DOCS | leggere per validazione https://vuetifyjs.com/en/components/text-fields/#hide-details -->
                            <template v-slot:input>
                                <div>
                                    Il valore verrà applicato a tutti i
                                    collaboratori
                                </div>
                                <v-text-field
                                    v-model.number="
                                        item.perc_provvigione_passiva
                                    "
                                    type="number"
                                    label="Modifica la percentuale per tutti i collaboratori"
                                    single-line
                                ></v-text-field>
                            </template>
                        </v-edit-dialog>
                    </template>
                </table-component>
            </v-tab-item>
            <v-tab-item>
                provvigioni

                <table-component
                    v-if="false"
                    id="provvigioni_collaboratore"
                    :key="'cr-' + collaboratore.id"
                    :resource="collaboratore.prodotti"
                    config="collaboratore-prodotti-table"
                    toolbar-title="Provvigioni"
                    @on-edit="onEdit($event)"
                    @on-delete="onDelete($event)"
                    @on-add-new-item="onAddNewItem($event)"
                    :items-per-page="itemsPerPage"
                >
                    <template
                        v-slot:[`item.pivot.perc_provvigione_passiva`]="{
                            item,
                        }"
                    >
                        <v-edit-dialog
                            :return-value.sync="
                                item.pivot.perc_provvigione_passiva
                            "
                            @save="update(item)"
                            @cancel="cancel(item)"
                            @open="open(item)"
                            @close="close(item)"
                            large
                        >
                            <span class="text-uppercase editable text-primary">
                                <v-icon class="editable__ico" small primary>
                                    mdi-pencil
                                </v-icon>
                                <span
                                    :class="{
                                        'red--text':
                                            item.perc_provvigione_passiva ==
                                            '0.00',
                                    }"
                                >
                                    {{ item.perc_provvigione_passiva }}
                                    %
                                </span>
                                <span
                                    class="tipo-provvigione-passiva-text percentuale-text"
                                >
                                    <span
                                        v-if="
                                            item.pivot
                                                .perc_provvigione_passiva ===
                                            'PERC_RETROCESSIONE'
                                        "
                                        class="label"
                                        >retrocessione</span
                                    >
                                    <span
                                        v-if="
                                            item.pivot
                                                .perc_provvigione_passiva ===
                                            'PERC_IMPONIBILE'
                                        "
                                        class="label"
                                        >imponibile</span
                                    >
                                </span>
                            </span>

                            <!-- DOCS | leggere per validazione https://vuetifyjs.com/en/components/text-fields/#hide-details -->
                            <template v-slot:input>
                                <div>
                                    Il valore verrà applicato a tutti i
                                    collaboratori
                                </div>
                                <v-text-field
                                    v-model.number="
                                        item.perc_provvigione_passiva
                                    "
                                    type="number"
                                    label="Modifica la percentuale per tutti i collaboratori"
                                    single-line
                                ></v-text-field>
                            </template>
                        </v-edit-dialog>
                    </template>
                </table-component>
            </v-tab-item>
            <v-tab-item>
                prodotto
                <prodotto-assicurativo-tab
                    v-if="false"
                    :key="'pa-' + collaboratore.id"
                    :resource="`/prodotto_assicurativo_search?filters[]=collaboratoreFornitore.id=${collaboratore.id}`"
                    config="collaboratore-fornitore-prodotti-table"
                >
                </prodotto-assicurativo-tab>
            </v-tab-item>
        </v-tabs-items>
    </section>
</template>
<script>
import CollaboratoreForm from "./CollaboratoreForm.vue";
import TableComponent from "../ui/TableComponent.vue";
// import { EventBus } from '../../global-event-bus';
// router.push('your-path').catch(err => {})

export default {
    name: "collaboratore-details",
    components: {
        CollaboratoreForm,
        TableComponent,
    },
    mounted() {
        console.log("CollaboratoreDetails");
    },
    props: {},
    data() {
        return {
            collaboratore_tabs: null,

            /** @var selectedItems elementi selezionati della tabella passati tramite un evento */
            selectedItems: [],
        };
    },
    computed: {
        collaboratore() {
            return this.$store.state.collaboratoreAttivo;
        },
    },
    methods: {},
};
</script>
