<template>
    <div class="invalid-feedback">
        <div class="invalid-feedback__msg_list" v-if="errors">
            <div
                class="invalid-feedback__msg_item"
                :key="i"
                v-for="(msg, i) in errors"
            >
                {{ msg }}
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "invalid-feedback",
    props: {
        errors: Array,
    },
};
</script>
