export function HasPermission(permission) {
    return window.sessionStorage["permissions"].includes(permission);
}
export function PuoEmetterePolizzaAutoStorica(quotazione) {
    // return HasPermission(permission)
    return (
        HasPermission("emettere_polizza_auto_storica") &&
        [8, 7, 11].includes(quotazione.stato_quotazione_id)
    );
}

/**
 *  {
 *   "id": 10,
 *   "cod": 10.00,
 *   "nome": "Conclusa con successo",
 *  },
 * @param {*} permission
 * @returns
 */
export function PuoEmettereAppendiceAutoStorica(quotazione) {
    // return
    //HasPermission("emettere_polizza_auto_storica")
    // &&
    return [
        9, //in attesa di firma
        10,
        11,
    ].includes(quotazione.stato_quotazione_id);
}
