<template>
    <v-container fluid>
        <v-alert text :type="alert.type" v-if="alert.message">
            {{ alert.message }}
        </v-alert>
        <v-card-title>
            <!-- hide-details -->
        </v-card-title>
        <v-data-table
            :headers="headers"
            :items="auto_storiche.data"
            hide-default-footer=""
        >
            <template v-slot:[`item.colore`]="{ item }">
                <v-chip :color="getColor(item.colore)" dark>
                    {{ item.colore }}
                </v-chip>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
                <v-icon small @click="editItem(item)"> mdi-pencil </v-icon>
                <!-- <v-icon small @click="deleteItem(item)">
          mdi-delete
        </v-icon> -->
            </template>
            <template v-slot:top>
                <v-toolbar flat>
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Cerca"
                        single-line
                        hide-details
                    ></v-text-field>
                    <v-spacer></v-spacer>
                    <v-dialog
                        v-model="addAutoStorica"
                        max-width="500px"
                        persistent
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                color="primary"
                                dark
                                class="mb-2"
                                v-bind="attrs"
                                v-on="on"
                            >
                                Aggiungi
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-title>Auto Storica</v-card-title>
                            <v-card-text>
                                <v-form ref="addForm">
                                    <v-container>
                                        <!-- Cognome Field -->
                                        <div class="form-group">
                                            <label for="marca">Marca:</label>
                                            <input
                                                class="form-control"
                                                v-model="autoStorica.marca"
                                                type="text"
                                            />

                                            <invalid-feedback
                                                :errors="errors.marca"
                                            />
                                        </div>
                                        <div class="form-group">
                                            <label for="modello"
                                                >Modello:</label
                                            >
                                            <input
                                                class="form-control"
                                                v-model="autoStorica.modello"
                                                type="text"
                                            />
                                            <invalid-feedback
                                                :errors="errors.modello"
                                            />
                                        </div>
                                        <v-row>
                                            <v-col cols="12" md="4">
                                                <div class="form-group">
                                                    <label for="cilindrata"
                                                        >Cilindrata:</label
                                                    >
                                                    <input
                                                        class="form-control"
                                                        v-model="
                                                            autoStorica.cilindrata
                                                        "
                                                        type="text"
                                                    />
                                                    <invalid-feedback
                                                        :errors="
                                                            errors.cilindrata
                                                        "
                                                    />
                                                </div>
                                            </v-col>
                                            <v-col cols="12" md="4">
                                                <div class="form-group">
                                                    <label for="cilindrata_da"
                                                        >Cilindrata Da</label
                                                    >
                                                    <input
                                                        class="form-control"
                                                        v-model="
                                                            autoStorica.cilindrata_da
                                                        "
                                                        type="text"
                                                    />
                                                    <invalid-feedback
                                                        :errors="
                                                            errors.cilindrata_da
                                                        "
                                                    />
                                                </div>
                                            </v-col>
                                            <v-col cols="12" md="4">
                                                <div class="form-group">
                                                    <label for="cilindrata_a"
                                                        >Cilindrata a:</label
                                                    >
                                                    <input
                                                        class="form-control"
                                                        v-model="
                                                            autoStorica.cilindrata_a
                                                        "
                                                        type="text"
                                                    />
                                                    <invalid-feedback
                                                        :errors="
                                                            errors.cilindrata_a
                                                        "
                                                    />
                                                </div>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" md="6">
                                                <div class="form-group">
                                                    <v-select
                                                        :items="anni"
                                                        label="Anno da"
                                                        v-model="
                                                            autoStorica.anno_da
                                                        "
                                                    />
                                                    <invalid-feedback
                                                        :errors="errors.anno_da"
                                                    />
                                                </div>
                                            </v-col>
                                            <v-col cols="12" md="6">
                                                <div class="form-group">
                                                    <v-select
                                                        :items="anni"
                                                        label="Anno a"
                                                        v-model="
                                                            autoStorica.anno_a
                                                        "
                                                    />
                                                    <invalid-feedback
                                                        :errors="errors.anno_a"
                                                    />
                                                </div>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" md="4">
                                                <div class="form-group">
                                                    <label for="valore_a"
                                                        >Valore a:</label
                                                    >
                                                    <input
                                                        class="form-control"
                                                        v-model="
                                                            autoStorica.valore_a
                                                        "
                                                        type="text"
                                                    />
                                                    <invalid-feedback
                                                        :errors="
                                                            errors.valore_a
                                                        "
                                                    />
                                                </div>
                                            </v-col>
                                            <v-col cols="12" md="4">
                                                <div class="form-group">
                                                    <label for="valore_b"
                                                        >Valore b:</label
                                                    >
                                                    <input
                                                        class="form-control"
                                                        v-model="
                                                            autoStorica.valore_b
                                                        "
                                                        type="text"
                                                    />
                                                    <invalid-feedback
                                                        :errors="
                                                            errors.valore_b
                                                        "
                                                    />
                                                </div>
                                            </v-col>
                                            <v-col cols="12" md="4">
                                                <div class="form-group">
                                                    <label for="valore_c"
                                                        >Valore c:</label
                                                    >
                                                    <input
                                                        class="form-control"
                                                        v-model="
                                                            autoStorica.valore_c
                                                        "
                                                        type="text"
                                                    />
                                                    <invalid-feedback
                                                        :errors="
                                                            errors.valore_c
                                                        "
                                                    />
                                                </div>
                                            </v-col>
                                        </v-row>
                                        <div class="form-group">
                                            <label for="nome_tipologia"
                                                >Nome Tipologia:</label
                                            >
                                            <input
                                                class="form-control"
                                                v-model="
                                                    autoStorica.nome_tipologia
                                                "
                                                type="text"
                                            />
                                            <invalid-feedback
                                                :errors="errors.nome_tipologia"
                                            />
                                        </div>
                                        <div class="form-group">
                                            <label for="tipologia"
                                                >Tipologia:</label
                                            >
                                            <input
                                                class="form-control"
                                                v-model="autoStorica.tipologia"
                                                type="text"
                                            />
                                            <invalid-feedback
                                                :errors="errors.tipologia"
                                            />
                                        </div>
                                        <div class="form-group">
                                            <label for="convenzione"
                                                >Convenzione:</label
                                            >
                                            <input
                                                class="form-control"
                                                v-model="
                                                    autoStorica.convenzione
                                                "
                                                type="text"
                                            />
                                            <invalid-feedback
                                                :errors="errors.convenzione"
                                            />
                                        </div>
                                    </v-container>
                                </v-form>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-actions>
                                <v-btn
                                    color="primary"
                                    dark
                                    @click="dismissModals"
                                >
                                    Annulla
                                </v-btn>
                                <v-btn
                                    color="primary"
                                    dark
                                    @click="storeAutoStorica"
                                >
                                    Salva
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card>
                            <v-card-title class="text-h5"
                                >Sicuro di voler cancellare il
                                veicolo?</v-card-title
                            >
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" dark text>Cancel</v-btn>
                                <v-btn
                                    color="primary"
                                    dark
                                    text
                                    @click="deleteItemConfirm"
                                    >OK</v-btn
                                >
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>
        </v-data-table>
        <v-pagination
            :length="lastPage"
            :total-visible="5"
            v-model="currentPage"
        ></v-pagination>
        <v-dialog v-model="editAutoStorica" max-width="500px" persistent>
            <v-card>
                <v-card-title>Modifica Auto Storica</v-card-title>
                <v-card-text>
                    <v-form ref="editForm">
                        <v-container>
                            <!-- Cognome Field -->
                            <div class="form-group">
                                <label for="marca">Marca:</label>
                                <input
                                    class="form-control"
                                    v-model="autoStorica.marca"
                                    type="text"
                                />

                                <invalid-feedback :errors="errors.marca" />
                            </div>
                            <div class="form-group">
                                <label for="modello">Modello:</label>
                                <input
                                    class="form-control"
                                    v-model="autoStorica.modello"
                                    type="text"
                                />
                                <invalid-feedback :errors="errors.modello" />
                            </div>
                            <v-row>
                                <v-col cols="12" md="4">
                                    <div class="form-group">
                                        <label for="cilindrata"
                                            >Cilindrata:</label
                                        >
                                        <input
                                            class="form-control"
                                            v-model="autoStorica.cilindrata"
                                            type="text"
                                        />
                                        <invalid-feedback
                                            :errors="errors.cilindrata"
                                        />
                                    </div>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <div class="form-group">
                                        <label for="cilindrata_da"
                                            >Cilindrata Da</label
                                        >
                                        <input
                                            class="form-control"
                                            v-model="autoStorica.cilindrata_da"
                                            type="text"
                                        />
                                        <invalid-feedback
                                            :errors="errors.cilindrata_da"
                                        />
                                    </div>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <div class="form-group">
                                        <label for="cilindrata_a"
                                            >Cilindrata a:</label
                                        >
                                        <input
                                            class="form-control"
                                            v-model="autoStorica.cilindrata_a"
                                            type="text"
                                        />
                                        <invalid-feedback
                                            :errors="errors.cilindrata_a"
                                        />
                                    </div>
                                </v-col>
                            </v-row>
                            <div class="form-group">
                                <label for="anno">Anno:</label>
                                <input
                                    class="form-control"
                                    v-model="autoStorica.anno"
                                    type="text"
                                />
                                <invalid-feedback :errors="errors.anno" />
                            </div>
                            <v-row>
                                <v-col cols="12" md="4">
                                    <div class="form-group">
                                        <label for="valore_a">Valore a:</label>
                                        <input
                                            class="form-control"
                                            v-model="autoStorica.valore_a"
                                            type="text"
                                        />
                                        <invalid-feedback
                                            :errors="errors.valore_a"
                                        />
                                    </div>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <div class="form-group">
                                        <label for="valore_b">Valore b:</label>
                                        <input
                                            class="form-control"
                                            v-model="autoStorica.valore_b"
                                            type="text"
                                        />
                                        <invalid-feedback
                                            :errors="errors.valore_b"
                                        />
                                    </div>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <div class="form-group">
                                        <label for="valore_c">Valore c:</label>
                                        <input
                                            class="form-control"
                                            v-model="autoStorica.valore_c"
                                            type="text"
                                        />
                                        <invalid-feedback
                                            :errors="errors.valore_c"
                                        />
                                    </div>
                                </v-col>
                            </v-row>
                            <div class="form-group">
                                <label for="nome_tipologia"
                                    >Nome Tipologia:</label
                                >
                                <input
                                    class="form-control"
                                    v-model="autoStorica.nome_tipologia"
                                    type="text"
                                />
                                <invalid-feedback
                                    :errors="errors.nome_tipologia"
                                />
                            </div>
                            <div class="form-group">
                                <label for="tipologia">Tipologia:</label>
                                <input
                                    class="form-control"
                                    v-model="autoStorica.tipologia"
                                    type="text"
                                />
                                <invalid-feedback :errors="errors.tipologia" />
                            </div>
                            <div class="form-group">
                                <label for="convenzione">Convenzione:</label>
                                <input
                                    class="form-control"
                                    v-model="autoStorica.convenzione"
                                    type="text"
                                />
                                <invalid-feedback
                                    :errors="errors.convenzione"
                                />
                            </div>
                        </v-container>
                    </v-form>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn color="primary" dark @click="dismissModals">
                        Annulla
                    </v-btn>
                    <v-btn color="primary" dark @click="updateAutoStorica">
                        Salva
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
//import TableComponent from '../ui/TableComponent.vue';
// import axios from 'axios';
import { Api, errorCallback } from "@/http_tools/axiosModule";
import InvalidFeedback from "../ui/InvalidFeedback.vue";

export default {
    components: {
        "invalid-feedback": InvalidFeedback,
    },
    name: "quotazione-page",
    data() {
        return {
            alert: {
                message: "",
                type: "",
            },
            anni: [],
            dialogDelete: false,
            addAutoStorica: false,
            editAutoStorica: false,
            response: null,
            file: null,
            search: "",
            errors: {},
            headers: [
                { text: "Marca", value: "marca" },
                { text: "Modello", value: "modello" },
                { text: "Cilindrata", value: "cilindrata" },
                { text: "Anno", value: "anno" },
                { text: "Colore", value: "colore" },
                { text: "Actions", value: "actions", sortable: false },
            ],
            auto_storiche: {},
            cancella: null,
            page: 1,
            queries: {
                page: 1,
                "filter[search]": "",
            },
            editedIndex: -1,
            autoStorica: {
                marca: "",
                modello: "",
                cilindrata: null,
                cilindrata_da: null,
                cilindrata_a: null,
                anno: null,
                valore_a: null,
                valore_b: null,
                valore_c: null,
                nome_tipologia: "",
                tipologia: "",
                convenzione: "",
            },
        };
    },
    mounted() {
        this.populateYearOptions();
    },
    methods: {
        dismissModals() {
            this.addAutoStorica = false;
            this.editAutoStorica = false;
            this.autoStorica = {};
        },
        populateYearOptions() {
            const startYear = 1900;
            const endYear = new Date().getFullYear();

            for (let year = startYear; year <= endYear; year++) {
                this.anni.push(year);
            }
        },
        async serverSideSearch(search) {
            var apiURL =
                process.env.VUE_APP_API_URL +
                "/api/autostoriche?filter[search]=" +
                search;
            let data = await Api.get(apiURL);
            this.auto_storiche = data.data;
        },

        async getAutoStoriche(page, search) {
            this.queries.page = page;
            this.queries["filter[search]"] = search;
            const qs = new URLSearchParams(this.queries).toString();
            var apiURL =
                process.env.VUE_APP_API_URL + "/api/autostoriche?" + qs;
            let data = await Api.get(apiURL);
            this.auto_storiche = data.data;
        },

        getColor(colore) {
            if (colore === "verde") return "green";
            else if (colore === "giallo") return "yellow";
            else return "red";
        },

        async storeAutoStorica() {
            var apiURL = process.env.VUE_APP_API_URL + "/api/autostoriche";
            await Api.post(apiURL, this.autoStorica)
                .then((response) => {
                    this.response = response;
                    this.alert.type = "success";
                    this.alert.message = response.data.message;
                    this.addAutoStorica = false;
                    this.autoStorica = {};
                })
                .catch((error) => {
                    if (error.response) {
                        this.errors = error.response.data.errors;
                        errorCallback(error);
                    }
                });
        },

        async updateAutoStorica() {
            var apiURL =
                process.env.VUE_APP_API_URL +
                "/api/autostoriche/" +
                this.autoStorica.id;
            await Api.put(apiURL, this.autoStorica)
                .then((response) => {
                    this.response = response;
                    this.alert.type = "success";
                    this.alert.message = response.data.message;
                    this.editAutoStorica = false;
                    this.autoStorica = {};
                })
                .catch((error) => {
                    if (error.response) {
                        this.errors = error.response.data.errors;
                        errorCallback(error);
                    }
                });
        },

        importaVeicoli() {
            var apiURL =
                process.env.VUE_APP_API_URL + "/api/autostoriche/import";
            let formData = new FormData();
            formData.append("file", this.file);
            formData.append("cancella", this.cancella);
            Api.post(apiURL, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }).then((response) => {
                response;
            });
        },

        editItem(item) {
            this.editedIndex = this.auto_storiche.data.indexOf(item);
            this.autoStorica = Object.assign({}, item);
            this.editAutoStorica = true;
        },

        deleteItem(item) {
            this.editedIndex = this.auto_storiche.data.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },

        async deleteItemConfirm() {
            this.auto_storiche.data.splice(this.editedIndex, 1);
            var apiURL =
                process.env.VUE_APP_API_URL +
                "/api/autostoriche/" +
                this.editedItem.id;
            await Api.delete(apiURL, this.autoStorica)
                .then((response) => {
                    this.response = response;
                    this.alert.type = "success";
                    this.alert.message = response.data.message;
                    this.dialogDelete = false;
                })
                .catch((error) => {
                    if (error.response) {
                        this.errors = error.response.data.errors;
                        errorCallback(error);
                    }
                });
        },
    },
    watch: {
        async currentPage(newVal) {
            await this.getAutoStoriche(newVal, this.search);
        },
        async search(newVal) {
            this.getAutoStoriche(this.currentPage, newVal);
        },
    },
    computed: {
        loading() {
            return this.$store.state.loading;
        },
        currentPage: {
            get() {
                return this.auto_storiche.current_page;
            },
            set(value) {
                this.auto_storiche.current_page = value;
            },
        },
        lastPage: {
            get() {
                return this.auto_storiche.last_page;
            },
        },
    },
    created: function () {
        //this.getItems(1);
        this.getAutoStoriche();
    },
};
</script>
