// src/mixins/envMixin.js

export const EnvMixin = {
    data() {
        return {
            VUE_APP_API_URL: process.env.VUE_APP_API_URL,
            VUE_APP_TITLE: process.env.VUE_APP_TITLE,
            VUE_APP_NAME: process.env.VUE_APP_NAME,
        };
    },
};
