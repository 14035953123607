<template>
    <div data-test="CoverageQuixaForm">
        <v-row v-if="vehicleCategory">
            <v-col col="12" order-sm="2" order-lg="1" md="6">
                <v-toolbar flat>
                    <v-toolbar-title>Coperture Disponibili</v-toolbar-title>
                </v-toolbar>
                <v-data-table
                    dense
                    v-model="selected"
                    :items="copertureDisponibili"
                    show-select
                    :disable-select-all="true"
                    item-key="code"
                    :disable-pagination="true"
                    :items-per-page="-1"
                    :headers="headersDisponibili"
                    @item-selected="onItemSelected"
                >
                </v-data-table>
            </v-col>

            <v-col col="12" order-sm="1" order-lg="2" md="6">
                <v-toolbar flat>
                    <v-toolbar-title>Coperture Selezionate</v-toolbar-title>
                </v-toolbar>

                <v-data-table
                    v-model="selected"
                    :items="copertureSelezionate"
                    show-select
                    item-key="code"
                    :disable-select-all="true"
                    :disable-pagination="true"
                    :items-per-page="-1"
                    :headers="headers"
                    :expanded.sync="expandedSelezionate"
                >
                    <template v-slot:[`item.description`]="{ item }">
                        <div class="py-3">
                            <strong>
                                {{ item.code }}
                                {{ item.description }}</strong
                            >
                            <div
                                class="mt-5"
                                v-if="
                                    item.HasMassimale === 'S' &&
                                    item.Massimale != 'VEHICLE_CUSTOM_VALUE'
                                "
                            >
                                <v-select
                                    :id="`select-massimale-copertura-${item.code}`"
                                    class="align-left"
                                    v-model="item.value"
                                    label="Massimale"
                                    color="primary"
                                    item-key="value"
                                    item-text="label"
                                    :return-object="false"
                                    :items="item.Massimale[vehicleCategory]"
                                    dense
                                >
                                </v-select>
                            </div>

                            <div
                                v-if="
                                    item.HasMassimale === 'S' &&
                                    item.Massimale == 'VEHICLE_CUSTOM_VALUE'
                                "
                            >
                                TODO: VEHICLE_CUSTOM_VALUE
                            </div>
                        </div>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <v-row v-if="!vehicleCategory">
            <v-col> Categoria Veicolo non selezionata </v-col>
        </v-row>
    </div>
</template>

<script>
// Rimuovi l'import di lodash se non lo usi
import _ from "lodash";

// Aggiungi l'import di mapIf
import { JsonToolsMixing, mapIf } from "@/services/JSONTools";
import { QuixaCoverage as CopertureDisponibili } from "./CoveragesQuixaDisponibiliList";
import { filterByVehicleCategory } from "./xxx_CoverageService.mjs";
import { StylesMixin } from "@/style/StyleMixing";
import { CurrencyMixin } from "@/filters/NumbersFilters";

export default {
    name: "coverage-quixa-form",
    props: ["vehicleCategory", "value", "obj"],
    mixins: [StylesMixin, CurrencyMixin, JsonToolsMixing],
    components: {},
    created() {
        console.log("created - value prop:", this.value);
    },
    async mounted() {
        try {
            // console.log("mounted - value prop:", this.value);
            // console.log("mounted - computed model:", this.model);
            console.log(
                "mounted - CopertureDisponibili model:",
                CopertureDisponibili,
            );
            // this.selected = this.model;
            const selected = mapIf(this.model, (coperturaDelModel) => {
                const coperturaDisponibile = CopertureDisponibili.find(
                    (coperturaDisponibile) =>
                        coperturaDisponibile.code == coperturaDelModel.code,
                );
                return { ...coperturaDisponibile, ...coperturaDelModel };
            });

            this.selected = this.orderByPackageAndCode(selected);
        } catch (error) {
            console.error("Errore in mounted hook:", error);
            this.selected = [];
        }
    },
    data() {
        return {
            show: true,
            schema: {},
            selected: [],
            expandedSelezionate: [],
            headers: [
                {
                    text: "Copertura",
                    align: "start",
                    sortable: false,
                    value: "description",
                },
            ],
            headersDisponibili: [
                {
                    text: "Cod",
                    align: "start",
                    sortable: false,
                    value: "code",
                    width: "1%",
                },
                {
                    text: "Copertura",
                    align: "start",
                    sortable: false,
                    value: "description",
                },
                {
                    text: "Obbligatori",
                    align: "start",
                    sortable: false,
                    value: "Requires",
                },
                {
                    text: "Esclusioni",
                    align: "start",
                    sortable: false,
                    value: "Excludes",
                },
                {
                    text: "Massimale",
                    align: "start",
                    sortable: false,
                    value: "HasMassimale",
                },
            ],
        };
    },
    methods: {
        /**
         * Ordina gli elementi mettendo P94 in cima, poi per PackageCode e code
         * @param {Array} items - Array di elementi da ordinare
         * @param {string} priorityPackage - PackageCode da mettere in cima (default: 'P94')
         * @returns {Array} Array ordinato
         */
        orderByPackageAndCode(items, priorityPackage = "P94") {
            return this.orderBy(
                items,
                [
                    // Funzione custom per mettere priorityPackage in cima
                    (item) => (item.PackageCode === priorityPackage ? 0 : 1),
                    "PackageCode",
                    "code",
                ],
                ["asc", "asc", "asc"],
            );
        },

        onItemSelected(selected) {
            console.log("onItemSelected", selected);
            const c = selected.item;
            const exists = _.find(this.selected, { code: c.code });
            if (!exists && selected.value) {
                if (c.Excludes) {
                    c.Excludes.forEach((exclude_code) => {
                        const index = _.findIndex(this.selected, {
                            code: exclude_code,
                        });
                        this.$delete(this.selected, index);
                    });
                }

                if (c.Requires) {
                    c.Requires.forEach((required_code) => {
                        const c_exists = _.some(this.selected, {
                            code: required_code,
                        });
                        if (!c_exists) {
                            // Aggiornato il riferimento a CopertureDisponibili
                            const c_new = _.find(CopertureDisponibili, {
                                code: required_code,
                            });
                            this.$set(
                                this.selected,
                                this.selected.length,
                                c_new,
                            );
                        }
                    });
                }

                this.selected.push(c);
            } else if (!selected.value && exists) {
                const index = _.findIndex(this.selected, { code: c.code });
                this.$delete(this.selected, index);
            }
        },
        clickRichiediPreventivo() {
            // alert("clickRichiediPreventivo");
            // this.submitFastQuotationFlowData();
            this.$emit("richiedi-preventivo");
        },
        // async submitFastQuotationFlowData() {
        //     // console.log(JSON.stringify(this.model, null, 2));
        //     // TODO: DA FARE: loading centralizzato
        //     this.loading = true;
        //     // this.model.quotation_request = this.model;
        //     this.messageBag = {};
        //     try {
        //         const response = await Api.post(
        //             process.env.VUE_APP_API_URL +
        //                 "/api/quixa/FastQuotationFlow",
        //             this.requestHTTP
        //         );

        //         console.log(
        //             "submitFastQuotationFlowData success",
        //             response.data.data
        //         );

        //         const responseData = response.data.data;
        //         // this.model.response = response.data.quotation_response;
        //         this.$set(
        //             this.model,
        //             "response",
        //             responseData.quotation_response
        //         );
        //         this.$set(
        //             this.model.quotation_request,
        //             "coverages",
        //             responseData.quotation_response.coverages
        //         );

        //         // this.model.response = await geremia_FastQuotation_Response();

        //         this.visualizzaProposta();
        //     } catch (err) {
        //         console.log("submitFastQuotationFlowData ERROR", err);
        //         if (err?.response?.data) {
        //             console.log(
        //                 "submitFastQuotationFlowData ERROR",
        //                 err.status
        //             );
        //             this.messageBag.message = err.response.data.message;
        //             this.messageBag.errors = transformErrors(
        //                 err.response.data.errors
        //             );

        //             this.$forceUpdate();
        //             console.log(this.messageBag.errors);
        //         } else {
        //             // alert("500 ??");
        //             console.log("submitFastQuotationFlowData 500 !!! ", err);
        //             this.messageBag = errorCallback(err);

        //             //console.log(err)
        //         }
        //     }
        //     this.loading = false;
        // },
        trasformModel() {
            try {
                // Prima trasforma gli elementi
                const transformed = mapIf(this.selected, (inputJson) => ({
                    code: inputJson.code,
                    description: inputJson.description,
                    selected: inputJson.selected || true,
                    value: inputJson.value || null,
                    coInsurance: inputJson.coInsurance || null,
                    PackageCode: inputJson.PackageCode || "",
                }));

                // Poi applica l'ordinamento usando la nuova funzione
                return this.orderByPackageAndCode(transformed);
            } catch (error) {
                console.error("Errore in trasformModel:", error);
                return [];
            }
        },
    },
    computed: {
        copertureDisponibili: {
            get() {
                try {
                    const garanzieEsistenti = CopertureDisponibili;
                    if (
                        !garanzieEsistenti ||
                        !Array.isArray(garanzieEsistenti)
                    ) {
                        console.warn(
                            "CopertureDisponibili non è un array o è undefined:",
                            garanzieEsistenti,
                        );
                        return [];
                    }
                    return filterByVehicleCategory(
                        this.vehicleCategory,
                        garanzieEsistenti,
                    );
                } catch (error) {
                    console.error(
                        "Errore in copertureDisponibili getter:",
                        error,
                    );
                    return [];
                }
            },
            set(v) {
                console.log(v);
            },
        },
        copertureSelezionate: {
            get() {
                return this.orderByPackageAndCode(this.selected);
            },
            set(v) {
                console.log(v);
            },
        },
        model: {
            get() {
                // console.log("model get", JSON.stringify(this.value));
                return this.value; // Ritorna array vuoto se value è undefined
            },
            set(v) {
                this.$emit("input", v);
            },
        },
    },
    watch: {
        /**
         * Osserva i cambiamenti dell'array selected
         * @param {Array} newVal - Nuovo valore dell'array selected
         * @param {Array} oldVal - Vecchio valore dell'array selected
         */
        selected: {
            handler(newVal, oldVal) {
                try {
                    console.log("Watch selected:", {
                        new: newVal,
                        old: oldVal,
                    });

                    // Trasforma i dati e aggiorna il model
                    const transformedData = this.trasformModel();

                    // Emetti il valore trasformato
                    this.$emit("input", transformedData);
                } catch (error) {
                    console.error("Errore nel watch di selected:", error);
                }
            },
            deep: true,
            immediate: true,
        },
        // value: {
        //     handler(newVal) {},
        //     immediate: true,
        // },
    },
};
</script>

<style scoped>
/* Riduce la larghezza della colonna checkbox */
.custom-data-table .v-data-table__checkbox {
    width: 40px !important; /* Regola la larghezza come necessario */
}

/* Riduce la larghezza della colonna espansione */
.custom-data-table .v-data-table__expand {
    width: 40px !important; /* Regola la larghezza come necessario */
}
</style>
