var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"model__form",attrs:{"id":"ProdottoAssicurativoForm"}},[_c('v-card',{staticClass:"pa-4"},[_c('CardAction',{attrs:{"sezione-attiva":"Prodotto Assicurativo"},on:{"on-annulla":function($event){return _vm.annulla()},"on-aggiorna":function($event){return _vm.aggiorna()}},model:{value:(_vm.formData.attivo),callback:function ($$v) {_vm.$set(_vm.formData, "attivo", $$v)},expression:"formData.attivo"}}),_c('v-row',{staticClass:"pt-4"},[_c('v-col',[_c('teo-autocomplete',{ref:"fornitore",attrs:{"color":"primary","name":"fornitore","label":"Fornitore","item-value":"id","item-text":"label","resource":"/api/autocomplete/fornitore"},model:{value:(_vm.formData.compagnia_fornitore_id),callback:function ($$v) {_vm.$set(_vm.formData, "compagnia_fornitore_id", $$v)},expression:"formData.compagnia_fornitore_id"}}),_c('teo-autocomplete',{ref:"contrattuale",attrs:{"name":"compagnia_contrattuale","label":"Compagnia Contrattuale","clearable":"","color":"primary","item-value":"id","item-text":"nome","resource":"/api/compagnia_contrattuale"},on:{"change":_vm.changeCompagniaContrattuale},model:{value:(_vm.formData.compagnia_contrattuale_id),callback:function ($$v) {_vm.$set(_vm.formData, "compagnia_contrattuale_id", $$v)},expression:"formData.compagnia_contrattuale_id"}}),_c('div',{staticClass:"mt-5 h6"},[_vm._v(" Inserisci una nuova categoria prodotto ")]),(
                        _vm.formData.compagnia_contrattuale_id &&
                        _vm.formData.compagnia_fornitore_id &&
                        _vm.new_categoria_prodotto
                    )?_c('v-row',{staticClass:"mt-5"},[_c('v-col',[_c('v-text-field',{attrs:{"label":"categoria/prodotto","dense":"","color":"primary","placeholder":"nome nuova categoria"},model:{value:(_vm.new_categoria_prodotto.nome),callback:function ($$v) {_vm.$set(_vm.new_categoria_prodotto, "nome", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"new_categoria_prodotto.nome"}})],1),_c('v-col',[_c('text-formatted',{attrs:{"label":"provvigione attiva","dense":"","color":"primary","error-messages":[],"min":"0","max":"100","suffix":"%"},model:{value:(
                                _vm.new_categoria_prodotto.mandato
                                    .percentuale_provvigione_attiva
                            ),callback:function ($$v) {_vm.$set(_vm.new_categoria_prodotto.mandato
                                    , "percentuale_provvigione_attiva", $$v)},expression:"\n                                new_categoria_prodotto.mandato\n                                    .percentuale_provvigione_attiva\n                            "}})],1),_c('v-col',[_c('teo-autocomplete',{ref:"ramo",attrs:{"name":"ramo","item-value":"id","item-text":"label","dense":"","clearable":"","label":"Ramo","resource":"/api/autocomplete/ramo"},model:{value:(_vm.new_categoria_prodotto.ramo_id),callback:function ($$v) {_vm.$set(_vm.new_categoria_prodotto, "ramo_id", $$v)},expression:"new_categoria_prodotto.ramo_id"}})],1),_c('v-col',[_c('date-picker',{attrs:{"label":"data inizio mandato","data-cy":"data_inizio_mandato","color":"primary","value-type":"YYYY-MM-DD","format":"DD/MM/YYYY"},model:{value:(
                                _vm.new_categoria_prodotto.mandato
                                    .data_inizio_mandato
                            ),callback:function ($$v) {_vm.$set(_vm.new_categoria_prodotto.mandato
                                    , "data_inizio_mandato", $$v)},expression:"\n                                new_categoria_prodotto.mandato\n                                    .data_inizio_mandato\n                            "}})],1),_c('v-col',[_c('teo-autocomplete',{ref:"ramo",attrs:{"name":"ramo","item-value":"id","item-text":"label","label":"Settore","resource":"/api/autocomplete/settore"},model:{value:(_vm.formData.settore_id),callback:function ($$v) {_vm.$set(_vm.formData, "settore_id", $$v)},expression:"formData.settore_id"}})],1),_c('v-col',[_c('v-btn',{attrs:{"dense":"","disabled":_vm.new_categoria_prodotto &&
                                _vm.new_categoria_prodotto.nome &&
                                _vm.new_categoria_prodotto.nome.length <= 0,"color":"primary"}},[_vm._v("aggiungi ")])],1)],1):_vm._e(),_c('div',{staticClass:"mt-5 h6"},[_vm._v("Categorie inserite")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }