import axios from "axios";
import { router } from "../main";

// import store from '@/store/store';
export const Api = axios.create();

/**
  https://github.com/garethredfern/laravel-vue/blob/v1.2.1/src/services/API.js
 */
Api.interceptors.request.use(function (config) {
    // store.commit('setLoading', true);
    // store.dispatch('loading/setLoading', true); // imposta loading a true all'inizio della richiesta

    // const token = store.getState().session.token;
    const token = window.sessionStorage.getItem("token");
    config.headers.Authorization = `Bearer ${token}`;
    config.headers["Cache-Control"] = "no-cache, no-store, must-revalidate";
    config.headers["Pragma"] = "no-cache";
    config.headers["Expires"] = "0";
    // config.headers["Content-Type"] = "application/json";
    // config.headers["Accept"] = "application/json, text/html";
    config.headers["Content-Type"] = "application/json";
    config.headers["Accept"] = "application/json";

    // Aggiungi timestamp per le richieste GET
    if (config.method === "get") {
        config.params = {
            ...config.params,
            _t: Date.now(),
        };
    }

    // Aggiungi questo console.log per debugging
    console.log("Request headers:", config.headers);
    console.log("Request payload:", config.data);

    return config;
});

/*
 * Add a response interceptor
 */
Api.interceptors.response.use(
    (response) => {
        return response;
    },
    function (error) {

        if (error.response && error.response.status === 503) {
            // Reindirizza a una pagina personalizzata o mostra un avviso
            window.location.href = '/manutenzione';
          }
        
        // Controlla se la risposta contiene il messaggio "Unauthenticated"
        if (error.response?.data?.message === "Unauthenticated.") {
            // Rimuovi il token
            window.sessionStorage.removeItem("token");
            // Reindirizza al login
            router.push("/login");

            // Opzionale: mostra un messaggio all'utente
            return Promise.reject({
                message: "Sessione scaduta. Effettua nuovamente il login.",
                errors: {},
            });
        }

        return Promise.reject(error);
    },
);

export function errorCallback(error, subject = "") {
    if (error.code && error.code == "ERR_NETWORK") {
        return {
            message: "Errore di connessione al server",
            errors: {},
        };
    }
    if (error.response && error.response.status == 401) {
        window.sessionStorage.removeItem("token");
        router.push("/login");
    }

    if (error.response && error.response.status == 404) {
        alert(subject + " risorsa non trovata ");
    }

    // if(error){
    //    alert(error.code)
    // }
}
