import Vue from "vue";
import Vuex from "vuex";

import * as getters from "./getters";
import * as mutations from "./mutations";
import * as actions from "./actions";

import MandatoProdottoModule from "./modules/MandatoProdottoModule";
import SettoreModule from "./modules/SettoreModule";
import CollaboratoreModule from "./modules/CollaboratoreModule";
import ClienteModule from "./modules/ClienteModule";
import CompagniaContrattualeModule from "./modules/CompagniaContrattualeModule";
import ProvinciaComuneModule from "./modules/ProvinciaComuneModule";
import LoadingModule from "./modules/LoadingModule";
import PolizzaModule from "./modules/PolizzaModule";
// import VehicleSearchQuiXAModule from "./modules/VehicleSearchQuiXAModule";
import state from "./state";
import UiRouterModule from "./modules/UiRouterModule";

Vue.use(Vuex);
// console.log(state)
export const store = new Vuex.Store({
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
    modules: {
        LoadingModule,
        MandatoProdottoModule,
        PolizzaModule,
        SettoreModule,
        CollaboratoreModule,
        ClienteModule,
        CompagniaContrattualeModule,
        ProvinciaComuneModule,
        UiRouterModule,
    },
});
