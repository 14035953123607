<template>
    <div id="BreakpointDebug"  :style="{ display: false ? 'block' : 'none' }">
        <v-alert outlined>
            <!-- Mostra quale breakpoint è attivo -->
            <span v-if="$vuetify.breakpoint.xs">xs - Mobile</span>
            <span v-else-if="$vuetify.breakpoint.sm">sm - Tablet</span>
            <span v-else-if="$vuetify.breakpoint.md">md - Tablet</span>
            <span v-else-if="$vuetify.breakpoint.lg">lg - Desktop</span>
            <span v-else-if="$vuetify.breakpoint.xl">xl - Desktop grande</span>
        </v-alert>
    </div>
</template>

<script>
export default {
    name: "BreakpointDebug",
    props: {
        debug: {
            type: Boolean,
            default: false, // Controlla se il componente è visibile
        },
    },
};
</script>
<style>
#BreakpointDebug {
    position: fixed;
    top: 67px;
    right: 0;
    background-color: white;
    height: 3.5rem;
    z-index: 10000;
}
</style>
<!-- <breakpoint-debug :debug="true" /> -->
