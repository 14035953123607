var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"model__form"},[_c('div',{attrs:{"id":"CollaboratoreForm"}},[_c('v-toolbar',{attrs:{"dense":""}},[_c('v-toolbar-title',[(_vm.collaboratore)?_c('v-card-title',{staticClass:"h4",attrs:{"primary-title":""}},[(!_vm.collaboratore.id)?_c('span',[_vm._v("Dati Collaboratore "+_vm._s(_vm.mode))]):_vm._e(),(_vm.collaboratore.id)?_c('span',[_vm._v(_vm._s(_vm.collaboratore.nome + " " + (_vm.collaboratore.cognome || "")))]):_vm._e()]):_vm._e()],1),_c('v-spacer'),_c('v-toolbar-items',[(_vm.mode === 'EDIT_MODE')?_c('v-btn',{staticClass:"primary",attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.aggiorna()}}},[_vm._v("Aggiorna")]):_vm._e(),(_vm.mode === 'ADD_NEW_MODE')?_c('v-btn',{staticClass:"primary",attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.aggiungi()}}},[_vm._v("Aggiungi")]):_vm._e()],1)],1),_c('v-card',{attrs:{"background-color":"white","loading":_vm.loading}},[_c('v-card-text',[_c('v-row',{staticClass:"pt-0 px-0"},[_c('v-col',{attrs:{"col":"12","sm":"9"}},[(_vm.collaboratore)?_c('div',{staticClass:"v-main__wrap"},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-switch',{attrs:{"id":"active","data-cy":"active","label":"Active"},model:{value:(_vm.collaboratore.active),callback:function ($$v) {_vm.$set(_vm.collaboratore, "active", $$v)},expression:"collaboratore.active"}})],1),_c('section',{staticClass:"fieldset",attrs:{"id":"collaboratore__anagrafica"}},[_c('h3',{staticClass:"h6 fieldset__title"},[_vm._v(" Anagrafica ")]),_c('div',{staticClass:"fieldset__content row"},[_c('div',{staticClass:"form-group col-lg-3 py-0 px-1 mb-3",attrs:{"id":"Sesso"}},[_c('label',{attrs:{"for":"sesso"}},[_vm._v("Sesso/Azienda")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.collaboratore.sesso),expression:"collaboratore.sesso"}],staticClass:"form-control-sm form-control custom-select",class:{
                                                'is-invalid': _vm.errors.sesso,
                                            },attrs:{"id":"sesso","data-cy":"sesso:select"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.collaboratore, "sesso", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"M"}},[_vm._v(" Maschio ")]),_c('option',{attrs:{"value":"F"}},[_vm._v(" Femmina ")]),_c('option',{attrs:{"value":"A"}},[_vm._v(" Azienda ")])]),_c('invalid-feedback',{attrs:{"errors":_vm.errors.sesso}})],1),(_vm.collaboratore.sesso !== 'A')?_c('div',{staticClass:"form-group col-lg-5 py-0 px-1 mb-3",attrs:{"id":"Nome"}},[_c('label',{attrs:{"for":"nome"}},[_vm._v("Nome:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.collaboratore.nome),expression:"collaboratore.nome"}],staticClass:"form-control-sm form-control",class:{
                                                'is-invalid': _vm.errors.nome,
                                            },attrs:{"data-cy":"nome","type":"text","id":"nome"},domProps:{"value":(_vm.collaboratore.nome)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.collaboratore, "nome", $event.target.value)}}}),_c('invalid-feedback',{attrs:{"errors":_vm.errors.nome}})],1):_vm._e(),(_vm.collaboratore.sesso !== 'A')?_c('div',{staticClass:"form-group col-lg-4 py-0 px-1 mb-3",attrs:{"id":"Cognome"}},[_c('label',{attrs:{"for":"cognome"}},[_vm._v("Cognome:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.collaboratore.cognome),expression:"collaboratore.cognome"}],staticClass:"form-control-sm form-control",class:{
                                                'is-invalid':
                                                    _vm.errors.cognome,
                                            },attrs:{"data-cy":"cognome","type":"text","id":"cognome"},domProps:{"value":(_vm.collaboratore.cognome)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.collaboratore, "cognome", $event.target.value)}}}),_c('invalid-feedback',{attrs:{"errors":_vm.errors.cognome}})],1):_vm._e(),(_vm.collaboratore.sesso === 'A')?_c('div',{staticClass:"form-group col-lg-8 py-0 px-1 mb-3",attrs:{"id":"ragioneSociale"}},[_c('label',{attrs:{"for":"ragioneSociale"}},[_vm._v("Ragione Sociale:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.collaboratore.nome),expression:"collaboratore.nome"}],staticClass:"form-control-sm form-control",class:{
                                                'is-invalid': _vm.errors.nome,
                                            },attrs:{"data-cy":"ragioneSociale","type":"text"},domProps:{"value":(_vm.collaboratore.nome)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.collaboratore, "nome", $event.target.value)}}}),_c('invalid-feedback',{attrs:{"errors":_vm.errors.nome}})],1):_vm._e()])]),_c('section',{staticClass:"fieldset",attrs:{"id":"collaboratore__nascita"}},[_c('div',{staticClass:"fieldset__content row"},[(_vm.collaboratore.sesso !== 'A')?_c('div',{staticClass:"form-group col-lg-6 py-0 px-1 mb-3"},[_c('label',{attrs:{"for":"comuneNascita"}},[_vm._v("Comune/Luogo di nascita:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                _vm.collaboratore.comuneNascita
                                            ),expression:"\n                                                collaboratore.comuneNascita\n                                            "}],staticClass:"form-control-sm form-control",class:{
                                                'is-invalid':
                                                    _vm.errors.comuneNascita,
                                            },attrs:{"data-cy":"comuneNascita","type":"text","id":"comuneNascita"},domProps:{"value":(
                                                _vm.collaboratore.comuneNascita
                                            )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.collaboratore, "comuneNascita", $event.target.value)}}}),_c('invalid-feedback',{attrs:{"errors":_vm.errors.comuneNascita}}),_c('autocomplete',{attrs:{"label":"Comune","data-cy":"comuneNascita","clearable":"","color":"primary","item-value":"id","item-text":"nome","resource":"/api/autocomplete/comune"},model:{value:(
                                                _vm.collaboratore.comune_nascita_id
                                            ),callback:function ($$v) {_vm.$set(_vm.collaboratore, "comune_nascita_id", $$v)},expression:"\n                                                collaboratore.comune_nascita_id\n                                            "}})],1):_vm._e(),(_vm.collaboratore.sesso !== 'A')?_c('div',{staticClass:"form-group col-lg-2 py-0 px-1 mb-3",attrs:{"id":"provinciaNascita"}},[_c('label',{attrs:{"for":"provinciaNascita"}},[_vm._v("Provincia di")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                _vm.collaboratore.provinciaNascita
                                            ),expression:"\n                                                collaboratore.provinciaNascita\n                                            "}],staticClass:"form-control-sm form-control",class:{
                                                'is-invalid':
                                                    _vm.errors.provinciaNascita,
                                            },attrs:{"data-cy":"provinciaNascita","type":"text"},domProps:{"value":(
                                                _vm.collaboratore.provinciaNascita
                                            )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.collaboratore, "provinciaNascita", $event.target.value)}}}),_c('invalid-feedback',{attrs:{"errors":_vm.errors.provinciaNascita}})],1):_vm._e(),(_vm.collaboratore.sesso !== 'A')?_c('div',{staticClass:"form-group col-lg-4 py-0 px-1 mb-3",attrs:{"id":"Datanascita"}},[_c('label',{attrs:{"for":"dataNascita"}},[_vm._v("Data di Nascita:")]),_c('date-picker',{attrs:{"data-cy":"dataNascita:date","value-type":"YYYY-MM-DD","format":"DD/MM/YYYY"},model:{value:(
                                                _vm.collaboratore.dataNascita
                                            ),callback:function ($$v) {_vm.$set(_vm.collaboratore, "dataNascita", $$v)},expression:"\n                                                collaboratore.dataNascita\n                                            "}})],1):_vm._e()]),(_vm.collaboratore.sesso !== 'A')?_c('div',{staticClass:"fieldset__content row"},[_c('div',{staticClass:"form-group col-lg-12 py-0 px-1 mb-3",attrs:{"id":"Cf"}},[_c('label',{attrs:{"for":"cf"}},[_vm._v("Cf:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.collaboratore.cf),expression:"collaboratore.cf"}],staticClass:"form-control-sm form-control",class:{
                                                'is-invalid': _vm.errors.cf,
                                            },attrs:{"data-cy":"cf","type":"text","id":"cf"},domProps:{"value":(_vm.collaboratore.cf)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.collaboratore, "cf", $event.target.value)}}}),_c('invalid-feedback',{attrs:{"errors":_vm.errors.cf}})],1)]):_vm._e()]),_c('section',{staticClass:"fieldset",attrs:{"id":"indirizzo__residenza"}},[(_vm.collaboratore.sesso !== 'A')?_c('h3',{staticClass:"h6 fieldset__title"},[_vm._v(" Residenza ")]):_vm._e(),(_vm.collaboratore.sesso === 'A')?_c('h3',{staticClass:"h6 fieldset__title"},[_vm._v(" Sede Legale/Operativa ")]):_vm._e(),_c('div',{staticClass:"fieldset__content row"},[_c('div',{staticClass:"form-group col-lg-5 py-0 px-1 mb-3",attrs:{"id":"Indirizzo"}},[_c('label',{attrs:{"for":"indirizzo"}},[_vm._v("Indirizzo:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                _vm.collaboratore.indirizzo
                                            ),expression:"\n                                                collaboratore.indirizzo\n                                            "}],staticClass:"form-control-sm form-control",class:{
                                                'is-invalid':
                                                    _vm.errors.indirizzo,
                                            },attrs:{"data-cy":"indirizzo","type":"text","id":"indirizzo"},domProps:{"value":(
                                                _vm.collaboratore.indirizzo
                                            )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.collaboratore, "indirizzo", $event.target.value)}}}),_c('invalid-feedback',{attrs:{"errors":_vm.errors.indirizzo}})],1),_c('div',{staticClass:"form-group col-lg-1 py-0 px-1 mb-3",attrs:{"id":"Civico"}},[_c('label',{attrs:{"for":"civico"}},[_vm._v("Civico:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.collaboratore.civico),expression:"collaboratore.civico"}],staticClass:"form-control-sm form-control",class:{
                                                'is-invalid': _vm.errors.civico,
                                            },attrs:{"data-cy":"civico","type":"text","id":"civico"},domProps:{"value":(_vm.collaboratore.civico)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.collaboratore, "civico", $event.target.value)}}}),_c('invalid-feedback',{attrs:{"errors":_vm.errors.civico}})],1),_c('div',{staticClass:"form-group col-lg-4 py-0 px-1 mb-3",attrs:{"id":"Citta"}},[_c('label',{attrs:{"for":"citta"}},[_vm._v("Citta:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.collaboratore.citta),expression:"collaboratore.citta"}],staticClass:"form-control-sm form-control",class:{
                                                'is-invalid': _vm.errors.citta,
                                            },attrs:{"data-cy":"citta","type":"text","id":"citta"},domProps:{"value":(_vm.collaboratore.citta)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.collaboratore, "citta", $event.target.value)}}}),_c('invalid-feedback',{attrs:{"errors":_vm.errors.citta}})],1),_c('div',{staticClass:"form-group col-lg-1 py-0 px-1 mb-3",attrs:{"id":"Cap"}},[_c('label',{attrs:{"for":"cap"}},[_vm._v("Cap:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.collaboratore.cap),expression:"collaboratore.cap"}],staticClass:"form-control-sm form-control",class:{
                                                'is-invalid': _vm.errors.cap,
                                            },attrs:{"data-cy":"cap","type":"text","id":"cap"},domProps:{"value":(_vm.collaboratore.cap)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.collaboratore, "cap", $event.target.value)}}}),_c('invalid-feedback',{attrs:{"errors":_vm.errors.cap}})],1),_c('div',{staticClass:"form-group col-lg-1 py-0 px-1 mb-3",attrs:{"id":"Prov"}},[_c('label',{attrs:{"for":"prov"}},[_vm._v("Prov:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.collaboratore.prov),expression:"collaboratore.prov"}],staticClass:"form-control-sm form-control",class:{
                                                'is-invalid': _vm.errors.prov,
                                            },attrs:{"data-cy":"prov","type":"text","id":"prov"},domProps:{"value":(_vm.collaboratore.prov)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.collaboratore, "prov", $event.target.value)}}}),_c('invalid-feedback',{attrs:{"errors":_vm.errors.prov}})],1)])]),_c('section',{staticClass:"fieldset",attrs:{"id":"collaboratore__contatti"}},[_c('h3',{staticClass:"h6 fieldset__title"},[_vm._v("Contatti")]),_c('div',{staticClass:"fieldset__content row"},[_c('div',{staticClass:"form-group col-lg-3 py-0 px-1 mb-3",attrs:{"id":"Cell"}},[_c('label',{attrs:{"for":"cell"}},[_vm._v("Cell:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.collaboratore.cell),expression:"collaboratore.cell"}],staticClass:"form-control-sm form-control",class:{
                                                'is-invalid': _vm.errors.cell,
                                            },attrs:{"data-cy":"cell","type":"text","id":"cell"},domProps:{"value":(_vm.collaboratore.cell)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.collaboratore, "cell", $event.target.value)}}}),_c('invalid-feedback',{attrs:{"errors":_vm.errors.cell}})],1),_c('div',{staticClass:"form-group col-lg-3 py-0 px-1 mb-3",attrs:{"id":"Tel"}},[_c('label',{attrs:{"for":"tel"}},[_vm._v("Tel:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.collaboratore.tel),expression:"collaboratore.tel"}],staticClass:"form-control-sm form-control",class:{
                                                'is-invalid': _vm.errors.tel,
                                            },attrs:{"data-cy":"tel","type":"text","id":"tel"},domProps:{"value":(_vm.collaboratore.tel)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.collaboratore, "tel", $event.target.value)}}}),_c('invalid-feedback',{attrs:{"errors":_vm.errors.tel}})],1),_c('div',{staticClass:"form-group col-lg-3 py-0 px-1 mb-3",attrs:{"id":"Email"}},[_c('label',{attrs:{"for":"email"}},[_vm._v("Email:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.collaboratore.email),expression:"collaboratore.email"}],staticClass:"form-control-sm form-control",class:{
                                                'is-invalid': _vm.errors.email,
                                            },attrs:{"data-cy":"email","type":"email","id":"email"},domProps:{"value":(_vm.collaboratore.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.collaboratore, "email", $event.target.value)}}}),_c('invalid-feedback',{attrs:{"errors":_vm.errors.email}})],1),_c('div',{staticClass:"form-group col-lg-3 py-0 px-1 mb-3",attrs:{"id":"Pec"}},[_c('label',{attrs:{"for":"pec"}},[_vm._v("Pec:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.collaboratore.pec),expression:"collaboratore.pec"}],staticClass:"form-control-sm form-control",class:{
                                                'is-invalid': _vm.errors.pec,
                                            },attrs:{"data-cy":"pec","type":"email","id":"pec"},domProps:{"value":(_vm.collaboratore.pec)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.collaboratore, "pec", $event.target.value)}}}),_c('invalid-feedback',{attrs:{"errors":_vm.errors.pec}})],1)])]),(_vm.collaboratore.sesso !== 'A')?_c('section',{staticClass:"fieldset",attrs:{"id":"collaboratore__documento"}},[_c('h3',{staticClass:"h6 fieldset__title"},[_vm._v(" Documento di identità ")]),_c('div',{staticClass:"fieldset__content row"},[_c('div',{staticClass:"form-group col-lg-3 py-0 px-1 mb-3",attrs:{"id":"Documentonumero"}},[_c('label',{attrs:{"for":"documentoNumero"}},[_vm._v("Numero:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                _vm.collaboratore.documentoNumero
                                            ),expression:"\n                                                collaboratore.documentoNumero\n                                            "}],staticClass:"form-control-sm form-control",class:{
                                                'is-invalid':
                                                    _vm.errors.documentoNumero,
                                            },attrs:{"data-cy":"documentoNumero","type":"text","id":"documentoNumero"},domProps:{"value":(
                                                _vm.collaboratore.documentoNumero
                                            )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.collaboratore, "documentoNumero", $event.target.value)}}}),_c('invalid-feedback',{attrs:{"errors":_vm.errors.documentoNumero}})],1),_c('div',{staticClass:"form-group col-lg-3",attrs:{"id":"Documentotipo"}},[_c('label',{attrs:{"for":"documentoTipo"}},[_vm._v("Tipo:")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(
                                                _vm.collaboratore.documentoTipo
                                            ),expression:"\n                                                collaboratore.documentoTipo\n                                            "}],staticClass:"form-control-sm form-control custom-select",class:{
                                                'is-invalid':
                                                    _vm.errors.documentoTipo,
                                            },attrs:{"id":"documentoTipo","data-cy":"documentoTipo"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.collaboratore, "documentoTipo", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"CARTA_IDENTITA"}},[_vm._v(" CARTA_IDENTITA ")]),_c('option',{attrs:{"value":"PASSAPORTO"}},[_vm._v(" PASSAPORTO ")])]),_c('invalid-feedback',{attrs:{"errors":_vm.errors.documentoTipo}})],1),_c('div',{staticClass:"form-group col-lg-3 py-0 px-1 mb-3",attrs:{"id":"Documentoscadenza"}},[_c('label',{attrs:{"for":"documentoScadenza"}},[_vm._v("Scadenza:")]),_c('date-picker',{class:{
                                                'is-invalid':
                                                    _vm.errors.documentoScadenza,
                                            },attrs:{"data-cy":"documentoScadenza:date","value-type":"YYYY-MM-DD","format":"DD/MM/YYYY"},model:{value:(
                                                _vm.collaboratore.documentoScadenza
                                            ),callback:function ($$v) {_vm.$set(_vm.collaboratore, "documentoScadenza", $$v)},expression:"\n                                                collaboratore.documentoScadenza\n                                            "}}),_c('invalid-feedback',{attrs:{"errors":_vm.errors.documentoScadenza}})],1),_c('div',{staticClass:"form-group col-lg-3 py-0 px-1 mb-3",attrs:{"id":"Documentorilasciatoda"}},[_c('label',{attrs:{"for":"documentoRilasciatoDa"}},[_vm._v("Rilasciato Da:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                _vm.collaboratore.documentoRilasciatoDa
                                            ),expression:"\n                                                collaboratore.documentoRilasciatoDa\n                                            "}],staticClass:"form-control-sm form-control",class:{
                                                'is-invalid':
                                                    _vm.errors.documentoRilasciatoDa,
                                            },attrs:{"data-cy":"documentoRilasciatoDa","type":"text","id":"documentoRilasciatoDa"},domProps:{"value":(
                                                _vm.collaboratore.documentoRilasciatoDa
                                            )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.collaboratore, "documentoRilasciatoDa", $event.target.value)}}}),_c('invalid-feedback',{attrs:{"errors":_vm.errors.documentoRilasciatoDa}})],1)])]):_vm._e(),_c('section',{staticClass:"fieldset",attrs:{"id":"collaboratore__professionale_fatturazione"}},[_c('h3',{staticClass:"h6 fieldset__title"},[_vm._v(" Dati professionali e contabili ")]),_c('div',{staticClass:"fieldset__content row"},[_c('div',{staticClass:"form-group col-lg-3 py-0 px-1 mb-3",attrs:{"id":"Tipo"}},[_c('label',{attrs:{"for":"tipo"}},[_vm._v("Tipo:")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.collaboratore.tipo),expression:"collaboratore.tipo"}],staticClass:"form-control-sm form-control custom-select",class:{
                                                'is-invalid': _vm.errors.tipo,
                                            },attrs:{"id":"tipo","data-cy":"tipo"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.collaboratore, "tipo", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"COLLABORATORE"}},[_vm._v(" COLLABORATORE ")]),_c('option',{attrs:{"value":"AGENTE"}},[_vm._v(" AGENTE ")]),_c('option',{attrs:{"value":"BROKER"}},[_vm._v(" BROKER ")])]),_c('invalid-feedback',{attrs:{"errors":_vm.errors.tipo}})],1),_c('div',{staticClass:"form-group col-lg-3 py-0 px-1 mb-3",attrs:{"id":"Iscrizionerui"}},[_c('label',{attrs:{"for":"iscrizioneRUI"}},[_vm._v("Iscrizione R.U.I.:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                _vm.collaboratore.iscrizioneRUI
                                            ),expression:"\n                                                collaboratore.iscrizioneRUI\n                                            "}],staticClass:"form-control-sm form-control",class:{
                                                'is-invalid':
                                                    _vm.errors.iscrizioneRUI,
                                            },attrs:{"data-cy":"iscrizioneRUI","type":"text","id":"iscrizioneRUI"},domProps:{"value":(
                                                _vm.collaboratore.iscrizioneRUI
                                            )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.collaboratore, "iscrizioneRUI", $event.target.value)}}}),_c('invalid-feedback',{attrs:{"errors":_vm.errors.iscrizioneRUI}})],1),_c('div',{staticClass:"form-group col-lg-3 py-0 px-1 mb-3",attrs:{"id":"Iscrizioneruidata"}},[_c('label',{attrs:{"for":"iscrizioneRUIData"}},[_vm._v("Data Iscrizione:")]),_c('date-picker',{class:{
                                                'is-invalid':
                                                    _vm.errors.iscrizioneRUIData,
                                            },attrs:{"data-cy":"iscrizioneRUIData:date","value-type":"YYYY-MM-DD","format":"DD/MM/YYYY"},model:{value:(
                                                _vm.collaboratore.iscrizioneRUIData
                                            ),callback:function ($$v) {_vm.$set(_vm.collaboratore, "iscrizioneRUIData", $$v)},expression:"\n                                                collaboratore.iscrizioneRUIData\n                                            "}}),_c('invalid-feedback',{attrs:{"errors":_vm.errors.iscrizioneRUIData}})],1),_c('div',{staticClass:"form-group col-lg-3 py-0 px-1 mb-3",attrs:{"id":"Piva"}},[_c('label',{attrs:{"for":"piva"}},[_vm._v("Partita Iva:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.collaboratore.piva),expression:"collaboratore.piva"}],staticClass:"form-control-sm form-control",class:{
                                                'is-invalid': _vm.errors.piva,
                                            },attrs:{"data-cy":"piva","type":"text","id":"piva"},domProps:{"value":(_vm.collaboratore.piva)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.collaboratore, "piva", $event.target.value)}}}),_c('invalid-feedback',{attrs:{"errors":_vm.errors.piva}})],1),_c('div',{staticClass:"form-group col-lg-4 py-0 px-1 mb-3",attrs:{"id":"Iban"}},[_c('label',{attrs:{"for":"IBAN"}},[_vm._v("IBAN Conto Premi:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.collaboratore.IBAN),expression:"collaboratore.IBAN"}],staticClass:"form-control-sm form-control",class:{
                                                'is-invalid': _vm.errors.IBAN,
                                            },attrs:{"data-cy":"IBAN","type":"text","id":"IBAN"},domProps:{"value":(_vm.collaboratore.IBAN)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.collaboratore, "IBAN", $event.target.value)}}}),_c('invalid-feedback',{attrs:{"errors":_vm.errors.IBAN}})],1),_c('div',{staticClass:"form-group col-lg-4 py-0 px-1 mb-3"},[_c('label',{attrs:{"for":"IBAN"}},[_vm._v("IBAN Conto Patrimonio:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                _vm.collaboratore.IBANPatrimonio
                                            ),expression:"\n                                                collaboratore.IBANPatrimonio\n                                            "}],staticClass:"form-control-sm form-control",class:{
                                                'is-invalid':
                                                    _vm.errors.IBANPatrimonio,
                                            },attrs:{"data-cy":"IBAN","type":"text","id":"IBANPatrimonio"},domProps:{"value":(
                                                _vm.collaboratore.IBANPatrimonio
                                            )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.collaboratore, "IBANPatrimonio", $event.target.value)}}}),_c('invalid-feedback',{attrs:{"errors":_vm.errors.IBANPatrimonio}})],1),_c('div',{staticClass:"form-group col-lg-4 py-0 px-1 mb-3"},[_c('autocomplete',{attrs:{"label":"Tipologia Rimessa","item-value":"id","item-text":"label","dense":"","resource":"/api/autocomplete/tipologia_rimessa"},model:{value:(
                                                _vm.collaboratore.tipologiaRimessa
                                            ),callback:function ($$v) {_vm.$set(_vm.collaboratore, "tipologiaRimessa", $$v)},expression:"\n                                                collaboratore.tipologiaRimessa\n                                            "}})],1),_c('div',{staticClass:"form-group col-lg-3 py-0 px-1 mb-3",attrs:{"id":"Codicedestinatario"}},[_c('label',{attrs:{"for":"codiceDestinatario"}},[_vm._v("Codice Destinatario:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                _vm.collaboratore.codiceDestinatario
                                            ),expression:"\n                                                collaboratore.codiceDestinatario\n                                            "}],staticClass:"form-control-sm form-control",class:{
                                                'is-invalid':
                                                    _vm.errors.codiceDestinatario,
                                            },attrs:{"data-cy":"codiceDestinatario","type":"text","id":"codiceDestinatario"},domProps:{"value":(
                                                _vm.collaboratore.codiceDestinatario
                                            )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.collaboratore, "codiceDestinatario", $event.target.value)}}}),_c('invalid-feedback',{attrs:{"errors":_vm.errors.codiceDestinatario}})],1)]),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-text-field',{attrs:{"id":"numeroREA","data-cy":"numeroREA","type":"text","error-messages":_vm.errors.numeroREA,"error":_vm.errors.numeroREA &&
                                            _vm.errors.numeroREA.length > 0,"label":"NumeroREA"},model:{value:(_vm.collaboratore.numeroREA),callback:function ($$v) {_vm.$set(_vm.collaboratore, "numeroREA", $$v)},expression:"collaboratore.numeroREA"}})],1)],1),_c('section',{staticClass:"fieldset",attrs:{"id":"collaboratore__professionale_fatturazione"}},[_c('h3',{staticClass:"h6 fieldset__title"},[_vm._v("Permessi")]),_c('div',{staticClass:"fieldset__content row"},[_c('div',{staticClass:"form-group col-lg-3 py-0 px-1 mb-3",attrs:{"id":"Web"}},[_c('div',{staticClass:"form-check"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.collaboratore.web),expression:"collaboratore.web"}],staticClass:"form-check-input",class:{
                                                    'is-invalid':
                                                        _vm.errors.web,
                                                },attrs:{"data-cy":"web","type":"hidden","value":"0"},domProps:{"value":(_vm.collaboratore.web)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.collaboratore, "web", $event.target.value)}}}),_c('input',{staticClass:"form-check-input",attrs:{"data-cy":"web","type":"checkbox","value":"1"}}),_c('label',{staticClass:"form-check-label",attrs:{"for":"web"}},[_vm._v("Web: ")])])])])])],1):_vm._e()]),_c('v-col',{attrs:{"col":"12","sm":"3"}},[_c('v-expansion-panels',{attrs:{"multiple":""},model:{value:(_vm.ui.panel),callback:function ($$v) {_vm.$set(_vm.ui, "panel", $$v)},expression:"ui.panel"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"pl-3 p-3"},[(_vm.collaboratore)?_c('div',[_c('h5',{staticClass:"h6 fieldset__title"},[_vm._v(" Settori e Provvigioni ")]),_c('VSpacer'),_vm._l((_vm.collaboratore.provvigioni_settori),function(item){return _c('span',{key:item.nome},[(item.perc_provvigione)?_c('span',[_vm._v(" "+_vm._s(item.nome)+":"+_vm._s(item.perc_provvigione)+"% ")]):_vm._e()])})],2):_vm._e()]),_c('v-expansion-panel-content',[(_vm.collaboratore)?_c('provvigioni-settori-form',{attrs:{"id":"collaboratore-provvigioni-settore"},model:{value:(
                                            _vm.collaboratore.provvigioni_settori
                                        ),callback:function ($$v) {_vm.$set(_vm.collaboratore, "provvigioni_settori", $$v)},expression:"\n                                            collaboratore.provvigioni_settori\n                                        "}}):_vm._e(),_c('v-text-field',{staticClass:"mt-5 p-0",attrs:{"label":"Percentuale Competenze"},model:{value:(
                                            _vm.collaboratore.percProvvigioneCompetenze
                                        ),callback:function ($$v) {_vm.$set(_vm.collaboratore, "percProvvigioneCompetenze", $$v)},expression:"\n                                            collaboratore.percProvvigioneCompetenze\n                                        "}}),_c('v-text-field',{staticClass:"mt-5 p-0",attrs:{"label":"Percentuale Diritti"},model:{value:(
                                            _vm.collaboratore.precProvvigioneDiritti
                                        ),callback:function ($$v) {_vm.$set(_vm.collaboratore, "precProvvigioneDiritti", $$v)},expression:"\n                                            collaboratore.precProvvigioneDiritti\n                                        "}})],1)],1),(_vm.collaboratore.sesso == 'A')?_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"pl-3 p-3"},[_c('h5',{staticClass:"h6 fieldset__title"},[_vm._v(" Responsabile Intermediazione ")])]),_c('v-expansion-panel-content',{staticClass:"p-0"},[_c('persona-fisica-form',{model:{value:(
                                            _vm.collaboratore.responsabileIntermediazione
                                        ),callback:function ($$v) {_vm.$set(_vm.collaboratore, "responsabileIntermediazione", $$v)},expression:"\n                                            collaboratore.responsabileIntermediazione\n                                        "}})],1)],1):_vm._e(),_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"pl-3 p-3"},[_c('h5',{staticClass:"h6 fieldset__title",attrs:{"data-cy-":"punto_vendita_superiore"}},[_vm._v(" Punto vendita superiore ")])]),_c('v-expansion-panel-content',{staticClass:"p-0"},[_c('autocomplete',{attrs:{"item-value":"id","item-text":"label","resource":`/api/autocomplete/punto_vendita_superiore/${_vm.collaboratore.id}`},model:{value:(
                                            _vm.collaboratore.punto_vendita_superiore_id
                                        ),callback:function ($$v) {_vm.$set(_vm.collaboratore, "punto_vendita_superiore_id", $$v)},expression:"\n                                            collaboratore.punto_vendita_superiore_id\n                                        "}})],1)],1)],1)],1)],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }