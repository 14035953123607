<template>
    <section class="veicolo_storico_select">
        <v-card class="border-red" :loading="loading">
            <v-card-title
                >Veicolo Storico

                <span class="box-modello pa-3">
                    <span class="box-modello-wrap" v-if="selectedMarca">
                        <!-- {{ selectedMarca }} -->
                        <span class="pr-3" v-if="selectedMarca">{{
                            selectedMarca
                        }}</span>
                        <span class="pr-3" v-if="auto_storica"
                            >modello {{ auto_storica?.modello }} del
                            {{ auto_storica?.anno }}</span
                        >
                        <span class="pr-3" v-if="auto_storica"
                            >colore {{ auto_storica?.colore }}</span
                        >
                    </span>
                </span>
            </v-card-title>

            <v-form>
                <v-container>
                    <v-autocomplete
                        autocomplete="new-password"
                        data-cy="selectedMarca"
                        v-model="selectedMarca"
                        :items="marche"
                        item-text="marca"
                        label="Seleziona la marca"
                        @change="onMarcaChange"
                        :error="!selectedMarca"
                        error-messages=""
                    >
                    </v-autocomplete>
                    <!-- @change="" -->
                    <v-autocomplete
                        autocomplete="new-password"
                        data-cy="auto_storica"
                        v-model="auto_storica"
                        :items="modelli"
                        item-value="id"
                        item-text="nome"
                        return-object
                        label="Seleziona il modello"
                        @change="autoStoricaChange"
                        :error="!auto_storica.id"
                        error-messages=""
                    >
                    </v-autocomplete>

                    <!-- <div v-if="!auto_storica.id || mode == 'edit'">
            visualizzo il form 
          </div>
          <v-btn color="primary" @click="mode = 'edit'"  >modifica</v-btn> -->
                </v-container>
            </v-form>
        </v-card>
        <!-- <pre>value da esterno {{ value }} </pre>
    <pre>auto_storica {{ auto_storica }} </pre> -->
    </section>
</template>

<script>
import { Api } from "@/http_tools/axiosModule";
import { AutoStoricaService } from "@/services/AutoStoricaService.js";

export default {
    name: "veicolo-storico-select",
    props: {
        value: {
            type: Object,
            required: true,
        },
    },
    mixing: [AutoStoricaService],
    data() {
        return {
            mode: "edit",
            marche: [],
            modelli: [],
            dialog: false,
            auto_storica: {},
            selectedMarca: null,
            loading: false,
        };
    },
    async mounted() {
        this.edit =
            this.autostorica && this.auto_storica.id ? "confermato" : "edit";
        console.log("mounted", this.value.id, this.value);
    },
    async created() {
        console.log(
            "created",
            this.value.id,
            this.value.modello,
            this.value.marca,
        );
        await this.fetchMarche();

        if (this.value.id) {
            this.selectedMarca = this.value.marca;
            await this.searchMarca();
            //   this.auto_storica = {};
            this.auto_storica = this.value;
        }
    },
    methods: {
        async onMarcaChange(value) {
            console.log("onMarcaChange", value);
            await this.searchMarca();
            this.auto_storica = {};
            this.$emit("change", this.auto_storica);
        },
        // cerca veicoli di una marca
        async searchMarca() {
            this.loading = true;

            const response = await Api.get(
                process.env.VUE_APP_API_URL +
                    "/api/autostoriche/search?filter[marca]=" +
                    this.selectedMarca,
            );
            this.modelli = response.data;
            this.loading = false;
            //   this.$emit("change", this.auto_storica = {});

            return response.data;
        },
        async fetchMarche() {
            const response = await Api.get(
                process.env.VUE_APP_API_URL + "/api/autostoriche/marche",
            );
            this.marche = response.data;
        },
        async autoStoricaChange() {
            this.$emit("change", this.auto_storica);
            console.log("emit --> change --> ", this.auto_storica);
        },
    },
    // ,
    // ,
    watch: {
        value: function (new_value) {
            //   console.log("watch new_value",new_value);
            this.auto_storica = { ...new_value };
            // this.$emit("change", new_value);
        },
    },
};
</script>

<style scoped></style>
