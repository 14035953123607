import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

import {
    VRow,
    VCol,
    VTextField,
    VTooltip,
    VCheckbox,
    VSelect,
    VCardTitle,
    VAutocomplete,
    VRadio,
    VRadioGroup,
    VSwitch,
} from "vuetify/lib/components";

// import { VAutocomplete } from 'vuetify/lib';

import VCard from "vuetify/lib/components/VCard/VCard";
// import VCardTitle from 'vuetify/lib/components/VCard/VCardTitle';
// import VCardText from 'vuetify/lib/components/VCard/VCardText';
// import VCardActions from 'vuetify/lib/components/VCard/VCardActions';
// import VBtn from 'vuetify/lib/components/VBtn/VBtn';

import { Ripple, Intersect, Touch, Resize } from "vuetify/lib/directives";

Vue.use(Vuetify, {
    components: {
        VRow,
        VTooltip,
        VCol,
        VTextField,
        VCheckbox,
        VSelect,
        VCard,
        VCardTitle,
        VAutocomplete,
        VRadio,
        VRadioGroup,
        VSwitch,
        // VCardText,
        // VCardActions,
        // VBtn
    },
    // components: { VRow },
    directives: {
        Ripple,
        Intersect,
        Touch,
        Resize,
    },
});

// # Codificare colori del thema
// https://vuetifyjs.com/en/features/theme/#custom-theme-variants
// https://betterprogramming.pub/changing-application-theme-dynamically-in-vuetify-js-c01d640699c4
// ;

// import colors from 'vuetify/lib/util/colors'

// const options = {
//     theme: {
//       themes: {
//         light: {
//           primary: '#3f51b5',
//         //   primary: '#009688',
//           secondary: '#b0bec5',
//           accent: '#8c9eff',
//           error: '#b71c1c',
//         },
//         tertiary: colors.pink.base,
//       },
//     },
// }
let options = {
    icons: {
        iconfont: "mdi", // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    },
};
switch (process.env.VUE_APP_API_URL) {
    case "https://teo.sarassoroberto.it":
        options = {
            theme: {
                themes: {
                    light: {
                        primary: "#adb53f",
                        danger: "#ff0000",
                        secondary: "#3e3e3e",
                        warning: "#FFA014",
                        action: "orange",
                    },
                    dark: {
                        primary: "#adb53f",
                        danger: "#ff0000",
                        secondary: "#3e3e3e",
                        warning: "#FFA014",
                        action: "orange",
                    },
                },
            },
        };
        break;
    case "http://161.97.76.57":
    case "http://api-test-teo.aurumib.it":
        options = {
            theme: {
                themes: {
                    light: {
                        primary: "#009688",
                        danger: "#ff0000",
                    },
                },
            },
        };
        break;
    case "http://staging-teo.aurumib.it":
        options = {
            theme: {
                themes: {
                    light: {
                        primary: "#5d3a6f",
                        danger: "#ff0000",
                    },
                },
            },
        };
        break;

    default:
        break;
}

export default new Vuetify(options);
