<template>
    <v-container fluid>
        <!-- Alert per i messaggi flash -->
        <v-alert
            v-if="flashMessage"
            :type="flashType"
            dismissible
            @input="clearFlash"
        >
            {{ flashMessage }}
        </v-alert>

        <QuotazioneRCASearch @flash="handleFlash" @error="handleError" />
    </v-container>
</template>

<script>
import { StylesMixin } from "@/style/StyleMixing";
import QuotazioneRCASearch from "@/components/quotazione_rca/QuotazioneRCASearch.vue";

export default {
    name: "QuotazioneRCAPage",
    mixins: [StylesMixin],
    components: {
        QuotazioneRCASearch,
    },
    created() {},
    async mounted() {},
    data() {
        return {
            flashMessage: null,
            flashType: null,
        };
    },
    methods: {
        handleFlash({ message, type }) {
            this.flashMessage = message;
            this.flashType = type;
        },

        handleError(error) {
            this.flashMessage = error.message || "Si è verificato un errore";
            this.flashType = "error";
        },

        clearFlash() {
            this.flashMessage = null;
            this.flashType = null;
        },
    },
};
</script>

<style></style>
