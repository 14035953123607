// store/modules/loading.js
const state = {
    isLoading: false,
};

const mutations = {
    SET_LOADING(state, payload) {
        state.isLoading = payload;
    },
};

const actions = {
    setLoading({ commit }, isLoading) {
        commit("SET_LOADING", isLoading);
    },
};

const getters = {
    isLoading: (state) => state.isLoading,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
