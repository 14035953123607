var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.formData)?_c('section',{staticClass:"CompagniaForm",attrs:{"id":"CompagniaForm"}},[_c('v-card',{staticClass:"px-3"},[_c('CardAction',{attrs:{"sezione-attiva":_vm.$store.state.sezioneAttiva},on:{"on-annulla":function($event){return _vm.annulla()},"on-aggiorna":function($event){return _vm.aggiorna()},"on-aggiungi":function($event){return _vm.aggiorna()}},model:{value:(_vm.formData.attivo),callback:function ($$v) {_vm.$set(_vm.formData, "attivo", $$v)},expression:"formData.attivo"}}),_c('VTabs',{attrs:{"color":"primary","slider-color":"primary"},on:{"change":function($event){return _vm.changeTab()}},model:{value:(_vm.tabs.compagnia),callback:function ($$v) {_vm.$set(_vm.tabs, "compagnia", $$v)},expression:"tabs.compagnia"}},[_c('VTab',{attrs:{"ripple":""}},[_vm._v(" Anagrafica ")]),_c('VTab',{attrs:{"ripple":"","disabled":_vm.mode == 'ADD_NEW_MODE'}},[_vm._v(" Prodotti ")]),_c('VTabItem',[_c('v-card-text',[_c('section',{staticClass:"row",attrs:{"id":"compagnia-app"}},[_c('div',{staticClass:"form-group col-md-3",attrs:{"id":"RagionesocialeField"}},[_c('label',{attrs:{"for":"ragioneSociale"}},[_vm._v("Ragione Sociale:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.ragioneSociale),expression:"formData.ragioneSociale"}],staticClass:"form-control- form-control",class:{
                                    'is-invalid': _vm.errors.ragioneSociale,
                                },attrs:{"name":"ragioneSociale","type":"text","id":"ragioneSociale"},domProps:{"value":(_vm.formData.ragioneSociale)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "ragioneSociale", $event.target.value)}}}),_c('invalid-feedback',{attrs:{"errors":_vm.errors.ragioneSociale}})],1),_c('div',{staticClass:"form-group col-md-3",attrs:{"id":"TipocompagniaField"}},[_c('label',{attrs:{"for":"tipoCompagnia"}},[_vm._v("Tipo di Fornitore:")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.tipoCompagnia),expression:"formData.tipoCompagnia"}],staticClass:"form-control- form-control custom-select",class:{
                                    'is-invalid': _vm.errors.tipoCompagnia,
                                },attrs:{"id":"tipoCompagnia","name":"tipoCompagnia"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.formData, "tipoCompagnia", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"DIRETTO"}},[_vm._v("Diretta")]),_c('option',{attrs:{"value":"FORNITORE"}},[_vm._v("Fornitore")])]),_c('invalid-feedback',{attrs:{"errors":_vm.errors.tipoCompagnia}})],1),_c('div',{staticClass:"form-group col-md-3",attrs:{"id":"ReferenteField"}},[_c('label',{attrs:{"for":"referente"}},[_vm._v("Referente:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.referente),expression:"formData.referente"}],staticClass:"form-control- form-control",class:{ 'is-invalid': _vm.errors.referente },attrs:{"name":"referente","type":"text","id":"referente"},domProps:{"value":(_vm.formData.referente)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "referente", $event.target.value)}}}),_c('invalid-feedback',{attrs:{"errors":_vm.errors.referente}})],1),_c('section',{staticClass:"fieldset",attrs:{"id":"indirizzo__sede_legale"}},[_c('h3',{staticClass:"h6 fieldset__title"},[_vm._v("Sede Legale")]),_c('div',{staticClass:"fieldset__content row"},[_c('div',{staticClass:"form-group col-md-5",attrs:{"id":"IndirizzoField"}},[_c('label',{attrs:{"for":"indirizzo"}},[_vm._v("Indirizzo:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.indirizzo),expression:"formData.indirizzo"}],staticClass:"form-control- form-control",class:{
                                            'is-invalid': _vm.errors.indirizzo,
                                        },attrs:{"name":"indirizzo","type":"text","id":"indirizzo"},domProps:{"value":(_vm.formData.indirizzo)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "indirizzo", $event.target.value)}}}),_c('invalid-feedback',{attrs:{"errors":_vm.errors.indirizzo}})],1),_c('div',{staticClass:"form-group col-md-1",attrs:{"id":"CivicoField"}},[_c('label',{attrs:{"for":"civico"}},[_vm._v("Civico:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.civico),expression:"formData.civico"}],staticClass:"form-control- form-control",class:{
                                            'is-invalid': _vm.errors.civico,
                                        },attrs:{"name":"civico","type":"text","id":"civico"},domProps:{"value":(_vm.formData.civico)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "civico", $event.target.value)}}}),_c('invalid-feedback',{attrs:{"errors":_vm.errors.civico}})],1),_c('div',{staticClass:"form-group col-md-3",attrs:{"id":"CittaField"}},[_c('label',{attrs:{"for":"citta"}},[_vm._v("Citta:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.citta),expression:"formData.citta"}],staticClass:"form-control- form-control",class:{
                                            'is-invalid': _vm.errors.citta,
                                        },attrs:{"name":"citta","type":"text","id":"citta"},domProps:{"value":(_vm.formData.citta)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "citta", $event.target.value)}}}),_c('invalid-feedback',{attrs:{"errors":_vm.errors.citta}})],1),_c('div',{staticClass:"form-group col-md-1",attrs:{"id":"CapField"}},[_c('label',{attrs:{"for":"cap"}},[_vm._v("Cap:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.cap),expression:"formData.cap"}],staticClass:"form-control- form-control",class:{
                                            'is-invalid': _vm.errors.cap,
                                        },attrs:{"name":"cap","type":"text","id":"cap"},domProps:{"value":(_vm.formData.cap)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "cap", $event.target.value)}}}),_c('invalid-feedback',{attrs:{"errors":_vm.errors.cap}})],1),_c('div',{staticClass:"form-group col-md-1",attrs:{"id":"ProvField"}},[_c('label',{attrs:{"for":"prov"}},[_vm._v("Prov:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.prov),expression:"formData.prov"}],staticClass:"form-control- form-control",class:{
                                            'is-invalid': _vm.errors.prov,
                                        },attrs:{"name":"prov","type":"text","id":"prov"},domProps:{"value":(_vm.formData.prov)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "prov", $event.target.value)}}}),_c('invalid-feedback',{attrs:{"errors":_vm.errors.prov}})],1)])]),_c('section',{staticClass:"fieldset",attrs:{"id":"contatti__email"}},[_c('h3',{staticClass:"h6 fieldset__title"},[_vm._v("Email")]),_c('div',{staticClass:"fieldset__content row"},[_c('div',{staticClass:"form-group col-md-3",attrs:{"id":"EmailamministrazioneField"}},[_c('label',{attrs:{"for":"emailAmministrazione"}},[_vm._v("Amministrazione:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                            _vm.formData.emailAmministrazione
                                        ),expression:"\n                                            formData.emailAmministrazione\n                                        "}],staticClass:"form-control- form-control",class:{
                                            'is-invalid':
                                                _vm.errors.emailAmministrazione,
                                        },attrs:{"name":"emailAmministrazione","type":"email","id":"emailAmministrazione"},domProps:{"value":(
                                            _vm.formData.emailAmministrazione
                                        )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "emailAmministrazione", $event.target.value)}}}),_c('invalid-feedback',{attrs:{"errors":_vm.errors.emailAmministrazione}})],1),_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"emailPerEstrattoConto"}},[_vm._v(" Amministrazione (per estratto conto) ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                            _vm.formData.emailPerEstrattoConto
                                        ),expression:"\n                                            formData.emailPerEstrattoConto\n                                        "}],staticClass:"form-control- form-control",class:{
                                            'is-invalid':
                                                _vm.errors.emailPerEstrattoConto,
                                        },attrs:{"name":"emailPerEstrattoConto","type":"email","id":"emailPerEstrattoConto"},domProps:{"value":(
                                            _vm.formData.emailPerEstrattoConto
                                        )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "emailPerEstrattoConto", $event.target.value)}}}),_c('invalid-feedback',{attrs:{"errors":_vm.errors.emailPerEstrattoConto}})],1),_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"riferimentoAmministrativoFatturazioneElettronica"}},[_vm._v(" Riferimento amministrativo fatturazione elettronica ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                            _vm.formData.riferimentoAmministrativoFatturazioneElettronica
                                        ),expression:"\n                                            formData.riferimentoAmministrativoFatturazioneElettronica\n                                        "}],staticClass:"form-control- form-control",class:{
                                            'is-invalid':
                                                _vm.errors.riferimentoAmministrativoFatturazioneElettronica,
                                        },attrs:{"name":"riferimentoAmministrativoFatturazioneElettronica","type":"email","id":"riferimentoAmministrativoFatturazioneElettronica"},domProps:{"value":(
                                            _vm.formData.riferimentoAmministrativoFatturazioneElettronica
                                        )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "riferimentoAmministrativoFatturazioneElettronica", $event.target.value)}}}),_c('invalid-feedback',{attrs:{"errors":_vm.errors.riferimentoAmministrativoFatturazioneElettronica}})],1),_c('div',{staticClass:"form-group col-md-3",attrs:{"id":"EmailquotazioniField"}},[_c('label',{attrs:{"for":"emailQuotazioni"}},[_vm._v("Quotazioni:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.emailQuotazioni),expression:"formData.emailQuotazioni"}],staticClass:"form-control- form-control",class:{
                                            'is-invalid':
                                                _vm.errors.emailQuotazioni,
                                        },attrs:{"name":"emailQuotazioni","type":"email","id":"emailQuotazioni"},domProps:{"value":(_vm.formData.emailQuotazioni)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "emailQuotazioni", $event.target.value)}}}),_c('invalid-feedback',{attrs:{"errors":_vm.errors.emailQuotazioni}})],1),_c('div',{staticClass:"form-group col-md-3",attrs:{"id":"EmailpecreclamiField"}},[_c('label',{attrs:{"for":"emailPECReclami"}},[_vm._v("Reclami:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.emailPECReclami),expression:"formData.emailPECReclami"}],staticClass:"form-control- form-control",class:{
                                            'is-invalid':
                                                _vm.errors.emailPECReclami,
                                        },attrs:{"name":"emailPECReclami","type":"email","id":"emailPECReclami"},domProps:{"value":(_vm.formData.emailPECReclami)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "emailPECReclami", $event.target.value)}}}),_c('invalid-feedback',{attrs:{"errors":_vm.errors.emailPECReclami}})],1),_c('div',{staticClass:"form-group col-md-3",attrs:{"id":"EmailField"}},[_c('label',{attrs:{"for":"email"}},[_vm._v("Email:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.email),expression:"formData.email"}],staticClass:"form-control- form-control",class:{
                                            'is-invalid': _vm.errors.email,
                                        },attrs:{"name":"email","type":"email","id":"email"},domProps:{"value":(_vm.formData.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "email", $event.target.value)}}}),_c('invalid-feedback',{attrs:{"errors":_vm.errors.email}})],1),_c('div',{staticClass:"form-group col-md-3",attrs:{"id":"PecField"}},[_c('label',{attrs:{"for":"pec"}},[_vm._v("Pec:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.pec),expression:"formData.pec"}],staticClass:"form-control- form-control",class:{
                                            'is-invalid': _vm.errors.pec,
                                        },attrs:{"name":"pec","type":"email","id":"pec"},domProps:{"value":(_vm.formData.pec)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "pec", $event.target.value)}}}),_c('invalid-feedback',{attrs:{"errors":_vm.errors.pec}})],1)])]),_c('section',{staticClass:"fieldset",attrs:{"id":"contatti__telefono"}},[_c('h3',{staticClass:"h6 fieldset__title"},[_vm._v("Telefono")]),_c('div',{staticClass:"fieldset__content row"},[_c('div',{staticClass:"form-group col-md-3",attrs:{"id":"TelField"}},[_c('label',{attrs:{"for":"te1"}},[_vm._v("Telefono 1")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.tel),expression:"formData.tel"}],staticClass:"form-control- form-control",attrs:{"name":"tel","type":"text","id":"tel"},domProps:{"value":(_vm.formData.tel)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "tel", $event.target.value)}}}),_c('invalid-feedback',{attrs:{"errors":_vm.errors.tel}})],1),_c('div',{staticClass:"form-group col-md-3",attrs:{"id":"CellField"}},[_c('label',{attrs:{"for":"cell"}},[_vm._v("Cell:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.cell),expression:"formData.cell"}],staticClass:"form-control- form-control",class:{
                                            'is-invalid': _vm.errors.cell,
                                        },attrs:{"name":"cell","type":"text","id":"cell"},domProps:{"value":(_vm.formData.cell)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "cell", $event.target.value)}}}),_c('invalid-feedback',{attrs:{"errors":_vm.errors.cell}})],1)])]),_c('section',{staticClass:"fieldset",attrs:{"id":"pagamenti__fatturazione"}},[_c('h3',{staticClass:"h6 fieldset__title"},[_vm._v(" Pagamenti e Fatturazione ")]),_c('div',{staticClass:"fieldset__content row"},[_c('div',{staticClass:"form-group col-md-3",attrs:{"id":"CodicedestinatarioField"}},[_c('label',{attrs:{"for":"codiceDestinatario"}},[_vm._v("Codice Destinatario")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                            _vm.formData.codiceDestinatario
                                        ),expression:"\n                                            formData.codiceDestinatario\n                                        "}],staticClass:"form-control- form-control",class:{
                                            'is-invalid':
                                                _vm.errors.codiceDestinatario,
                                        },attrs:{"name":"codiceDestinatario","type":"text","id":"codiceDestinatario"},domProps:{"value":(
                                            _vm.formData.codiceDestinatario
                                        )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "codiceDestinatario", $event.target.value)}}}),_c('invalid-feedback',{attrs:{"errors":_vm.errors.codiceDestinatario}})],1),_c('div',{staticClass:"form-group col-md-3",attrs:{"id":"PivaField"}},[_c('label',{attrs:{"for":"piva"}},[_vm._v("Partita Iva")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.piva),expression:"formData.piva"}],staticClass:"form-control- form-control",class:{
                                            'is-invalid': _vm.errors.piva,
                                        },attrs:{"name":"piva","type":"text","id":"piva"},domProps:{"value":(_vm.formData.piva)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "piva", $event.target.value)}}}),_c('invalid-feedback',{attrs:{"errors":_vm.errors.piva}})],1),_c('div',{staticClass:"form-group col-md-3",attrs:{"id":"NumerocciaaField"}},[_c('label',{attrs:{"for":"numeroCCIAA"}},[_vm._v("Numero: CCIAA")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.numeroCCIAA),expression:"formData.numeroCCIAA"}],staticClass:"form-control- form-control",class:{
                                            'is-invalid':
                                                _vm.errors.numeroCCIAA,
                                        },attrs:{"name":"numeroCCIAA","type":"text","id":"numeroCCIAA"},domProps:{"value":(_vm.formData.numeroCCIAA)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "numeroCCIAA", $event.target.value)}}}),_c('invalid-feedback',{attrs:{"errors":_vm.errors.numeroCCIAA}})],1),_c('div',{staticClass:"form-group col-md-3",attrs:{"id":"NumeroreaField"}},[_c('label',{attrs:{"for":"numeroREA"}},[_vm._v("Numero REA")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.numeroREA),expression:"formData.numeroREA"}],staticClass:"form-control- form-control",class:{
                                            'is-invalid': _vm.errors.numeroREA,
                                        },attrs:{"name":"numeroREA","type":"text","id":"numeroREA"},domProps:{"value":(_vm.formData.numeroREA)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "numeroREA", $event.target.value)}}}),_c('invalid-feedback',{attrs:{"errors":_vm.errors.numeroREA}})],1),_c('div',{staticClass:"form-group col-md-3",attrs:{"id":"PercentritenutaField"}},[_c('label',{attrs:{"for":"percentRitenuta"}},[_vm._v("% Ritenuta ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.percentRitenuta),expression:"formData.percentRitenuta"}],staticClass:"form-control custom-select",class:{
                                            'is-invalid':
                                                _vm.errors.percentRitenuta,
                                        },attrs:{"id":"percentRitenuta","name":"percentRitenuta"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.formData, "percentRitenuta", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{domProps:{"value":4.6}},[_vm._v("4,60 %")]),_c('option',{domProps:{"value":11.5}},[_vm._v(" 11,50 % ")])]),_c('invalid-feedback',{attrs:{"errors":_vm.errors.percentRitenuta}})],1),_c('div',{staticClass:"form-group col-lg-3 py-0 px-1 mb-3"},[_c('label',{attrs:{"for":"iscrizioneRUIData"}},[_vm._v("Data Accordo")]),_c('date-picker',{class:{
                                            'is-invalid':
                                                _vm.errors.dataAccordo,
                                        },attrs:{"id":"#dataAccordo","data-cy":"dataAccordo","value-type":"YYYY-MM-DD","format":"DD/MM/YYYY"},model:{value:(_vm.formData.dataAccordo),callback:function ($$v) {_vm.$set(_vm.formData, "dataAccordo", $$v)},expression:"formData.dataAccordo"}}),_c('invalid-feedback',{attrs:{"errors":_vm.errors.dataAccordo}})],1),_c('div',{staticClass:"form-group col-md-3",attrs:{"id":"TipologiarimesseField"}},[_c('label',{attrs:{"for":"tipologiaRimesse"}},[_vm._v("Tipologia Rimesse")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.tipologiaRimesse),expression:"formData.tipologiaRimesse"}],staticClass:"form-control- form-control custom-select",class:{
                                            'is-invalid':
                                                _vm.errors.tipologiaRimesse,
                                        },attrs:{"id":"tipologiaRimesse","name":"tipologiaRimesse"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.formData, "tipologiaRimesse", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"FINE_MESE"}},[_vm._v(" FINE MESE ")]),_c('option',{attrs:{"value":"ENTRO_5"}},[_vm._v(" ENTRO_5 ")]),_c('option',{attrs:{"value":"ENTRO_10"}},[_vm._v(" ENTRO_10 ")]),_c('option',{attrs:{"value":"EMISSIONE"}},[_vm._v(" EMISSIONE ")]),_c('option',{attrs:{"value":"DECADALE"}},[_vm._v(" DECADALE ")]),_c('option',{attrs:{"value":"QUINDICINALE"}},[_vm._v(" QUINDICINALE ")])]),_c('invalid-feedback',{attrs:{"errors":_vm.errors.tipologiaRimesse}})],1)])]),_c('form-fieldset',{attrs:{"header":"RUI"}},[_c('div',{staticClass:"form-group col-lg-3 py-0 px-1 mb-3"},[_c('label',{attrs:{"for":"iscrizioneRUIData"}},[_vm._v("Data Iscrizione:")]),_c('date-picker',{class:{
                                        'is-invalid':
                                            _vm.errors.iscrizioneRUIData,
                                    },attrs:{"data-cy":"iscrizioneRUIData","value-type":"YYYY-MM-DD","format":"DD/MM/YYYY"},model:{value:(_vm.formData.iscrizioneRUIData),callback:function ($$v) {_vm.$set(_vm.formData, "iscrizioneRUIData", $$v)},expression:"formData.iscrizioneRUIData"}}),_c('invalid-feedback',{attrs:{"errors":_vm.errors.iscrizioneRUIData}})],1),_c('div',{staticClass:"form-group col-lg-3 py-0 px-1 mb-3"},[_c('label',{attrs:{"for":"iscrizioneRUI"}},[_vm._v("Numero Iscrizione RUI")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.iscrizioneRUI),expression:"formData.iscrizioneRUI"}],staticClass:"form-control- form-control",class:{
                                        'is-invalid': _vm.errors.iscrizioneRUI,
                                    },attrs:{"type":"text","id":"iscrizioneRUI"},domProps:{"value":(_vm.formData.iscrizioneRUI)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "iscrizioneRUI", $event.target.value)}}}),_c('invalid-feedback',{attrs:{"errors":_vm.errors.iscrizioneRUI}})],1)]),_c('section',{staticClass:"fieldset",attrs:{"id":"pagamenti__IBAN"}},[_c('h3',{staticClass:"h6 fieldset__title"},[_vm._v(" IBAN (conto premi) ")]),_c('div',{staticClass:"fieldset__content row"},[_c('div',{staticClass:"form-group col-md-3",attrs:{"id":"IBANContoPremiField"}},[_c('label',{attrs:{"for":"IBANContoPremi"}},[_vm._v("IBAN Conto Premi:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.IBANContoPremi),expression:"formData.IBANContoPremi"}],staticClass:"form-control- form-control",class:{
                                            'is-invalid':
                                                _vm.errors.IBANContoPremi,
                                        },attrs:{"name":"IBANContoPremi","type":"text","id":"IBANContoPremi"},domProps:{"value":(_vm.formData.IBANContoPremi)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "IBANContoPremi", $event.target.value)}}}),_c('invalid-feedback',{attrs:{"errors":_vm.errors.IBANContoPremi}})],1),_c('div',{staticClass:"form-group col-md-3",attrs:{"id":"ReferenteruoloField"}},[_c('label',{attrs:{"for":"referenteRuolo"}},[_vm._v("Referente Ruolo")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.referenteRuolo),expression:"formData.referenteRuolo"}],staticClass:"form-control- form-control",class:{
                                            'is-invalid':
                                                _vm.errors.referenteRuolo,
                                        },attrs:{"name":"referenteRuolo","type":"text","id":"referenteRuolo"},domProps:{"value":(_vm.formData.referenteRuolo)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "referenteRuolo", $event.target.value)}}}),_c('invalid-feedback',{attrs:{"errors":_vm.errors.referenteRuolo}})],1)])]),_c('FormFieldset',{attrs:{"header":"SIC ANIA"}},[_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"sicAnia"}},[_vm._v("Sic ANIA")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.sicAnia),expression:"formData.sicAnia"}],staticClass:"form-control- form-control",class:{
                                        'is-invalid': _vm.errors.sicAnia,
                                    },attrs:{"name":"sicAnia","type":"text","id":"sicAnia"},domProps:{"value":(_vm.formData.sicAnia)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "sicAnia", $event.target.value)}}}),_c('invalid-feedback',{attrs:{"errors":_vm.errors.sicAnia}})],1),_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"sicAniaPassword"}},[_vm._v("SIC ANIA Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.sicAniaPassword),expression:"formData.sicAniaPassword"}],staticClass:"form-control- form-control",class:{
                                        'is-invalid':
                                            _vm.errors.sicAniaPassword,
                                    },attrs:{"name":"sicAniaPassword","type":"text","id":"sicAniaPassword"},domProps:{"value":(_vm.formData.sicAniaPassword)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "sicAniaPassword", $event.target.value)}}}),_c('invalid-feedback',{attrs:{"errors":_vm.errors.sicAniaPassword}})],1),_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"sicAniaCodiceEnte"}},[_vm._v("SIC ANIA Codice Ente")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.sicAniaCodiceEnte),expression:"formData.sicAniaCodiceEnte"}],staticClass:"form-control- form-control",class:{
                                        'is-invalid':
                                            _vm.errors.sicAniaCodiceEnte,
                                    },attrs:{"name":"sicAniaCodiceEnte","type":"text","id":"sicAniaCodiceEnte"},domProps:{"value":(_vm.formData.sicAniaCodiceEnte)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "sicAniaCodiceEnte", $event.target.value)}}}),_c('invalid-feedback',{attrs:{"errors":_vm.errors.sicAniaCodiceEnte}})],1)]),_c('div',{staticClass:"form-group col-md-3",attrs:{"id":"NoteField"}},[_c('label',{attrs:{"for":"note"}},[_vm._v("Note")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.note),expression:"formData.note"}],staticClass:"form-control- form-control",class:{ 'is-invalid': _vm.errors.note },attrs:{"name":"note","type":"text","id":"note"},domProps:{"value":(_vm.formData.note)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "note", $event.target.value)}}}),_c('invalid-feedback',{attrs:{"errors":_vm.errors.note}})],1),_c('div',{staticClass:"form-group col-md-3",attrs:{"id":"IsbloccataField"}},[_c('div',{staticClass:"form-check"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.isBloccata),expression:"formData.isBloccata"}],staticClass:"form-check-input",class:{
                                        'is-invalid': _vm.errors.isBloccata,
                                    },attrs:{"name":"isBloccata","type":"hidden","value":"0"},domProps:{"value":(_vm.formData.isBloccata)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "isBloccata", $event.target.value)}}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.isBloccata),expression:"formData.isBloccata"}],staticClass:"form-check-input",class:{
                                        'is-invalid': _vm.errors.isBloccata,
                                    },attrs:{"name":"isBloccata","type":"checkbox","value":"1"},domProps:{"checked":Array.isArray(_vm.formData.isBloccata)?_vm._i(_vm.formData.isBloccata,"1")>-1:(_vm.formData.isBloccata)},on:{"change":function($event){var $$a=_vm.formData.isBloccata,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v="1",$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.formData, "isBloccata", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.formData, "isBloccata", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.formData, "isBloccata", $$c)}}}}),_c('label',{staticClass:"form-check-label",attrs:{"for":"isBloccata"}},[_vm._v("Isbloccata:")])])]),_c('div',{staticClass:"form-group col-md-3",attrs:{"id":"IsesclusofogliocassaField"}},[_c('div',{staticClass:"form-check"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.isEsclusoFoglioCassa),expression:"formData.isEsclusoFoglioCassa"}],staticClass:"form-check-input",class:{
                                        'is-invalid':
                                            _vm.errors.isEsclusoFoglioCassa,
                                    },attrs:{"name":"isEsclusoFoglioCassa","type":"hidden","value":"0"},domProps:{"value":(_vm.formData.isEsclusoFoglioCassa)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "isEsclusoFoglioCassa", $event.target.value)}}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.isEsclusoFoglioCassa),expression:"formData.isEsclusoFoglioCassa"}],staticClass:"form-check-input",class:{
                                        'is-invalid':
                                            _vm.errors.isEsclusoFoglioCassa,
                                    },attrs:{"name":"isEsclusoFoglioCassa","type":"checkbox","value":"1"},domProps:{"checked":Array.isArray(_vm.formData.isEsclusoFoglioCassa)?_vm._i(_vm.formData.isEsclusoFoglioCassa,"1")>-1:(_vm.formData.isEsclusoFoglioCassa)},on:{"change":function($event){var $$a=_vm.formData.isEsclusoFoglioCassa,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v="1",$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.formData, "isEsclusoFoglioCassa", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.formData, "isEsclusoFoglioCassa", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.formData, "isEsclusoFoglioCassa", $$c)}}}}),_c('label',{staticClass:"form-check-label",attrs:{"for":"isEsclusoFoglioCassa"}},[_vm._v("Isesclusofogliocassa:")])])]),_c('div',{staticClass:"form-group col-md-3",attrs:{"id":"Articolo117Field"}},[_c('div',{staticClass:"form-check"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.articolo117),expression:"formData.articolo117"}],staticClass:"form-check-input",class:{
                                        'is-invalid': _vm.errors.articolo117,
                                    },attrs:{"name":"articolo117","type":"hidden","value":"0"},domProps:{"value":(_vm.formData.articolo117)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "articolo117", $event.target.value)}}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.articolo117),expression:"formData.articolo117"}],staticClass:"form-check-input",class:{
                                        'is-invalid': _vm.errors.articolo117,
                                    },attrs:{"name":"articolo117","type":"checkbox","value":"1"},domProps:{"checked":Array.isArray(_vm.formData.articolo117)?_vm._i(_vm.formData.articolo117,"1")>-1:(_vm.formData.articolo117)},on:{"change":function($event){var $$a=_vm.formData.articolo117,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v="1",$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.formData, "articolo117", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.formData, "articolo117", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.formData, "articolo117", $$c)}}}}),_c('label',{staticClass:"form-check-label",attrs:{"for":"articolo117"}},[_vm._v("Articolo117:")])])]),_c('div',{staticClass:"form-group col-md-3",attrs:{"id":"SitoufficialeField"}},[_c('label',{attrs:{"for":"sitoUfficiale"}},[_vm._v("Sitoufficiale:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.sitoUfficiale),expression:"formData.sitoUfficiale"}],staticClass:"form-control- form-control",class:{
                                    'is-invalid': _vm.errors.sitoUfficiale,
                                },attrs:{"name":"sitoUfficiale","type":"text","id":"sitoUfficiale"},domProps:{"value":(_vm.formData.sitoUfficiale)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "sitoUfficiale", $event.target.value)}}}),_c('invalid-feedback',{attrs:{"errors":_vm.errors.sitoUfficiale}})],1)],1)])],1),_c('VTabItem',[_c('tabella-prodotto-assicurativo-mandato',{key:_vm.rerenderTabellaProdotti})],1)],1)],1),_c('div',{class:{ 'loading-overlay': _vm.loading }})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }