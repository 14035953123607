// colorMixin.js
export const AutoStoricaService = {
    methods: {
        /**
         * Converti il nome di un colore in italiano nel suo equivalente CSS.
         * @param {string} coloreItaliano - Il nome del colore in italiano o iniziale.
         * @returns {string} Il nome del colore in CSS, o una stringa vuota se il colore non è trovato.
         */
        toCSSColor(coloreItaliano) {
            const colorMap = {
                rosso: "red",
                verde: "green",
                giallo: "yellow",
                r: "red",
                v: "green",
                g: "yellow",
                // Aggiungi altri colori se necessario
            };
            return colorMap[coloreItaliano.toLowerCase()] || "";
        },
    },
};
