var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"data-test":"CoverageQuixaForm"}},[(_vm.vehicleCategory)?_c('v-row',[_c('v-col',{attrs:{"col":"12","order-sm":"2","order-lg":"1","md":"6"}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Coperture Disponibili")])],1),_c('v-data-table',{attrs:{"dense":"","items":_vm.copertureDisponibili,"show-select":"","disable-select-all":true,"item-key":"code","disable-pagination":true,"items-per-page":-1,"headers":_vm.headersDisponibili},on:{"item-selected":_vm.onItemSelected},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('v-col',{attrs:{"col":"12","order-sm":"1","order-lg":"2","md":"6"}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Coperture Selezionate")])],1),_c('v-data-table',{attrs:{"items":_vm.copertureSelezionate,"show-select":"","item-key":"code","disable-select-all":true,"disable-pagination":true,"items-per-page":-1,"headers":_vm.headers,"expanded":_vm.expandedSelezionate},on:{"update:expanded":function($event){_vm.expandedSelezionate=$event}},scopedSlots:_vm._u([{key:`item.description`,fn:function({ item }){return [_c('div',{staticClass:"py-3"},[_c('strong',[_vm._v(" "+_vm._s(item.code)+" "+_vm._s(item.description))]),(
                                item.HasMassimale === 'S' &&
                                item.Massimale != 'VEHICLE_CUSTOM_VALUE'
                            )?_c('div',{staticClass:"mt-5"},[_c('v-select',{staticClass:"align-left",attrs:{"id":`select-massimale-copertura-${item.code}`,"label":"Massimale","color":"primary","item-key":"value","item-text":"label","return-object":false,"items":item.Massimale[_vm.vehicleCategory],"dense":""},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}})],1):_vm._e(),(
                                item.HasMassimale === 'S' &&
                                item.Massimale == 'VEHICLE_CUSTOM_VALUE'
                            )?_c('div',[_vm._v(" TODO: VEHICLE_CUSTOM_VALUE ")]):_vm._e()])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1):_vm._e(),(!_vm.vehicleCategory)?_c('v-row',[_c('v-col',[_vm._v(" Categoria Veicolo non selezionata ")])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }