// import axios from "axios";
// import { Vue } from "vue";
import { put } from "../services/HTTPClient";
import { router } from "../main.js";
import { Api, errorCallback } from "../http_tools/axiosModule";

export const ACTION_LOGIN = ({ commit }, formData) => {
    commit("setAuthErrors", {});
    Api.post(process.env.VUE_APP_API_URL + "/api/login", formData)
        .then((response) => {
            //console.log("response.data.roles,response.data.permissions ->",response)

            window.sessionStorage.clear();
            window.sessionStorage.removeItem("user");
            window.sessionStorage.removeItem("token");
            window.sessionStorage.removeItem("permissions");
            window.sessionStorage.removeItem("roles");
            window.sessionStorage.removeItem("env");

            window.localStorage.removeItem("user");
            window.localStorage.removeItem("token");
            window.localStorage.removeItem("permissions");
            window.localStorage.removeItem("roles");

            commit("setUser", response.data);

            router.push("/dashboard");
        })
        .catch((errors) => {
            errorCallback(errors);
            // TODO: LOGIN | [**]  Fare la visualizzazione degli errori durante il login
            commit("setAuthErrors", errors);
        });
};

export const ACTION_LOGOUT = ({ commit }) => {
    commit("setUser", {});
    Api.get(process.env.VUE_APP_API_URL + "/api/api_logout")
        .then(() => {
            // console.log("logout",response)
            window.sessionStorage.clear();
            window.sessionStorage.removeItem("token");
            window.sessionStorage.removeItem("permissions");
            window.sessionStorage.removeItem("roles");

            window.localStorage.removeItem("token");
            window.localStorage.removeItem("permissions");
            window.localStorage.removeItem("roles");
            router.push("/login");
        })
        .catch((errors) => {
            // router.push('/login')
            errorCallback(errors);
        });
    // commit('setAuthenticated',false)
};

// DOCS: https://youtu.be/M0xOzWHaoOU?t=1100
export const ACTION_GET_COLLABORATORI = ({ commit }) => {
    Api.get(process.env.VUE_APP_API_URL + "/api/collaboratore")
        .then((res) => {
            // console.log("res",res.data.data)
            const collaboratoriElenco = res.data.data;
            // console.log("ACTION_GET_COLLABORATORI", collaboratoriElenco)
            commit("setCollaboratori", collaboratoriElenco);
        })
        .catch((error) => {
            errorCallback(error);
            commit("setLoading", false);
        });
};

export const ACTION_STORE_COLLABORATORE = ({ commit }, collaboratore) => {
    let headersList = {
        "Content-Type": "application/json",
        Accept: "application/json",
    };

    console.log("ACTION_STORE_COLLABORATORE");

    commit("setLoading", true);

    if (collaboratore.id) {
        // TODO: USARE AXIOS RIMUOVERE PUT
        put(
            process.env.VUE_APP_API_URL +
                "/api/collaboratore/" +
                collaboratore.id,
            collaboratore,
        )
            .then((response) => {
                const collaboratoreAggiornato = response.data.data;
                commit("setCollaboratoreAttivo", collaboratoreAggiornato);
                commit("setLoading", false);

                commit("setCollaboratoreFormError", []);
                // router.push('your-path').catch(err => {})
                router
                    .push(
                        "/modifica/collaboratore/" + collaboratoreAggiornato.id,
                    )
                    .catch((err) => {
                        err;
                    });
            })
            .catch((error) => {
                console.log("ACTION_STORE_COLLABORATORE", error);
                //commit('setCollaboratoreFormError', error.response.data.errors)
                //commit('setLoading', false)

                //errorCallback(error)
            });
    } else {
        let bodyContent = JSON.stringify(collaboratore);

        let reqOptions = {
            url: process.env.VUE_APP_API_URL + "/api/collaboratore",
            method: "POST",
            headers: headersList,
            data: bodyContent,
        };

        Api.request(reqOptions)

            .then((response) => {
                const collaboratoreCreato = response.data.data;
                commit("setCollaboratoreAttivo", collaboratoreCreato);
                commit("setLoading", false);
                router.push(
                    "/modifica/collaboratore/" + collaboratoreCreato.id,
                );
            })
            .catch((error) => {
                console.log(">> errors", error);
                if (error.response) {
                    errorCallback(error);

                    commit(
                        "setCollaboratoreFormError",
                        error.response.data.errors,
                    );
                    commit("setLoading", false);
                } else {
                    alert("errore ACTION_STORE_COLLABORATORE");
                    console.log("errors ACTION_STORE_COLLABORATORE", error);
                }
            });
    }
};

/**************************************************
 * CLIENTE
 **************************************************/

export const ACTION_STORE_CLIENTE = ({ commit }, cliente) => {
    let headersList = {
        "Content-Type": "application/json",
        Accept: "application/json",
    };

    commit("setLoading", true);

    if (cliente.id) {
        put(process.env.VUE_APP_API_URL + "/api/cliente/" + cliente.id, cliente)
            .then((response) => {
                const clienteAggiornato = response.data.data;
                commit("setClienteAttivo", clienteAggiornato);
                commit("setLoading", false);

                commit("setClienteFormError", []);
                // router.push('your-path').catch(err => {})
                router
                    .push("/modifica/cliente/" + clienteAggiornato.id)
                    .catch((err) => {
                        err;
                    });
            })
            .catch((errors) => {
                // commit('setClienteFormError', error.response.data.errors)
                // commit('setLoading', false)

                errorCallback(errors);

                console.log(">> errors", errors);
                // #TODO: ERROR MODULE
                const status = errors.response.status;
                if (status == 422) {
                    commit("setClienteFormError", errors.response.data.errors);
                    // commit('setNotification', { message: errors.response.data.message, type: 'error' })
                    commit("setLoading", false);
                } else {
                    errorCallback(errors);
                }
            });
    } else {
        let bodyContent = JSON.stringify(cliente);

        let reqOptions = {
            url: process.env.VUE_APP_API_URL + "/api/cliente",
            method: "POST",
            headers: headersList,
            data: bodyContent,
        };

        Api.request(reqOptions)

            .then((response) => {
                const clienteCreato = response.data.data;
                commit("setClienteAttivo", clienteCreato);
                commit("setLoading", false);
                router.push("/modifica/cliente/" + clienteCreato.id);
            })
            .catch((errors) => {
                const status = errors.response.status;
                if (status == 422) {
                    commit("ç_", errors.response.data.errors);
                    // commit('setNotification', { message: errors.response.data.message, type: 'error' })
                    commit("setLoading", false);
                } else {
                    errorCallback(errors);
                }
            });
    }
};

/**************************************************
 * COMPAGNIA
 **************************************************/
export const ACTION_GET_COMPAGNIE = ({ commit }) => {
    commit("setCompagnie", []);
    Api.get(process.env.VUE_APP_API_URL + "/api/compagnia")
        .then((res) => {
            const compagnieElenco = res.data.data || res.data;
            console.log("ACTION_GET_COMPAGNIE - then", res.data.data);
            commit("setCompagnie", compagnieElenco);
            commit("setNotify", res.data.message);
        })
        .catch((err) => {
            errorCallback(err);
        });
};

export const ACTION_DELETE_COMPAGNIA = ({ commit }, compagnia) => {
    Api.delete(process.env.VUE_APP_API_URL + "/api/compagnia/" + compagnia.id)
        .then((res) => {
            // alert("ACTION_DELETE_COMPAGNIA")
            const compagnieElenco = res.data.data || res.data;
            // console.log("ACTION_DELETE_COMPAGNIA - then",compagnieElenco.filter(row => row.id != compagnia.id))
            commit(
                "setCompagnie",
                compagnieElenco.filter((row) => row.id != compagnia.id),
            );
            commit("setNotify", res.data.message);
        })
        .catch((err) => {
            errorCallback(err);
        });
};

export const ACTION_GET_COMPAGNIA = ({ commit, root }, id) => {
    commit("setLoading", true);
    commit("setCompagniaAttivo", {});
    Api.get(process.env.VUE_APP_API_URL + "/api/compagnia/" + id)
        .then((res) => {
            const model = res.data.data;
            // console.log("ACTION_GET_COMPAGNIA", model)
            commit("setCompagniaAttivo", model);
            commit("setLoading", false);
            commit(
                "MandatoProdottoModule/setResetTabellaProdotti",
                root.route.params.id,
            );
            // TODO: IMPLEMENTARE
            // commit('setProdotti',model.prodotti)
        })
        .catch((error) => {
            console.log("ACTION_GET_COMPAGNIA - error", error);
            errorCallback(error);
            commit("setLoading", false);
        });
};

export const ACTION_STORE_FORNITORE = ({ commit }, compagnia) => {
    let headersList = {
        "Content-Type": "application/json",
        Accept: "application/json",
    };

    commit("setLoading", true);
    commit("resetFormError");

    // console.log('00 >>> ACTION_STORE_FORNITORE',compagnia);

    if (compagnia.id) {
        Api.put(
            process.env.VUE_APP_API_URL + "/api/compagnia/" + compagnia.id,
            compagnia,
        )
            .then((response) => {
                const {
                    data: { message, data },
                } = response;
                // console.log('01A PUT - ACTION_STORE_FORNITORE',message,data);

                commit("setCompagniaAttivo", data.model);
                commit("setLoading", false);
                commit("setNotify", message);

                commit("setFormError", data.errors);
            })
            .catch((AxiosError) => {
                const {
                    data: { message, errors },
                } = AxiosError.response;
                // console.log('01B PUT - ACTION_STORE_FORNITORE - error',status,message,errors);
                commit("setFormError", errors);
                commit("setLoading", false);
                message;
                commit("setNotify", "");
                errorCallback(AxiosError);
            });
    } else {
        // # INSERIMENTO_NUOVO

        let bodyContent = JSON.stringify(compagnia);

        let reqOptions = {
            url: process.env.VUE_APP_API_URL + "/api/compagnia",
            method: "POST",
            headers: headersList,
            data: bodyContent,
        };

        Api.request(reqOptions)

            .then((response) => {
                const compagniaCreato = response.data.data.model;

                console.log("creato", response);
                commit("setCompagniaAttivo", compagniaCreato);
                commit("setLoading", false);
                commit("setNotify", response.data.message);

                router.push("/modifica/compagnia/" + compagniaCreato.id);
            })
            .catch((error) => {
                console.log(">> errors", error);
                if (error.response) {
                    commit("setFormError", error.response.data.errors);
                    commit("setLoading", false);
                    errorCallback(error);
                } else {
                    alert("errore ACTION_STORE_FORNITORE");
                    console.log("errors ACTION_STORE_FORNITORE", error);
                }
            });
    }
};

export const ACTION_ANNULLA_COMPAGNIA = ({ commit }) => {
    commit("setLoading", true);
    commit("setFormError", []);
    commit("setCompagniaAttivo", {});
    router.push("/compagnia");
};

export const ACTION_GET_FORNITORE_AUTOCOMPLETE = async ({ commit }) => {
    Api.get(
        process.env.VUE_APP_API_URL +
            `/api/autocomplete/fornitore?api_token=${window.sessionStorage.getItem("token")}`,
    ).then((response) => {
        console.log("ACTION_GET_FORNITORE_AUTOCOMPLETE", response.data);
        commit("setFornitoriAutocomplete", response.data.data);
    });
};

export const ACTION_STORE_PRODOTTO_ASSICURATIVO = (
    { commit },
    prodotto_assicurativo,
) => {
    let headersList = {
        "Content-Type": "application/json",
        Accept: "application/json",
    };

    commit("setLoading", true);
    commit("setProdottoAssicurativoFormError", {});
    if (prodotto_assicurativo.id) {
        // TODO: USARE AXIOS RIMUOVERE PUT
        put(
            process.env.VUE_APP_API_URL +
                "/api/prodotto_assicurativo/" +
                prodotto_assicurativo.id,
            prodotto_assicurativo,
        )
            .then((response) => {
                const prodotto_assicurativoAggiornato = response.data.data;
                commit("setProdottoAss", prodotto_assicurativoAggiornato);
                commit("setLoading", false);

                commit("setProdottoAssicurativoAttivo", {})
                    // router.push('your-path').catch(err => {})
                    // router.push('/modifica/prodotto_assicurativo/'+prodotto_assicurativoAggiornato.id)
                    .catch((err) => {
                        err;
                    });
            })
            .catch((error) => {
                console.log("ACTION_STORE_prodotto_assicurativo", error);
                commit(
                    "setProdottoAssicurativoFormError",
                    error.response.data.errors,
                );
                commit("setLoading", false);

                errorCallback(error);
            });
    } else {
        let bodyContent = JSON.stringify(prodotto_assicurativo);

        let reqOptions = {
            url: process.env.VUE_APP_API_URL + "/api/prodotto_assicurativo",
            method: "POST",
            headers: headersList,
            data: bodyContent,
        };

        Api.request(reqOptions)

            .then((response) => {
                const prodotto_assicurativoCreato = response.data.data;
                commit(
                    "setProdottoAssicurativoAttivo",
                    prodotto_assicurativoCreato,
                );
                commit("setLoading", false);
                router.push(
                    "/modifica/prodotto_assicurativo/" +
                        prodotto_assicurativoCreato.id,
                );
            })
            .catch((error) => {
                console.log(">> errors", error);
                if (error.response) {
                    errorCallback(error);

                    commit(
                        "setProdottoAssicurativoFormError",
                        error.response.data.errors,
                    );
                    commit("setLoading", false);
                } else {
                    console.log(
                        "errors ACTION_STORE_prodotto_assicurativo",
                        error,
                    );
                }
            });
    }
};
