// import { StatoQuotazione } from "@/enum/StatoQuotazioneEnum";

import { QuotazioneStatoObj } from "@/enum/Teo/QuotazioneStatoObj";

export const QuotazioneUtilsMixing = {
    data() {
        return {};
    },
    methods: {
        isPremioEditabile(
            quotazione,
            // ,user
        ) {
            let isEditable = true;

            if (quotazione.stato_quotazione.cod > 2) {
                isEditable = false;
            } else if (
                quotazione.stato_quotazione.cod > 1
                // && !this.can("show_quotazioni_suoi_collaboratori")
            ) {
                isEditable = false;
            }

            return isEditable;
        },

        /**
         * polizzaEmessaConSuccesso
         * si può fare un inclusione
         * si può fare un esclusione
         * si può stornare
         * si può rinnovare
         * dopo la richiesta emissione (cod == 8) e diverso da  Conclusa con errore (id !== 11)
         * @param {*} quotazione
         * @returns
         */
        POLIZZA_EMESSA_CON_SUCCESSO(quotazione) {
            return (
                quotazione.stato_quotazione.cod >
                    QuotazioneStatoObj.STATO_RICHIESTA_EMISSIONE_COD &&
                quotazione.stato_quotazione_id !=
                    QuotazioneStatoObj.STATO_CONCLUSA_CON_ERRORE_ID
            );
        },
        POLIZZA_HA_RINNOVO_IN_PROGRESS(quotazione) {
            return quotazione?.rinnovo?.id;
        },
    },
};
