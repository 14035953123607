<template>
    <v-row>
        <!-- roleIs('super_admin') && -->
        <v-col cols="8" id="form-veicolo">
            <v-alert text type="success" v-if="this.success">
                Inclusione Emessa Correttamente
            </v-alert>
            <v-alert text type="error" v-if="this.errors">
                <pre>
        {{ errors.message }}
        </pre
                >
            </v-alert>
            <v-row id="data-decorrenza-quotazione-spese-competenze">
                <v-col id="data-decorrenza">
                    <!-- {{ storage?.appendice?.data_effetto || appendice?.data_effetto     }} -->
                    Data di decorrenza esclusione
                    <date-picker
                        label="Data di decorrenza esclusione"
                        v-model="appendice.data_effetto"
                        value-type="YYYY-MM-DD"
                        format="DD/MM/YYYY"
                        @change="calcoloInclusione"
                    ></date-picker>

                    <div class="text-danger" v-if="!appendice?.data_effetto">
                        data di decorrenza obbligatoria
                    </div>
                </v-col>
                <v-col id="appendice-importo-spese-emissione-competenze">
                    <!-- Modificabili solo da Admin -->
                    <v-text-field
                        label="Appendice Verti"
                        type="number"
                        v-model="premio_verti"
                        :readonly="!roleIs('super_admin')"
                    >
                    </v-text-field>

                    <v-text-field
                        label="Spese di emissione"
                        type="number"
                        v-model="spese_emissione"
                        :readonly="!roleIs('super_admin')"
                    ></v-text-field>
                    <!-- :readonly="!roleIs('super_admin')" -->

                    <!-- Modificabile da tutti -->
                    <v-text-field
                        label="Competenze"
                        type="number"
                        v-model="competenze"
                    >
                    </v-text-field>
                </v-col>
            </v-row>
            <veicolo-storico-select
                @change="cambioModelloAutostorica"
                v-model="auto_storica"
            />

            <form-emissione-veicolo
                @salva="saveAppendice"
                :quotazione="quotazione"
                v-model="veicolo_appendice"
            />
        </v-col>
        <v-col cols="4" id="preview-emissione">
            <v-card>
                <v-card-title class="loading">
                    Emissione
                    <span v-if="auto_storica" class="pl-3"
                        >Veicolo {{ this.auto_storica.colore }}</span
                    >
                </v-card-title>
                <v-row align="center" class="px-3 pt-0 pb-3">
                    <p v-if="premio_annuale">
                        Nuovo premio annuale teorico: {{ premio_annuale }}
                    </p>
                    <div class="text-h2">
                        €
                        <span v-if="appendice_totale">{{
                            appendice_totale
                        }}</span>
                        <span v-if="!appendice_totale" class="text-gray"
                            >00.00</span
                        >
                    </div>
                </v-row>

                <v-list>
                    <v-list-item>
                        <v-btn
                            v-if="
                                !roleIs('super_admin') &&
                                auto_storica.colore == 'rosso'
                            "
                            color="red"
                            text
                            dark
                        >
                            IN ATTESA DI DEROGA
                        </v-btn>
                    </v-list-item>
                    <v-list-item>
                        <v-btn
                            v-bind="BtnMainSideBar"
                            @click="saveAppendice"
                            color="red"
                        >
                            SALVA
                        </v-btn>
                    </v-list-item>
                    <v-list-item>
                        <QuotazioneDrawer
                            :data-preview="dataPreview"
                            :drawer-is-open-prop="drawerIsOpen"
                            :button-preview-disabled-prop="
                                buttonPreviewDisabled
                            "
                            :errors="errors"
                            message-modal-dialog="Conferma Appendice Inclusione"
                            @button-preview-pressed="previewAppendiceInclusione"
                            @button-dialog-ok-pressed="
                                emissioneAppendiceInclusione
                            "
                        />
                    </v-list-item>
                </v-list>
                <v-list>
                    <pre>{{ appendiceDiInclusioneRequest }}</pre>
                </v-list>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import { StyleComponent } from "@/components/ui/theme/StyleComponent";
import VeicoloStoricoSelect from "./VeicoloStoricoSelect.vue";
import FormEmissioneVeicolo from "./FormEmissioneVeicolo.vue";
import QuotazioneDrawer from "@/components/quotazione/QuotazioneDrawer.vue";
// import DatePicker from "../ui/form/teo-date-picker.vue";
import DatePicker from "vue2-datepicker";
import {
    // eslint-disable-next-line
    // HasPermission,
    PuoEmettereAppendiceAutoStorica,
    // ,
} from "@/services/PermessiRuoliUtente.js";

import { Api } from "@/http_tools/axiosModule";
import {
    readFromRemoteStorage,
    saveToRemoteStorage,
} from "@/services/StorageModule.js";
import { getTodaySQLDate } from "@/services/DateUtils.js";
// import { AutoStoricaService } from "@/services/AutoStoricaService.js";

export default {
    name: "appendice-inclusione-form",
    mixins: [StyleComponent],
    components: {
        "veicolo-storico-select": VeicoloStoricoSelect,
        "form-emissione-veicolo": FormEmissioneVeicolo,
        DatePicker,
        QuotazioneDrawer,
    },
    props: ["quotazione"],
    data() {
        return {
            label: "DateTime",
            focus: false,
            storage: {},
            appendice: {
                data_effetto: getTodaySQLDate(),
            },
            veicolo_appendice: {},
            auto_storica: {},
            debug: false,
            errors: null,
            response: {},
            loading: false,
            premio_verti: 0,
            spese_emissione: 15,
            competenze: 0,
            premio_annuale: null,
            dataPreview: {},
            success: false,
            drawerIsOpen: false,
        };
    },
    async mounted() {
        const storage = await this.getStorage();
        if (storage && Object.keys(storage).length !== 0) {
            /* eslint-disable */
            this.competenze = storage?.appendice?.competenze ?? 0;
            this.premio_verti = storage?.appendice?.importo ?? 0;

            this.spese_emissione = storage?.appendice?.spese_emissione || 15;
            this.veicolo_appendice = storage?.veicolo;
            this.appendice = storage?.appendice;
            this.storage = storage;
            this.auto_storica = storage.auto_storica;
            console.log(
                "mounted parent this",
                this.auto_storica.id,
                this.auto_storica,
            );
        }
    },
    computed: {
        appendice_totale() {
            // this.competenze = undefined
            return (
                (Number(this.competenze) ?? 0) +
                (Number(this.spese_emissione) ?? 0) +
                (Number(this.premio_verti) ?? 0)
            );
        }, // competenze + premio_verti + competenze
        storageKey() {
            return `inclusione-${this.$route.params.id}`;
        },
        appendiceDiInclusioneRequest() {
            const auto_storica = this.auto_storica;
            const request = {
                auto_storica: auto_storica,
                veicolo: {
                    ...this.veicolo_appendice,
                    ...{
                        quotazione_id: this.$route.params.id,
                        auto_storica_id: auto_storica?.id ?? null,
                        tipologia: "S",
                    },
                },
                appendice: {
                    ...this.appendice,
                    ...{
                        quotazione_id: this.$route.params.id,
                        operazione: "inclusione",
                        competenze: this.competenze,
                        spese_emissione: this.spese_emissione,
                        appendice_totale: this.appendice_totale,
                        importo: this.premio_verti,
                    },
                },
            };

            return request;
        },
        showEmettiBtn() {
            return PuoEmettereAppendiceAutoStorica(this.quotazione);
        },
        enableEmettiBtn() {
            return !(
                this.data_effetto &&
                this.importo &&
                PuoEmettereAppendiceAutoStorica(this.quotazione)
            );
        },
        buttonPreviewDisabled() {
            return this.auto_storica.id && this.data_effetto;
        },
    },
    methods: {
        roleIs(role) {
            return JSON.parse(window.sessionStorage["roles"]).includes(role);
        },

        async previewAppendiceInclusione() {
            this.errors = "";
            this.loading = true;
            await Api.post(
                process.env.VUE_APP_API_URL +
                    "/api/preview_appendice_inclusione_polizza_autostoriche_verti",
                this.appendiceDiInclusioneRequest,
            )
                .then((response) => {
                    this.dataPreview = response.data;
                    this.errors = null;
                    this.loading = false;
                    this.drawerIsOpen = true;
                })
                .catch((error) => {
                    if (error.response) {
                        this.loading = false; // this.response = response.data;
                        this.errors = error.response.data;
                        this.response = "";
                        this.drawerIsOpen = false;
                    }
                });
        },

        async emissioneAppendiceInclusione() {
            this.loading = true;
            this.success = false;
            await Api.post(
                process.env.VUE_APP_API_URL +
                    "/api/emissione_appendice_inclusione_polizza_autostoriche_verti",
                this.appendiceDiInclusioneRequest,
            )
                .then((response) => {
                    this.response = response.data;
                    this.errors = null;
                    this.loading = false;
                    this.success = true;
                })
                .catch((error) => {
                    if (error.response) {
                        this.loading = false; // this.response = response.data;
                        // this.errors = error.response.data || error.response.data.message;
                        this.errors = error.response.data;
                        console.log(" this.errors", this.errors);
                        this.response = "";
                    }
                });
        },
        cambioModelloAutostorica(evt) {
            this.auto_storica = evt;
            this.calcoloInclusione();
            // console.log("cambioModelloAutostorica", evt);
        },
        async calcoloInclusione() {
            if (
                this?.appendice &&
                this?.appendice?.data_effetto != null &&
                this?.auto_storica?.id != null
            ) {
                this.loading = true;
                const payload = {
                    date: this.appendice.data_effetto,
                    historic: this.auto_storica,
                };
                await Api.post(
                    process.env.VUE_APP_API_URL +
                        "/api/v1/goold/quotations/inclusion/calculate/" +
                        this.quotazione.id,
                    payload,
                )
                    .then((response) => {
                        console.log(response.data.data.premio_verti);
                        this.premio_verti = response.data.data.premio_verti;
                        this.premio_annuale =
                            response.data.data.nuovo_premio_annuale;

                        // this.calcoloImporto();
                        this.loading = false;
                    })
                    .catch((error) => {
                        if (error.response) {
                            console.log("error", error.response);
                            this.loading = false;
                            // errorCallback(error);
                            this.errors = error.response.data.errors;
                        }
                    });
            }
        },
        unsetCalcoloInclusione() {
            this.auto_storica = {};
        },
        async getStorage() {
            const storage = await readFromRemoteStorage(this.storageKey);
            // console.log("ottengo storage", storage);
            await new Promise((resolve) => setTimeout(resolve, 100));
            return storage;
        },
        async saveAppendice() {
            console.log(this.storageKey, this.appendiceDiInclusioneRequest);
            if (this.appendiceDiInclusioneRequest) {
                this.storage = this.appendiceDiInclusioneRequest;
                // saveToLocalStorage(this.storageKey, this.appendiceDiInclusioneRequest);
                await saveToRemoteStorage(
                    this.storageKey,
                    this.appendiceDiInclusioneRequest,
                );
            }
        },
    },
    watch: {
        // auto_storica(newVal) {
        //   if (newVal != null) {
        //     // this.calcoloInclusione();
        //   }
        // },
        appendice: {
            handler(newVal) {
                if (newVal != null) {
                    // this.calcoloInclusione();
                }
            },
            deep: true,
        },
    },
};
</script>

<style scoped>
.v-alert__content {
    white-space-collapse: preserve-breaks;
}
</style>
