import { Api, errorCallback } from "../../http_tools/axiosModule";
import { getField, updateField } from "vuex-map-fields";
import { setLoading } from "../mutations";

errorCallback;
// # STATE
const new_compagnia_contrattuale = {
    attivo: true,
    nome: "",
    visibile_allegato_4: false,
    esiste_ratifica: false,
    associato_preventivatore_rca: false,
    effettua_versamenti_netto_provvigioni: false,
    prodotti: [],
};

const state = {
    loading: false,
    compagnia_contrattuale_autocomplete: [],
    new_compagnia_contrattuale: new_compagnia_contrattuale,
    errors: {},
    // UI DIALOG
    dialog_aggiungi_nuova_compagnia_contrattuale: false,
    loading_aggiungi_nuova_compagnia_contrattuale: false,
};

// # ACTION
const actions = {
    // rootState
    // NOTE: non utilizzato singola chiamata sul componente
    async action_compagnia_contrattuale_autocomplete({ commit }) {
        const res = await Api.get(
            process.env.VUE_APP_API_URL + `/api/compagnia_contrattuale`,
        );
        // console.log("res", res.data.data)
        commit("compagnia_contrattuale_autocomplete", res.data.data);
    },

    // STORE CREA
    store({ commit, state, rootState }, compagnia_contrattuale) {
        commit("setLoading", true);

        let reqOptions = {
            url: process.env.VUE_APP_API_URL + "/api/compagnia_contrattuale",
            method: "POST",
            data: JSON.stringify(compagnia_contrattuale),
        };

        Api.request(reqOptions)
            .then((response) => {
                const model = response.data.data.model;
                state.compagnia_contrattuale_autocomplete.push(model);

                commit("setLoading", false);
                // Auto seleziona la compagnia contrattuale appena creata
                rootState.MandatoProdottoModule.new_categoria_prodotto.compagnia_contrattuale_id =
                    model.id;
                rootState.MandatoProdottoModule.compagnia_fornitore_id =
                    rootState.route.params.id;
                console.log("rootState --> ", rootState.route.params.id);
                // Chiude la finestre di dialogo nuova compagnia contrattuale
                state.dialog_aggiungi_nuova_compagnia_contrattuale = false;
            })
            .catch((errors) => {
                console.log(">> errors", errors);
                // #TODO: ERROR MODULE
                const status = errors.response.status;
                if (status == 422) {
                    commit("setErrors", errors.response.data.errors);
                    // commit('setNotification', { message: errors.response.data.message, type: 'error' })
                    commit("setLoading", false);
                    state.dialog_aggiungi_nuova_compagnia_contrattuale = true;
                } else {
                    errorCallback(errors);
                    commit("setLoading", false);
                }
            });
    },

    reset({ commit }) {
        commit("setCompagniaContrattuale", new_compagnia_contrattuale);

        console.log(JSON.stringify(new_compagnia_contrattuale, null, 2));
        state.dialog_aggiungi_nuova_compagnia_contrattuale = false;
        setLoading(state, false);
    },
};

const mutations = {
    updateField,
    setLoading(state, loading) {
        (state.loading = loading),
            (state.loading_aggiungi_nuova_compagnia_contrattuale = loading);
    },
    setCompagniaContrattuale(state, compagnia) {
        state.new_compagnia_contrattuale = compagnia;
    },
    setErrors: (state, errors) => {
        state.errors = errors;
    },
    compagnia_contrattuale_autocomplete(state, elenco_compagnie) {
        state.compagnia_contrattuale_autocomplete = elenco_compagnie;
    },
};
const getters = {
    getField,
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
