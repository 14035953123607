<template>
    <div>
        <v-card>
            <v-card-title>
                <v-toolbar flat color="transparent">
                    <v-toolbar-title
                        >Documenti Per Autostoriche GOOld</v-toolbar-title
                    >
                </v-toolbar>
            </v-card-title>
            <v-card-text>
                <v-list>
                    <v-list-item v-for="(item, index) in items" :key="index">
                        <v-list-item-avatar>
                            <v-icon>mdi-file-pdf-box</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>
                                <a :href="item.link" download target="_blank">{{
                                    item.name
                                }}</a>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-card-text>
        </v-card>

        <!-- <a :href="VUE_APP_API_URL+'/docs/set_informativo_verti_veicoli_storici.pdf'" @click.stop
                                                target="_blank">Set
                                                informativo VERTI Assicurazioni SpA</a>,
                                            <a :href="VUE_APP_API_URL+'/docs/set_informativo_am_trust_privacy.pdf'" @click.stop
                                                target="_blank">Set informativo AmTrust Assicurazioni SpA</a>,
                                            <a :href="VUE_APP_API_URL+'/docs/informativa_infodrive_spa.pdf'" @click.stop
                                                target="_blank">Informativa Infodrive
                                                SpA</a>                                        -->
    </div>
</template>

<script>
import { EnvMixin } from "@/mixin/EnvMixin";
export default {
    mixins: [EnvMixin],
    data: () => ({
        drawer: null,
        items: [
            {
                name: "Set Informativo AM Trust Privacy",
                link:
                    process.env.VUE_APP_API_URL +
                    "/docs/set_informativo_am_trust_privacy.pdf",
            },
            {
                name: "Set Informativo Verti Veicoli Storici",
                link:
                    process.env.VUE_APP_API_URL +
                    "/docs/set_informativo_verti_veicoli_storici.pdf",
            },
            {
                name: "Set Informativa infodrive spa",
                link:
                    process.env.VUE_APP_API_URL +
                    "/docs/informativa_infodrive_spa.pdf",
            },
            {
                name: "Privacy Aurum dal Febbraio 2023",
                link:
                    process.env.VUE_APP_API_URL +
                    "/docs/privacy-AURUM-dal-2-2023.pdf",
            },
        ],
    }),
    components: {},
    mounted() {
        this.$store.state.compagnie;
    },
    computed: {
        cards() {
            return this.$store.state.dashboard.cards;
        },
        breadcrumbs() {
            return this.$store.state.breadcrumbs;
        },
    },
};
</script>
