<template>
    <v-card>
        <v-card-text v-if="alert.showAlert">
            <v-alert :type="alert.type" :title="alert.title">
                <h5>{{ alert.title }}</h5>
                {{ alert.text }}
            </v-alert>
        </v-card-text>
        <span>
            <v-card-text class="text--primary" v-if="this.show">
                <div v-for="upload in uploads" :key="upload.collection">
                    <v-card>
                        <v-card-title
                            >{{ upload.title }}
                            <v-divider vertical class="mx-2"></v-divider>
                            <upload-dialog-auth
                                :collection="upload.collection"
                                :model="upload.model"
                                :id="upload.id"
                                v-if="upload.items.length < 4"
                                @success="onAddDocumentSuccessQ($event, upload)"
                                @error="onAddDocumentErrorQ($event)"
                            ></upload-dialog-auth>
                        </v-card-title>

                        <v-card-text>
                            <v-simple-table>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-left prima-colonna">
                                                Documento
                                            </th>
                                            <th
                                                class="text-left seconda-colonna"
                                            >
                                                Categoria
                                            </th>
                                            <th class="text-left terza-colonna">
                                                Download
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="item in upload.items"
                                            :key="item.id"
                                        >
                                            <td>{{ item.name }}</td>
                                            <td>{{ item.collection_name }}</td>
                                            <td>
                                                <a
                                                    target="_blank"
                                                    :href="
                                                        documentUrl(
                                                            item,
                                                            'quotazione',
                                                        )
                                                    "
                                                    >Download</a
                                                >
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-card-text>
                    </v-card>
                    <v-divider></v-divider>
                </div>
                <div v-for="item in veicoli_storici" :key="item.id">
                    <v-card>
                        <v-card-title
                            >Libretto {{ item.brand }} {{ item.model }}
                            <v-divider vertical class="mx-2"></v-divider>
                            <upload-dialog-auth
                                collection="libretto"
                                model="Veicolo"
                                :id="item.id"
                                @success="onAddDocumentSuccessV($event, item)"
                                @error="onAddDocumentErrorV($event)"
                            ></upload-dialog-auth>
                        </v-card-title>
                        <v-card-text>
                            <v-simple-table>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-left prima-colonna">
                                                Documento
                                            </th>
                                            <th
                                                class="text-left seconda-colonna"
                                            >
                                                Categoria
                                            </th>
                                            <th class="text-left terza-colonna">
                                                Download
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr v-for="m in item.media" :key="m.id">
                                            <td>{{ m.name }}</td>
                                            <td>{{ m.collection_name }}</td>
                                            <td>
                                                <a
                                                    target="_blank"
                                                    :href="documentUrl(m)"
                                                    >Download</a
                                                >
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-card-text>
                    </v-card>
                    <v-divider></v-divider>
                </div>
            </v-card-text>
        </span>
        <div
            :class="{ 'loading-overlay': loading }"
            style="z-index: 10000"
        ></div>
    </v-card>
</template>

<script>
import UploadDialogAuth from "./UploadDialogAuth.vue";
import { errorCallback, Api } from "@/http_tools/axiosModule";

export default {
    components: {
        UploadDialogAuth,
    },
    data() {
        return {
            refreshKey: 0,
            datiInviati: false,
            show: false,
            loading: false,
            alert: {
                type: "",
                message: "",
                title: "",
                showAlert: false,
            },
            errors: [],
        };
    },
    props: {
        quotazione: {
            type: Object,
            required: true,
        },
        veicoli_storici: {
            type: Array,
            required: true,
        },
    },
    methods: {
        async fetchMedia(quotation) {
            this.loading = true;
            await Api.get(
                process.env.VUE_APP_API_URL +
                    "/api/v1/goold/quotations/" +
                    quotation.id +
                    "/media",
            )
                .then((response) => {
                    this.q.media = response.data;
                    this.loading = false;
                    this.show = true;
                })
                .catch((error) => {
                    errorCallback(error);
                    this.errors = error.response.data.errors;
                });
        },
        onAddDocumentSuccessQ(event, item) {
            const newMedia = event.data;
            console.log(item);
            this.q.media.push(newMedia);
            this.refreshKey++;
        },
        onAddDocumentSuccessV(event, item) {
            const newMedia = event.data;
            console.log(newMedia);
            item.media.push(newMedia);
        },
        onAddDocumentErrorQ(event) {
            console.log(event);
            this.alert.type = "error";
            this.alert.title = "Errore";
            this.alert.text = "Errore nel caricamento del file";
            this.alert.showAlert = true;
        },
        onAddDocumentErrorV(event) {
            console.log(event);
            this.alert.type = "error";
            this.alert.title = "Errore";
            this.alert.text = "Errore nel caricamento del file";
            this.alert.showAlert = true;
        },
        documentUrl(item) {
            return (
                process.env.VUE_APP_API_URL +
                "/api/quotazioni/getDocument/" +
                item.collection_name +
                "/" +
                item.id
            );
        },
    },
    created: function () {
        this.q = this.quotazione;
    },
    mounted() {
        this.fetchMedia(this.q);
    },
    computed: {
        uploads() {
            this.refreshKey;
            console.log(this.q.media);
            return [
                {
                    title: "Carta d'Identità",
                    collection: "carta_identita",
                    model: "Quotazione",
                    id: this.q.id,
                    items: this.q.media.filter((item) => {
                        return item.collection_name
                            .toLowerCase()
                            .includes("carta_identita");
                    }),
                },
                {
                    title: "Patente",
                    collection: "patente",
                    model: "Quotazione",
                    id: this.q.id,
                    items: this.q.media.filter((item) => {
                        return item.collection_name
                            .toLowerCase()
                            .includes("patente");
                    }),
                },
                {
                    title: "Codice Fiscale",
                    collection: "codice_fiscale",
                    model: "Quotazione",
                    id: this.q.id,
                    items: this.q.media.filter((item) => {
                        return item.collection_name
                            .toLowerCase()
                            .includes("codice_fiscale");
                    }),
                },
                {
                    title: "Tessera Club",
                    collection: "tessera_club",
                    model: "Quotazione",
                    id: this.q.id,
                    items: this.q.media.filter((item) => {
                        return item.collection_name
                            .toLowerCase()
                            .includes("tessera_club");
                    }),
                },
                {
                    title: "Varie",
                    collection: "varie",
                    model: "Quotazione",
                    id: this.q.id,
                    items: this.q.media.filter((item) => {
                        return item.collection_name
                            .toLowerCase()
                            .includes("tessera_club");
                    }),
                },
            ];
        },
    },
};
</script>

<style>
.prima-colonna {
    width: 50%;
}

.seconda-colonna {
    width: 40%;
}

.terza-colonna {
    width: 10%;
}
</style>
