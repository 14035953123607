/* eslint-disable */

// import { AddressTypeEnum } from "@/enum/QuiXA/AddressTypeEnum";
import { DrivingFormulaDtoEnum } from "@/enum/QuiXA/DrivingFormulaDtoEnum";
// import { GenderDtoEnum } from "@/enum/QuiXA/GenderDtoEnum";
import GenderDtoObj from "@/enum/QuiXA/GenderDtoObj";

import { MaritalStatusDtoEnum } from "@/enum/QuiXA/MaritalStatusDtoEnum";
// import { OccupationDtoEnum } from "@/enum/QuiXA/OccupationDtoEnum";
import { PersonTypeDtoEnum } from "@/enum/QuiXA/PersonTypeDtoEnum";

import { getComuni } from "@/store/modules/ProvinciaComuneModule";
// import { radio_yes_or_no_style, select_style, wrap_style } from "./v-form-style";
// import { select_style  } from "./v-form-style";
import AddressTypeObj from "@/enum/QuiXA/AddressTypeObj";
import { GenderDtoEnum } from "@/enum/QuiXA/GenderDtoEnum";
import PersonTypeDtoObj from "@/enum/QuiXA/PersonTypeDtoObj";
import { VehicleCategoryEnum } from "@/enum/QuiXA/VehicleCategoryEnum";
import {
    civil_status_select_style,
    date_picker_style,
    new_radio_yes_or_no_style,
    radio_yes_or_no_style,
    select_style,
    yes_or_not_radio_form_style,
} from "./v-form-style";
import { KmsYearEnum } from "@/enum/QuiXA/KmsYearEnum";
import { UtilizationTypeEnum } from "@/enum/QuiXA/UtilizationTypeEnum";
import { getTodaySQLDate } from "@/services/DateUtils";
import { setErrorMessageInSchema } from "./form-common-validator";
import { form_base_style } from "@/style/StyleMixing";
// import { select_style } from "./v-form-style";

export const toUpper = ({ value }) =>
    typeof value === "string" ? value.toUpperCase() : value;

export const getDescription = (enumValue, enums) => {
    return enums.find((value) => enumValue == value).description;
};
export const getLabel = (enumValue, enums) => {
    return enums.find((value) => enumValue == value).label;
};

export const CityIstat = async () => {
    const comuni = await getComuni();
    // console.log("comuni", comuni)
    return {
        toCtrl: (v) => {
            // console.log("toCtrl", v.value)
            return Number(v.value);
        },
        fromCtrl: (v) => {
            // console.log("fromCtrl", v)
            return v.value.toString().padStart(6, "0");
        },
        type: "autocomplete",
        label: "Comune di",

        col: 8,
        multiple: false,
        returnObject: false,
        itemValue: "codice",
        itemText: "nome",
        items: comuni,
        flex: {
            sm: 6,
            md: 3,
            cols: 12,
        },
    };
};

export const FastQuotationFlowDefaultModel = async () => {
    return {
        fastFlowData: {
            plate: "",
            birthdate: "",
            vehicleCategory: "Auto",
            convention: "QP_STANDARD",
        },
        contacts: [
            {
                type: "Email",
                isPrincipal: true,
                value: "",
            },
        ],
        addresses: [
            {
                addressType: "PermanentResidency",
                streetType: "via",
                streetName: "",
                number: "",
                cityIstat: "",
                zipCode: "",
            },
        ],
        privacyData: {
            question1: true,
            question2: true,
            question3: true,
        },
        coverages: [
            {
                code: "P01",
                description: "Responsabilità Civile",
                selected: true,
                value: "6000000",
                coInsurance: null,
            },
        ],
    };
};

export const QuotationFlowDefaultModel = async () => {
    return {
        agentData: {
            agentEmail: "u.muoio@etisicura.it",
            agencyCode: "340",
        },
        commonData: {
            effectDate: getTodaySQLDate(),
            bonusMalus: 1,
            insuranceSituation: "AlreadyInsuredATR",
            numBlamelessClaimsLast2Years: "Zero",
            hasBersani: null,
            bersaniPlate: null,
            insuranceCompany: "",
            yearsInsuranceCompany: 1,
            isLeasing: false,
            convention: "QP_STANDARD",
            atr: {
                year0: 0,
                year1: 0,
                year2: 0,
                year3: 0,
                year4: 0,
                year5: 0,
            },
        },
        personData: {
            persons: await PersonDefaultModel(),
        },
        privacyData: {
            question1: true,
            question2: true,
            question3: true,
        },
        simulationObject: {
            vehicleCategory: "Auto",
            vehicleCode: "",
            plate: "",
            plateDate: "2022-03-31",
            utilizationType: "SpareTime",
            kmsPerYear: "Between10And20Thousand",
            customValue: 0,
            isDrivenByYoungers: false,
            youngestDriverAge: null,
            hasGpsTracking: false,
            purchaseDate: "2022-07-01",
        },
        coverages: [
            {
                code: "P01",
                description: "Responsabilità Civile",
                selected: true,
                value: "6000000",
                coInsurance: null,
            },
        ],
    };
};

/* ---------------------------------------------------- 
PERSON 
---------------------------------------------------- */
export const PersonDefaultModel = async () => {
    return {
        typeCode: PersonTypeDtoObj.OwnerAndHolder,
        name: "",
        surname: "",
        gender: GenderDtoObj.Male,
        birthDate: "",
        civilStatus: "",
        hasUnderAgeChildren: false,
        licenseYear: "",
        drivingFormulas: "",
        professionCode: "",
        fiscalCode: "",
        contacts: [
            {
                type: "Mobile",
                isPrincipal: true,
                value: "",
            },
            {
                type: "Email",
                isPrincipal: true,
                value: "",
            },
        ],
        residenzaCoincideDomicilio: false,
        addresses: [
            {
                addressType: "PermanentResidency",
                streetType: "",
                streetName: "",
                number: "",
                cityIstat: "",
                zipCode: "",
            },
        ],
    };
};

export const PersonSchema = async (genderValue, errors = []) => {
    let schema = {
        // "typeCode": {
        //     type: 'select',
        //     readonly: true,
        //     hidden: true,
        //     items: PersonTypeDtoEnum,
        //     ...select_style,
        // },

        gender: {
            label: "Genere", // La proprietà "label" che sarà visualizzata come testo dell'opzione
            type: "radio",
            row: true,
            multiple: false, // Non selezionare più opzioni
            returnObject: false, // Restituisce solo il valore selezionato, non l'intero oggetto
            value: "value", // La proprietà "value" che sarà utilizzata per il binding del radio button
            options: GenderDtoEnum,
            col: 12, // Numero di colonne (layout grid)
        },
        name: {
            toCtrl: toUpper,
            type: "text",
            label: "Nome",
            col: 4,
        },
        surname: {
            toCtrl: toUpper,
            type: "text",
            label: "Cognome",

            col: 4,
        },
        birthDate: {
            label: "Data di Nascita",
            type: "text",
            ext: "date",
            locale: "it",
            // "prependIcon": "mdi-event",
            col: 4,
        },

        civilStatus: {
            ...civil_status_select_style,
            label: "Stato Civile",
        },
        fiscalCode: {
            toCtrl: toUpper,
            type: "text",
            label: "Codice Fiscale",
            col: 4,
        },
        piva: {
            toCtrl: toUpper,
            type: "text",
            label: "Partita IVA",
            col: 4,
        },
    };

    setGender(genderValue, schema);
    // schema.name.rules = [true];

    return schema;
};
export const setGender = (value, schema) => {
    if (value == GenderDtoObj.Juridic) {
        schema.name.label = "Ragione Sociale";

        schema.surname.hidden = true;
        schema.birthDate.hidden = true;
        schema.civilStatus.hidden = true;
        schema.fiscalCode.hidden = true;
        schema.piva.hidden = false;

        schema.name.col = 8;
    }

    if (value != GenderDtoObj.Juridic) {
        schema.name.label = "Nome";

        schema.surname.hidden = false;
        schema.birthDate.hidden = false;
        schema.civilStatus.hidden = false;
        schema.fiscalCode.hidden = false;
        schema.piva.hidden = true;

        schema.name.col = 4;
    }
};

/* --------------------------------------------------
CONTRAENTE E LEASING
-------------------------------------------------- */
export const PersonTypeCodeOrder = [
    "OwnerAndHolder",
    "Holder",
    "Owner",
    "TenantAndHolder",
    "CounterParty",
];

function getFormList(leasing, contraenteDiverso) {
    switch (leasing + "" + contraenteDiverso) {
        case "falsefalse":
            return ["OwnerAndHolder"];
        case "falsetrue":
            return ["Holder", "Owner"];
        case "truefalse":
            return ["TenantAndHolder", "CounterParty"];
        case "truetrue":
            return ["Holder", "Tenant", "CounterParty"];
        default:
            return [];
    }
}

export function personIsHolder(personTypeCode) {
    return [
        PersonTypeDtoObj.Holder,
        PersonTypeDtoObj.OwnerAndHolder,
        PersonTypeDtoObj.TenantAndHolder,
    ].includes(personTypeCode);
}

export function isMainAddress(addressType) {
    return [
        AddressTypeObj.PermanentResidency,
        AddressTypeObj.CompanyHeadquarters,
    ].includes(addressType);
}

export function setPersonDataForm(mainPerson, leasing, contraenteDiverso) {
    // LEASING e PROPRIETARI
    const formList = getFormList(leasing, contraenteDiverso);

    return formList.map((formTypeCode) => {
        let Person;
        if (personIsHolder(formTypeCode)) {
            Person = mainPerson;
        } else {
            Person = {
                typeCode: formTypeCode,
                name: "",
                surname: "",
                gender: "",
                birthDate: "",
                civilStatus: "",
                hasUnderAgeChildren: false,
                licenseYear: false,
                drivingFormulas: "",
                professionCode: "",
                fiscalCode: "",
                contacts: [],
                addresses: [],
            };
        }
        return Person;
        // return res
    });
}

/* ---------------------------------------------------- 
ADDRESS
---------------------------------------------------- */

/**
 * A seconda delle caratteristiche del genere della persona e della residenza e della sede legale
 * @param {GenderDtoObj} personGender
 * @param {Boolean} residenzaCoincideDomicilio
 * @returns array elenco dei tipi di indirizzo da utilizzare
 */
export const trovaIndirizziDaUtilizzare = (
    personGender,
    residenzaCoincideDomicilio,
) => {
    // alert(personGender == GenderDtoObj.Juridic)
    if (personGender === GenderDtoObj.Juridic) {
        if (residenzaCoincideDomicilio) {
            return [AddressTypeObj.CompanyHeadquarters];
        }

        if (!residenzaCoincideDomicilio) {
            return [
                AddressTypeObj.CompanyHeadquarters,
                AddressTypeObj.CompanyOther,
            ];
        }
    }

    if (personGender !== GenderDtoObj.Juridic) {
        if (residenzaCoincideDomicilio) {
            return [AddressTypeObj.PermanentResidency];
        }

        if (!residenzaCoincideDomicilio) {
            return [
                AddressTypeObj.PermanentResidency,
                AddressTypeObj.OtherResidency,
            ];
        }
    }
};

export const AddressSchema = async (errors) => {
    let formSchema = {
        streetType: {
            type: "select",
            label: "via...",
            tooltip: false,
            items: ["Via", "Corso", "Piazza", "Strada"],
            hidden: false,
            col: 3,
            ...form_base_style,
        },
        streetName: {
            toCtrl: toUpper,
            type: "text",
            label: "Indirizzo",
            tooltip: false,
            col: 6,
            ...form_base_style,
        },
        number: {
            toCtrl: toUpper,
            type: "text",
            label: "Civico",
            tooltip: false,
            col: 3,
            ...form_base_style,
        },
        cityIstat: await CityIstat(),
        zipCode: {
            type: "text",
            label: "Cap",
            tooltip: false,
            col: 3,
            ...form_base_style,
        },
    };

    formSchema = setErrorMessageInSchema(formSchema, errors);
    return formSchema;
};

/* ---------------------------------------------------- 
VEHICLE SCHEMA
---------------------------------------------------- */
export const VehicleCategorySelect = (errors = {}) => {
    return {
        label: "Tipo di Veicolo",
        items: VehicleCategoryEnum,
        ...select_style,
    };
};
export const VehicleCategoryRadio = async () => {
    return {
        label: "Tipo di Veicolo",
        items: VehicleCategoryEnum,
        ...select_style,
        type: "radio",
    };
};

/** @see VehicleForm.vue  (ricerca veicolo) */
export const VehicleSchema = async (errors = []) => {
    return {
        vehicleCategory: await VehicleCategorySelect(errors),
        plate: {
            type: "text",
            label: "Targa Veicolo",
            col: 3,
            ...form_base_style,
        },
        plateDate: {
            label: "Data di Immatricolazione",
            ...date_picker_style,
            ...form_base_style,
            col: 3,
        },

        purchaseDate: {
            label: "Data di Acquisto",
            ...date_picker_style,
            col: 3,
            ...form_base_style,
        },
        vehicleCode: {
            type: "text",
            label: "Codice Veicolo",
            col: 3,
            ...form_base_style,
        },
        utilizationType: {
            type: "select",
            label: "Tipo di Utilizzo",
            items: UtilizationTypeEnum,
            ...select_style,
            ...form_base_style,
        },
        kmsPerYear: {
            type: "select",
            label: "Km per Anno",
            items: KmsYearEnum,
            ...select_style,
            col: 6,
            ...form_base_style,
        },
        customValue: {
            type: "number",
            label: "Valore Personalizzato (Km Per Anno)",
            col: 6,
            ...form_base_style,
        },
        isDrivenByYoungers: {
            ...yes_or_not_radio_form_style,
            label: "Guidato da Giovani in v.s.",
            col: 6,
            ...form_base_style,
        },
        youngestDriverAge: {
            type: "number",
            label: "Età Conducente piu giovane",
            col: 6,
            ...form_base_style,
        },
        hasGpsTracking: {
            label: "Il Veicolo ha tracciamento GPS ?",
            ...yes_or_not_radio_form_style,
            col: 12,
            ...form_base_style,
        },
    };
};

export const CommonDataSchema = async () => {
    const schema = {
        effectDate: {
            ...date_picker_style,
            label: "Data Effetto",
            ...form_base_style,
        },
        bonusMalus: {
            type: "number",
            label: "Bonus Malus",
            // rules: ['required', 'min:1', 'max:18'],
            ...form_base_style,
        },
        insuranceSituation: {
            type: "select",
            label: "Insurance Situation",
            items: ["AlreadyInsuredATR", "NewCustomer", "Other"],
            rules: ["required"],
            ...form_base_style,
        },
        numBlamelessClaimsLast2Years: {
            type: "select",
            label: "Number of Blameless Claims (Last 2 Years)",
            items: ["Zero", "One", "Two", "Three", "More"],
            rules: ["required"],
            ...form_base_style,
        },
        hasBersani: {
            type: "switch",
            label: "Has Bersani",
            trueValue: true,
            falseValue: false,
        },
        bersaniPlate: {
            type: "text",
            label: "Bersani Plate",
            rules: ["required_if:hasBersani,true"],
            visible: (model) => model.hasBersani === true,
        },
        insuranceCompany: {
            type: "select",
            label: "Insurance Company",
            items: ["198", "199", "200"], // Substitute with actual company list
            rules: ["required"],
        },
        yearsInsuranceCompany: {
            type: "number",
            label: "Years with Insurance Company",
            rules: ["required", "min:0"],
        },
        isLeasing: {
            type: "radio",
            label: "Is Leasing",
            items: [
                { text: "Yes", value: "yes" },
                { text: "No", value: "no" },
            ],
            rules: ["required"],
        },
        convention: {
            type: "select",
            label: "Convention",
            items: ["QP_STANDARD", "QP_PREMIUM", "OTHER"],
            rules: ["required"],
        },
        atr: {
            type: "group",
            label: "ATR Data",
            children: {
                year0: {
                    type: "number",
                    label: "ATR Year 0",
                    rules: ["required", "min:0"],
                },
                year1: {
                    type: "number",
                    label: "ATR Year 1",
                    rules: ["required", "min:0"],
                },
                year2: {
                    type: "number",
                    label: "ATR Year 2",
                    rules: ["required", "min:0"],
                },
                year3: {
                    type: "number",
                    label: "ATR Year 3",
                    rules: ["required", "min:0"],
                },
                year4: {
                    type: "number",
                    label: "ATR Year 4",
                    rules: ["required", "min:0"],
                },
                year5: {
                    type: "number",
                    label: "ATR Year 5",
                    rules: ["required", "min:0"],
                },
            },
        },
    };

    return schema;
};

// return {
//     type: 'object',
//     label: 'Dati Comuni',
//     schema: {
//
//         bonusMalus: { type: 'number', label: 'Bonus Malus' },
//         insuranceSituation: {
//             type: 'select',
//             label: 'Situazione Assicurativa',
//             items: [
//                 { text: 'Già Assicurato ATR', value: 'AlreadyInsuredATR' },
//                 { text: 'Non Assicurato', value: 'NotInsured' }
//             ] // Aggiungi altri valori possibili se necessario
//         },
//         numBlamelessClaimsLast2Years: {
//             type: 'select',
//             label: 'Sinistri Non Colpevoli (Ultimi 2 Anni)',
//             items: [
//                 { text: 'Zero', value: 'Zero' },
//                 { text: 'Uno', value: 'One' },
//                 { text: 'Due', value: 'Two' }
//             ]
//         },
//         hasBersani: { type: 'checkbox', label: 'Ha la Bersani' },
//         bersaniPlate: { type: 'text', label: 'Targa Bersani', required: false }, // Campo opzionale
//         insuranceCompany: { type: 'text', label: 'Compagnia Assicurativa' },
//         yearsInsuranceCompany: { type: 'number', label: 'Anni con la Compagnia Assicurativa' },
//         isLeasing: { type: 'checkbox', label: 'È in Leasing' },
//         convention: {
//             type: 'select',
//             label: 'Convenzione',
//             items: [
//                 { text: 'QP_STANDARD', value: 'QP_STANDARD' },
//                 { text: 'Altra Convenzione', value: 'OtherConvention' }
//             ]
//         },
//         atr: {
//             type: 'object',
//             label: 'ATR',
//             schema: {
//                 year0: { type: 'number', label: 'Anno 0' },
//                 year1: { type: 'number', label: 'Anno 1' },
//                 year2: { type: 'number', label: 'Anno 2' },
//                 year3: { type: 'number', label: 'Anno 3' },
//                 year4: { type: 'number', label: 'Anno 4' },
//                 year5: { type: 'number', label: 'Anno 5' }
//             }
//         }
//     }
// }
