var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"page",attrs:{"id":"CollaboratorePage"}},[_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('v-card',{staticClass:"p-0"},[_c('table-component',{attrs:{"resource":_vm.tableDataset,"fixed-header":"","config":`collaboratore-index-table`,"toolbar-title":"Collaboratori","loading":_vm.loading,"loading-text":"attendi un attimo","items-per-page":_vm.itemsPerPage},on:{"on-edit":function($event){return _vm.onEdit($event)},"on-delete":function($event){return _vm.onDelete($event)},"on-add-new-item":function($event){return _vm.onAddNewItem($event)}},scopedSlots:_vm._u([{key:`item.settore.rca`,fn:function({ item }){return [_c('v-chip',{attrs:{"color":_vm.getColorRCA(
                                    _vm.getProvvigioneValue(item, 'RCA'),
                                ),"dark":"","label":""}},[_vm._v(" "+_vm._s(_vm.getProvvigioneValue(item, "RCA"))+" % ")])]}},{key:`item.settore.danni`,fn:function({ item }){return [_c('v-chip',{attrs:{"color":_vm.getColorDanni(
                                    _vm.getProvvigioneValue(item, 'DANNI'),
                                ),"dark":"","label":""}},[_vm._v(" "+_vm._s(_vm.getProvvigioneValue(item, "DANNI"))+" % ")])]}},{key:`item.settore.vita`,fn:function({ item }){return [_c('v-chip',{attrs:{"color":_vm.getColorVita(
                                    _vm.getProvvigioneValue(item, 'VITA'),
                                ),"dark":"","label":""}},[_vm._v(" "+_vm._s(_vm.getProvvigioneValue(item, "VITA"))+"% ")])]}}],null,true)})],1)],1),_c('v-tab-item',[_c('v-card',[_vm._v(" tab 3 ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }