import { getField, updateField } from "vuex-map-fields";
import { SpatieQueryBuilder } from "@/http_tools/spatie/query-builder";
import { router } from "@/main";
import { Api, errorCallback } from "../../http_tools/axiosModule";

function polizzaPayload(_polizza) {
    const polizza = { ..._polizza };
    polizza.collaboratore_id = polizza.collaboratore
        ? polizza.collaboratore.id
        : null;
    polizza.cliente_id = polizza.cliente ? polizza.cliente.id : null;
    polizza.mandato_prodotto_id = polizza.mandato_prodotto
        ? polizza.mandato_prodotto.id
        : null;
    polizza.frazionamento_slug = polizza.frazionamento?.slug;
    return polizza;
}

/**
 * alert ci saranno mandati sui prodotti e mandati sulle garanzie
 */

const state = {
    ui: {
        mode: null,
    },

    tipologie_pagamento: [
        {
            id: "ACCREDITO",
            label: "ACCREDITO",
        },
        {
            id: "ADDEBITO",
            label: "ADDEBITO",
        },
        {
            id: "ASSEGNO",
            label: "ASSEGNO",
        },
        {
            id: "BONIFICI_NON_ABBINATI",
            label: "BONIFICI NON ABBINATI",
        },
        {
            id: "BONIFICO",
            label: "BONIFICO",
        },
        {
            id: "CARTA_DI_CREDITO",
            label: "CARTA DI CREDITO",
        },
        {
            id: "CONTANTI",
            label: "CONTANTI",
        },
        {
            id: "GIROCONTO",
            label: "GIROCONTO",
        },
        {
            id: "LOTTOMATICA",
            label: "LOTTOMATICA",
        },
        {
            id: "POS",
            label: "POS",
        },
        {
            id: "PROVVIGIONI_ATTIVE",
            label: "PROVVIGIONI ATTIVE",
        },
        {
            id: "RITENUTE",
            label: "RITENUTE",
        },
        {
            id: "SCONTO",
            label: "SCONTO",
        },
        {
            id: "SPESE_BANCARIE_CONTO_GESTIONALE",
            label: "SPESE BANCARIE CONTO GESTIONALE",
        },
        {
            id: "SPESE_BANCARIE_CONTO_PREMI",
            label: "SPESE BANCARIE CONTO PREMI",
        },
        {
            id: "SPESE_BONIFICO_CONTO_PATRIMONIO",
            label: "SPESE BONIFICO CONTO PATRIMONIO",
        },
        {
            id: "SPESE_BONIFICO_CONTO_PREMI",
            label: "SPESE BONIFICO CONTO PREMI",
        },
    ],
    frazionamenti: [],
    polizza_index: [],
    polizza_numero: "1233",
    status: "npolizza",
    // Ricerca
    prodotti: [],
    compagnie_contrattuali: [],
    fornitori: [],
    rami: [],
    mandato_prodotto_filter: {
        compagnia_contrattuale: null,
        fornitore: null,
        ramo: null,
    },
    polizza: {
        polizza_numero: "",
        polizza_numero_sostituita: "",
        cliente_id: "",
        collaboratore_id: "",
        broker_id: "",
        mandato_prodotto_id: "",
        data_entrata_in_effetto: "",
        data_scadenza: "",
        frazionamento: "",
        tacito_rinnovo: "",
        numero_polizza_sostituita: "",
        tipo_pagamento: "",
        competenze: 0,
        totale_premio_lordo: 0,
        totale_premio_imponibile: 0,
        totale_premio_imposte: 0,
        diritti: 0,
        spese: 0,
    },
    garanzie: [
        {
            garanzia: "nom garanzia",
            importo_netto: 0,
            importo_lordo: 0,
        },
    ],
    garanzieHeader: [
        {
            text: "Id",
            value: "id",
            align: "right",
            sortable: false,
            filterable: false,
            groupable: true,
            divider: false,
            class: ["id"],
            cellClass: ["cell__id"],
            width: "3rem",
        },
        {
            text: "garanzia",
            value: "garanzia",
            align: "end",
            sortable: false,
            filterable: false,
            groupable: true,
            divider: false,
            class: ["data_inizio"],
            cellClass: ["cell__data"],
            width: "auto",
        },
        {
            text: "importo_netto",
            value: "importo_netto",
            align: "end",
            sortable: false,
            filterable: false,
            groupable: true,
            divider: false,
            class: ["data_fine"],
            cellClass: ["cell__data"],
            width: "auto",
        },
        {
            text: "importo_lordo",
            value: "importo_lordo",
            align: "end",
            sortable: false,
            filterable: false,
            groupable: true,
            divider: false,
            class: ["data_fine"],
            cellClass: ["cell__data"],
            width: "auto",
        },
    ],
    titoli_headers: titoliHeaders(),
    titolo_attivo: {
        totale_netto: "",
        diritti: "",
        accessori: "",
        spese: "",
        imposte: "",
        ssn: "",
        premio_polizza: "",
        spese_emissione: "",
        competenze: "",
        valore_ritenuta: "",
        totale_cliente: "",

        perc_provvigioni_attive: "",
        perc_competenze_collaboratore: "",

        collaboratore_totale: "",
    },
    titoli: [
        {
            data_inizio: "17/05/2022",
            data_fine: "17/11/2022",
            premio: "306.77",
            stato: "GIACENZA",
            data_ultimo_stato: "08/04/2022",
            saldato: true,
        },
        {
            data_inizio: "17/11/2021",
            data_fine: "17/05/2022",
            premio: "306.77",
            stato: "GIACENZA",
            data_ultimo_stato: "08/04/2022",
            saldato: true,
        },
        {
            data_inizio: "17/05/2021",
            data_fine: "17/11/2021",
            premio: "306.77",
            stato: "GIACENZA",
            data_ultimo_stato: "08/04/2022",
            saldato: true,
        },
    ],
    errors: {},
    frazionamento: [],
};
const getters = {
    getField,
    // /**
    //  * @param {*} state.prodotto_assicurativo corrisponde a un mandato
    //  * @returns
    //  */
    titoloAttivo: (state) => {
        return state.titolo_attivo;
    },
};

const actions = {
    async index({ state, commit }) {
        Api.get(process.env.VUE_APP_API_URL + "/api/polizza").then((res) => {
            state.polizza_index = res.data.data;
            commit("setLoading", false);
            console.log("res.data.data", res.data.data);
        });
    },
    async create(context) {
        const { commit } = context;
        commit("setPolizza", {
            polizza_numero: "",
            polizza_numero_sostituita: "",
            cliente_id: "",
            collaboratore_id: "",
            broker_id: "",
            mandato_prodotto_id: "",
            data_entrata_in_effetto: "",
            data_scadenza: "",
            frazionamento: "",
            tacito_rinnovo: "",
            numero_polizza_sostituita: "",
            tipo_pagamento: "",
            competenze: 0,
            totale_premio_lordo: 0,
            totale_premio_imponibile: 0,
            totale_premio_imposte: 0,
            diritti: 0,
            spese: 0,
        });
        commit("setErrors", {});
    },
    async edit(context, _polizza) {
        const { commit } = context;

        const polizza = polizzaPayload(_polizza);

        commit("setPolizza", polizza);
        commit("setErrors", {});
        commit("setLoading", true);

        let reqOptions = {
            url: process.env.VUE_APP_API_URL + "/api/polizza/" + polizza.id,
            method: "PUT",
            data: JSON.stringify(polizza),
        };
        Api.request(reqOptions)
            .then((res) => {
                // TODO: fare versione giusta delprodotto nella response
                res;
                commit("setPolizza", polizza);
                commit("setErrors", {});
            })
            .catch(function (errors) {
                commit("setErrors", errors.response.data.errors);
                errorCallback(errors);
            });
    },
    async show(context, polizza_id) {
        const { commit, dispatch } = context;

        commit("setPolizza", {});
        commit("setErrors", {});
        commit("setLoading", true);

        Api.get(process.env.VUE_APP_API_URL + "/api/polizza/" + polizza_id)
            .then((res) => {
                const polizza = res.data.data;
                commit("setPolizza", polizza);
                state.mandato_prodotto_filter = {
                    compagnia_contrattuale:
                        polizza.mandato_prodotto.compagnia_contrattuale,
                    fornitore: polizza.mandato_prodotto.fornitore,
                    ramo: polizza.mandato_prodotto.prodotto.ramo,
                };
                dispatch(
                    "ACTION_SEARCH_MANDATO_PRODOTTO",
                    state.mandato_prodotto_filter,
                );
            })
            .catch((error) => {
                // SET ERRORS
                commit("setErrors", {});
                errorCallback(error, "Polizza");
            });
    },
    async store({ commit }, _polizza) {
        const polizza = polizzaPayload(_polizza);

        commit("setErrors", {});
        let reqOptions = {
            url: process.env.VUE_APP_API_URL + "/api/polizza",
            method: "POST",
            data: JSON.stringify(polizza),
        };

        Api.request(reqOptions)
            .then((response) => {
                const polizza = response.data.data.model;
                commit("setPolizza", polizza);
                commit("setNotification", {
                    message: "polizza inserito",
                    type: "success",
                });
                router.push("/modifica/polizza/" + polizza.id);
            })
            .catch((errors) => {
                const status = errors.response.status;
                if (status == 422) {
                    commit("setErrors", errors.response.data.errors);
                    commit("setNotification", {
                        message: errors.response.data.message,
                        type: "error",
                    });
                } else {
                    errorCallback(errors);
                }
            });
    },
    async delete({ commit }, polizza) {
        // TODO: Spostare dentro il componente
        const polizza_index = [
            ...state.polizza_index.filter((item) => polizza.id !== item.id),
        ];
        Api.delete(process.env.VUE_APP_API_URL + "/api/polizza/" + polizza.id);
        commit("setPolizze", polizza_index);
    },
    async ACTION_SEARCH_MANDATO_PRODOTTO({ state, commit }) {
        const fornitore_id = state.mandato_prodotto_filter.fornitore
            ? state.mandato_prodotto_filter.fornitore.id
            : null;
        const compagnia_contrattuale_id = state.mandato_prodotto_filter
            .compagnia_contrattuale
            ? state.mandato_prodotto_filter.compagnia_contrattuale.id
            : null;

        const _queryStringParams = {
            compagnia_fornitore_id: fornitore_id,
            prodotto: {
                compagnia_contrattuale_id: compagnia_contrattuale_id,
            },
        };

        commit("setLoading", true);
        const spatieParams = SpatieQueryBuilder.stringify(_queryStringParams);
        const res = await Api.get(
            process.env.VUE_APP_API_URL +
                `/api/mandato_prodotto_in_polizza/search?${spatieParams}`,
        );

        state.prodotti = res.data.data;
        commit("setLoading", false);
    },
    async action_load_compagnie_contrattuali({ commit }) {
        const compagnie_contrattuali = await Api.get(
            process.env.VUE_APP_URL + "/api/compagnie_contrattuali",
        );
        commit("setCompagnieContrattuali", compagnie_contrattuali);
    },
    async action_load_fornitori({ commit }) {
        const fornitori = await Api.get(
            process.env.VUE_APP_URL + "/api/fornitori",
        );
        commit("setFornitori", fornitori);
    },
    async action_load_rami({ commit }) {
        const rami = await Api.get(process.env.VUE_APP_URL + "/api/rami");
        commit("setRami", rami);
    },
    ACTION_GET_FRAZIONAMENTO: ({ commit, state }) => {
        Api.get(process.env.VUE_APP_API_URL + "/api/autocomplete/frazionamento")
            .then((res) => {
                state.frazionamenti = res.data;
                commit("setLoading", false);
            })
            .catch((error) => {
                errorCallback(error);
                commit("setLoading", false);
            });
    },
};

const mutations = {
    updateField,
    setTitoloAttivo: (state, data) => (state.titolo_attivo = data),
    calcolaProvvigioniDaProdotto: (state) =>
        calcolaProvvigioniDaProdotto(state),
    setProdotti(state, prodotti) {
        state.prodotti = prodotti;
    },
    setCompagnieContrattuali(state, compagnie_contrattuali) {
        state.compagnie_contrattuali = compagnie_contrattuali;
    },
    setFornitori(state, fornitori) {
        state.fornitori = fornitori;
    },
    setRami(state, rami) {
        state.rami = rami;
    },
    setLoading(state, loading) {
        state.loading = loading;
    },
    setPolizza: (state, polizza) => {
        state.polizza = polizza;
        state.loading = false;
    },
    setNotification: (state, message) => {
        state.message = message;
        state.loading = false;
    },
    setErrors: (state, errors) => {
        state.errors = errors;
        state.loading = false;
    },
    setPolizze: (state, polizza_index) => {
        state.polizza_index = polizza_index;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

function calcolaProvvigioniDaProdotto() {
    // // :polizza="polizza"
    // // :collaboratore="collaboratore"
    // tipo_polizza_id
    // const percentuale_provvigione_attiva = prodotto_assicurativo.percentuale_provvigione_attiva
    // return {
    //     "percentuale_provvigione_attiva": percentuale_provvigione_attiva,
    // }
    // percentuale_provvigione_attiva
}

function titoliHeaders() {
    const headers = [
        {
            text: "Id",
            value: "id",
            align: "right",
            sortable: false,
            filterable: false,
            groupable: true,
            divider: false,
            class: ["id"],
            cellClass: ["cell__id"],
            width: "3rem",
        },
        { title: "", key: "data-table-expand" },
        {
            text: "Inizio",
            value: "data_inizio",
            align: "end",
            sortable: false,
            filterable: false,
            groupable: true,
            divider: false,
            class: ["data_inizio"],
            cellClass: ["cell__data"],
            width: "auto",
        },
        {
            text: "Fine",
            value: "data_fine",
            align: "end",
            sortable: false,
            filterable: false,
            groupable: true,
            divider: false,
            class: ["data_fine"],
            cellClass: ["cell__data"],
            width: "auto",
        },

        {
            text: "Premio",
            value: "premio",
            align: "end",
            sortable: false,
            filterable: false,
            groupable: true,
            divider: false,
            class: ["premio"],
            cellClass: ["cell__premio"],
            width: "auto",
        },

        {
            text: "Stato",
            value: "stato",
            align: "end",
            sortable: false,
            filterable: false,
            groupable: true,
            divider: false,
            class: ["stato"],
            cellClass: ["cell__stato"],
            width: "auto",
        },
        {
            text: "Ultimo Stato",
            value: "data_ultimo_stato",
            align: "end",
            sortable: false,
            filterable: false,
            groupable: true,
            divider: false,
            class: ["data_ultimo_stato"],
            cellClass: ["cell__stato"],
            width: "auto",
        },
        {
            text: "Saldato",
            value: "saldato",
            align: "end",
            sortable: false,
            filterable: false,
            groupable: true,
            divider: false,
            class: ["saldato"],
            cellClass: ["cell__saldato"],
            width: "auto",
        },
    ];
    return headers;
}
