<template>
    <div data-test="FastQuotationForm">
        <div v-if="model">
            <v-form-base v-if="show" :schema="schema" :model="model">
            </v-form-base>
            <div v-if="model.addresses">
                <div :key="a.addressType" v-for="(a, index) in model.addresses">
                    <address-form
                        v-model="model.addresses[index]"
                        :errors="errors?.addresses[String(index)]"
                    >
                    </address-form>
                </div>
            </div>
            <div v-if="model.contacts">
                <dir :key="c.value" v-for="(c, index) in model.contacts">
                    <ContactForm
                        v-model="model.contacts[index]"
                        :errors="errors?.contacts[String(index)]"
                    >
                    </ContactForm>
                </dir>
            </div>
            <pre><b>FastQuotationForm</b>{{ model }}</pre>
        </div>
    </div>
</template>

<script>
// import { Api, errorCallback } from "@/http_tools/axiosModule";
// import { castToBoolean } from "@/services/JSONTools";
import {
    // civil_status_select_style,
    date_picker_style,
    // select_style,
} from "@/v-form-base/v-form-style";
import VFormBase from "vuetify-form-base";
import AddressForm from "./AddressForm.vue";
import ContactForm from "./ContactForm.vue";
import { setErrorMessageInSchema } from "@/v-form-base/form-common-validator";
import { toUpper, VehicleCategorySelect } from "@/v-form-base/form-common";

export default {
    name: "fast-quotation-form",
    props: ["type", "value", "obj", "errors"],
    components: {
        VFormBase,
        AddressForm,
        ContactForm,
        // CoverageQuixaForm
    },
    created() {},
    async mounted() {
        this.show = true;
    },
    data() {
        return {
            show: true,
            // schema: {},
        };
    },

    computed: {
        model: {
            get() {
                return this.value;
            },
            set(v) {
                this.$emit("input", v);
            },
        },
        schema() {
            return FastQuotationSchema(this.errors);
        },
        // requestHTTP() {
        //     return castToBoolean(this.model);
        // },
    },
};

function FastQuotationSchema(errors = {}) {
    // console.log(errors);

    const formSchema = {
        fastFlowData: {
            type: "group",
            class: "title pb-5 elevation-0",
            color: "lighten-5",
            col: 12,
            schema: {
                vehicleCategory: VehicleCategorySelect(errors),
                plate: {
                    toCtrl: toUpper,
                    type: "text",
                    label: "Targa Veicolo",
                    // rules: ['required'],
                    col: 6,
                },
                birthdate: {
                    ...date_picker_style,
                    label: "Data di Nascita",
                    col: 6,
                },
            },
        },
    };

    formSchema.fastFlowData.schema = setErrorMessageInSchema(
        formSchema.fastFlowData.schema,
        errors.fastFlowData,
    );

    /* 
    
    "addresses": {
            "0": {
                "streetName": [
                    "è obbligatorio."
                ],
                "number": [
                    "è obbligatorio."
                ],
                "cityIstat": [
                    "città è obbligatorio."
                ],
                "zipCode": [
                    "Il CAP è obbligatorio."
                ]
            }
    }
    
    */

    return formSchema;
}
</script>

<style></style>
