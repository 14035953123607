<template>
    <v-card-actions class="card_action__top mt-n4 ml-n4">
        <!-- <v-card-title class="text-h5 lighten-2">
      {{ sezioneAttiva }} {{ $route.params.id }}
    </v-card-title> -->
        <!-- <div class="px-3">
      <v-switch
        id="attivo"
        @change="changeHandler"
        data-cy="attivo"
        label="Attivo">
      </v-switch>
    </div> -->

        <!-- CHIP STATO -->
        <v-chip
            v-if="$store.state.touch"
            class="ma-2 p-3"
            :color="isValid ? 'green' : 'red'"
            label
            text-color="white"
        >
            <v-icon v-if="isValid" left> mdi-check-circle </v-icon>
            <!-- <v-icon left> mdi-alert-circle </v-icon> -->
            <v-icon v-if="!isValid" left> mdi-alert-rhombus </v-icon>
            {{
                isValid ? "ok" : "sono presenti degli errori - " + errorsNumbers
            }}
        </v-chip>
        <!-- CHIP STATO -->
        <v-chip v-if="$store.state.notify.type" color="success">
            {{ $store.state.notify.message }}
        </v-chip>

        <v-divider></v-divider>
        <v-btn
            data-cy="btn-annulla"
            color="blue darken-1"
            text
            @click="$emit('on-annulla')"
        >
            Annulla
        </v-btn>

        <v-btn
            v-if="mode === 'EDIT_MODE'"
            data-cy="btn-edit"
            color="blue darken-1"
            text
            @click="$emit('on-aggiorna')"
        >
            Aggiorna
        </v-btn>

        <v-btn
            v-if="mode !== 'EDIT_MODE'"
            data-cy="btn-edit"
            color="blue darken-1"
            text
            @click="$emit('on-aggiungi')"
        >
            Aggiungi
        </v-btn>
    </v-card-actions>
</template>

<script>
export default {
    data() {
        return {
            active: this.value,
        };
    },
    props: ["value", "sezioneAttiva"],
    computed: {
        errors() {
            return this.$store.state.formError || {};
        },
        errorsNumbers() {
            return this.$store.state.errorsNumbers;
        },
        isValid() {
            return this.$store.state.isValid;
        },
        mode() {
            return !this.$route.params.id ? "ADD_NEW_MODE" : "EDIT_MODE";
        },
    },
    methods: {
        changeHandler(value) {
            // console.log(value)
            this.$emit("input", value);
        },
    },
};
</script>

<style lang="scss" scoped></style>
