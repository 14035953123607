export const GenderDtoEnum = [
    {
        label: "Enum description",
        description: "Enum description",
        value: "Se il contraente della polizza è una ditta individuale, scegliere il sesso del professionista.",
    },
    {
        label: "Maschio",
        description: "Maschio",
        value: "Male",
    },
    {
        label: "Femmina",
        description: "Femmina",
        value: "Female",
    },
    {
        label: "Giuridica",
        description: "Persona giuridica",
        value: "Juridic",
    },
];
