<template>
    <div class="p-3">
        <h6>{{ options.label }}</h6>
        <vue-dropzone
            ref="myVueDropzone"
            id="dropzone"
            v-bind="$attrs"
            v-on="$listeners"
            :options="dropzoneOptions"
            @vdropzone-complete="afterUploadComplete"
        >
        </vue-dropzone>
        <!-- {{ options }} -->
    </div>
</template>
<!-- https://youtu.be/1yNytVVLF0Q?t=133 -->
<script>
// import { signature_requests_index,upload_document } from '@/services/YouSignService'
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
export default {
    name: "dropzone-upload",
    props: ["options"],

    data() {
        return {
            dialog: false,
            dropzoneOptions: {
                url: `${process.env.VUE_APP_API_URL}${this.options.url}?model=${this.options.model}&id=${this.options.model_id}&tag=${this.options.tag}&collection=${this.options.collection}`,
                thumbnailHeight: 130,
                paramName: "file_name",
                //   maxFilesize: this.options.maxFiles,
                maxFiles: this.options.maxFiles,
                // headers: { 'My-Awesome-Header': 'header value' },
                autoProcessQueue: true,
            },
        };
    },
    methods: {
        vdend(UploadFile) {
            this.$refs.MyDropzone.removeFile(UploadFile);
        },
        async afterUploadComplete(files, xhr, formData) {
            //alert("complete");
            console.log("afterUploadComplete", files, xhr, formData);
        },
        // # @vdropzone-sending-multiple="sendFiles"
        // async sendFiles(files, xhr, formData) {
        //   // console.log("send");
        //   // formData.append("tag", this.options.tag);
        //   // formData.append("collection", this.options.collection);
        // },
    },
    components: {
        vueDropzone: vue2Dropzone,
    },
};
</script>
<style>
.dropzone {
    height: 100px;
    width: 100%;
    border: 1px dashed #ccc;
    padding: 10px;
    text-align: center;
}

.dropzone .dz-preview {
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin: 0px;
    min-height: 100px;
}
</style>
