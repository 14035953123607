export default {
    // DASHBOARD
    dashboard: {
        cards: [
            { label: "PRODOTTI", route: "/prodotti" },
            { label: "APPENDICI", route: "/appendici" },
            {
                label: "TRACK GESTIONE DOCUMENTI",
                route: "/track_gestione_documenti",
            },
            { label: "ESTRATTO CONTO", route: "/estratto_conto" },
            { label: "PROVVIGIONALE", route: "/provvigionale" },
            { label: "GESTIONE INCASSI", route: "/gestione_incassi" },
            { label: "QUIETANZE", route: "/quietanze" },
            { label: "GESTIONE DOCUMENTI", route: "/gestione_documenti" },
        ],
    },

    // UI
    breadcrumbs: [
        {
            text: "Dashboard",
            disabled: false,
            href: "breadcrumbs_dashboard",
        },
        {
            text: "Link 1",
            disabled: false,
            href: "breadcrumbs_link_1",
        },
        {
            text: "Link 2",
            disabled: true,
            href: "breadcrumbs_link_2",
        },
    ],
    loading: false,
    notify: {
        message: "messaggio di prova",
        type: false,
    },

    sezioneAttiva: "Sezione attiva",
    edit_mode: undefined,
    add_mode: undefined,

    // FORM
    formError: {},
    errorsNumbers: 0,
    isValid: true,
    touch: false,

    // USER
    token: "",
    authenticated: false,
    authErrors: {},
    user: {},
    permissions: [],
    roles: [],

    // TODO: VUEX | Mettere tutto sotto un cappello | Esempio: collaboratore = {index,attivo,form ecc}

    collaboratori: [],
    collaboratoreAttivo: {},
    // collaboratoreFormError:{},

    clienti: [],
    clienteAttivo: {},
    // clienteFormError:{},

    polizze: [],
    polizzaAttivo: {},
    // polizzaFormError:{},

    compagnie: [],
    compagniaNuova: { attivo: false },
    compagniaAttivo: {},
    // TODO: IMPLEMENTARE QUALE USARE ?
    compagniaProdotti: [],

    fornitoriAutocomplete: [],

    /**
     * Reimposta categoria prodotto ogni volta che avviene un inserimento o cambia compagnia fornitore o compagnia contrattuale
     */
    default_categoria_prodotto: {
        nome: "",
        mandato: {
            percentuale_provvigione_attiva: "0",
            data_inizio_mandato: new Date().toJSON().slice(0, 10),
            mandatabile_type: "App\\Models\\CategoriaProdotto",
            compagnia_fornitore_id: null,
        },
    },
    compagnieContrattuali: [],
    compagniaContrattualeNuova: { attivo: false },
    compagniaContrattualeAttivo: {},

    // Autocomplete
    settoreAutocomplete: [],
};
