var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"model__form",attrs:{"id":"PolizzaForm"}},[_c('v-toolbar',{attrs:{"dense":""}},[_c('v-toolbar-title',[(_vm.polizza)?_c('v-card-title',{staticClass:"h4",attrs:{"primary-title":""}},[(!_vm.polizza.id)?_c('span',[_vm._v("Dati Polizza")]):_vm._e(),(_vm.polizza.id)?_c('span',[_vm._v(_vm._s("N°" + _vm.polizza.polizza_numero + " " + (_vm.polizza.cliente.label || "") + " " + (_vm.polizza.mandato_prodotto.label || "")))]):_vm._e()]):_vm._e()],1),_c('v-spacer'),_c('v-toolbar-items',[(_vm.ui.mode === 'EDIT_MODE')?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.edit(_vm.polizza)}}},[_vm._v("Aggiorna")]):_vm._e(),(_vm.ui.mode === 'ADD_NEW_MODE')?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.aggiungi(_vm.polizza)}}},[_vm._v("Aggiungi")]):_vm._e(),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.annulla()}}},[_vm._v("Annulla")])],1)],1),_c('v-card',{attrs:{"background-color":"white","loading":_vm.loading}},[_c('v-card-text',[_c('v-row',{attrs:{"align":"center","justify":"start"}},[_c('v-col',[_c('autocomplete',{staticClass:"flex-grow-1",attrs:{"loading":_vm.loading,"clearable":"","return-object":"","prepend-icon":"mdi-account","label":"Collaboratore","item-value":"id","item-text":"label","dense":"","resource":"/api/autocomplete/collaboratore","error-messages":_vm.errors.collaboratore_id,"error":_vm.errors.collaboratore_id &&
                            _vm.errors.collaboratore_id.length > 0},model:{value:(_vm.polizza.collaboratore),callback:function ($$v) {_vm.$set(_vm.polizza, "collaboratore", $$v)},expression:"polizza.collaboratore"}})],1),_c('v-col',[_c('autocomplete',{attrs:{"loading":_vm.loading,"disabled":_vm.loading,"clearable":"","prepend-icon":"mdi-account","label":"Contraente","item-value":"id","item-text":"label","return-object":"","dense":"","resource":"/api/autocomplete/cliente","error-messages":_vm.errors.cliente_id,"error":_vm.errors.cliente_id &&
                            _vm.errors.cliente_id.length > 0},model:{value:(_vm.polizza.cliente),callback:function ($$v) {_vm.$set(_vm.polizza, "cliente", $$v)},expression:"polizza.cliente"}})],1),_c('v-col',[_c('autocomplete',{attrs:{"loading":_vm.loading,"clearable":"","return-object":"","label":"Ramo","dense":"","item-value":"id","item-text":"label","resource":"/api/autocomplete/ramo"},on:{"change":_vm.updateRicercaMandatoProdottoPolizza},model:{value:(_vm.mandato_prodotto_filter.ramo),callback:function ($$v) {_vm.$set(_vm.mandato_prodotto_filter, "ramo", $$v)},expression:"mandato_prodotto_filter.ramo"}})],1),_c('v-col',[_c('autocomplete',{staticClass:"flex-grow-1",attrs:{"loading":_vm.loading,"clearable":"","return-object":"","prepend-icon":"mdi-document","label":"Compagnia Contrattuale","item-value":"id","item-text":"label","dense":"","error-messages":_vm.errors.compagnia_contrattuale_id,"error":_vm.errors.compagnia_contrattuale_id &&
                            _vm.errors.compagnia_contrattuale_id.length > 0,"resource":"/api/autocomplete/compagnia_contrattuale"},on:{"change":_vm.updateRicercaMandatoProdottoPolizza},model:{value:(
                            _vm.mandato_prodotto_filter.compagnia_contrattuale
                        ),callback:function ($$v) {_vm.$set(_vm.mandato_prodotto_filter, "compagnia_contrattuale", $$v)},expression:"\n                            mandato_prodotto_filter.compagnia_contrattuale\n                        "}})],1),_c('v-col',[_c('autocomplete',{staticClass:"flex-grow-1",attrs:{"loading":_vm.loading,"clearable":"","return-object":"","prepend-icon":"mdi-document","label":"Fornitore","item-value":"id","item-text":"label","dense":"","error-messages":_vm.errors.fornitore_id,"error":_vm.errors.fornitore_id &&
                            _vm.errors.fornitore_id.length > 0,"resource":"/api/autocomplete/fornitore"},on:{"change":_vm.updateRicercaMandatoProdottoPolizza},model:{value:(_vm.mandato_prodotto_filter.fornitore),callback:function ($$v) {_vm.$set(_vm.mandato_prodotto_filter, "fornitore", $$v)},expression:"mandato_prodotto_filter.fornitore"}})],1),_c('v-col',[_c('v-autocomplete',{staticClass:"flex-grow-1",attrs:{"return-object":"","loading":_vm.loading,"clearable":"","prepend-icon":"mdi-document","label":"Prodotto","item-value":"id","item-text":"label","error-messages":_vm.errors.mandato_prodotto_id,"error":_vm.errors.mandato_prodotto_id &&
                            _vm.errors.mandato_prodotto_id.length > 0,"dense":"","items":_vm.prodotti},model:{value:(_vm.polizza.mandato_prodotto),callback:function ($$v) {_vm.$set(_vm.polizza, "mandato_prodotto", $$v)},expression:"polizza.mandato_prodotto"}})],1)],1),_c('v-row',{attrs:{"align":"center","justify":"start"}},[_c('v-col',[_c('v-text-field',{attrs:{"id":"polizza_numero","data-cy":"polizza_numero","dense":"","type":"text","error-messages":_vm.errors.polizza_numero,"error":_vm.errors.polizza_numero &&
                            _vm.errors.polizza_numero.length > 0,"label":"Numero Polizza"},model:{value:(_vm.polizza.polizza_numero),callback:function ($$v) {_vm.$set(_vm.polizza, "polizza_numero", $$v)},expression:"polizza.polizza_numero"}})],1),_c('v-col',[_c('teo-date-picker',{attrs:{"label":"Entrata In Effetto","errors":_vm.errors.data_entrata_in_effetto},model:{value:(_vm.polizza.data_entrata_in_effetto),callback:function ($$v) {_vm.$set(_vm.polizza, "data_entrata_in_effetto", $$v)},expression:"polizza.data_entrata_in_effetto"}})],1),_c('v-col',[_c('v-autocomplete',{attrs:{"label":"Frazionamento","dense":"","clearable":"","autocomplete":false,"item-value":"slug","item-text":"label","return-object":"","items":_vm.frazionamenti,"error-messages":_vm.errors.frazionamento_slug,"error":_vm.errors.frazionamento_slug &&
                            _vm.errors.frazionamento_slug.length == 0},on:{"change":function($event){return _vm.updateFrazionameto()}},model:{value:(_vm.polizza.frazionamento),callback:function ($$v) {_vm.$set(_vm.polizza, "frazionamento", $$v)},expression:"polizza.frazionamento"}})],1),_c('v-col',[_c('teo-date-picker',{attrs:{"label":"Scadenza","errors":_vm.errors.data_scadenza},model:{value:(_vm.polizza.data_scadenza),callback:function ($$v) {_vm.$set(_vm.polizza, "data_scadenza", $$v)},expression:"polizza.data_scadenza"}})],1),_c('v-col',[_c('teo-date-picker',{attrs:{"label":"Scadenza Rata"},model:{value:(_vm.polizza.data_scadenza_rata),callback:function ($$v) {_vm.$set(_vm.polizza, "data_scadenza_rata", $$v)},expression:"polizza.data_scadenza_rata"}})],1),_c('v-col',[_c('v-checkbox',{attrs:{"label":"Tacito Rinnovo","id":"tacito_rinnovo","data-cy":"tacito_rinnovo","dense":"","color":"primary","error-messages":_vm.errors.tacito_rinnovo,"error":_vm.errors.tacito_rinnovo &&
                            _vm.errors.tacito_rinnovo.length > 0},on:{"input":function($event){return _vm.$emit('input', _vm.polizza)}},model:{value:(_vm.polizza.tacito_rinnovo),callback:function ($$v) {_vm.$set(_vm.polizza, "tacito_rinnovo", $$v)},expression:"polizza.tacito_rinnovo"}})],1)],1),_c('v-row',{attrs:{"align":"center","justify":"start"}},[_c('v-col',[_c('text-formatted',{attrs:{"label":"Imponibile (Totale netto)","id":"totale_premio_imponibile","data-cy":"totale_premio_imponibile","type":"number","dense":"","error-messages":_vm.errors.totale_premio_imponibile,"error":_vm.errors.totale_premio_imponibile &&
                            _vm.errors.totale_premio_imponibile.length > 0},on:{"change":_vm.getCalcoloTotalePremioLordo,"focus":function($event){return $event.target.select()}},model:{value:(_vm.polizza.totale_premio_imponibile),callback:function ($$v) {_vm.$set(_vm.polizza, "totale_premio_imponibile", $$v)},expression:"polizza.totale_premio_imponibile"}})],1),_c('v-col',[_c('text-formatted',{attrs:{"label":"Diritti","dense":"","color":"primary","error":_vm.errors.diritti && _vm.errors.diritti.length > 0,"error-messages":_vm.errors.diritti},on:{"change":_vm.getCalcoloTotalePremioLordo,"focus":function($event){return $event.target.select()}},model:{value:(_vm.polizza.diritti),callback:function ($$v) {_vm.$set(_vm.polizza, "diritti", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"polizza.diritti"}})],1),_c('v-col',[_c('text-formatted',{attrs:{"label":"Spese di Gestione","dense":"","color":"primary","error":_vm.errors.spese && _vm.errors.spese.length > 0,"error-messages":_vm.errors.spese},on:{"change":_vm.getCalcoloTotalePremioLordo,"focus":function($event){return $event.target.select()}},model:{value:(_vm.polizza.spese),callback:function ($$v) {_vm.$set(_vm.polizza, "spese", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"polizza.spese"}})],1),_c('v-col',[_c('text-formatted',{attrs:{"id":"totale_premio_imposte","data-cy":"totale_premio_imposte","type":"number","dense":"","error-messages":_vm.errors.totale_premio_imposte,"error":_vm.errors.totale_premio_imposte &&
                            _vm.errors.totale_premio_imposte.length > 0,"label":"Imposte"},on:{"change":_vm.getCalcoloTotalePremioLordo,"focus":function($event){return $event.target.select()}},model:{value:(_vm.polizza.totale_premio_imposte),callback:function ($$v) {_vm.$set(_vm.polizza, "totale_premio_imposte", $$v)},expression:"polizza.totale_premio_imposte"}})],1),_c('v-col',[_c('text-formatted',{attrs:{"label":"Competenze","dense":"","color":"primary","error":_vm.errors.competenze &&
                            _vm.errors.competenze.length > 0,"error-messages":_vm.errors.competenze},on:{"change":_vm.getCalcoloTotalePremioLordo,"focus":function($event){return $event.target.select()}},model:{value:(_vm.polizza.competenze),callback:function ($$v) {_vm.$set(_vm.polizza, "competenze", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"polizza.competenze"}})],1),_c('v-col',[_c('text-formatted',{attrs:{"id":"totale_premio_lordo","data-cy":"totale_premio_lordo","type":"number","readonly":true,"dense":"","error-messages":_vm.errors.totale_premio_lordo,"error":_vm.errors.totale_premio_lordo &&
                            _vm.errors.totale_premio_lordo.length > 0,"label":"Premio Lordo"},on:{"change":_vm.getCalcoloTotalePremioLordo,"focus":function($event){return $event.target.select()}},model:{value:(_vm.polizza.totale_premio_lordo),callback:function ($$v) {_vm.$set(_vm.polizza, "totale_premio_lordo", $$v)},expression:"polizza.totale_premio_lordo"}})],1),_c('v-col')],1),(false)?_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"id":"numero_polizza_sostituita","data-cy":"numero_polizza_sostituita","dense":"","type":"text","error-messages":_vm.errors.numero_polizza_sostituita,"error":_vm.errors.numero_polizza_sostituita &&
                            _vm.errors.numero_polizza_sostituita.length > 0,"label":"Numero Polizza Sostituita"},model:{value:(_vm.polizza.numero_polizza_sostituita),callback:function ($$v) {_vm.$set(_vm.polizza, "numero_polizza_sostituita", $$v)},expression:"polizza.numero_polizza_sostituita"}})],1)],1):_vm._e(),(false)?_c('v-row',[_c('v-col',{staticClass:"polizza__sx"},[(_vm.polizza)?_c('section',{staticClass:"v-main__wrap"},[_c('VTabs',{staticClass:"m-0 p-0 mt-3",attrs:{"height":"2rem","color":"primary","slider-color":"primary"},model:{value:(_vm.tabs_caratteristiche_polizza),callback:function ($$v) {_vm.tabs_caratteristiche_polizza=$$v},expression:"tabs_caratteristiche_polizza"}},[_c('VTab',{attrs:{"ripple":""}},[(
                                        _vm.mandato_prodotto_filter.ramo.id == 5
                                    )?_c('span',[_vm._v(" RCA ")]):_vm._e(),(
                                        _vm.mandato_prodotto_filter.ramo.id == 1
                                    )?_c('span',[_vm._v(" MULTIRISCHI ")]):_vm._e()]),_c('VTabItem',[_c('VCard',{attrs:{"flat":""}},[_c('VCardText',[(
                                                true ||
                                                _vm.mandato_prodotto_filter.ramo
                                                    .id == 5
                                            )?_c('div',{staticClass:"dati"},[_c('table',{},[_c('tr',[_c('td',[_c('v-checkbox',{attrs:{"dense":"","label":"Guida Esperta"}})],1),_c('td',[_c('v-checkbox',{attrs:{"dense":"","label":"Guida Esclusiva"}})],1),_c('td',[_c('v-checkbox',{attrs:{"dense":"","label":"Guida Cognugi"}})],1),_c('td',[_c('v-checkbox',{attrs:{"dense":"","label":"Antifurto Satellitare"}})],1)]),_c('tr',[_c('td',[_c('v-checkbox',{attrs:{"dense":"","label":"ARD"}})],1),_c('td',[_c('v-checkbox',{attrs:{"dense":"","label":"Iscrizione ASI"}})],1),_c('td',[_c('v-checkbox',{attrs:{"dense":"","label":"Iscrizione FMI"}})],1),_c('td',[_c('v-checkbox',{attrs:{"dense":"","label":"Cessione Contraenza"}})],1)])])]):_vm._e(),(
                                                _vm.mandato_prodotto_filter.ramo
                                                    .id == 1
                                            )?_c('div',{staticClass:"dati"},[_vm._v(" Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quisquam, eius dicta nulla a obcaecati earum praesentium beatae dolores velit dolor harum dolorum possimus qui quidem deleniti soluta repellat voluptas eum! ")]):_vm._e()])],1)],1),_c('v-tab',[_vm._v(" Convenzioni ")]),_c('v-tab-item',[_c('h6',[_vm._v("Convenzioni")]),_vm._v(" Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus dolorem laborum vel, deserunt, consequuntur sit pariatur commodi voluptatibus repellendus vero accusamus, quo sunt officiis ut cupiditate sed nulla vitae nobis? ")])],1)],1):_vm._e()]),_c('v-col',{staticClass:"polizza__dx"},[_c('v-row',{staticClass:"pt-0 px-0"},[_c('v-data-table',{attrs:{"items":_vm.garanzie,"headers":_vm.garanzieHeader}})],1),_c('section',{attrs:{"id":"tab_provvigionale"}},[_c('polizza-tab-provvigionale',{model:{value:(_vm.polizza.titolo_attivo),callback:function ($$v) {_vm.$set(_vm.polizza, "titolo_attivo", $$v)},expression:"polizza.titolo_attivo"}})],1),_c('section',{attrs:{"id":"titoli_fatture"}},[_c('VTabs',{staticClass:"m-0 p-0 mt-3",attrs:{"height":"2rem","color":"primary","dense":"","slider-color":"primary"},model:{value:(
                                _vm.tabs_titoli_quietanze_appendici_fatture_legate_titoli
                            ),callback:function ($$v) {
                                _vm.tabs_titoli_quietanze_appendici_fatture_legate_titoli
                            =$$v},expression:"\n                                tabs_titoli_quietanze_appendici_fatture_legate_titoli\n                            "}},[_c('VTab',{attrs:{"ripple":""}},[_vm._v(" titoli quietanze appendici ")]),_c('VTab',{attrs:{"ripple":""}},[_vm._v(" fatture legate titoli ")]),_c('VTabItem',[_c('v-data-table',{attrs:{"items":_vm.titoli,"headers":_vm.titoli_headers,"dense":""}})],1),_c('VTabItem',[_vm._v(" fatture ")])],1)],1)],1)],1):_vm._e()],1)],1),(_vm.ui.mode == 'EDIT_MODE')?_c('section',_vm._l((_vm.uploads),function(zone,index){return _c('div',{key:index},[_c('dropzone-upload',{attrs:{"options":zone}})],1)}),0):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }