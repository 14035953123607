/**
 * il box di firma ha questi stati
 * disattivato   Non viene visualizzato
 * pronto_per_la_firma      Si può scegliere il tipo di firma per il documento
 * 
 * in_attesa_di_firma       La firma OTP viene inviata 
 * concluso_con_successo    YS ha confermato l'avvenuta firma
 * @param {*} quotazione json della quotazione
 * {
	"table": "stato_quotazione",
	"rows":
	[
		{
			"id": 1,
			"nome": "Bozza"
		},
		{
			"id": 2,
			"nome": "In attesa di deroga"
		},
		{
			"id": 3,
			"nome": "Quotazione approvata"
		},
		{
			"id": 4,
			"nome": "In attesa compilazione form"
		},
		{
			"id": 5,
			"nome": "Preventivo - in attesa documentazione"
		},
		{
			"id": 6,
			"nome": "Preventivo - in attesa di verifica",
		},
		{
			"id": 7,
			"nome": "In attesa di emissione"
		},
		{
			"id": 8,
			"nome": "Richiesta emissione"
		},
		{
			"id": 9,
			"nome": "In attesa di firma"
		},
		{
			"id": 10,
			"nome": "Conclusa con successo"
		},
		{
			"id": 11,
			"nome": "Conclusa con errore"
		}
	]
}
 */
export function boxFirmaStato(quotazione) {
    if (quotazione.stato_quotazione) {
        /** La quotazione è stata inviata per la firma */
        if (quotazione.last_signature_request) {
            // console.log("last_signature_request", quotazione.last_signature_request.response.status);
            switch (quotazione.last_signature_request.response.status) {
                case "done":
                    return {
                        slug: "documenti_firmati",
                        label: "Documenti Firmati",
                        description: "",
                        type: "success",
                    };

                case "draft":
                    return {
                        slug: "firma_stato_bozza",
                        label: "Firma in Bozza",
                        description:
                            "la richiesta di firma è in stato di bozza",
                        type: "warning",
                    };

                case "ongoing":
                    return {
                        slug: "firma_inviata",
                        label: "Documenti inviati per la firma",
                        description: "",
                        type: "warning",
                    };
            }
        }

        const stato_id = quotazione.stato_quotazione.id;
        // console.log(quotazione.polizza?.polizza);
        if ([1, 2, 3, 4, 5, 6, 7].includes(stato_id)) {
            return {
                slug: "disattivato",
                label: "",
                type: "",
            };
        }

        /**  
    "id": 9,
    "nome": "In attesa di firma"
    */
        if ([9].includes(quotazione.stato_quotazione.id)) {
            return {
                slug: "pronto_per_la_firma",
                label: "Documenti pronti per essere firmati",
                type: "warning",
            };
        }
    }

    return {
        slug: "nessuno_stato",
        label: "",
        type: "",
    };
}

export function boxFirmaMedia(quotazione) {
    // const quotazione = quotazione
    quotazione;
    return [];
}
