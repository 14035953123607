export const QuixaCoverage = [
    {
        code: "P01",
        description: "Responsabilita Civile",
        PackageCode: "P94",
        Requires: [],
        Excludes: [],
        VehicleCategory: ["Auto", "MotoLess50cc", "MotoMore50cc"],
        AvailableForAuto: "Y",
        AvailableForMoto: "Y",
        HasMassimale: "S",
        Massimale: {
            Auto: [
                {
                    value: "6000000",
                    label: "Persone 6.450.000 - Cose 1.300.000",
                },
                {
                    value: "12000000",
                    label: "Persone 10.000.000 - Cose 2.000.000",
                },
                {
                    value: "18000000",
                    label: "Persone 15.000.000 - Cose 3.000.000",
                },
                {
                    value: "25000000",
                    label: "Persone 20.000.000 - Cose 5.000.000",
                },
            ],
            MotoMore50cc: [
                {
                    value: "6000000",
                    label: "Persone 6.450.000 - Cose 1.300.000",
                },
                {
                    value: "12000000",
                    label: "Persone 10.000.000 - Cose 2.000.000",
                },
            ],
            MotoLess50cc: [
                {
                    value: "6000000",
                    label: "Persone 6.450.000 - Cose 1.300.000",
                },
                {
                    value: 12000000,
                    label: "Persone 10.000.000 - Cose 2.000.000",
                },
            ],
        },
        HasScopertoMinimo: "N",
    },
    {
        code: "P03",
        description: "Incendio",
        PackageCode: "P93",
        Requires: ["P04"],
        Excludes: [],
        VehicleCategory: ["Auto", "MotoLess50cc", "MotoMore50cc"],
        AvailableForAuto: "S",
        AvailableForMoto: "S",
        HasMassimale: "N",
        Massimale: "VEHICLE_CUSTOM_VALUE",
        HasScopertoMinimo: "N",
    },
    {
        code: "P04",
        description: "Furto",
        PackageCode: "P93",
        Requires: ["P03"],
        Excludes: [],
        VehicleCategory: ["Auto", "MotoLess50cc", "MotoMore50cc"],
        AvailableForAuto: "S",
        AvailableForMoto: "S",
        HasMassimale: "N",
        Massimale: "VEHICLE_CUSTOM_VALUE",
        HasScopertoMinimo: "N",
    },
    {
        code: "P05",
        description: "Cristalli",
        PackageCode: "P93",
        Requires: ["P03", "P04"],
        Excludes: [],
        VehicleCategory: ["Auto"],
        AvailableForAuto: "S",
        AvailableForMoto: "N",
        HasMassimale: "N",
        Massimale: "VEHICLE_CUSTOM_VALUE",
        HasScopertoMinimo: "S",
    },
    {
        code: "P14",
        description: "Kasko",
        PackageCode: null,
        Requires: ["P03", "P04"],
        Excludes: ["P50", "P51"],
        VehicleCategory: ["Auto"],
        AvailableForAuto: "S",
        AvailableForMoto: "N",
        HasMassimale: "N",
        Massimale: "VEHICLE_CUSTOM_VALUE",
        HasScopertoMinimo: "S",
    },
    {
        code: "P25",
        description: "Atti vandalici",
        PackageCode: null,
        Requires: ["P03", "P04", "P32", "P05", "P51"],
        Excludes: [],
        VehicleCategory: ["Auto"],
        AvailableForAuto: "S",
        AvailableForMoto: "N",
        HasMassimale: "N",
        Massimale: "VEHICLE_CUSTOM_VALUE",
        HasScopertoMinimo: "S",
    },
    {
        code: "P32",
        description: "Eventi naturali",
        PackageCode: "P93",
        Requires: ["P03", "P04", "P05"],
        Excludes: [],
        VehicleCategory: ["Auto"],
        AvailableForAuto: "S",
        AvailableForMoto: "N",
        HasMassimale: "N",
        Massimale: "VEHCLE_CUSTOM_VALUE",
        HasScopertoMinimo: "S",
    },
    {
        code: "P50",
        description: "Veicoli non assicurati",
        PackageCode: "P91",
        Requires: [],
        Excludes: ["P14"],
        VehicleCategory: ["Auto"],
        AvailableForAuto: "Y",
        AvailableForMoto: "N",
        HasMassimale: "N",
        HasScopertoMinimo: "N",
    },
    {
        code: "P51",
        description: "Collisione",
        PackageCode: null,
        Requires: [],
        Excludes: ["P14"],
        VehicleCategory: ["Auto"],
        AvailableForAuto: "Y",
        AvailableForMoto: "N",
        HasMassimale: "N",
        HasScopertoMinimo: "N",
    },
    {
        code: "P52",
        description: "Soccorso Stradale Base",
        PackageCode: "P91",
        Requires: [],
        Excludes: ["P53"],
        VehicleCategory: ["Auto", "MotoLess50cc", "MotoMore50cc"],
        AvailableForAuto: "S",
        AvailableForMoto: "S",
        HasMassimale: "N",
        HasScopertoMinimo: "N",
    },
    {
        code: "P53",
        description: "Soccorso Stradale Top",
        PackageCode: "P91",
        Requires: [],
        Excludes: ["P52"],
        VehicleCategory: ["Auto"],
        AvailableForAuto: "S",
        AvailableForMoto: "N",
        HasMassimale: "N",
        HasScopertoMinimo: "N",
    },
    {
        code: "P49",
        description: "QuixaBox",
        PackageCode: null,
        Requires: ["P52", "P53"],
        Excludes: [],
        VehicleCategory: ["Auto"],
        AvailableForAuto: "S",
        AvailableForMoto: "N",
        HasMassimale: "N",
        HasScopertoMinimo: "N",
    },
    {
        code: "P13",
        description: "Tutela Legale",
        PackageCode: "P92",
        Requires: [],
        Excludes: [],
        VehicleCategory: ["Auto", "MotoLess50cc", "MotoMore50cc"],
        AvailableForAuto: "S",
        AvailableForMoto: "S",

        HasMassimale: "S",
        Massimale: {
            Auto: [
                {
                    value: "15000",
                    label: "15.000 €",
                },
            ],
            MotoMore50cc: [
                {
                    value: "15000",
                    label: "15.000 €",
                },
            ],
        },
        HasScopertoMinimo: "N",
    },
    {
        code: "P40",
        description: "Zero rivalsa in caso di ebbrezza",
        PackageCode: null,
        Requires: [],
        Excludes: [],
        VehicleCategory: ["Auto"],
        AvailableForAuto: "Y",
        AvailableForMoto: "N",
        HasMassimale: "N",
        HasScopertoMinimo: "N",
    },
    {
        code: "P30",
        description: "Infortuni Conducente",
        PackageCode: null,
        Requires: [],
        Excludes: [],
        VehicleCategory: ["Auto"],
        AvailableForAuto: "N",
        AvailableForMoto: "N",
        HasMassimale: "S",
        Massimale: {
            Auto: [
                {
                    value: "100000",
                    label: "100.000 €",
                },
            ],
            MotoMore50cc: [
                {
                    value: "100000",
                    label: "100.000 €",
                },
            ],
        },
        HasScopertoMinimo: "N",
    },
    {
        code: "P42",
        description: "Perdita chiavi",
        PackageCode: null,
        Requires: [],
        Excludes: [],
        VehicleCategory: ["Auto"],
        AvailableForAuto: "S",
        AvailableForMoto: "N",
        HasMassimale: "N",
        HasScopertoMinimo: "N",
    },
];
