<template>
    <div>
        <VTabs
            v-if="formData"
            height="2rem"
            class="m-0 p-0 mt-3"
            dense
            v-model="tab"
            color="primary"
            slider-color="primary"
        >
            <VTab ripple class="p-2 m-0"> Calcolo Premio </VTab>
            <VTabItem class="p-0 m-0">
                <v-row class="calcolo_provvigionale__row">
                    <v-col>
                        <v-autocomplete
                            id="tipo_pagamento"
                            dense
                            clearable
                            label="Tipo Pagamento"
                            data-cy="tipo_pagamento"
                            item-value="id"
                            item-text="label"
                            v-model="formData.tipo_pagamento"
                            :error-messages="errors.tipo_pagamento"
                            :error="
                                errors.tipo_pagamento &&
                                errors.tipo_pagamento.length > 0
                            "
                            :items="tipologie_pagamento"
                        />
                    </v-col>
                    <v-col>
                        <text-formatted
                            label="Totale Netto"
                            v-model.trim="formData.totale_netto"
                            dense
                            color="primary"
                            :error="
                                errors.totale_netto &&
                                errors.totale_netto.length > 0
                            "
                            :error-messages="errors.totale_netto"
                        />
                    </v-col>
                    <v-col>
                        <text-formatted
                            label="Diritti"
                            v-model.trim="formData.diritti"
                            dense
                            color="primary"
                            :error="errors.diritti && errors.diritti.length > 0"
                            :error-messages="errors.diritti"
                        />
                    </v-col>
                    <v-col>
                        <text-formatted
                            label="Accessori"
                            v-model.trim="formData.accessori"
                            dense
                            color="primary"
                            :error="
                                errors.accessori && errors.accessori.length > 0
                            "
                            :error-messages="errors.accessori"
                        />
                    </v-col>
                    <v-col>
                        <text-formatted
                            label="Spese"
                            v-model.trim="formData.spese"
                            dense
                            color="primary"
                            :error="errors.spese && errors.spese.length > 0"
                            :error-messages="errors.spese"
                        />
                    </v-col>
                    <v-col>
                        <text-formatted
                            label="Imposte"
                            v-model.trim="formData.imposte"
                            dense
                            color="primary"
                            :error="errors.imposte && errors.imposte.length > 0"
                            :error-messages="errors.imposte"
                        />
                    </v-col>
                    <v-col>
                        <text-formatted
                            label="SSN"
                            v-model.trim="formData.ssn"
                            dense
                            color="primary"
                            :error="errors.ssn && errors.ssn.length > 0"
                            :error-messages="errors.ssn"
                        />
                    </v-col>
                </v-row>
                <v-row class="calcolo_provvigionale__row">
                    <v-col>
                        <text-formatted
                            label="Premio Polizza"
                            v-model.trim="formData.premio_polizza"
                            dense
                            color="primary"
                            :error="
                                errors.premio_polizza &&
                                errors.premio_polizza.length > 0
                            "
                            :error-messages="errors.premio_polizza"
                        />
                    </v-col>
                    <v-col>
                        <text-formatted
                            label="Spese Emissione"
                            v-model.trim="formData.spese_emissione"
                            dense
                            color="primary"
                            :error="
                                errors.spese_emissione &&
                                errors.spese_emissione.length > 0
                            "
                            :error-messages="errors.spese_emissione"
                        />
                    </v-col>
                    <v-col>
                        <text-formatted
                            label="Competenze"
                            v-model.trim="formData.Competenze"
                            dense
                            color="primary"
                            :error="
                                errors.Competenze &&
                                errors.Competenze.length > 0
                            "
                            :error-messages="errors.Competenze"
                        />
                    </v-col>

                    <v-col>
                        <text-formatted
                            label="Valore Ritenuta"
                            v-model.trim="formData.valore_ritenuta"
                            dense
                            color="primary"
                            :error="
                                errors.valore_ritenuta &&
                                errors.valore_ritenuta.length > 0
                            "
                            :error-messages="errors.valore_ritenuta"
                        />
                    </v-col>

                    <v-col>
                        <text-formatted
                            label="Totale Cliente"
                            v-model.trim="formData.totale_cliente"
                            dense
                            color="primary"
                            :error="
                                errors.totale_cliente &&
                                errors.totale_cliente.length > 0
                            "
                            :error-messages="errors.totale_cliente"
                        />
                    </v-col>

                    <v-col>
                        <text-formatted
                            label="totale_netto"
                            v-model.trim="formData.totale_netto"
                            dense
                            color="primary"
                            :error="
                                errors.totale_netto &&
                                errors.totale_netto.length > 0
                            "
                            :error-messages="errors.totale_netto"
                        />
                    </v-col>
                </v-row>
            </VTabItem>

            <VTab ripple class="p-2 m-0">
                Calcolo Provv. Su Netto e Competenze
            </VTab>
            <!-- v-model.trim="formData.perc_provvigioni_attive" -->
            <VTabItem class="p-0 m-0">
                <v-row class="calcolo_provvigionale__row">
                    <v-col>
                        <text-formatted
                            label="% Broker"
                            :value="
                                polizza.mandato_prodotto
                                    .percentuale_provvigione_attiva
                            "
                            dense
                            color="primary"
                            :error="
                                errors.perc_broker &&
                                errors.perc_broker.length > 0
                            "
                            :error-messages="errors.perc_broker"
                        />
                    </v-col>
                    <v-col>
                        <text-formatted
                            label="€ Broker"
                            v-model.trim="formData.diritti"
                            dense
                            color="primary"
                            :error="errors.diritti && errors.diritti.length > 0"
                            :error-messages="errors.diritti"
                        />
                    </v-col>
                    <v-col>
                        <text-formatted
                            label="% Competenze Broker"
                            v-model.trim="formData.accessori"
                            dense
                            color="primary"
                            :error="
                                errors.accessori && errors.accessori.length > 0
                            "
                            :error-messages="errors.accessori"
                        />
                    </v-col>
                    <v-col>
                        <text-formatted
                            label="€ Competenze Broker"
                            v-model.trim="formData.spese"
                            dense
                            color="primary"
                            :error="errors.spese && errors.spese.length > 0"
                            :error-messages="errors.spese"
                        />
                    </v-col>
                    <v-col>
                        <text-formatted
                            label="Calcolate su"
                            v-model.trim="formData.imposte"
                            dense
                            color="primary"
                            :error="errors.imposte && errors.imposte.length > 0"
                            :error-messages="errors.imposte"
                        />
                    </v-col>
                </v-row>
                <v-row class="calcolo_provvigionale__row">
                    <v-col>
                        <text-formatted
                            label="% Collaboratore"
                            v-model.trim="formData.premio_polizza"
                            dense
                            color="primary"
                            :error="
                                errors.premio_polizza &&
                                errors.premio_polizza.length > 0
                            "
                            :error-messages="errors.premio_polizza"
                        />
                    </v-col>

                    <v-col>
                        <text-formatted
                            label="€ Collaboratore"
                            v-model.trim="formData.competenze"
                            dense
                            color="primary"
                            :error="
                                errors.competenze &&
                                errors.competenze.length > 0
                            "
                            :error-messages="errors.competenze"
                        />
                    </v-col>

                    <v-col>
                        <text-formatted
                            label="% Competenze Collaboratore"
                            v-model.trim="
                                formData.perc_competenze_collaboratore
                            "
                            dense
                            color="primary"
                            :error="
                                errors.perc_competenze_collaboratore &&
                                errors.perc_competenze_collaboratore.length > 0
                            "
                            :error-messages="
                                errors.perc_competenze_collaboratore
                            "
                        />
                    </v-col>

                    <v-col>
                        <text-formatted
                            label="€ Competenze Collaboratore"
                            v-model.trim="formData.competenze"
                            dense
                            color="primary"
                            :error="
                                errors.competenze &&
                                errors.competenze.length > 0
                            "
                            :error-messages="errors.competenze"
                        />
                    </v-col>

                    <v-col>
                        <text-formatted
                            label="Per un totale di"
                            v-model.trim="formData.collaboratore_totale"
                            dense
                            color="primary"
                            :error="
                                errors.collaboratore_totale &&
                                errors.collaboratore_totale.length > 0
                            "
                            :error-messages="errors.collaboratore_totale"
                        />
                    </v-col>
                </v-row>
            </VTabItem>
        </VTabs>
    </div>
</template>

<script>
// import { mapGetters, mapState } from 'vuex';
import textFormatted from "../ui/form/text-formatted.vue";
export default {
    components: { textFormatted },
    props: ["value", "polizza"],
    data() {
        return {
            tab: 1,
            formData: {},
            errors: {},
        };
    },
    watch: {
        formData(newValue) {
            this.$emit("input", newValue);
            console.log("input", newValue);
        },
    },
};
</script>

<style lang="scss" scoped></style>
