export const tipologieAnia = [
    {
        codice: "A",
        nome: "Autovettura",
    },
    {
        codice: "B",
        nome: "Autobus",
    },
    {
        codice: "C",
        nome: "Autocarro",
    },
    {
        codice: "M",
        nome: "Motocicli",
    },
    {
        codice: "Q",
        nome: "Motocarri",
    },
    {
        codice: "R",
        nome: "Rimorchi",
    },
    {
        codice: "S",
        nome: "Macchine Agricole",
    },
    {
        codice: "T",
        nome: "Macchine Operatrici",
    },
    {
        codice: "W",
        nome: "Ciclomotori",
    },
    {
        codice: "Z",
        nome: "Quadriciclo",
    },
];

export const tipologieTarga = [
    {
        nome: "Definitiva cee",
        codice: "N",
    },
    {
        nome: "Prova",
        codice: "R",
    },
    {
        nome: "Targhe Speciali",
        codice: "S",
    },
    {
        nome: "Definitiva Vecchia",
        codice: "V",
    },
];
