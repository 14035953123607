<template>
    <section id="LayoutBase">
        <v-navigation-drawer
            v-model="drawer"
            :src="require('./assets/bg-aurum.png')"
            app
        >
            <SidebarNav></SidebarNav>
        </v-navigation-drawer>
        <v-app-bar
            app
            color="white"
            :src="require('./assets/bg-aurum.png')"
            class="pr-5"
            light
        >
            <v-app-bar-nav-icon class="pl-3" @click="drawer = !drawer">
            </v-app-bar-nav-icon>
            <breakpoint-debug :debug="true" />

            <v-img
                class="header__ logo"
                :src="require('./assets/logo-header.png')"
                max-height="73"
                max-width="122"
                contain
            >
            </v-img>
            <!-- NAV_BAR -->
            <!-- TODO: inserire il mega menu -->
            <!-- NAV_BAR -->

            <v-spacer></v-spacer>

            <v-menu bottom min-width="200px" rounded offset-y>
                <template v-if="user" v-slot:activator="{ on }">
                    <v-btn icon x-large v-on="on">
                        <v-avatar data-cy="avatar" color="secondary" size="48">
                            <span class="white--text text-h5">{{
                                user.initials
                            }}</span>
                        </v-avatar>
                    </v-btn>
                    <div class="px-5">
                        <div class="white--text m-1" data-cy="user.name">
                            {{ user.name }}
                        </div>
                    </div>
                    {{ user }}
                </template>
                <v-card>
                    <v-list-item-content class="justify-center">
                        <div class="mx-auto text-center">
                            <section v-if="user">
                                <v-avatar color="indigo">
                                    <span class="white--text text-h5">{{
                                        user.initials
                                    }}</span>
                                </v-avatar>
                                <h3>{{ user.name }}</h3>
                                <p class="text-caption mt-1">
                                    {{ user.email }}
                                </p>
                            </section>
                            <v-divider class="my-2"></v-divider>
                            <v-btn depressed rounded text> Edit Account </v-btn>
                            <v-divider class="my-2"></v-divider>
                            <v-btn
                                data-cy="logOutClicked"
                                @click="logOutClicked"
                                depressed
                                rounded
                                text
                            >
                                Esci
                            </v-btn>
                        </div>
                    </v-list-item-content>
                </v-card>
            </v-menu>
        </v-app-bar>

        <!-- MAIN_CONTENT -->

        <v-main class="mt-0">
            <v-toolbar color="primary" dark flat>
                <v-toolbar-title>
                    <!-- <v-icon @click="historyBack()">indietro</v-icon> -->
                    <v-icon>{{ $route.meta.icon }}</v-icon>
                    <span>
                        {{ $route.meta.title }}
                    </span>
                </v-toolbar-title>

                <v-spacer></v-spacer>

                <!-- <v-btn icon>
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
        <v-btn icon>
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn> -->
            </v-toolbar>
            <v-card
                :elevation="0"
                class="application_card"
                :src="require('./assets/bg-aurum.png')"
            >
                <!-- BREAD_CRUMB -->
                <v-card-actions v-if="false">
                    sono il sottotitolo
                    <v-breadcrumbs :items="breadcrumbs" divider="/">
                    </v-breadcrumbs>

                    <v-spacer></v-spacer>
                </v-card-actions>
                <!-- / BREAD_CRUMB -->
                <v-card-text class="application_card__text mt-5">
                    <section class="application_slot_main">
                        <!-- 
            <v-snackbar v-model="notifySnackbar" center>
              {{ notify }}
              <template v-slot:action="{ attrs }">
                <v-btn
                  color="red"
                  text
                  v-bind="attrs"
                  @click="snackbar = false">
                  chiudi
                </v-btn>
              </template>
            </v-snackbar> 
            -->
                        <router-view></router-view>
                    </section>
                </v-card-text>
            </v-card>
        </v-main>
    </section>
</template>

<script>
import BreakpointDebug from "./components/BreakpointDebug.vue";
import SidebarNav from "./components/ui/SidebarNav.vue";

export default {
    name: "layout-base",
    props: ["breadcrumbs", "sezione"],
    data: () => ({
        drawer: true,
        notifySnackbar: true,
    }),
    components: {
        SidebarNav,
        BreakpointDebug,
    },
    methods: {
        historyBack() {
            this.$router.back();
        },
        logOutClicked() {
            this.$store.dispatch("ACTION_LOGOUT");
        },
    },
    computed: {
        user() {
            //return window.sessionStorage['roles'];
            return this.$store.state.user;
        },
        notify() {
            return this.$store.state.notify;
        },
        env() {
            return process.env.VUE_APP_API_URL;
        },
    },
};
</script>
<style>
.v-application {
    background-color: transparent !important;
}

.header__section {
    font-weight: 100;
    color: #ffffff;
    font-size: 1.5rem !important;
    margin: 1rem 5rem 1rem 1rem;
}

.v-card__title.header__card_section {
    display: flex;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    background-color: var(--primary);
    margin-bottom: 0 !important;
    padding-bottom: 0;
}

.header-fixed.toolbar-fixed .wrapper {
    padding-top: calc(49px + var(--kt-toolbar-height));
}

.theme--light.v-card {
    background-color: #fff0;
    color: rgba(0, 0, 0, 0.87);
}

.header__,
logo {
    color: rgba(0, 0, 0, 0.87);
    margin-right: 5rem;
    /* font-size: 1rem; */
}

.mega__menu {
    padding: 1rem !important;
    /* position: absolute; */
    z-index: 105;
    position: fixed;
    inset: 0px auto auto 0px;
    margin: 0px;
    transform: translate(0px, 65px);
    left: -1.5rem !important;
    right: 4.5rem !important;
}

div#kt_content_container {
    background-color: #97b8f100;
    /* background-image: url(../logo-800.png); */
    background-image: url("~@/assets/logo-800.png");
    background-position: 90% 0%;
    background-size: 30%;
}

.v-card__text.application_card__text {
    padding-top: 0 !important;
}

section.application_slot_main {
    padding: 0rem;
    margin-top: 0rem;
    padding-top: 0rem;
    background-color: white;
    border-radius: 0.25rem;
}

div#CaricamentoPolizzaApp section.application_slot_main,
div.no-margin-top section.application_slot_main {
    padding-top: 0;
}

.v-dialog.v-dialog--active main.v-main {
    background: red;
    padding: 0 !important;
    margin: 0;
}
</style>
