<template>
    <v-container fluid key="QuotazioneRCAForm" class="px-5">
        <FastQuotationStepperForm v-model="model"></FastQuotationStepperForm>
    </v-container>
    <!-- @update:model="handleModelUpdate" -->
</template>

<script>
import { validationMixin } from "@/v-form-base/form-common-validator";
import { FastQuotationFlowDefaultModel } from "@/v-form-base/form-common";
import { mixinRcaTabs } from "./mixins/rcaTabs";
import FastQuotationStepperForm from "./FastQuotationStepperForm.vue";
import { Api } from "@/http_tools/axiosModule";

export default {
    name: "quotazione-rca-form",
    mixins: [validationMixin, mixinRcaTabs],
    components: {
        FastQuotationStepperForm,
    },

    data() {
        return {
            model: {
                request: {},
            },
        };
    },

    methods: {
        /**
         * Inizializza il modello con i valori di default
         *
         */
        async initializeModel() {
            try {
                if (this.$route.name === "quotazione_rca_store") {
                    const defaultModel = await FastQuotationFlowDefaultModel();
                    this.model.quotation_request = JSON.parse(
                        JSON.stringify(defaultModel)
                    );
                }

                if (this.$route.name === "quotazione_rca_edit") {
                    const id = this.$route.params.id;
                    const response = await Api.get(
                        `${process.env.VUE_APP_API_URL}/api/quixa/FastQuotationFlow/${id}`
                    );
                    // Aggiorna il model con i dati ricevuti
                    this.model = response.data.data;
                    this.model.quotation_request = JSON.parse(
                        JSON.stringify(FastQuotationFlowDefaultModel)
                    );
                }
            } catch (error) {
                console.error(
                    "Errore nell'inizializzazione del modello:",
                    error
                );
                this.model.quotation_request = {};
            }
        },
    },

    async mounted() {
        await this.initializeModel();
    },

    watch: {
        // Osserva i cambiamenti del modello per evitare riferimenti circolari
        model: {
            handler(newVal) {
                try {
                    // Verifica che il modello sia serializzabile
                    JSON.stringify(newVal);
                } catch (error) {
                    console.error(
                        "Struttura circolare rilevata nel modello:",
                        error
                    );
                    // Puoi gestire qui la pulizia del modello se necessario
                }
            },
            deep: true,
        },
    },
};
</script>
<style scoped>
.fixed-box-parent {
    position: relative;
}

.fixed-box {
    /* height: -webkit-fill-available; */
    min-height: 25rem;
    background-color: #f0f0f0;
    display: flex;
    align-items: start;
    justify-content: start;
    /* position: fixed; */
    top: 8rem;
    right: 20px;
    width: inherit;
    display: none;
}

.v-input .v-label {
    color: black;
    font-weight: bold;
    color: red !important;
}

.v-tab-label-step {
    background-color: aqua;
}

.numbered-icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    /* Adjust width */
    height: 24px;
    /* Adjust height */
    border-radius: 50%;
    background-color: var(--v-primary-base);
    /* Use primary color */
    color: white;
    /* Text color */
    font-size: 14px;
    /* Adjust text size */
    font-weight: bold;
}

/* 
.v-tab.v-tab--active {
    background-color: #5d3a6f !important;
    color: white;
    border-radius: 0rem 2rem 0rem 0rem;
} */

/* .v-tabs--grow > .v-tabs-bar .v-tab {
    border: 1px solid gray;

    color: white;
    border-radius: 0rem 2rem 0rem 0rem; 
} 
*/
/* .tab-with-arrow {
    display: flex;
    justify-content: space-between;
} */

/* .arrow-right {
    width: 0;
    height: 0;
    border-top: 40px solid transparent;
    border-bottom: 40px solid transparent;
    border-left: 40px solid rgb(255, 255, 255); 
    border-left: 40px solid var(--v-primary-base);
} */

.tab-with-arrow.v-tab.v-tab--active {
    width: auto;
    max-width: fit-content;
    background-color: #8a2be22e;
}
</style>
