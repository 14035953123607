<template>
    <v-container fluid>
        <table-component
            fixed-header
            :id="model.toLowerCase() + '_elenco'"
            :resource="polizza_index"
            :config="model.toLowerCase() + '-table'"
            :toolbar-title="models"
            :loading="loading"
            loading-text="attendi un attimo"
            @on-edit="onEdit($event)"
            @on-delete="onDelete($event)"
            @on-add-new-item="onAddNewItem($event)"
            :items-per-page="itemsPerPage"
        >
        </table-component>
    </v-container>
</template>
<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
import TableComponent from "../ui/TableComponent.vue";

const model = "Polizza";
const models = "Polizze";

export default {
    //  name: model.toLowerCase+"-page",
    components: { TableComponent },
    data() {
        return {
            model: model,
            models: models,
            /** @var selectedItems elementi selezionati della tabella passati tramite un evento */
            selectedItems: [],
        };
    },
    mounted() {
        this.$store.dispatch("PolizzaModule/index");
    },
    methods: {
        onEdit(itemToEdit) {
            this.$router.push("/modifica/" + model + "/" + itemToEdit.id);
        },
        /** ADD_NEW */
        onAddNewItem() {
            this.$router.push("/new/" + model.toLowerCase());
        },
        afterItemCreated() {},
        onAnnulla() {
            this.dialog = false;
        },
        onDelete(evt) {
            console.log("onDelete", evt);
            this.delete(evt);
        },
        ...mapActions("PolizzaModule", ["delete"]),
    },
    computed: {
        ...mapFields("PolizzaModule", ["ui", "polizza_index", "loading"]),
    },
};
</script>
